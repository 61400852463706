import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { activateFeedback } from "../../redux/actions";
import useRequest from "../../hooks/useRequest";
import Preloader from "../components/preloader";
import { MultiLang } from "../MultiLang";

const WithdrawMoney = ({ setPaymentMethod }) => {
    const { loading, user } = useSelector(state => state.Auth)
    const { settings } = useSelector(state => state.Site)
    const { language } = useSelector((state) => state.reducer);

    const [showModel, setShowModel] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { request, response } = useRequest()

    const formik = useFormik({
        initialValues: {
            withdraw_amount: "",
            bank_name: "",
            account_holder_name: "",
            routing_no: "",
            ifsc_code: "",
            account_no: ""
        },
        validationSchema: Yup.object({
            withdraw_amount: Yup.number()
                .required('Please Enter Amount')
                .min(+settings?.amount, `Min Amount must be ${+settings?.amount}€`)
                .max(10000, 'Max Amount must be 10000€'),
            bank_name: Yup.string()
                .required('Please Enter Your Bank')
                .matches(/^[A-Za-z ]+$/, 'Only alphabets allowed.'),
            account_holder_name: Yup.string()
                .required('Please Enter Account Holder Name')
                .matches(/^[A-Za-z ]+$/, 'Only alphabets allowed.'),
            routing_no: Yup.string()
                .matches(/^[0-9]*$/, 'Only numbers allowed.'),
            ifsc_code: Yup.string()
                .required('Please Select IFSC/Swift Code')
                .matches(/^[a-zA-Z0-9]*$/, 'Only alphabets or numbers allowed.'),
            account_no: Yup.string()
                .required('Please Enter Account Number')
                .min(9, 'Must be min 9 characters')
                .max(18, 'Must be max 18 characters')
                .matches(/^[a-zA-Z0-9]*$/, 'Only alphabets or numbers allowed.')

        }),
        onSubmit: values => {
            if (user?.verification_status !== 'approved' && settings?.withdraw_before == 1) {
                setShowModel(true)
                return
            }
            request("POST", "/withdraw-money", values)
        }
    });

    useEffect(() => {
        if (response) {
            dispatch(
                activateFeedback({
                    type: response?.status,
                    message: response?.message,
                })
            );
            if (response?.status === "success") {
                for (let key in formik.values) {
                    formik.setFieldValue(key, "")
                    formik.errors[key] = null
                    formik.touched[key] = false;                    
                }
            }
        }
    }, [response]);

    return (
        <>
            {loading && <Preloader />}
                <div className="setting-content-con-header">
                {MultiLang["Bank"][language]} {MultiLang["Withdrawal"][language]}
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="setting-input">
                        <div className="setting-input-con">
                            <label htmlFor="withdraw_amount">{MultiLang["Amount"][language]} (€)</label>
                            <input
                                name="withdraw_amount"
                                {...formik.getFieldProps("withdraw_amount")}
                                type="number"
                            />
                            {formik.touched.withdraw_amount &&
                                formik.errors.withdraw_amount && (
                                    <div className="input-error">
                                        {formik.errors.withdraw_amount}
                                    </div>
                                )}
                        </div>
                        <div className="setting-input-con">
                            <label htmlFor="bank_name">{MultiLang["Bank Name"][language]}</label>
                            <input
                                name="bank_name"
                                {...formik.getFieldProps("bank_name")}
                                type="text"
                            />
                            {formik.touched.bank_name &&
                                formik.errors.bank_name && (
                                    <div className="input-error">
                                        {formik.errors.bank_name}
                                    </div>
                                )}
                        </div>
                        <div className="setting-input-con">
                            <label htmlFor="account_holder_name">
                            {MultiLang["Account Holder Name"][language]}
                            </label>
                            <input
                                name="account_holder_name"
                                {...formik.getFieldProps("account_holder_name")}
                                type="text"
                            />
                            {formik.touched.account_holder_name &&
                                formik.errors.account_holder_name && (
                                    <div className="input-error">
                                        {formik.errors.account_holder_name}
                                    </div>
                                )}
                        </div>
                        <div className="setting-input-con">
                            <label htmlFor="routing_no">
                                {MultiLang["Routing Number"][language]} ({MultiLang["Optional"][language]})
                            </label>
                            <input
                                name="routing_no"
                                {...formik.getFieldProps('routing_no')}
                                type="text"
                            />
                            {formik.touched.routing_no &&
                                formik.errors.routing_no && (
                                    <div className="input-error">
                                        {formik.errors.routing_no}
                                    </div>
                                )}
                        </div>
                        <div className="setting-input-con">
                            <label htmlFor="account_no">{MultiLang["Account Number"][language]}</label>
                            <input
                                name="account_no"
                                {...formik.getFieldProps('account_no')}
                                type="text"
                            />
                            {formik.touched.account_no && formik.errors.account_no && <div className="input-error">{formik.errors.account_no}</div>}
                        </div>
                        <div className="setting-input-con">
                            <label htmlFor="ifsc_code">Swift/IFSC Code</label>
                            <input
                                name="ifsc_code"
                                {...formik.getFieldProps("ifsc_code")}
                                type="text"
                            />
                            {formik.touched.ifsc_code &&
                                formik.errors.ifsc_code && (
                                    <div className="input-error">
                                        {formik.errors.ifsc_code}
                                    </div>
                                )}
                        </div>
                    </div>
                    <div className="setting-bottom margin-btn">
                        <button
                            type="submit"
                            className="blue-btn btn setting-btn"
                        >
                            {MultiLang["Send Request"][language]}
                        </button>
                    </div>
                </form>

                <div className="setting-bottom">
                    <button
                        className="btn setting-btn"
                        onClick={() => {
                            setPaymentMethod(null);
                        }}
                    >
                        {MultiLang["Go Back"][language]}
                    </button>
                </div>
                <div
                    className={
                        showModel
                            ? "finish-modal setting-modal active"
                            : "finish-modal setting-modal"
                    }
                >
                    <div
                        className="modal-close"
                        onClick={() => {
                            setShowModel(false);
                        }}
                    >
                        <img src="images/auth/auth-close.png" alt="" />
                    </div>
                    <div className="modal-header">{MultiLang["Verification Alert"][language]}</div>
                    <div className="modal-body">
                        <div>
                        {MultiLang["not-verified"][language]}
                        </div>

                        <div className="setting-bottom">
                            <button
                                type="button"
                                className="blue-btn btn setting-btn"
                                onClick={() => {
                                    navigate("/setting?tab=kyc");
                                }}
                            >
                                {MultiLang["Verify"][language]}
                            </button>
                        </div>
                    </div>
                </div>
        </>
    );
};

export default WithdrawMoney;
