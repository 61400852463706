import React, { useEffect, useState } from "react";
import useRequestV2 from "../../../../hooks/useRequestV2";
import Sidebar from "../Sidebar/Sidebar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PreloaderOuter from "../../../components/preloaderOuter";


const CorporateAcctount = () => {
  const { request, response } = useRequestV2();
  const { request: getplansreq, response: getplansres } = useRequestV2();
  const { request: cancelreq, response: cancelres } = useRequestV2();
  const [packages, setPackages] = useState();
  const [plans, setPlans] = useState();
  const [hide, setHide] = useState(false);


  const navigate = useNavigate();
  useEffect(() => {
    request("GET", `client-packages`);
    setPackages(response);
    console.log(response, "subs");

  }, []);
  const getPlans = () => {
    getplansreq("GET", `subscriptions`);
    setPlans(getplansres?.data);
    console.log(getplansres?.data, "responseeee");
  };
  const cancelPlan = () => {
    cancelreq("POST", `cancel-subscription`);
    setHide(true);
  };
  useEffect(() => {
    if (cancelres) {
      if (cancelres.status == "success") {
        toast.success(cancelres?.message);
        navigate("/dashboard")
      } else if (cancelres.status == "error" || cancelres.status == "fail") {
        toast.error(cancelres?.message);
      }
    }
  }, [cancelres]);
  return (
    <>
      <Sidebar />
      <div className="page_container">
        <div className="main_content">
          <div className="dashboardCard">
            <div className="row inTab">
              <div className="col-xl-3 col-md-12">
                <div className="leftLableBx">
                  <h3 className="leftLable">Subscription Package</h3>
                  <div className="leftBxBtn">
                    <button
                      type="submit"
                      className="BlueThemeBtn themeRoundedBtn"
                      onClick={getPlans}
                    >
                      Change Plan
                    </button>
                    {hide == true ? (
                      <>
                        <a href="" className=""></a>
                      </>
                    ) : (
                      <>
                        <button
                          type="submit"
                          className="BlueThemeBtn themeRoundedBtn dangerBg"
                          onClick={cancelPlan}
                        >
                          Cancellation
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-9 col-md-12">
                <div className="packagesCard">
                  <div className="row inTab50">
                    <div className="col-xl-5 col-md-6">
                      <div className="packageBx">
                        <div className="packCardHead">
                          <ul className="packPoints mb-5">
                            <li style={{ listStyle: "circle" }}>
                              {response?.data?.subscription_name ===
                              "Starter" ? (
                                <>25 Participants by session</>
                              ) : (
                                <></>
                              )}

                              {response?.data?.subscription_name ===
                              "Business" ? (
                                <>50 Participants by session</>
                              ) : (
                                <></>
                              )}

                              {response?.data?.subscription_name ===
                              "Entreprise Custom package" ? (
                                <>50+ Participants by session</>
                              ) : (
                                <></>
                              )}
                               {hide == true ? (
                      <>
                     0 Participants by session
                      </>
                    ) : (
                      <>
                        0  Participants by session
                      </>
                    )}
                            </li>
                            <li style={{ listStyle: "circle" }}>
                              {response?.data?.session_per_day
                                ? response?.data?.session_per_day
                                : 0}{" "}
                              &nbsp;Days Per Week
                            </li>
                            <li style={{ listStyle: "circle" }}>
                              {response?.data?.days_per_week * 12
                                ? response?.data?.days_per_week * 12
                                : 0}{" "}
                              &nbsp;session Per Month
                            </li>
                          </ul>
                          <span className="PackpriTag">
                            <sub>€</sub>
                            {response?.data?.amount
                              ? response?.data?.amount
                              : 0}
                          </span>
                        </div>

                        <div className="packCardBody">
                          <h3 className="packCardBodyTitle">Overview</h3>
                          <div className="row">
                            <div className="col-xl-6">
                              <ul className="inBodyList">
                                <li>Yoga</li>
                                <li>Mindfullness</li>
                                <li>Hiit</li>
                                <li>Daily Burn</li>
                                <li>Stretching</li>
                                <li>Relaxation</li>
                              </ul>
                            </div>
                            <div className="col-xl-6">
                              <ul className="inBodyList">
                                <li>Core Strength</li>
                                <li>Pilates</li>
                              </ul>
                            </div>
                            <p
                              className="text-dark mt-4"
                              style={{ fontSize: "13px" }}
                            >
                              Expired At:{" "}
                              {response?.data?.expired_at
                                ? response?.data?.expired_at
                                : 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-xl-5 col-md-6">
                      <div className="packageBx">
                        <div className="packCardHead">
                          <ul className="packPoints">
                            <li>10 participants per session</li>
                            <li>10 participants per session</li>
                            <li>10 participants per session</li>
                            <li>10 participants per session</li>
                          </ul>
                          <span className="PackpriTag">
                            <sub>€</sub>350
                          </span>
                        </div>

                        <div className="packCardBody">
                          <h3 className="packCardBodyTitle">Overview</h3>
                          <div className="row">
                            <div className="col-xl-6">
                              <ul className="inBodyList">
                                <li>Yoga</li>
                                <li>Mindfullness</li>
                                <li>Hiit</li>
                                <li>Daily Burn</li>
                                <li>Stretching</li>
                                <li>Relaxation</li>
                              </ul>
                            </div>
                            <div className="col-xl-6">
                              <ul className="inBodyList">
                                <li>Core Strength</li>
                                <li>Pilates</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-9 col-md-12">
                <div className="packagesCard">
                  <div className="row inTab50">
                    {getplansres?.data.map((ele, index) => (
                      <div className="col-xl-6 col-md-6 mb-3" key={index}>
                        <div className="packageBx">
                          <div className="packCardHead">
                            <ul className="packPoints mb-5">
                              <li style={{ listStyle: "circle" }}>
                                10 &nbsp;participants per session
                              </li>
                              <li style={{ listStyle: "circle" }}>
                                {ele?.session_per_day} &nbsp;Days Per Week
                              </li>
                              <li style={{ listStyle: "circle" }}>
                                {ele?.days_per_week * 12} &nbsp;session Per
                                Month
                              </li>
                            </ul>
                            <span className="PackpriTag">
                              <sub>€</sub>
                              {ele?.price}
                            </span>
                          </div>

                          <div className="packCardBody">
                            <h3 className="packCardBodyTitle">Overview</h3>
                            <div className="row">
                              <div className="col-xl-6">
                                <ul className="inBodyList">
                                  <li>Yoga</li>
                                  <li>Mindfullness</li>
                                  <li>Hiit</li>
                                  <li>Daily Burn</li>
                                  <li>Stretching</li>
                                  <li>Relaxation</li>
                                </ul>
                              </div>
                              <div className="col-xl-6">
                                <ul className="inBodyList">
                                  <li>Core Strength</li>
                                  <li>Pilates</li>
                                </ul>
                              </div>
                              {/* <p
                               className="text-dark mt-4"
                               style={{ fontSize: "13px" }}
                             >
                               Expired At:{" "}
                               {response?.data?.expired_at
                                 ? response?.data?.expired_at
                                 : 0}
                             </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {/* <div className="col-xl-5 col-md-6">
                       <div className="packageBx">
                         <div className="packCardHead">
                           <ul className="packPoints">
                             <li>10 participants per session</li>
                             <li>10 participants per session</li>
                             <li>10 participants per session</li>
                             <li>10 participants per session</li>
                           </ul>
                           <span className="PackpriTag">
                             <sub>€</sub>350
                           </span>
                         </div>
 
                         <div className="packCardBody">
                           <h3 className="packCardBodyTitle">Overview</h3>
                           <div className="row">
                             <div className="col-xl-6">
                               <ul className="inBodyList">
                                 <li>Yoga</li>
                                 <li>Mindfullness</li>
                                 <li>Hiit</li>
                                 <li>Daily Burn</li>
                                 <li>Stretching</li>
                                 <li>Relaxation</li>
                               </ul>
                             </div>
                             <div className="col-xl-6">
                               <ul className="inBodyList">
                                 <li>Core Strength</li>
                                 <li>Pilates</li>
                               </ul>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="manageId manageId_page2">
              <div className="row mb-4">
                <div className="col-md-3">
                  <div className="Subscription-lebal"> Last Payment</div>
                </div>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-4">
                      <input
                        type="email"
                        className="form-control"
                        value={response?.data?.created_at}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-3">
                  <div className="Subscription-lebal"> Package Expire Date</div>
                </div>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-4">
                      <input
                        type="email"
                        className="form-control"
                        value={response?.data?.expired_at}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row mb-4">
                <div className="col-md-3">
                  <div className="Subscription-lebal"> Payment Methods</div>
                </div>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-12">
                      <a href="#" className="ds-theme-btn Subscription-btn">
                        Credit Card
                      </a>
                    </div>
                    <div className="col-md-12 clone-box">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="****  ****  **** 5678"
                      />
                      <a href="#" className="ds-theme-btn Subscription-btn">
                        Change
                      </a>
                    </div>
                    <div className="col-12">
                      <a href="#" className="add-new_card">
                        {" "}
                        + Add new card
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-3">
                  <div className="Subscription-lebal"> Invoices</div>
                </div>
                <div className="col-md-9">
                  <div className="row g-4">
                    <div className="col-md-3">
                      <select className="form-select" id="monthSelect">
                        <option selected>Month</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>

                    <div className="col-md-3">
                      <select className="form-select" id="yearSelect">
                        <option selected>Year</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="col-md-4">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email@example.com"
                      />
                    </div>
                    <div className="col-12 mt-4">
                      <button className="ds-theme-btn Subscription-btn">
                        {" "}
                        Send{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CorporateAcctount;
