import "./../../css/challenge.css"
import { useEffect, useState, useRef } from "react"

import "react-input-range/lib/css/index.css"
import usePagination from "../../hooks/usePagination"
import useRequest from "../../hooks/useRequest"

import ResultMOdel from "../components/ResultModel";
import { MultiLang } from "../MultiLang"
import { useSelector } from "react-redux"

const PastChallenge = () => {

    const [profileId, setProfileId] = useState("");
    const [challenges, setChallenges] = useState(null)
    const [totalResults, setTotalResults] = useState(0)    
    const { language } = useSelector((state) => state.reducer);

    const { request, response } = useRequest()
    const { pagination, activePage, pageSize } = usePagination(totalResults)

    const [queryData, setQueryData] = useState({
        sort_by: 'joining_fee',
        page_size: pageSize,
        page: activePage,
        isOngoing: false
    })

    const modalRef = useRef(null);

    const openModal = (id) => {
        modalRef.current.setModalStateOut(true);
        modalRef.current.setChall(id)
    }

    useEffect(() => {
        setQueryData({ ...queryData, page_size: pageSize, page: activePage })
    }, [activePage, pageSize])

    useEffect(() => {
        request("GET", `/challenges?sort_by=${queryData.sort_by}&page-size=${queryData.page_size}&page=${queryData.page}&isOngoing=${queryData.isOngoing}`)
    }, [queryData])

    useEffect(() => {
        if (response) {
            if (response?.status === 'success') {
                setChallenges(response?.data?.result)
                setTotalResults(response?.data?.total_record)
            } else {
                setChallenges(null)
            }
        }
    }, [response])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <>
            <div className="profile-main challenge-main">
                <div className="profile-tab-content  active">

                    <div className="challenge-content challengePage-block" >
                        <div className="challenge-content-header content   ">
                            {totalResults} {MultiLang["Challenges"][language]}
                        </div>
                        <div className="content">
                            {
                                challenges && challenges.length > 0 ?
                                    <div className="cont_bx">
                                        {
                                            challenges.map((value, i) => {
                                                return (
                                                    <div key={i} className="profile-main-con challenge-main-con" >
                                                        <div className="profile-main-con-header">
                                                            <img src={value?.cover_image} alt="" />
                                                            {
                                                                value?.is_challengeSubmitted &&
                                                                <div className="profile-main-con-badge1">
                                                                    <img src="./images/profile/success.png" alt="" />
                                                                    {MultiLang["Completed"][language]}
                                                                        </div>
                                                            }
                                                            <div className="profile-main-con-badge2">
                                                                {
                                                                    value?.joining_fee_commission ?
                                                                        <>
                                                                            {MultiLang["Registration"][language]}: <br /> <span>{+value?.joining_fee - +value?.joining_fee_commission}€ + {+value?.joining_fee_commission}€</span>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {MultiLang["Registration"][language]}: <br /> <span>{+value?.joining_fee}€</span>
                                                                        </>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="profile-main-con-bottom">
                                                            <div>
                                                                {value?.title}
                                                                {/* <img src="./images/profile/badge-gold.png" alt="" /> */}
                                                            </div>
                                                            <div className="profile-main-con-text">
                                                                <div>
                                                                    <img src="./images/challenge/icon-profile.png" alt="" />
                                                                    {MultiLang["Participants"][language]}: <strong>{value?.total_participate}/{value?.participant_count}</strong>
                                                                </div>
                                                                <div>
                                                                    <img src="./images/challenge/icon-prize-fill.png" alt="" />
                                                                    {MultiLang["First prize"][language]}: <strong>{value?.first_winner_price}€</strong>
                                                                </div>
                                                            </div>
                                                            {
                                                                value?.is_result ?
                                                                    <div>
                                                                        <button className="profile-main-con-btn btn blue-btn" onClick={() => { openModal(value?.id); setProfileId("01");}}>
                                                                            <img src="./images/challenge/icon-prize.png" alt="" />
                                                                            {MultiLang["See Result"][language]}
                                                                                </button>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <button className="profile-main-con-btn btn blue-btn" >
                                                                            <img src="./images/challenge/icon-prize.png" alt="" />
                                                                            {MultiLang["Result not announced yet!"][language]}
                                                                                </button>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {pagination}
                                    </div>
                                    : <div className="challenge-nodata"> {MultiLang["No data found"][language]} </div>
                            }
                        </div>
                    </div>
                </div>
            </div>            
            <ResultMOdel id={profileId} ref={modalRef}/>
        </>
    )
}

export default PastChallenge;