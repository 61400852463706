import React, { useEffect,useState } from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import Sidebar from '../Sidebar/Sidebar'
import { useForm } from "react-hook-form";
import useRequestV2 from '../../../../hooks/useRequestV2';
import { toast } from "react-toastify";

const ChangePassword = () => {

    const navigate = useNavigate();

    let { id:employeeId }                                                           = useParams();
    const [isNewPassVisible,setIsNewPassVisible]                                    = useState(false);
    const [isConfirmPassVisible,setIsConfirmPassVisible]                            = useState(false);
    const {request:requestChangePassword, response:responseChangePassword}          = useRequestV2()
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        setError
    } = useForm();
  
    const onSubmit = (data) => {
        const {newPassword, confirmPassword} = data;

        if(newPassword != confirmPassword){
            setError("confirmPassword", {
                type: "manualConfirmPassErr",
              });
            return;
        }
        
        requestChangePassword('POST',`change-employee-password/${employeeId}`,{new_password:newPassword,confirm_new_password:confirmPassword});
    }

    useEffect(() => {
        if(responseChangePassword){
            if(responseChangePassword.status == "success"){
                // toast.success('Employee password has been changed successfully.');
               
                navigate("/ids",{state:{matchValue:'change-password'}});
            }else{
                toast.error(responseChangePassword.message);
            }
        }
    },[responseChangePassword])


    return (
        <>
            <Sidebar/>
            <div className="page_container">
                <div className="main_content">
                    <div className="pagetopTitle">
                        <h2 className="page-title pageHeaderData"><span>Agenda</span><span>Your next session starts in 2 D : 16 h : 20 min</span><span>Pilates</span></h2>
                    </div>
                    <div className="dashboardCard">
                        <div className="cardHeader">
                            <div className="row">
                                <div className="col-md-6">                                    
                                    <div className="cardLeftSide">
                                        <h3 className="CardHeadTitle">Change Password</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-end">
                            <div className="col-md-7">
                                <div className="manageId">
                                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="generateBox">
                                            <div className="col-md-7 mb-5">
                                                <label className="form-label">New Password*</label>
                                                <div className="inputRow">
                                                    <input  type={isNewPassVisible ? "text" : "password"} className="form-control" name="newPassword" placeholder="New Password*"  {...register("newPassword", {
                                                        required: true,
                                                        pattern: {
                                                            value:/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
                                                        },
                                                    })}/>  
                                                 
                                                     {/* <Link to="#" onClick={() => setIsNewPassVisible((prev) => !prev)} >
                                                      
                                                        {!isNewPassVisible ? <i className="bi bi-eye-slash fs-2"></i> : <i className="bi bi-eye fs-2"></i>}
                                                    
                                                    </Link>     */}
                                                </div>
                                                {errors.newPassword?.type === "required" && (
                                                    <div className="invalid-field text-danger fw-bold">
                                                        This field is required.
                                                    </div>
                                                )}
                                                {errors.newPassword?.type === "pattern" && (
                                                    <div className="invalid-field text-danger fw-bold">
                                                       New password must be of 8 or more characters long with a mix of letters,numbers.
                                                    </div>
                                                )}
                                            </div>     
                                            <div className="col-md-7 mb-5">
                                                <label className="form-label">Confirm Password*</label>
                                                <div className="inputRow">
                                                    <input type={isConfirmPassVisible ? "text" : "password"} className="form-control" name="confirmPassword" placeholder="Confirm Password*"  {...register("confirmPassword", {
                                                        required: true,
                                                        pattern: {
                                                            value:/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
                                                        },
                                                    })}/>      
                                                </div>
                                                {errors.confirmPassword?.type === "required" && (
                                                    <div className="invalid-field text-danger fw-bold">
                                                        This field is required.
                                                    </div>
                                                )}
                                                 {errors.confirmPassword?.type === "pattern" && (
                                                    <div className="invalid-field text-danger fw-bold">
                                                       Confirm password must be of 8 or more characters long with a mix of letters,numbers.
                                                    </div>
                                                )}

                                                 {errors.confirmPassword?.type === "manualConfirmPassErr" && (
                                                    <div className="invalid-field text-danger fw-bold">
                                                       Confirm password must be same as new password.
                                                    </div>
                                                )}
                                            </div>                                
                                        </div>
                                        
                                        
                                       
                                        <div className="col-12 mt-4">
                                          <button type="button" onClick={handleSubmit(onSubmit)} className="BlueThemeBtn themeRoundedBtn">Submit</button>
                                        </div>
                                      </form>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="sideSectionimg">
                                    <img src="assets/img/aboutImg.png" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        </div>
        </>
  )
}

export default ChangePassword
