import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

// import { toPng } from 'html-to-image';
import { FacebookButton, TwitterButton } from 'react-social';
// import domtoimage from 'dom-to-image';

const SocialShare = ({ challId }) => {
    const url = `${process.env.NODE_ENV === "development" ? 'http://192.168.235.200:7035' : window.location.protocol === "https:" ? 'https://stronger.stage04.obdemo.com' : 'http://stronger.stage04.obdemo.com'}/result/${challId}`



    return (
        <div className="social-con">
            <FacebookButton url={url} appId={process.env.REACT_APP_FB_APP_ID}>
                <img src="./images/profile/facebook.png" alt="" />
            </FacebookButton>

            <TwitterButton url={url}>
                <img src="./images/profile/twitter.png" alt="" />
            </TwitterButton>

            {/* <Link target="_blank" to="#"><img src="./images/profile/facebook.png" alt="" /></Link>
            <Link target="_blank" to="#"><img src="./images/profile/twitter.png" alt="" /></Link>
            <Link target="_blank" to="#"><img src="./images/profile/instagram.png" alt="" /></Link> */}
        </div>
    )
}

export default SocialShare
