import { useEffect, memo } from "react";
import { useSelector } from "react-redux"

import { useRef } from "react";
import {
    ClassCategory,
    Features,
    Assessment,
    // XtraVisionOnDemandProvider,
    // useXtraVisionOnDemandContext,
    useXtraVisionAssessmentContext,
    XtraVisionAssessmentProvider,
} from "xtravision-react";

const AppContainer = ({ videoElementRef, setCamControls, setResults }) => {
    const { training } = useSelector(state => state.Auth)

    const { lastJsonMessage, isCamOn, setIsCamOn } =
        useXtraVisionAssessmentContext();

    console.log(lastJsonMessage);

    if (lastJsonMessage?.error) {
        console.log("lastJsonMessage: ", lastJsonMessage?.error);
    } else console.log("lastJsonMessage: ", lastJsonMessage?.data);

    // console.log(lastJsonMessage)

    const intensity = lastJsonMessage?.intensity;
    const calBurned = lastJsonMessage?.calBurned;
    const assessmentName = lastJsonMessage?.data?.assessment;
    const repCount = lastJsonMessage?.data?.reps;
    const message = lastJsonMessage?.message;
    const isPassed = lastJsonMessage?.isPassed;

    const startCamera = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
            const deviceInfos = await navigator.mediaDevices.enumerateDevices();
            let defaultCamId;

            console.log(deviceInfos)

            for (let i = 0; i !== deviceInfos.length; ++i) {
                const deviceInfo = deviceInfos[i];
                if (deviceInfo.kind === "videoinput") {
                    defaultCamId = deviceInfo.deviceId;
                    break;
                }
            }

            // console.log(defaultCamId)
            if (!defaultCamId) return;

            const constraints = {
                video: {
                    deviceId: { exact: defaultCamId },
                },
            };

            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            videoElementRef.current.stream = stream;
            videoElementRef.current.srcObject = stream;
            videoElementRef.current.play();
            setIsCamOn(true);

        } catch (err) {
            console.log(err);
            setIsCamOn(false);
        }
    };

    const stopCamera = () => {
        var stream = videoElementRef.current.srcObject;
        var tracks = stream.getTracks();

        for (var i = 0; i < tracks.length; i++) {
            var track = tracks[i];
            track.stop();
        }
        videoElementRef.current.srcObject = null;

    };

    useEffect(() => {
        setCamControls({
            startCamera,
            stopCamera,
            setIsCamOn
        })
    }, [])

    useEffect(() => {
        if (isCamOn) {
            setResults({
                intensity: intensity ?? 0,
                calBurned: calBurned ?? 0,
                assessmentName: assessmentName ?? training?.type,
                repCount: repCount ?? 0,
                message: message ?? "",
                isPassed
            })
        }
    }, [intensity, calBurned, assessmentName, repCount, message, isPassed])

    return (
        <div >
            {/* <div style={{ display: "flex", flexDirection: "row" }}>
                <button
                    onClick={() => {

                        startCamera();
                    }}
                    disabled={isCamOn}
                >
                    START
                </button>
                <button
                    onClick={() => {
                        setIsCamOn(false);
                        stopCamera();
                    }}
                    disabled={!isCamOn}
                >
                    STOP
                </button>
            </div> */}

            {/* {isCamOn && (
                <div>
                    <div>Intensity: {intensity ?? 0}</div>
                    <div>Cal burned: {calBurned ?? 0}</div>
                    <div>Assessment: {assessmentName ?? ''} </div>
                    <div>Rep Count: {repCount ?? 0}</div>
                </div>
            )} */}
        </div>
    );
};

function App({ setCamControls, setResults, isEdu, assesmentType }) {
    const category = ClassCategory.Yoga; // change as per our need

    const features = [
        Features.YOGA_QUALITY,
        Features.YOGA_SCORE,
        Features.VORTEX,
    ];

    // const clientScheduleId = "SOME-SCHEDULE-ID";
    const sessionId = "SESSIONID";
    const videoElementRef = useRef(null);
    const isEduScreen = isEdu;

    // assessment name you want
    let assessmentName = Assessment[assesmentType];
    assessmentName = assessmentName ?? assesmentType;
    // console.log(assessmentName)

    const authToken = process.env.REACT_APP_XTRA_AUTHTOKEN

    return (
        <XtraVisionAssessmentProvider
            authToken={authToken}
            videoElementRef={videoElementRef}
            isEduScreen={isEdu}
            assessmentName={assessmentName}
        >

            <div className="videoParent">
                <video ref={videoElementRef} />
                <AppContainer
                    setResults={setResults}
                    setCamControls={setCamControls}
                    videoElementRef={videoElementRef}
                />
            </div>
        </XtraVisionAssessmentProvider>
    );
}

export default memo(App);

