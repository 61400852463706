import react, { useState, useEffect } from "react";
import { useFormik, Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
// import "./style.css"
// import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { authSuccess } from "../../../redux/corporation-store/auth/action";
import useRequestV2 from "../../../hooks/useRequestV2";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
// import style from "../public/assets/css/style1.css";
// import "../../../css/style1.css";
// import "./img/"
import { activateFeedback } from "../../../redux/actions";
import { Link } from "react-router-dom";
import moment from "moment";
import { countries } from "../../../helpers/countries";

const Signup = () => {
  const { request, response } = useRequestV2();
  const [profile, setprofile] = useState();
  const [dispicture, setDispicture] = useState();
  const [type, setType] = useState("password");
  const [type1, setType1] = useState("password");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
      // password: "",
      profile_first_name: "",
      profile_last_name: "",
      // confirm_password: "",
      country: "",
      // company_name: "",
      dob: "",
      // profile_image: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please enter your email")
        .matches(
          /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
          "please enter a valid email"
        ),
      // password: Yup.string()
      //   .required("Please enter your password")
      //   .min(8, "Password is too short - should be 8 characters minimum.")
      //   .matches(
      //     /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,1}).{8,}$/,
      //     "Password must contain Uppercase, lowercase, number and special character."
      //   ),

      // confirm_password: Yup.string()
      //   .oneOf([Yup.ref("password"), null], "Both password need to be the same")
      //   .required("Please enter your password"),
      //  Yup.string().oneOf(
      //     [Yup.ref("password") , null],
      //     "Both password need to be the same"
      //   ),
      profile_first_name: Yup.string().required("Please enter your Firstname"),
      profile_last_name: Yup.string().required("Please enter your Lastname"),
      // company_name: Yup.string().required("Please enter your Company name"),
      dob: Yup.string().required("Please enter your Date Of Birth"),
      country: Yup.string().required("Please Select Your Country"),
    }),
    onSubmit: (values) => {
      //   if (values.password !== values.confirm_password) {
      //     dispatch({message: 'Please confirm the password' })
      //     return;
      // }
      let formdata = new FormData();
      formdata.append("profile_first_name", values.profile_first_name);
      formdata.append("profile_last_name", values.profile_last_name);
      formdata.append("email", values.email);
      // formdata.append("password", values.password);
      // formdata.append("confirm_password", values.confirm_password);
      formdata.append("country", values.country);
      // formdata.append("company_name", values.company_name);
      formdata.append("dob", values.dob);
      // formdata.append("profile_image", profile ? profile : "");

      request("POST", "employee-register", formdata);
    },
  });
  useEffect(() => {
    if (response) {
      if (response.status == "success") {
        toast.success(response.message);
        // console.log(response.data.access_token, "response.data.access_token");
        // dispatch(
        //   authSuccess({
        //     token: response.data.access_token,
        //     uuid_string: response.data.user.uuid_string,
        //   })
        // );

        navigate("/employee-verify", {
          state: {
            id: response?.data?.user?.uuid_string,
            hash: response?.data?.access_token,
            user_role_id: response?.data?.user.user_role_id,
          },
        });
      } else if (response.status == "fail") {
        toast.error(response.message);
      }
    }
  }, [response]);

  // const profilePictureSave = (e) => {
  //   setprofile(e.target.files[0]);
  //   setDispicture(URL.createObjectURL(e.target.files[0]));
  // };
  // const changeType = () => {
  //   if (type === "password") {
  //     setType("text");
  //     return;
  //   }
  //   setType("password");
  // };
  // const changeType1 = () => {
  //   if (type1 === "password") {
  //     setType1("text");
  //     return;
  //   }
  //   setType1("password");
  // };
  return (
    <>
      {/* <Helmet>
                <meta charSet="utf-8" />
                <title>My Title</title>
                <link href="/assets/css/bootstrap.min.css" rel="canonical" type="text/css" />
                <link href="/assets/css/font-awesome.css" rel="canonical" type="text/css" />
                <link href="/assets/css/style.css" rel="stylesheet" type="text/css" />
                <link href="/assets/css/responsive.css" rel="canonical" type="text/css" />
                <link rel="canonical" href="/assets/css/style.css"/>

            </Helmet> */}
      <div
        className="sign-up-container"
        // style={{
        //   backgroundImage: "url(../img/sinup-bg.jpg)",
        // }}
      >
        <img
          className="right_doted d-md-none d-lg-block"
          src="/img/sinup_doted.png"
          alt="right img"
        />
        <div className="container-fluid">
          <div className="sign-up-inner-container">
            <div className="row align-items-center">
              {/* <div className="col-md-5 p-0">
                <div className="myBackground">
                  <div className="signup-left-box">
                    <a href="#" className="logo-img">
                      {" "}
                      <img src="img/full-logo.png" alt="" />
                    </a>
                    <div className="upload-img-box">
                      <span className="uploaded-img">
                        <img src={dispicture} alt="" />{" "}
                      </span>
                      <input
                        type="file"
                        className="d-none"
                        id="signup-upload"
                        onChange={(e) => {
                          profilePictureSave(e);
                        }}
                      />
                      <label for="signup-upload" className="upload-btn">
                        {" "}
                        Upload{" "}
                      </label>
                    </div>
                    <h5 className="setup-heading">lets get you set up</h5>
                    <p className="setup-styleheading-info">
                      {" "}
                      It Should only take a couple of minutes to par with your
                      watch
                    </p>
                    <a href="#" className="form-next-btn">
                      <svg
                        width="17"
                        height="28"
                        viewBox="0 0 17 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.44062 27.5L0 25.0594L11.3094 13.75L0 2.44062L2.44062 0L16.1906 13.75L2.44062 27.5Z"
                          fill="white"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div> */}
              <div className="col-md-7 ms-auto me-auto p-0">
              
                <div className="signup-right-box">
                  <form
                    className="row sign_in-form-box"
                    onSubmit={formik.handleSubmit}
                  >
                    <a href="#" className="logo-img text-center">
                      {" "}
                      <img src="../../img/full-logo.png" alt="" />
                    </a>
                    <h1 className="form-title text-center"> Sign up</h1>
                    <div className="mb-3 col-md-6 ">
                      <label className="form-label">
                        First Name <sup>*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        aria-describedby="emailHelp"
                        {...formik.getFieldProps("profile_first_name")}
                      />
                      {formik.touched.profile_first_name &&
                        formik.errors.profile_first_name && (
                          <div className="text-danger fw-bold">
                            {formik.errors.profile_first_name}
                          </div>
                        )}
                    </div>
                    <div className="mb-3 col-md-6 ">
                      <label className="form-label">
                        Last Name <sup>*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        {...formik.getFieldProps("profile_last_name")}
                      />
                      {formik.touched.profile_last_name &&
                        formik.errors.profile_last_name && (
                          <div className="text-danger fw-bold">
                            {formik.errors.profile_last_name}
                          </div>
                        )}
                    </div>
                    {/* <div className="mb-3 col-md-6 ">
                      <label className="form-label">
                        Company Name <sup>*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Company Name"
                        aria-describedby="emailHelp"
                        {...formik.getFieldProps("company_name")}
                      />
                      {formik.touched.company_name &&
                        formik.errors.company_name && (
                          <div className="text-danger fw-bold">
                            {formik.errors.company_name}
                          </div>
                        )}
                    </div> */}
                    <div className="mb-3 col-md-6 ">
                      <label className="form-label">
                        Country <sup>*</sup>
                      </label>
                      <select
                        name="country" 
                        className=" form-control form-select"
                        {...formik.getFieldProps("country")}
                      >
                        <option value="">Select country</option>
                        {countries.map((val) => {
                          return (
                            <option key={val.code} value={val.name}>
                              {val.name}
                            </option>
                          );
                        })}
                      </select>

                      {formik.touched.country && formik.errors.country && (
                        <div className="text-danger fw-bold">
                          {formik.errors.country}
                        </div>
                      )}
                    </div>
                    <div className="mb-3 col-md-6 ">
                      <label className="form-label">
                        Email <sup>*</sup>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email@example.com"
                        aria-describedby="emailHelp"
                        {...formik.getFieldProps("email")}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className="text-danger fw-bold">
                          {formik.errors.email}
                        </div>
                      )}
                    </div>
                    <div className="mb-3 col-md-6 ">
                      <label className="form-label">
                        Birthday <sup>*</sup>
                      </label>
                      <input
                        type="date"
                        max={moment(new Date()).format("YYYY-MM-DD")}
                        placeholder="Birthday"
                        className="form-control"
                        {...formik.getFieldProps("dob")}
                      />
                      {formik.touched.dob && formik.errors.dob && (
                        <div className="text-danger fw-bold">
                          {formik.errors.dob}
                        </div>
                      )}
                    </div>
                    {/* <div className="mb-3 col-md-6 ">
                      <label className="form-label">
                        Password <sup>*</sup>
                      </label>
                      <span className="input_password_box">
                        <input
                          type={type}
                          className="form-control"
                          placeholder="Password"
                          aria-describedby="emailHelp"
                          {...formik.getFieldProps("password")}
                        />
                        <span className="eye-icon_box" onClick={changeType}>
                          {type === "password" ? (
                            <i className="fas fa-eye-slash" id="togglePassword"></i>
                          ) : (
                            <i className="fas fa-eye" id="togglePassword"></i>
                          )}
                        </span>
                      </span>
                      {formik.touched.password && formik.errors.password && (
                        <div className="text-danger fw-bold">
                          {formik.errors.password}
                        </div>
                      )}
                    </div>
                    <div className="mb-3 col-md-6 ">
                      <label className="form-label">
                        Confirm Password <sup>*</sup>
                      </label>
                      <span className="input_password_box">
                        <input
                          type={type1}
                          placeholder="Confirm Password"
                          className="form-control"
                          {...formik.getFieldProps("confirm_password")}
                        />
                        <span className="eye-icon_box" onClick={changeType1}>
                          {type1 === "password" ? (
                            <i className="fas fa-eye-slash" id="togglePassword"></i>
                          ) : (
                            <i className="fas fa-eye" id="togglePassword"></i>
                          )}
                        </span>
                      </span>
                      {formik.touched.confirm_password &&
                        formik.errors.confirm_password && (
                          <div className="text-danger fw-bold">
                            {formik.errors.confirm_password}
                          </div>
                        )}
                    </div> */}
                    <div className="col-12">
                      <button
                        type="submit"
                        className="btn btn-primary register-btn w-100"
                      >
                        Register
                      </button>
                    </div>
                    <div className="col-12">
                      <div className=" Signin-info">
                        {" "}
                        Already Have An Account?
                        <Link to="/companyupdate"> Sign In</Link>{" "}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
