// export const API = {
//     PORT: process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_DEVELOPMENT_URL : window.location.protocol === "https:" ? process.env.REACT_APP_API_PRODUCTION_URL_SECURE : process.env.REACT_APP_API_PRODUCTION_URL
// };

export const API = {
    PORT: process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_DEVELOPMENT_URL : window.location.protocol === "https:" ? process.env.REACT_APP_API_PRODUCTION_URL_SECURE : process.env.REACT_APP_API_PRODUCTION_URL
};

export const API_V2 = {
    PORT: process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_V2_DEVELOPMENT_URL : window.location.protocol === "https:" ? process.env.REACT_APP_API_V2_PRODUCTION_URL : process.env.REACT_APP_API_V2_PRODUCTION_URL
};