import React , {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom"
import { useFormik } from "formik";
import * as Yup from "yup";
import useRequest from "../../../hooks/useRequest";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const { request, response } = useRequest();
  const navigate = useNavigate();


    const formik = useFormik({
        initialValues: {
          email: "",
          password: "",
        },
        validationSchema: Yup.object({
          email: Yup.string().required("This Field is required"),
        }),
        onSubmit: (values) => {
          //   const data = {};
          //   console.log(data);
    
          request("POST", "/auth/forgot-password/", {
            email: values.email,
          });
        },
      });
      useEffect(() => {
        if (response) {
          console.log(response, "response");
          if (response.status === "success") {
            toast.success(response.message);
            navigate("/employee-change-password", {
              state: {
                id: response?.data?.uuid_string,
              },
            });
            localStorage.setItem("access_token" , response?.data?.access_token)
          } else if (response.status === "error") {
            toast.error(response.messagev);
          }
        }
      }, [response]);

  return <>
  
  <div
        className="verify_container client-sign_up"
        style={{
          backgroundImage: "url(https://images.pexels.com/photos/1552242/pexels-photo-1552242.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)",
        }}
      >
        <img className="right_doted" src="/img/doted.png" alt="right img" />
        <div className="container-fluid">
          <div className="main_container">
            {/* <div className="logo_block">
              <img src="/img/full-logo.png" alt="logo white" />
            </div> */}
            <div className="center_blog">
              <div className="inner_form_block">
                <div className="sign-up-inner-container">
                  <div className="row align-items-center">
                    <div className="col-md-12 p-0">
                      <div className="signup-right-box">
                        <form
                          className="row "
                          onSubmit={formik.handleSubmit}
                          style={{textAlign:"left"}}

                        >
                          <h2 className=" text-center mb-5"> Forgot Password</h2>
                          <div className="mb-3 col-md-8 ms-auto me-auto">
                            <label className="form-label ">Email Address </label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Enter Your Email Address"
                              {...formik.getFieldProps("email")}

                            />
                           {formik.touched.email && formik.errors.email && (
                              <div className="input-error text-danger fw-bold">
                                {formik.errors.email}
                              </div>
                            )}
                           <div className="auth-input-list">
                                <div className="auth-alert">
                                <i className="fa fa-triangle-exclamation"></i>   A new password will be sent to the mail you enter during registration
                            </div>
                            </div>

                          </div>
                        
                          <div className="mb-3 col-md-8 text-center  ms-auto me-auto">
                            <button className="upload-btn mt-3 w-100">
                              {" "}
                              Send
                            </button>
                            {/* <p>Don’t have account?&nbsp;<Link to="/corporation/employee/register" className="text-light">Sign Up</Link></p> */}
                          </div>
                        </form>
                      </div>
                      <div className=" Signin-info pt-0">
                        {" "}
                        <a href="mailto:m.bluth@example.com">
                          Contact info@stronger.lu
                        </a>{" "}
                        <a href="tel:+123456789">+352 621652518 </a>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

  </>;
};

export default ForgotPassword;
