import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import usePagination from "../../hooks/usePagination";
import { activateFeedback } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { MultiLang } from "../MultiLang";

const imgObj = {
  deposit: "/images/profile/deposite.png",
  pp_deposit: "/images/profile/deposite.png",
  withdraw: "/images/profile/withdraw.png",
  pp_withdraw: "/images/profile/withdraw.png",
  join: "/images/profile/chall.png",
  winning: "/images/profile/icon-prize.png",
};

const TransactionTypeName = {
  deposit: "Deposit",
  pp_deposit: "Paypal Deposit",
  withdraw: "Withdraw",
  pp_withdraw: "Paypal Withdraw",
  join: "Join",
  winning: "Winning",
};

const TransactionHistory = () => {
  const dispatch = useDispatch();
  const { request, response } = useRequest();
  const [tableData, setTableData] = useState(null);
  const [totalResults, setTotalResults] = useState(0);

  const { pagination, activePage, pageSize } = usePagination(totalResults);
  const { language } = useSelector((state) => state.reducer);

  useEffect(() => {
    if (response) {
      // console.log(response)
      if (response?.status === "success") {
        setTableData(response?.data?.result);
        setTotalResults(response?.data?.total_record);
      } else {
        dispatch(
          activateFeedback({
            type: response?.status,
            message: response?.message,
          })
        );
      }
    }
  }, [response]);

  useEffect(() => {
    request("GET", `/transcations?page-size=${pageSize}&page=${activePage}`);
  }, [activePage, pageSize]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="profile-tab-content active setting-content-con tabular">
      <div className="setting-content-con-header">
        {MultiLang["Transaction History"][language]}
      </div>
      <div className="">
        {tableData && tableData.length > 0 ? (
          <>
            <div className="Transaction-history cont_bx table-responsive">
              <table className="table table-bordered">
                <tbody>
                  {tableData.map((value, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <div className="transt_data_block">
                            <div className="transaction-icon">
                              <img
                                src={imgObj[value?.transaction_type]}
                                alt=""
                              />
                            </div>

                            <div>
                              <h6>
                                {" "}
                                {value?.transaction_type
                                  ? TransactionTypeName[value?.transaction_type]
                                  : "Unknown"}
                              </h6>
                              {moment(value?.created_at).format("DD/MM/YYYY")}
                            </div>
                          </div>
                        </td>
                        <td className="text-right">
                          <div className="transt_data_block1">
                            <div className="deposit_pos ">
                              {value?.transaction_type === "deposit" ||
                              value?.transaction_type === "pp_deposit" ||
                              value?.transaction_type === "winning"
                                ? "+"
                                : "-"}
                              {(+value?.amount).toFixed(2)} €
                            </div>
                            <div className="tooltip-custom">
                              <div className="transaction-icon ">
                                <img
                                  src="../images/profile/mark01.png"
                                  alt=""
                                />
                              </div>
                              <span className="tooltiptext">
                                {value?.slug
                                  ? value.slug
                                  : "No data available."}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="paginationProfile">{pagination}</div>
          </>
        ) : (
          <div className="challenge-nodata nodata-white">
            {" "}
            {MultiLang["No data found"][language]}{" "}
          </div>
        )}
      </div>
    </div>
  );
};
export default TransactionHistory;
