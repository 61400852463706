import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import { logout, updateLoading } from "../redux/corporation-store/auth/action";
import { API } from "../constants/api";

import { API_V2 } from "../constants/api";

const BACKEND_URL = API_V2.PORT;

const useRequestV2 = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const { token } = useSelector((state) => state.AuthEmployee);
  const access_token = localStorage.getItem('token');


  // const { language } = useSelector((state) => state.setting);
  // const  token  = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzA4MDgyNzI1LCJpYXQiOjE2NzY1NDY3MjUsImp0aSI6IjUyN2JiOWUzMjAzZTRjMmZiZWYxYzgyZDhkM2IxOWQ3IiwidXNlcl9pZCI6NDM4fQ.5IunKbrx1vEfFOqbyB5m94FHXb-OzvERJ150CbhF2Gs';

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateLoading({ loading }));
  }, [loading]);

  const startFetching = () => {
    setResponse(null);
    setLoading(true);
    setError(null);
  };

  const clear = () => {
    setResponse(null);
    setError(null);
  };

  const fetchedData = () => {
    setLoading(false);
    setError(null);
  };

  // useEffect(() => {
  //   const url = `${API.PORT}/secure/profile/`;
  //   console.log(access_token,"access_token")
  //   if (token || access_token) {
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${token?token:access_token}`,
  //         // "Accept-Language": "it",
  //       },
  //     };
  //     axios
  //       .get(url, config)
  //       .then((res) => {
  //         let userResponse = res.data;
  //         if (userResponse.status === "FAIL") {
  //           dispatch(logout());
  //         } else {
  //           dispatch(
  //             authSuccess({
  //               loggedIn: true,
  //               token,
  //               access_token
  //               // userId: userResponse.data.user_data[0].user.id_user,
  //               // employeeId: userResponse.data.user_data[0].user.id_employee,
  //               // userName: userResponse.data.user_data[0].user.username,
  //               // name: userResponse.data.user_data[0].user.employee_detail.name,
  //               // surName: userResponse.data.user_data[0].user.employee_detail.surname,
  //               // Role: userResponse.data.user_data[0].user.employee_detail.role,
  //               // companyName: userResponse.data.user_data[0].user.employee_detail.company.company_name,
  //               // email: userResponse.data.user_data[0].user.employee_detail.company.general_mail,
  //               // country: userResponse.data.user_data[0].user.employee_detail.place.country,
  //               // state: userResponse.data.user_data[0].user.employee_detail.place.state,
  //               // name: userResponse.data.name,
  //               // email: userResponse.data.email,
  //               // userRoleId: userResponse.data.id_role,
  //               // role: userResponse.data.role,
  //             })
  //           );
  //         }
  //       })
  //       .catch((err) => {
  //         dispatch(logout());
  //       });
  //   }
  // }, [token || access_token]);
  const requestData = (method, url, data) => {
    let config;
    if (token || access_token) {
      config = {
        method,
        url: `${BACKEND_URL}/${url}`,
        headers: {
          Authorization: `Bearer ${token ? token : access_token}`,
          // "Accept-Language": language,
        },
        data,
      };
    } else {
      config = {
        method,
        url: `${BACKEND_URL}/${url}`,
        headers: {
          // "Access-Control-Allow-Origin": "origin"
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Headers': '*',
          // "Accept-Language": language,
          // 'Content-Type': 'application/json'
        },
        data,
      };
      // console.log(config,"<<<< config ")
    }

    startFetching();

    axios(config)
      .then((res) => {
        fetchedData();
        setResponse(res.data);
      })
      .catch((err) => {
        fetchedData();
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(logout());
          } else if (err.response.status === 404) {
            navigate("/404");
          } else {
            // alert(err.response.data.message);
          }
        } else if (err.request) {
          // dispatch(logout());
        } else {
          // dispatch(logout());
        }
      });
  };

  return {
    loading,
    error,
    request: requestData,
    clear,
    response,
    setError,
  };
};

export default useRequestV2;
