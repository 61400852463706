import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  LOGIN_ERROR,
  API_FAILED,
  VERIRY_CODE,
  LOADING,
  AUTO_LOGIN_SUCCESS,
  ADD_USER_DATA,
  UPDATE_WALLET,
  UPDATE_KYC_STATUS,
  SET_STRIPE,
  TRAINING,
  ACTIVE_TIMING_CHALLENGES,
  NOTIFICATION,
} from "./constants";

const INIT_STATE = {
  loggedIn: null,
  user: {
    first_name: "Guest",
    last_name: "User",
    email: "",
  },
  access_token: null,
  // error: null,
  loading: false,
  training: null,
  language: "GB",
  activeTimingChallenges: null,
  notification: {
    show: true,
    data: [],
  },
};

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        user: action.payload.user,
        access_token: action.payload.access_token,
        loading: false,
      };

    case REGISTER_USER:
      return { ...state, loading: true };

    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        user: action.payload.user,
        access_token: action.payload.access_token,
        loading: false,
      };

    case VERIRY_CODE:
      return { ...state, loading: false, error: null };

    case LOGOUT_USER:
      return { ...INIT_STATE, user: null, loggedIn: false, access_token: null };

    case FORGET_PASSWORD:
      return { ...state, username: action.payload.username, loading: true };

    case FORGET_PASSWORD_SUCCESS:
      return { ...state, loading: false, error: null };

    case API_FAILED:
      return { ...state, loading: false, error: action.payload };

    case LOGIN_ERROR:
      return { ...state, loading: false, error: action.payload };

    case LOADING:
      return { ...state, loading: action.payload };

    case AUTO_LOGIN_SUCCESS:
      return {
        ...state,
        employeeLogin: true,
        loggedIn: true,
        user: action.payload.data,
        access_token: action.payload.access_token,
        loading: false,
      };

    case ADD_USER_DATA:
      return { ...state, user: { ...state.user, ...action.payload } };

    case UPDATE_WALLET:
      return {
        ...state,
        user: { ...state.user, wallet_balance: action.payload },
      };

    case UPDATE_KYC_STATUS:
      return {
        ...state,
        user: { ...state.user, verification_status: action.payload },
      };
    case SET_STRIPE:
      return {
        ...state,
        user: { ...state.user, strip_account_id: action.payload },
      };
    case TRAINING:
      return { ...state, training: action.payload };
    case ACTIVE_TIMING_CHALLENGES:
      return { ...state, activeTimingChallenges: action.payload };
    case NOTIFICATION:
      return { ...state, notification: action.payload };

    default:
      return { ...state };
  }
};

export default Auth;
