export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";

export const LOGOUT_USER = "LOGOUT_USER";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";

export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";

export const API_FAILED = "AUTH_API_FAILED";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const VERIRY_CODE_SUCCESS = "VERIRY_CODE_SUCCESS";
export const VERIRY_CODE = "VERIRY_CODE";

export const LOADING = "LOADING"
export const AUTO_LOGIN_SUCCESS = "AUTO_LOGIN_SUCCESS"

export const ADD_USER_DATA = "ADD_USER_DATA"
export const UPDATE_WALLET = "UPDATE_WALLET"
export const UPDATE_KYC_STATUS = "UPDATE_KYC_STATUS"
export const SET_STRIPE = "SET_STRIPE"

export const TRAINING = 'TRAINING'

export const ACTIVE_TIMING_CHALLENGES = 'ACTIVE_TIMING_CHALLENGES'
export const NOTIFICATION = 'NOTIFICATION'
