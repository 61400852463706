import React, { useEffect, useState } from "react";
import BankDeposit from "./BankDeposit";

import CreditCardDeposit from "./CreditCardDeposit";
import PayPalDeposit from "./PayPalDeposit";
import { MultiLang } from "../MultiLang";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom"
import { getQueryObj } from "../../helpers/getQueryObj"
import { useDispatch } from "react-redux";
import { activateFeedback, updateWallet } from "../../redux/actions";
import useRequest from "../../hooks/useRequest";

const DepositMoney = () => {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const { language } = useSelector((state) => state.reducer);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { search } = useLocation();
  const queryObj = getQueryObj(search)

  //if redirected from stripe payment checkout --------
  const { request: savePamentReq, response: savePamentRes } = useRequest()
  useEffect(() => {
    if (queryObj?.success === 'true' && queryObj?.session_id) {
      savePamentReq("POST", "/save-payment", { session_id: queryObj?.session_id })
    } else if (queryObj.success === 'false') {
      dispatch(activateFeedback({ type: 'error', message: 'Amount deposit failed !' }))
    }
  }, [])

  useEffect(() => {
    if (savePamentRes) {
      dispatch(activateFeedback({ type: savePamentRes?.status, message: savePamentRes?.message }))
      navigate('/setting?tab=payment')
      if (savePamentRes?.status === "success") {
        dispatch(updateWallet(savePamentRes?.amount))
        navigate('/setting?tab=payment')
      }
    }
  }, [savePamentRes])
  //---------------------------------------------------

  return (
    <>
      <div className="setting-content-con">
        <div className="setting-content-con-header">{MultiLang["Deposit Money"][language]}</div>
        {!paymentMethod && (
          <div className="bank-container newBankBlock">
            <div
              className="bank-list light"
              onClick={() => {
                setPaymentMethod("bank");
              }}
            >
              <img src="./images/bank.png" width={"40px"} alt="" />
              <div>
                {MultiLang["Bank"][language]}
                <br />
                {MultiLang["Payment"][language]}
              </div>
            </div>
            <div
              className="bank-list light"
              onClick={() => {
                setPaymentMethod("creditCard");
              }}
            >
              <img src="./images/credit-card.png" width={"40px"} alt="" />
              <div>
                {MultiLang["Credit Card"][language]} <br />
                {MultiLang["Payment"][language]}
              </div>
            </div>
            <div
              className="bank-list light"
              onClick={() => {
                setPaymentMethod("paypal");
              }}
            >
              <img src="./images/paypal.png" width={"40px"} alt="" />
              <div>
               11 PayPal <br />
                {MultiLang["Payment"][language]}
              </div>
            </div>
          </div>
        )}
        {paymentMethod === "creditCard" && (
          <CreditCardDeposit setPaymentMethod={setPaymentMethod} />
        )}
        {paymentMethod === "bank" && (
          <BankDeposit setPaymentMethod={setPaymentMethod} />
        )}
        {paymentMethod === "paypal" && (
          <PayPalDeposit setPaymentMethod={setPaymentMethod} />
        )}
      </div>
    </>
  );
};

export default DepositMoney;
