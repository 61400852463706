export const SET_SETTING = "SET_SETTING";
export const ACTIVATE_FEEDBACK = "ACTIVATE_FEEDBACK";
export const DEACTIVATE_FEEDBACK = "DEACTIVATE_FEEDBACK";
export const SET_SOCKET = "SET_SOCKET"
export const SOCKET_ACTIVE_CHALLENGE = "SOCKET_ACTIVE_CHALLENGE"
export const ACTIVE_CHALLENGE = "ACTIVE_CHALLENGE";
export const ONLINE_USERS = "ONLINE_USERS"


// "total_count_join": total_count_in_challenge :::: join count
// "total_count_in_challenge":total_count_join :::  participater count

// participater count-join count = join or not 