import "./../../css/challenge_start.css"
import { useEffect, useState, Fragment } from "react"
import { Link } from "react-router-dom";
import useRequest from "../../hooks/useRequest"
import VideoRecorder from "./VideoRecorder";
import { useSelector, useDispatch } from "react-redux"
import { activateFeedback, setActiveTimingChallenges } from "../../redux/actions"
import moment from "moment"
import VideoBox from "./VideoBox";
import Counter from "../components/Counter";
import Preloader from "../components/preloader";

import fiveToOne from "../../audio/5to1Female.mp3"
import tenToOne from "../../audio/10to0Female2.wav"
import ReverseClock from "../components/ReverseClock";

export default function StartChallenge({ id, challengeData, uploadVideo, cancelChallenge, setChallengeData }) {
    const { socketActiveChallenge } = useSelector(state => state.Site)
    const [mode, setMode] = useState('msg')
    const [videoTime, setVideoTime] = useState(null)
    const [message, setMessage] = useState("")
    const [confirmRejectModel, setConfirmRejectModel] = useState(false)
    const dispatch = useDispatch()

    const { request: partcipateStatusReq, response: partcipateStatusRes } = useRequest()

    useEffect(() => {
        window.scrollTo(0, 0)
        // partcipateStatusReq('GET', `/participate-status/${id}`)
    }, [])

    useEffect(() => {
        if (socketActiveChallenge) {
            console.log('available')
            setMessage(socketActiveChallenge?.message)
            if (socketActiveChallenge?.start) {
                setVideoTime({
                    challengeTimeSec: socketActiveChallenge?.totalTimeSec,
                    joinTimeSec: socketActiveChallenge?.joinTimeSec,
                    // totalTimeSec: socketActiveChallenge?.totalTimeSec
                })
                if (socketActiveChallenge?.joinTimeSec > 0) {
                    setMode('prepare')
                } else {
                    setMode('start')
                }
            }
        } else {
            console.log('not available')
            partcipateStatusReq('GET', `/participate-status/${id}`)
        }
    }, [socketActiveChallenge])

    useEffect(() => {
        if (partcipateStatusRes) {
            setMessage(partcipateStatusRes?.message)

            

            if (partcipateStatusRes?.endTimeSecond && !challengeData?.timingChallengeData) {
                setChallengeData({
                    ...challengeData, timingChallengeData: {
                        startTime: parseFloat(partcipateStatusRes.endTimeSecond),
                        challId: id,
                        challengeTimeSec: parseInt(partcipateStatusRes?.challengeTimeSec) - parseInt(partcipateStatusRes?.joinTimeSec),
                        joinTimeSec: parseInt(partcipateStatusRes?.joinTimeSec),
                        totalTimeSec: parseInt(partcipateStatusRes?.challengeTimeSec)
                    }
                })
            }

            //-----------------------------------------------

            if (partcipateStatusRes?.start) {

                if (partcipateStatusRes?.challengeTimeSec < 0) {
                    setMode('msg2')
                    return
                }
                setVideoTime({
                    start: partcipateStatusRes?.joinTime,
                    end: partcipateStatusRes?.challengeTime,
                    challengeTimeSec: partcipateStatusRes?.challengeTimeSec,
                    joinTimeSec: partcipateStatusRes?.joinTimeSec
                })

                if (partcipateStatusRes?.joinTimeSec > 0) {
                    setMode('prepare')
                } else {
                    setMode('start')
                }
            }
        }
    }, [partcipateStatusRes])

    return (
        <>
            <div className="content">
                <div className="create-challenge-header">
                    <Link to="/challenge" className="create-challenge-back"><img src="/images/create_challenge/icon-left-arrow.png" alt="" /> Back</Link>
                </div>
                {
                    mode === 'prepare' && videoTime &&
                    <div className="challenge-start02-header webcam_header">
                        <div className="content">
                            <VideoRecorder
                                uploadVideo={uploadVideo}
                                uploadType='prepration'
                                timeInterval={challengeData?.challenge_type?.interval_time ?? 0}
                                videoTime={videoTime}
                                time={videoTime.joinTimeSec}
                                setMode={setMode}
                                currentMode={mode}
                                nextMode='gap'
                            />
                            {
                                challengeData?.video_description &&
                                <div className="auth-alert">{challengeData?.video_description} Password : {socketActiveChallenge?.password}</div>
                            }
                        </div>
                    </div>
                }
                {
                    mode === 'gap' &&
                    <div className="challenge-start02-header">
                        <span>Starting the Challenge after</span>
                        <Counter
                            time={5}
                            setMode={setMode}
                            nextMode='start'
                            audioSrc={fiveToOne}
                            audioNum={{
                                1: 1,
                                2: 2,
                                3: 3,
                                4: 4,
                                5: 5
                            }}
                        />
                        <span>seconds</span>
                    </div>
                }
                {
                    mode === 'start' && videoTime &&
                    <div className="challenge-start02-header webcam_header">
                        <div className="content">
                            <VideoRecorder
                                uploadVideo={uploadVideo}
                                uploadType='challenge'
                                timeInterval={challengeData?.challenge_type?.interval_time ?? 0}
                                videoTime={videoTime}
                                time={videoTime.challengeTimeSec - videoTime.joinTimeSec}
                                setMode={setMode}
                                currentMode={mode}
                                nextMode='loading'
                                audioSrc={tenToOne}
                                audioNum={{
                                    1: 1,
                                    2: 2,
                                    3: 3,
                                    4: 4,
                                    5: 5,
                                    6: 6,
                                    7: 7,
                                    8: 8,
                                    9: 9,
                                    10: 10
                                }}
                            />
                        </div>
                    </div>
                }
                {
                    mode === 'loading' && <Preloader />
                }
            </div>            

            {
                mode === 'msg' &&
                <>
                    {
                        challengeData?.isChallenge_type === 'timing' && challengeData?.timingChallengeData ?
                            <div className="challenge-start02-header">
                                <span>Time left before the challenge</span>
                                <ReverseClock
                                    timingChallengeData={challengeData.timingChallengeData}
                                    // challenge_type={challengeData?.challenge_type}
                                    // challId={challengeData?.id}
                                    startTime={challengeData?.end_time}
                                />
                                <div className="challenge-heading">
                                    <div className="challenge-start02-bottom web_cambottombtn">
                                        <button className="blue-btn btn" onClick={() => { setConfirmRejectModel(true) }}>Cancel This Challenge</button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="challenge-heading">
                                <div>{message}</div>
                                <div className="challenge-start02-bottom web_cambottombtn">
                                    <button className="blue-btn btn" onClick={() => { setConfirmRejectModel(true) }}>Cancel This Challenge</button>

                                </div>
                            </div>
                    }


                </>
            }
            {
                mode === 'msg2' &&
                <div className="challenge-heading">
                    <div>Time for submitting the challenge is over !</div>
                </div>
            }

            <div className="content">
                <div className="challenge-start01-main">
                    <div>
                        <div className="challenge-start02-con01 _sp">
                            {challengeData?.exercise_title ? challengeData?.exercise_title : 'Warm up'}:
                            <div className="auth-alert">
                                {challengeData?.exercise_description ? challengeData?.exercise_description : "Don't rush these exercises. Warming up improves performance, reduces risk of injury and prepares you mentally"}

                            </div>
                        </div>
                        <div className="challenge-start01-con03">
                            <div>
                                Rules:
                            </div>
                            <div >
                                <div className='challenge-rules' dangerouslySetInnerHTML={{ __html: challengeData?.rules }} />
                                {
                                    challengeData?.challenge_rule_images &&
                                    <div className="challenge-start01-con03-imgs">
                                        {
                                            challengeData?.challenge_rule_images.map((val, i) => {
                                                return (
                                                    <Fragment key={i}>
                                                        {
                                                            val?.correct_image &&
                                                            <div className="false-img">
                                                                <img src={val?.correct_image} alt="" />
                                                            </div>
                                                        }
                                                        {
                                                            val?.incorrect_image &&
                                                            <div className="true-img">
                                                                <img src={val?.incorrect_image} alt="" />
                                                            </div>
                                                        }
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            challengeData?.challenge_exercise && challengeData?.challenge_exercise.length > 0 &&
                            <div className="challenge-start01-con04">
                                <div className="challenge-start01-con04-header">{challengeData?.exercise_title ? challengeData?.exercise_title : 'Exercise'} :</div>
                                {
                                    challengeData.challenge_exercise.map((val, i) => {
                                        return (
                                            <div key={i} className="challenge-start01-con04-list">
                                                <div >
                                                    <img src={val?.image ? val.image : '/images/profile/chall.png'} alt="" />
                                                    <div>{val?.exercise_name ? val.exercise_name : "--"} </div>
                                                </div>
                                                <span>{val?.exercise_point ? val.exercise_point : "--"} xp</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>

            {/* <div className={confirmRejectModel === false ? "finish-modal " : "finish-modal active"}>
                <div className="finish-modal-main  video-confirm-modal-main">
                    <div className="setting-input">
                        <div className="setting-input-con">
                            <label htmlFor="new_email">Cancel Reason?</label>
                            <input
                                name='reject_reason'
                                type="text"
                                onChange={(e) => { setRejectText(e.target.value) }}
                                value={rejectText}
                            />
                            {rejectErr && <div className="input-error">{rejectErr}</div>}
                        </div>
                    </div>
                </div>
                <div className="video-confirm-modal-bottom">
                    <button className="btn blue-btn" onClick={handleSubmitReject}>Submit</button>                    
                </div>
            </div> */}

            <div className={confirmRejectModel === false ? "finish-modal" : "finish-modal active"}>
                <div className="finish-modal-main  video-confirm-modal-main">
                    <div>
                        Are you sure, you want to cancel?
                    </div>
                </div>
                <div className="video-confirm-modal-bottom">
                    <button className="btn blue-btn" onClick={() => { cancelChallenge(); setConfirmRejectModel(false) }}>Yes</button>
                    <button className="btn blue-btn" onClick={() => { setConfirmRejectModel(false) }}>No</button>
                </div>
            </div>
        </>
    )
}