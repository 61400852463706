import React, { useState, useEffect } from 'react'
import useRequest from "../../hooks/useRequest";
// import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// import CheckoutForm from "./CheckoutForm";
import { useSelector, useDispatch } from "react-redux"
import PreloaderInner from '../components/preloaderInner';
import Preloader from '../components/preloader';
import { Link } from "react-router-dom"
import { MultiLang } from '../MultiLang';


import { activateFeedback } from "../../redux/actions";

import { useFormik } from "formik"
import * as Yup from 'yup';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const CreditCardDeposit = ({ setPaymentMethod }) => {
    const dispatch = useDispatch()

    const { loading, user } = useSelector(state => state.Auth)
    const { settings } = useSelector(state => state.Site)

    // const [showPamentModel, setShowPamentModel] = useState(false)
    const [isShow, setIsShow] = useState(false)
    // const [clientSecret, setClientSecret] = useState(null);
    const [netAmt, setNetAmt] = useState(0)
    const [verificationModel, setVerificationModel] = useState(false)
    const { language } = useSelector((state) => state.reducer);

    // const { request: reqCreateIntent, response: resCreateIntent } = useRequest()
    const { request: reqCheckout, response: resCheckout } = useRequest()

    const formik = useFormik({
        initialValues: {
            amount: ""
        },
        // validationSchema: Yup.object({
        //     amount: Yup.number()
        //         .required('Please Enter Amount')
        //         .min(1, `Min Amount must be 1€`)
        //         .max(1000, 'Max Amount must be 1000€'),
        // }),
        onSubmit: values => {
            if (user?.verification_status !== 'approved' && settings?.deposite_before == 1) {
                setVerificationModel(true)
                return
            }

            if (settings?.service_fee && settings?.fixed_service_fee) {
                setNetAmt((+values.amount + (+values.amount * (+settings?.service_fee / 100) + +settings.fixed_service_fee)).toFixed(2))
            }

            setIsShow(true)
            // setShowPamentModel(true);
        },
        validate: (values) => {
            let { amount } = values
            let errors = {};

            if (!amount) {
                errors.amount = "Please Enter Amount"
            }

            if (+amount < 1) {
                errors.amount = "Min Amount must be 1€"
            }
            if (+amount > 1000) {
                errors.amount = "Max Amount must be 1000€"
            }
            return errors;
        },
    });

    const handleSubmit = () => {

        reqCheckout('POST', '/create-checkout', { amount: +formik?.values?.amount })


        // setIsShow(false)
        // setShowPamentModel(true);
        // reqCreateIntent("POST", "/create-payment-intent", { amount: +formik?.values?.amount })
    }

    useEffect(() => {
        if (resCheckout) {
            if (resCheckout?.data?.url) {
                window.location.replace(resCheckout.data.url);
            } else {
                dispatch(activateFeedback({ type: 'error', message: 'Amount deposit failed !' }))
            }

        }
    }, [resCheckout])

    // useEffect(() => {
    //     if (resCreateIntent) {
    //         setClientSecret(resCreateIntent?.client_secret);
    //         setShowPamentModel(true);
    //     }
    // }, [resCreateIntent])
    return (
        <>
            {/* {!loading && <Preloader />} */}
            <form onSubmit={formik.handleSubmit}>
                <div className="setting-input">
                    <div className="setting-input-con">
                        <div className="card_labelbx">
                            <label htmlFor="new_email">{MultiLang["Amount"][language]} (€)</label>
                        </div>
                        <input
                            // disabled={showPamentModel}
                            disabled={isShow}

                            name="amount"
                            {...formik.getFieldProps('amount')}
                            type="number"
                        />
                        {formik.touched.amount && formik.errors.amount && <div className="input-error">{formik.errors.amount}</div>}
                    </div>
                </div>
                <div className="setting-bottom margin-btn">
                    <button
                        type='submit'
                        className="blue-btn btn setting-btn"
                    >
                        {MultiLang["Deposit"][language]}
                    </button>
                </div>
                <div className="setting-bottom">
                    <button
                        className="btn setting-btn"
                        onClick={() => {
                            setPaymentMethod(null);
                        }}
                    >
                        {MultiLang["Go Back"][language]}
                    </button>
                </div>
            </form>
            {/* {loading && !showPamentModel && <PreloaderInner />} */}

            {/* <div
                className={
                    showPamentModel
                        ? "finish-modal setting-modal active"
                        : "finish-modal setting-modal"
                }
            >
                <div
                    className="modal-close"
                    onClick={() => {
                        setShowPamentModel(false);
                    }}
                >
                    <img src="images/auth/auth-close.png" alt="" />
                </div>
                <div className="modal-header">{MultiLang["Payment"][language]}</div>
                <div className="modal-body">
                    <Elements stripe={stripePromise}>
                        <CheckoutForm
                            clientSecret={clientSecret}
                            setIsShow={setShowPamentModel}
                            amount={+formik?.values?.amount}
                        />
                    </Elements>
                </div>
            </div> */}

            <div className={isShow === false ? "finish-modal deposit-modal" : "finish-modal deposit-modal active"}>
                <div className="finish-modal-main ">
                    <table>
                        <tr>
                            <td>{MultiLang["Credit to Wallet"][language]}</td>
                            <td>{formik?.values?.amount}€</td>
                        </tr>
                        <tr>
                            <td>{MultiLang["Service Charge"][language]}</td>
                            <td>{(+netAmt - +formik?.values?.amount).toFixed(2)}€</td>
                        </tr>
                        <tr>
                            <td>{MultiLang["Debit from Account"][language]}</td>
                            <td>{netAmt}€</td>
                        </tr>
                    </table>
                </div>
                <div className="video-confirm-modal-bottom mt-4">
                    <button className="btn blue-btn" onClick={handleSubmit}>Yes</button>
                    <button className="btn blue-btn" onClick={() => { setIsShow(false); }}>No</button>
                </div>
            </div>
            <div className={verificationModel === false ? "finish-modal finish_modalspace" : "finish-modal  finish_modalspace active"}>
                <div className="modal-close" onClick={() => { setVerificationModel(false) }}>
                    <img src="/images/auth/auth-close.png" alt="" />
                </div>
                <div className="finish-modal-main">
                    <div>{MultiLang["not-verified"][language]}</div>
                </div>
                <div >
                    <Link to="/setting?tab=kyc" onClick={() => { setVerificationModel(false) }} className="btn blue-btn">{MultiLang["Verify"][language]}</Link>
                </div>
            </div>
        </>
    )
}

export default CreditCardDeposit
