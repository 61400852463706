import {
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  SIDEBAR_TOGGLE,
  LOADING,
} from "./actionTypes";

const initialState = {
  loggedIn: null,
  clientLogin: null,

  user: {
    first_name: "Guest",
    last_name: "User",
    email: "",
  },
  access_token: null,
  // error: null,
  loading: false,
  language: "GB",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SUCCESS:
      const auth = localStorage.getItem("auth");
      const user = { ...action.updates, access_token: auth?.access_token };
      return {
        ...state,
        loggedIn: true,
        clientLogin:true,
        user: user,
        access_token: auth?.access_token,
        ...action.updates,
        loading: false,
      };
    case AUTH_LOGOUT:
      return {
        ...initialState,
        user: null,
        clientLogin:false,
        loggedIn: false,
        access_token: null,
      };
    case SIDEBAR_TOGGLE:
      return {
        ...state,
        ...action.updates,
      };
    case LOADING:
      return {
        ...state,
        ...action.updates,
      };
    default:
      return state;
  }
};

export default authReducer;
