import "./../../css/challenge_start.css"
import { useEffect, useState, Fragment } from "react"
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import useRequest from "../../hooks/useRequest"
import PreloaderOuter from "./../components/preloaderOuter";
import Preloader from "./../components/preloader";
import { useSelector, useDispatch } from "react-redux";
import { activateFeedback, updateKycStatus, updateWallet, setActiveChallenge, setActiveTimingChallenges } from "../../redux/actions";
import VideoRecorder from "./VideoRecorder";
import StartChallenge from "./StartChallenge";
import StartChallengeTest from "./StartChallengeTest";
import { MultiLang } from "../MultiLang";

export default function ViewOne() {
    const { language } = useSelector((state) => state.reducer);
    const { settings, socket, socketActiveChallenge, activeChallenge } = useSelector(state => state.Site)
    const { loading, user } = useSelector(state => state.Auth)
    const dispatch = useDispatch()
    const { id } = useParams()
    // const {state} = useLocation()
    // console.log(state)

    const [finishModalState, setFinishModalState] = useState(false);
    const [reviewState, setReviewState] = useState(false);
    const [reviewData, setReviewData] = useState({ is_like: null })
    const [reviewErr, setReviewErr] = useState({})

    const [challengeData, setChallengeData] = useState(null)
    const [isBought, setIsBought] = useState(false)
    // const [isSubmited, setIsSubmited] = useState(false)


    const [videofile, setVideofile] = useState(null)
    const [mode, setMode] = useState("test")
    const [startChall, setStartChall] = useState(false)
    const [stopWatch, setStopWatch] = useState(5)

    const [verificationModel, setVerificationModel] = useState(false)


    const navigate = useNavigate()

    const { request, response } = useRequest()
    const { request: buyReq, response: buyRes } = useRequest()
    const { request: partcipateStatusReq, response: partcipateStatusRes } = useRequest()
    const { request: uploadChallengeVideoReq, response: uploadChallengeVideoRes } = useRequest()
    const { request: uploadPreprationVideoReq, response: uploadPreprationVideoRes } = useRequest()
    const { request: sendReviewReq, response: sendReviewRes } = useRequest()
    const { request: cancelReq, response: cancelRes } = useRequest()

    useEffect(() => {
        window.scrollTo(0, 0)
        request('GET', `/challenge/${id}`)
        // partcipateStatusReq('GET', `/participate-status/${id}`)
    }, [])

    useEffect(() => {
        if (socketActiveChallenge?.start) {
            // console.log('fetching');
            // request('GET', `/challenge/${id}`)
            setMode('live')
        }
    }, [socketActiveChallenge])

    useEffect(() => {
        if (response) {

            setChallengeData(response?.data)
            setIsBought(response?.data?.isParticipated)
            // setIsSubmited(response?.data?.isChallengeSubmitted)

            dispatch(setActiveChallenge({
                isParticipated: response?.data?.isParticipated,
                isChallengeSubmitted: response?.data?.isChallengeSubmitted
            }))


            // if (response?.data?.isParticipated) {
            //     setMode('live')
            //     if (response?.data?.isChallengeSubmitted) {
            //         setMode('done')
            //     }
            // } else {
            //     setMode('test')
            // }

        }
    }, [response])

    useEffect(() => {
        if (activeChallenge) {
            if (activeChallenge.isParticipated) {
                setMode('live')
                if (activeChallenge.isChallengeSubmitted) {
                    setMode('done')
                }
            } else {
                setMode('test')
            }
        }
    }, [activeChallenge])

    const handleBuyChallenge = () =>
    // if (user?.verification_status !== 'approved') {
    //     setVerificationModel(true)
    //     return
    // }    

    {
        if (isBought) {
            return
        }
        buyReq('POST', '/challenge-participate', {
            challenge_id: id,
            joining_amount: challengeData?.joining_fee,
            // service_fee: +settings?.ServiceFee
        })
    }

    useEffect(() => {
        if (buyRes) {
            dispatch(activateFeedback({ type: buyRes?.status, message: buyRes?.message }))
            if (buyRes?.status === 'success') {

                dispatch(setActiveChallenge({ isParticipated: true }))
                dispatch(updateWallet((+user?.wallet_balance - +challengeData?.joining_fee).toFixed(2)))

                if (challengeData?.isChallenge_type === 'regular') {
                    socket?.emit('JoinChallenge', { room: id });
                    socket?.emit('ChallengeInfo', { challenge_id: id })
                }

                if (buyRes?.endTimeSecond) {
                    setChallengeData({
                        ...challengeData, timingChallengeData: {
                            startTime: parseFloat(buyRes.endTimeSecond),
                            challId: id,
                            challengeTimeSec: parseInt(buyRes?.challengeTimeSec) - parseInt(buyRes?.joinTimeSec),
                            joinTimeSec: parseInt(buyRes?.joinTimeSec),
                            totalTimeSec: parseInt(buyRes?.challengeTimeSec)
                        }
                    })
                }

                //-----------------------------------------------
            }
        }
    }, [buyRes])

    const uploadVideo = (file, type) => {
        localStorage.removeItem('timeCount')
        if (!file || !type) {
            dispatch(activateFeedback({ type: 'error', message: 'Video was not recorded correctly' }))
            return
        }
        let formData = new FormData()

        if (type === 'prepration') {
            formData.append('preparation_video', file)
            formData.append('challenge_id', id)
            uploadPreprationVideoReq('POST', '/save-preparation-video', formData)
        }

        if (type === 'challenge') {
            formData.append('challenge_video', file)
            formData.append('challenge_id', id)
            uploadChallengeVideoReq('POST', '/save-challange', formData)
        }

    }

    const handleReviewChange = (e) => {
        setReviewErr({})
        const { name, value } = e.target
        setReviewData((prev) => {
            return { ...prev, [name]: value }
        })
    }

    const reviewValidate = () => {

        if (!reviewData.experience) {
            setReviewErr({ ...reviewErr, experience: 'required' })
            return false
        }
        if (!reviewData.suggestion) {
            setReviewErr({ ...reviewErr, suggestion: 'required' })
            return false
        }
        if (reviewData.is_like === null) {
            setReviewErr({ ...reviewErr, is_like: 'required' })
            return false
        }
        return true
    }

    const handleSubmitReview = () => {
        setReviewErr({})
        let isValidate = reviewValidate()
        if (!isValidate) return
        sendReviewReq('POST', '/review', { ...reviewData, challenge: id })
    }

    useEffect(() => {
        if (sendReviewRes) {
            if (sendReviewRes?.status === 'success') {
                setReviewState(false)
                navigate('/challenge')
            } else {
                dispatch(activateFeedback({ type: 'error', message: sendReviewRes?.message }))
            }
        }
    }, [sendReviewRes])

    useEffect(() => {
        if (uploadChallengeVideoRes) {
            if (uploadChallengeVideoRes?.status === 'success') {
                setFinishModalState(true)
                // request('GET', `/challenge/${id}`)
                dispatch(setActiveChallenge({ isChallengeSubmitted: true }))
            } else {
                dispatch(activateFeedback({ type: 'error', message: uploadChallengeVideoRes?.message }))
            }
        }
    }, [uploadChallengeVideoRes])

    const handleCounter = () => {
        if (stopWatch > 0) {
            setTimeout(() => {
                setStopWatch(stopWatch - 1)
            }, 1000)
        } else {
            setStartChall(false)
        }
    }

    useEffect(() => {
        if (startChall) {
            handleCounter()
        }
    }, [stopWatch])

    const handleCancelChallenge = () => {
        // console.log('cancelled')
        cancelReq('POST', '/reject-challange', {
            challenge: id,
            status: 'reject',
            // reject_reason: reason
        })
    }

    useEffect(() => {
        if (cancelRes) {
            dispatch(activateFeedback({ type: cancelRes?.status, message: cancelRes?.message }))
            if (cancelRes?.status === 'success') {
                request('GET', `/challenge/${id}`)
                dispatch(updateWallet(+user?.wallet_balance + +challengeData?.joining_fee))
            }
        }
    }, [cancelRes])



    return (
        <>
            {/* {
                loading && <Preloader />
            } */}
            {
                challengeData ?
                    <div className="_challenge-start _challenge-start02">

                        {
                            mode === 'test' &&
                            <StartChallengeTest id={id} challengeData={challengeData} buyChallenge={handleBuyChallenge} />
                        }
                        {
                            mode === 'live' &&
                            <StartChallenge
                                id={id}
                                challengeData={challengeData}
                                uploadVideo={uploadVideo}
                                cancelChallenge={handleCancelChallenge}
                                setChallengeData={setChallengeData}
                            />
                        }
                        {
                            mode === 'done' &&
                            <div className="challenge-heading" >Your challenge video was submitted. Result will be announced soon.</div>
                        }

                        <div className={reviewState === false ? "finish-modal " : "finish-modal active"}>
                            <div className="finish-modal-main  video-confirm-modal-main">
                                <div className="setting-input">
                                    <div className="setting-input-con">
                                        <label style={{ color: 'white' }} htmlFor="new_email">Do you like it?</label>
                                        <button className={reviewData.is_like === false ? "active" : ""} onClick={() => { setReviewData({ ...reviewData, is_like: false }) }}>
                                            <div className="icon-svg"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path d="M466.27 225.31c4.674-22.647.864-44.538-8.99-62.99 2.958-23.868-4.021-48.565-17.34-66.99C438.986 39.423 404.117 0 327 0c-7 0-15 .01-22.22.01C201.195.01 168.997 40 128 40h-10.845c-5.64-4.975-13.042-8-21.155-8H32C14.327 32 0 46.327 0 64v240c0 17.673 14.327 32 32 32h64c11.842 0 22.175-6.438 27.708-16h7.052c19.146 16.953 46.013 60.653 68.76 83.4 13.667 13.667 10.153 108.6 71.76 108.6 57.58 0 95.27-31.936 95.27-104.73 0-18.41-3.93-33.73-8.85-46.54h36.48c48.602 0 85.82-41.565 85.82-85.58 0-19.15-4.96-34.99-13.73-49.84zM64 296c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zm330.18 16.73H290.19c0 37.82 28.36 55.37 28.36 94.54 0 23.75 0 56.73-47.27 56.73-18.91-18.91-9.46-66.18-37.82-94.54C206.9 342.89 167.28 272 138.92 272H128V85.83c53.611 0 100.001-37.82 171.64-37.82h37.82c35.512 0 60.82 17.12 53.12 65.9 15.2 8.16 26.5 36.44 13.94 57.57 21.581 20.384 18.699 51.065 5.21 65.62 9.45 0 22.36 18.91 22.27 37.81-.09 18.91-16.71 37.82-37.82 37.82z" fill="Currentcolor" /></svg>
                                            </div>
                                            <span >
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 56v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24H24C10.745 32 0 42.745 0 56zm40 200c0-13.255 10.745-24 24-24s24 10.745 24 24-10.745 24-24 24-24-10.745-24-24zm272 256c-20.183 0-29.485-39.293-33.931-57.795-5.206-21.666-10.589-44.07-25.393-58.902-32.469-32.524-49.503-73.967-89.117-113.111a11.98 11.98 0 0 1-3.558-8.521V59.901c0-6.541 5.243-11.878 11.783-11.998 15.831-.29 36.694-9.079 52.651-16.178C256.189 17.598 295.709.017 343.995 0h2.844c42.777 0 93.363.413 113.774 29.737 8.392 12.057 10.446 27.034 6.148 44.632 16.312 17.053 25.063 48.863 16.382 74.757 17.544 23.432 19.143 56.132 9.308 79.469l.11.11c11.893 11.949 19.523 31.259 19.439 49.197-.156 30.352-26.157 58.098-59.553 58.098H350.723C358.03 364.34 384 388.132 384 430.548 384 504 336 512 312 512z" fill="Currentcolor" /></svg>
                                            </span>
                                        </button>
                                        <button className={reviewData.is_like === true ? "active" : ""} onClick={() => { setReviewData({ ...reviewData, is_like: true }) }}>
                                            <div className="icon-svg"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M466.27 286.69C475.04 271.84 480 256 480 236.85c0-44.015-37.218-85.58-85.82-85.58H357.7c4.92-12.81 8.85-28.13 8.85-46.54C366.55 31.936 328.86 0 271.28 0c-61.607 0-58.093 94.933-71.76 108.6-22.747 22.747-49.615 66.447-68.76 83.4H32c-17.673 0-32 14.327-32 32v240c0 17.673 14.327 32 32 32h64c14.893 0 27.408-10.174 30.978-23.95 44.509 1.001 75.06 39.94 177.802 39.94 7.22 0 15.22.01 22.22.01 77.117 0 111.986-39.423 112.94-95.33 13.319-18.425 20.299-43.122 17.34-66.99 9.854-18.452 13.664-40.343 8.99-62.99zm-61.75 53.83c12.56 21.13 1.26 49.41-13.94 57.57 7.7 48.78-17.608 65.9-53.12 65.9h-37.82c-71.639 0-118.029-37.82-171.64-37.82V240h10.92c28.36 0 67.98-70.89 94.54-97.46 28.36-28.36 18.91-75.63 37.82-94.54 47.27 0 47.27 32.98 47.27 56.73 0 39.17-28.36 56.72-28.36 94.54h103.99c21.11 0 37.73 18.91 37.82 37.82.09 18.9-12.82 37.81-22.27 37.81 13.489 14.555 16.371 45.236-5.21 65.62zM88 432c0 13.255-10.745 24-24 24s-24-10.745-24-24 10.745-24 24-24 24 10.745 24 24z" fill="Currentcolor" /></svg></div>
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M104 224H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V248c0-13.255-10.745-24-24-24zM64 472c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zM384 81.452c0 42.416-25.97 66.208-33.277 94.548h101.723c33.397 0 59.397 27.746 59.553 58.098.084 17.938-7.546 37.249-19.439 49.197l-.11.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632C440.202 511.587 389.616 512 346.839 512l-2.845-.001c-48.287-.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.111 14.804-14.832 20.188-37.236 25.393-58.902C282.515 39.293 291.817 0 312 0c24 0 72 8 72 81.452z" fill="Currentcolor" /></svg>
                                            </span>
                                        </button>
                                        {reviewErr?.is_like && <div className="input-error">{reviewErr?.is_like}</div>}
                                    </div>
                                    <div className="setting-input-con">
                                        <label style={{ color: 'white' }} htmlFor="new_email">How was the experience?</label>
                                        <input
                                            name='experience'
                                            type="text"
                                            onChange={handleReviewChange}
                                        />
                                        {reviewErr?.experience && <div className="input-error">{reviewErr?.experience}</div>}
                                    </div>
                                    <div className="setting-input-con">
                                        <label style={{ color: 'white' }} htmlFor="password">What can we do to improve?</label>
                                        <input
                                            name='suggestion'
                                            type="text"
                                            onChange={handleReviewChange}
                                        />
                                        {reviewErr?.suggestion && <div className="input-error">{reviewErr?.suggestion}</div>}
                                    </div>
                                </div>
                            </div>
                            <div >
                                <button className="btn blue-btn" onClick={handleSubmitReview}>Submit Review</button>
                            </div>
                        </div>
                        <div className={finishModalState === false ? "finish-modal finish_modalspace" : "finish-modal  finish_modalspace active"}>

                            <div className="modal-close" onClick={() => { setFinishModalState(false); navigate('/challenge') }}>
                                <img src="/images/auth/auth-close.png" alt="" />
                            </div>

                            <div className="img-block">
                                <img src="/images/start_challenge/finish.png" alt="" />
                            </div>
                            <div className="finish-modal-main">
                                <div>
                                    You did it, you're a warrior!
                                </div>
                                <div>
                                    The results will be soon publish in your profile. In the  mean time one of the keys for a good recovery after an intense effort is to stretch with the following exercices.
                                    <br /> See you soon! 👋
                                </div>
                            </div>
                            <div >
                                {/* <Link to="/challenge" className="btn blue-btn">Find New Challenge</Link> */}
                                <button className="btn blue-btn reviewbtn_challenge" onClick={() => {
                                    setFinishModalState(false);
                                    setReviewState(true)
                                }}>Submit Review</button>
                            </div>
                        </div>
                        <div className={verificationModel === false ? "finish-modal finish_modalspace" : "finish-modal  finish_modalspace active"}>
                            <div className="modal-close" onClick={() => { setVerificationModel(false) }}>
                                <img src="/images/auth/auth-close.png" alt="" />
                            </div>
                            <div className="finish-modal-main">
                                <div>{MultiLang["not-verified"][language]}</div>
                            </div>
                            <div >
                                <Link to="/setting?tab=kyc" onClick={() => { setVerificationModel(false) }} className="btn blue-btn">{MultiLang["Verify"][language]}</Link>
                            </div>
                        </div>
                    </div>
                    :
                    <PreloaderOuter />
            }
        </>
    )
}