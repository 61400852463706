import { useEffect, useState } from "react";
import "./../css/privacy.css";
import useRequest from "../hooks/useRequest";
import PreloaderOuter from "./components/preloaderOuter";
import { useSelector } from "react-redux";
import { MultiLang } from "./MultiLang";

export default function Privacy() {
  const { request, response } = useRequest();
  const { loading } = useSelector(state => state.Auth)
  
  const [privacyData, setPrivacyData] = useState(null);
  const { language } = useSelector((state) => state.reducer);

  useEffect(() => {
    window.scrollTo(0, 0);
    request("GET", '/privacy');
  }, []);

  useEffect(() => {
    if (response) {       
      setPrivacyData(response?.privacyPolicy);
    }
  }, [response])

  return (
    loading ? <PreloaderOuter /> :
      <div className="_privacy">
        <div className="content">
          {/* <div className="main-title">{privacyData?.page_title}</div> */}
          <div className="main-title">{MultiLang["privacy-policy"][language]}</div>
          <div className="p-main">
            <div
              dangerouslySetInnerHTML={{
                __html: privacyData?.description
              }}
            />
          </div>
        </div>
      </div>
  );
}
