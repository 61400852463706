import React, { useState, useRef, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { deactivateFeedback } from "../../redux/actions"

export const FeedbackModel = React.memo(({ show, data, setShow }) => {
    // console.log("model renders")

    const { feedbackModel } = useSelector(state => state.Site)
    const dispatch = useDispatch()

    const modalRef = useRef(null)
    // const checkIfClickedOutside = e => {
    //     if (!modalRef.current.contains(e.target)) {
    //         dispatch(deactivateFeedback());
    //     }
    // }
    if (feedbackModel?.active) {
        // window.addEventListener("mousedown", checkIfClickedOutside)

        let timer = setTimeout(() => {
            dispatch(deactivateFeedback());
        }, 3000)

        modalRef.current.addEventListener("mouseover", () => {
            clearTimeout(timer);
        })

        modalRef.current.addEventListener("mouseout", () => {
            timer = setTimeout(() => {
                dispatch(deactivateFeedback());
            }, 1000)
        })

    }

    return (
        <div
            ref={modalRef}
            className={feedbackModel?.active ? "auth-modal finish-modal active" : "auth-modal finish-modal"}
        >
            <div
                className="modal-close"
                onClick={() => { dispatch(deactivateFeedback()) }}
            >
                <img src="images/auth/auth-close.png" alt="" />
            </div>
            <div className="modal-body">
                {feedbackModel?.type === 'error' || feedbackModel?.type === 'fail' ? <img src="./images/error.png" alt="" /> : null}
                <div>
                    {feedbackModel?.message}
                </div>
            </div>
        </div>
    )
});