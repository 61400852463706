import React, { useState, useEffect } from 'react'
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux"
import { setActiveTimingChallenges, setSocketActiveChallenge } from "../../redux/actions"
import { useNavigate } from 'react-router-dom';
import useRequest from '../../hooks/useRequest';

const ReverseClock = ({ timingChallengeData }) => {
    const {
        startTime,
        challId,
        challengeTimeSec,
        joinTimeSec,
        totalTimeSec
    } = timingChallengeData


    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { request: reqPassword, response: resPassword } = useRequest()

    const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
    const [availableSeconds, setAvailableSeconds] = useState(null)

    useEffect(() => {
        setAvailableSeconds(Math.round(startTime))
        dispatch(setActiveTimingChallenges({
            startTime,
            challId
        }))
    }, [])

    useEffect(() => {
        if (availableSeconds <= 0) {
            return
        } else {
            setTimeout(() => {
                let seconds = availableSeconds;
                let minutes = 0;
                let hours = 0;

                if (seconds >= 60) {
                    minutes = Math.floor(seconds / 60);
                    seconds = seconds % 60;
                }

                if (minutes >= 60) {
                    hours = Math.floor(minutes / 60);
                    minutes = minutes % 60;
                }

                // console.log(`${hour}:${min}:${sec}`);
                setTime({ hours, minutes, seconds });
                setAvailableSeconds(availableSeconds - 1)
            }, 1000)
        }
    }, [availableSeconds]);

    return (
        <div>
            {time.hours}
            <span className="time-dot">:</span>
            {time.minutes}
            <span className="time-dot">:</span>
            {time.seconds}
        </div>
    )
}

export default ReverseClock