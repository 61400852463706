import {
    SET_SETTING,
    ACTIVATE_FEEDBACK,
    DEACTIVATE_FEEDBACK,
    SET_SOCKET,
    SOCKET_ACTIVE_CHALLENGE,
    ACTIVE_CHALLENGE,
    ONLINE_USERS
} from './constants';

export const setSetting = data => {
    return {
        type: SET_SETTING,
        payload: data
    }
}

export const activateFeedback = data => {
    return {
        type: ACTIVATE_FEEDBACK,
        payload: data
    }
}
export const deactivateFeedback = data => {
    return {
        type: DEACTIVATE_FEEDBACK,
    }
}

export const makeSocket = data => {
    return {
        type: SET_SOCKET,
        payload: data
    }
}

export const setSocketActiveChallenge = data => {
    return {
        type: SOCKET_ACTIVE_CHALLENGE,
        payload: data
    }
}

export const setActiveChallenge = data => {
    return {
        type: ACTIVE_CHALLENGE,
        payload: data
    }
}

export const setOnlineUsers = count => {
    return {
        type: ONLINE_USERS,
        payload: count
    }
}