import { useState } from "react"
import "../css/pagination.css"


const usePagination = (totalResults = 0) => {

    const [activePage, setActivePage] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    let noOfPages = Math.ceil(totalResults / pageSize)
    let pageArr = []
    for (let i = 1; i <= noOfPages; i++) {
        pageArr.push(i)
    }

    const fromItem = () => {
        let from = pageSize * (activePage - 1) + 1
        if (from <= 0) return 1
        return from
    }

    const toItem = () => {
        let to = pageSize * (activePage - 1) + pageSize
        if (to >= totalResults) return totalResults
        return to
    }

    const decreasePage = () => {
        if (activePage <= 1) return
        setActivePage(activePage - 1)
    }

    const increasePage = () => {
        if (activePage >= noOfPages) return
        setActivePage(activePage + 1)
    }

    let pagination = <>
        <div className='pagination-div newPagination'>
            <div className="pagination-width33 pagination_left">Showing {fromItem()} to {toItem()} of {totalResults}</div>

            <div className=" text-right newPage_size">
                <label htmlFor='pageSize'>Size</label>
                <select name='pageSize' value={`${pageSize}`} onChange={(e) => { setPageSize(+e.target.value); setActivePage(1) }}>
                    <option value="10">Default</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                    <option value='1'>1</option>
                </select>
            </div>
            <div className="leftbtn_nav ">
                <div className="page_num">
                    <button onClick={decreasePage} className="pagelink">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" fill="Currentcolor" /></svg>
                    </button>
                    {
                        pageArr[pageArr.indexOf(activePage) - 2] &&
                        <span
                            className='pagelink other-page'
                            onClick={() => { setActivePage(pageArr[pageArr.indexOf(activePage) - 2]) }}
                        >
                            {pageArr[pageArr.indexOf(activePage) - 2]}
                        </span>
                    }
                    {
                        pageArr[pageArr.indexOf(activePage) - 1] &&
                        <span
                            className='pagelink other-page'
                            onClick={() => { setActivePage(pageArr[pageArr.indexOf(activePage) - 1]) }}
                        >
                            {pageArr[pageArr.indexOf(activePage) - 1]}
                        </span>
                    }
                    <span className='pagelink active-page'>{activePage}</span>
                    {
                        pageArr[pageArr.indexOf(activePage) + 1] &&
                        <span
                            className='pagelink other-page'
                            onClick={() => { setActivePage(pageArr[pageArr.indexOf(activePage) + 1]) }}
                        >
                            {pageArr[pageArr.indexOf(activePage) + 1]}
                        </span>
                    }
                    {
                        pageArr[pageArr.indexOf(activePage) + 2] &&
                        <span
                            className='pagelink other-page'
                            onClick={() => { setActivePage(pageArr[pageArr.indexOf(activePage) + 2]) }}
                        >
                            {pageArr[pageArr.indexOf(activePage) + 2]}
                        </span>}
                    <button className="pagelink rightbtn_nav" onClick={increasePage}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z" fill="Currentcolor" /></svg>
                    </button>
                </div>
            </div>
        </div>
    </>

    return {
        pagination,
        activePage,
        pageSize,
    }
}
export default usePagination;