import "./../../css/challenge_start.css"
import { useEffect, useState, Fragment } from "react"
import { Link } from "react-router-dom";
import VideoRecorderTest from "./VideoRecorderTest";
import moment from "moment";
import { MultiLang } from "../MultiLang";
import { useSelector } from "react-redux";
import useTranslate from "../../hooks/useTranslate";

export default function StartChallengeTest({ challengeData, buyChallenge }) {
    const [videoConfirmState, setVideoConfirmState] = useState(false);
    const [videoMode, setVideoMode] = useState(false)
    const [videofile, setVideofile] = useState(null)
    const [buyConfirmState, setBuyConfirmState] = useState(false)
    const { language } = useSelector((state) => state.reducer);

    const [challengeRule, setChallengeRule] = useState(challengeData?.rules);
    const [challengeDescription, setChallengeDescription] = useState(challengeData?.description);
    const [challengeTitle, setChallengeTitle] = useState(challengeData?.title);

    const { translateRequest: descTranslateRequest, translateResponse: descTranslateResponse } = useTranslate();
    const { translateRequest: ruleTranslateRequest, translateResponse: ruleTranslateResponse } = useTranslate();
    const { translateRequest: titleTranslateRequest, translateResponse: titleTranslateResponse } = useTranslate();

    const initialTime = challengeData?.end_time;
    const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });

    useEffect(() => {
        if (challengeData?.isChallenge_type !== 'timing') {
            return
        }
        let hours = moment(initialTime).diff(moment(), 'hours');
        let minutes = moment(initialTime).diff(moment(), 'minutes') % 60;
        let seconds = moment(initialTime).diff(moment(), 'seconds') % 60;
        setTimeout(() => {
            setTime({ hours, minutes, seconds });
        }, 1000)
    }, [time]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    useEffect(() => {
        titleTranslateRequest(challengeData?.title, language);
    }, [language]);

    useEffect(() => {
        setChallengeTitle(titleTranslateResponse);
        descTranslateRequest(challengeData?.description, language);
    }, [titleTranslateResponse]);

    useEffect(() => {
        setChallengeDescription(descTranslateResponse);
        ruleTranslateRequest(challengeData?.rules, language);
    }, [descTranslateResponse]);

    useEffect(() => {
        setChallengeRule(ruleTranslateResponse);
    }, [ruleTranslateResponse]);

    // console.log(time.hours)
    // console.log(time.minutes)
    // console.log(time.seconds)

    return (
        <>
            <div className="content">
                <div className="create-challenge-header">
                    <Link to="/challenge" className="create-challenge-back"><img src="/images/create_challenge/icon-left-arrow.png" alt="" /> Back</Link>
                </div>
                {
                    videoMode ?
                        <div className="challenge-start02-header webcam_header">
                            <div className="content">
                                <VideoRecorderTest
                                    setVideofile={setVideofile}
                                />
                                {
                                    videofile &&
                                    <div className="challenge-start02-bottom web_cambottombtn">
                                        <button className="blue-btn btn" onClick={() => { setVideoConfirmState(true) }}>{MultiLang["Finish Challenge"][language]}</button>
                                    </div>
                                }
                            </div>
                        </div>
                        :
                        <div className="challenge-start01-header">
                            <div className="back-gradient _sp">
                            </div>
                            <div className="back-img">
                                {/* <img src={challengeData?.cover_image} className="_pc" alt="" />
                                <img src={challengeData?.cover_image} className="_sp" alt="" /> */}
                                {
                                    challengeData?.cover_video ?
                                        <video muted autoPlay loop>
                                            <source src={challengeData?.cover_video} type="video/mp4" />
                                        </video>
                                        :
                                        <>
                                            <img src={challengeData?.cover_image} className="_pc" alt="" />
                                            <img src={challengeData?.cover_image} className="_sp" alt="" />
                                        </>
                                }
                            </div>
                            <div className="challenge-start01-header-main">
                                {challengeTitle}
                            </div>
                            {/* <div className="challenge-start-btn">
                                <button onClick={() => {
                                    setVideoMode(true)
                                    window.scrollTo(0, 0);
                                }}><img src="/images/start_challenge/icon-play.png" alt="" /></button>
                            </div> */}
                        </div>
                }

            </div>

            <div className="content">
                <div className="challenge-start01-main">
                    <div>
                        <div className="challenge-start01-con01">
                            <div>
                                <img src="/images/start_challenge/icon-profile.png" alt="" />
                                <div>
                                    {MultiLang["Participants"][language]}: <span>{challengeData?.total_participate}/{challengeData?.participant_count}</span>
                                </div>
                            </div>
                            <div>
                                <img src="/images/start_challenge/icon-prize.png" alt="" />
                                {
                                    challengeData?.joining_fee_commission ?
                                        <div>
                                            {MultiLang["Registration"][language]}: <span>{+challengeData?.joining_fee - +challengeData?.joining_fee_commission}€ + {+challengeData?.joining_fee_commission}€(Platform fee)</span>
                                        </div>
                                        :
                                        <div>
                                            {MultiLang["Registration"][language]}: <span>{+challengeData?.joining_fee}€</span>
                                        </div>
                                }

                            </div>
                        </div>
                        <div className="_sp">
                            <div className="challenge-start01-prize-con">
                                <div className="challenge-start01-prize-main">
                                    <div>
                                        {challengeData?.second_winner_price}€
                                        <span>{challengeData?.second_winner_xp}xp</span>
                                        <div>
                                            <img src="/images/start_challenge/silver.png" alt="" />
                                            <div>
                                                2nd Place
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {challengeData?.first_winner_price}€
                                        <span>{challengeData?.first_winner_xp}xp</span>
                                        <div>
                                            <img src="/images/start_challenge/gold.png" alt="" />
                                            <div>
                                                1st Place
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {challengeData?.third_winner_price}€
                                        <span>{challengeData?.third_winner_xp}xp</span>
                                        <div>
                                            <img src="/images/start_challenge/bronze.png" alt="" />
                                            <div>
                                                3rd Place
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            challengeDescription &&
                            <div className="challenge-start01-con03">
                                <div>
                                    {MultiLang["Description"][language]}:
                                </div>
                                <div >
                                    <div className='challenge-rules' style={{ color: 'white !important' }} dangerouslySetInnerHTML={{ __html: challengeDescription }} />
                                    {/* {
                                        challengeData?.challenge_password &&
                                        <div className="auth-alert" style={{ marginBottom: '20px' }}>
                                            Password : {challengeData?.challenge_password}
                                        </div>
                                    } */}
                                </div>
                            </div>
                        }

                        <div className="challenge-start01-con02">
                            <div>
                                <img src={challengeData?.best_performer_image ? challengeData?.best_performer_image : '/images/profile/noAvatar.png'} alt="" />
                                <div>
                                    {MultiLang["Best performer"][language]}
                                    <span>{challengeData?.best_performer_name ? challengeData?.best_performer_name : "--"}</span>
                                </div>
                            </div>
                            <div>
                                {MultiLang["Best Result"][language]}
                                <span>{challengeData?.best_performer_result ? challengeData?.best_performer_result + "xp" : "--"}</span>
                            </div>
                        </div>

                        {/* <div className="challenge-start02-con01 _sp">
                            {challengeData?.exercise_title ? challengeData?.exercise_title : 'Warm up'}:
                            <div className="auth-alert">
                                {challengeData?.exercise_description ? challengeData?.exercise_description : "Don't rush these exercises. Warming up improves performance, reduces risk of injury and prepares you mentally"}
                            </div>
                        </div> */}
                        {/* <div className="challenge-start02-con01 _pc">
                            {challengeData?.exercise_title ? challengeData?.exercise_title : 'Warm up'}:
                            <div className="auth-alert">
                                {challengeData?.exercise_description ? challengeData?.exercise_description : "Don't rush these exercises. Warming up improves performance, reduces risk of injury and prepares you mentally"}
                            </div>
                        </div> */}
                        {
                            (challengeRule || challengeData?.challenge_rule_images) &&
                            <div className="challenge-start01-con03">
                                <div>
                                    {MultiLang["Rules"][language]}:
                                </div>
                                <div >
                                    {
                                        challengeRule &&
                                        <div className='challenge-rules' dangerouslySetInnerHTML={{ __html: challengeRule }} />
                                    }
                                    {
                                        challengeData?.challenge_rule_images &&
                                        <div className="challenge-start01-con03-imgs">
                                            {
                                                challengeData?.challenge_rule_images.map((val, i) => {
                                                    return (
                                                        <Fragment key={i}>
                                                            {
                                                                val?.correct_image &&
                                                                <div className="true-img">
                                                                    <img src={val?.correct_image} alt="" />
                                                                </div>
                                                            }
                                                            {
                                                                val?.incorrect_image &&
                                                                <div className="false-img">
                                                                    <img src={val?.incorrect_image} alt="" />
                                                                </div>
                                                            }
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                    </div>
                    <div>
                        <div className="_pc">
                            <div className="challenge-start01-prize-con">
                                <div>
                                    <div>
                                        {MultiLang["Prizes"][language]}
                                    </div>
                                </div>
                                <div className="challenge-start01-prize-main">
                                    <div>
                                        {challengeData?.second_winner_price}€
                                        <span>{challengeData?.second_winner_xp}xp</span>
                                        <div>
                                            <img src="/images/start_challenge/silver.png" alt="" />
                                            <div>
                                                2nd Place
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {challengeData?.first_winner_price}€
                                        <span>{challengeData?.first_winner_xp}xp</span>
                                        <div>
                                            <img src="/images/start_challenge/gold.png" alt="" />
                                            <div>
                                                1st Place
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {challengeData?.third_winner_price}€
                                        <span>{challengeData?.third_winner_xp}xp</span>
                                        <div>
                                            <img src="/images/start_challenge/bronze.png" alt="" />
                                            <div>
                                                3rd Place
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="challenge-start01-prize-bottom">
                                    {
                                        challengeData?.isChallenge_type === 'timing' &&
                                        time.hours >= 0 &&
                                        time.minutes >= 0 &&
                                        time.seconds >= 0 &&
                                        <>
                                            <p>Start Date : {challengeData?.challenge_start_date}</p>
                                            <p>Start Time : {challengeData?.challenge_start_time} <br /> <span style={{ fontSize: '12px' }}>UTC + 1, Central European Time</span></p>
                                            <p>Start in {time.hours}:{time.minutes}:{time.seconds}</p>
                                        </>
                                    }
                                    <button className="blue-btn btn" onClick={() => { setBuyConfirmState(true) }}>Register</button>
                                    {/* {
                                        challengeData?.total_participate < challengeData?.participant_count &&
                                        <>
                                            {
                                                challengeData?.joining_fee_commission ?
                                                    <button className="blue-btn btn" onClick={() => { setBuyConfirmState(true) }}> Buy-in ({+challengeData?.joining_fee - +challengeData?.joining_fee_commission}€ + {+challengeData?.joining_fee_commission}€) </button>
                                                    :
                                                    <button className="blue-btn btn" onClick={() => { setBuyConfirmState(true) }}> Buy-in ({+challengeData?.joining_fee}€) </button>

                                            }
                                        </>
                                    } */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="challenge-start02-con01 _pc">
                            {challengeData?.exercise_title ? challengeData?.exercise_title : 'Warm up'}:
                            <div className="auth-alert">
                                {challengeData?.exercise_description ? challengeData?.exercise_description : "Don't rush these exercises. Warming up improves performance, reduces risk of injury and prepares you mentally"}
                            </div>
                        </div> */}

                        {
                            challengeData?.challenge_exercise && challengeData?.challenge_exercise.length > 0 &&
                            <div className="challenge-start01-con04">
                                <div className="challenge-start01-con04-header">{challengeData?.exercise_title ? challengeData?.exercise_title : 'Exercise'} :</div>
                                <div className="auth-alert" style={{ marginBottom: '20px' }}>
                                    {challengeData?.exercise_description ? challengeData?.exercise_description : "Don't rush these exercises. Warming up improves performance, reduces risk of injury and prepares you mentally"}
                                </div>
                                {
                                    challengeData.challenge_exercise.map((val, i) => {
                                        return (
                                            <div key={i} className="challenge-start01-con04-list">
                                                <div >
                                                    <img src={val?.image ? val.image : '/images/profile/chall.png'} alt="" />
                                                    <div>{val?.exercise_name ? val.exercise_name : "--"} </div>
                                                </div>
                                                <span>{val?.exercise_point ? val.exercise_point : "--"} xp</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }

                    </div>
                </div>
            </div>

            <div className="challenge-start01-prize-bottom _sp">
                {
                    challengeData?.isChallenge_type === 'timing' &&
                    time.hours >= 0 &&
                    time.minutes >= 0 &&
                    time.seconds >= 0 &&
                    <>
                        <p>Start Date : {challengeData?.challenge_start_date}</p>
                        <p>Start Time : {challengeData?.challenge_start_time} <br /> <span style={{ fontSize: '12px' }}>UTC + 1, Central European Time</span></p>
                        <p>Start in {time.hours}:{time.minutes}:{time.seconds}</p>
                    </>

                }
                <button onClick={() => { setBuyConfirmState(true) }} className="blue-btn btn">Register</button>
                {/* {
                    challengeData?.total_participate < challengeData?.participant_count &&
                    <>
                        {
                            challengeData?.joining_fee_commission ?
                                <button onClick={() => { setBuyConfirmState(true) }} className="blue-btn btn">Start Challenge ({+challengeData?.joining_fee - +challengeData?.joining_fee_commission}€ + {+challengeData?.joining_fee_commission}€) </button>
                                :
                                <button onClick={() => { setBuyConfirmState(true) }} className="blue-btn btn">Start Challenge ({+challengeData?.joining_fee}€) </button>
                        }
                    </>
                } */}
            </div>
            <div className={videoConfirmState === false ? "finish-modal" : "finish-modal active"}>
                <div className="finish-modal-main  video-confirm-modal-main">
                    <div>
                        This recording was for testing purpose only. If you want to participate in this challenge then buy it.
                    </div>
                </div>
                <div className="video-confirm-modal-bottom">
                    <button className="btn blue-btn" onClick={() => { setBuyConfirmState(true); setVideoConfirmState(false) }}>Buy Challenge</button>
                    <button className="btn blue-btn" onClick={() => { setVideoConfirmState(false); setVideoMode(false) }}>Cancel</button>
                </div>
            </div>

            <div className={buyConfirmState === false ? "finish-modal" : "finish-modal active"}>
                <div className="finish-modal-main  video-confirm-modal-main">
                    <div>
                        Are you sure, you want to join?
                    </div>
                </div>
                <div className="video-confirm-modal-bottom">
                    <button className="btn blue-btn" onClick={() => { buyChallenge(); setBuyConfirmState(false) }}>Yes</button>
                    <button className="btn blue-btn" onClick={() => { setBuyConfirmState(false); setVideoMode(false) }}>No</button>
                </div>
            </div>

        </>

    )
}