import { useState } from "react"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { setLoading, activateFeedback, setNotification } from "../redux/actions";
import { API } from "../constants/api"


const useRequest = () => {
    const [response, setResponse] = useState(null)
    const [notificationFetched, setNotificationFetched] = useState(false)
    const { access_token, notification } = useSelector(state => state.Auth)
    const dispatch = useDispatch()

    const getNotifications = () => {
        axios.get(
            `${API.PORT}/notifications`,
            {
                headers: { Authorization: `Bearer ${access_token}` }
            }
        )
            .then((res) => {
                // console.log(res.data.data.result)
                dispatch(setNotification({ show: true, data: res?.data?.data?.result }))
            })
    }

    const request = (method, path, data, head) => {
        dispatch(setLoading(true))
        let url = `${API.PORT}${path}`
        let headers = {}
        if (head) {
            headers = { ...head }
        }
        if (access_token) {
            headers.Authorization = `Bearer ${access_token}`
        }
        const config = {
            method,
            headers: { ...headers },
            url,
            data
        }
        axios(config).then((res) => {
            // console.log(res)
            dispatch(setLoading(false))
            setResponse(res.data)

            if (notification?.show && access_token) {
                getNotifications()
            }

        }).catch((err) => {
            dispatch(setLoading(false))
            dispatch(activateFeedback({ type: 'error', message: "something went wrong" }))
            
            if (notification?.show && access_token) {
                getNotifications()
            }
        })



    }
    return {
        request,
        response
    }
}
export default useRequest;