import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import useRequestV2 from "../../../../hooks/useRequestV2";
import Sidebar from "../Sidebar/Sidebar";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import debounce from "lodash.debounce";
import { AiOutlineCheck } from "react-icons/ai";

const ViewAll = () => {
  const location = useLocation();
  const { request, response } = useRequestV2();
  const { request: requestDelete, response: responseDelete } = useRequestV2();
  const { request: requestChangeStatus, response: responseChangeStatus } =
    useRequestV2();
  const { request: requestSendCredentials, response: responseSendCredentials } =
    useRequestV2();

  const [responseData, setResponseData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    if (location.state && location.state.matchValue == "add-employee") {
      toast.success("Employee id has been sent successfully on this email.");
    } else if (
      location.state &&
      location.state.matchValue == "change-password"
    ) {
      toast.success("Employee password has been changed successfully.");
    }
    request("GET", `list-company-employees`);
  }, []);

  useEffect(() => {
    if (response) {
      if (
        response.status == "success" &&
        response.data &&
        response.data.result.length > 0
      ) {
        setResponseData(response.data.result);
      } else {
        setResponseData([]);
      }
    }
  }, [response]);

  const onSubmit = () => {
    const search = document.getElementById("search").value;
    setSearchValue(search);
    request("GET", `list-company-employees?search=${search}`);
  };

  const handleChangeSearch = () => {
    const search = document.getElementById("search").value;
    if (search == "" || search == null) {
      request("GET", `list-company-employees`);
      setSearchValue("");
    }
  };

  const debouncedChangeHandler = useCallback(
    debounce(handleChangeSearch, 1000),
    []
  );

  const changeStatusHandler = (id, status) => {
    // const user = activities.find((activities) => activities.id == id);
    const newStatus = status == 1 ? 0 : 1;

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestChangeStatus("POST", `change-employee-status/${id}`, {
          is_active: newStatus,
        });
      } else if (result.isDismissed) {
      }
    });
  };

  useEffect(() => {
    if (responseChangeStatus) {
      if (responseChangeStatus.status == "success") {
        toast.success("Employee status has been updated successfully.");
        request("GET", `list-company-employees?search=${searchValue}`);
      }
    }
  }, [responseChangeStatus]);

  const handleDelete = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You want to delete this employee?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestDelete("DELETE", `delete-employee/${id}`);
      } else if (result.isDismissed) {
      }
    });
  };

  useEffect(() => {
    if (responseDelete) {
      if (responseDelete.status == "success") {
        toast.success("Employee has been deleted successfully");
        request("GET", `list-company-employees?search=${searchValue}`);
      }
    }
  }, [responseDelete]);

  const handleSendCredentials = (id) => {
    requestSendCredentials("GET", `send-employee-credentials/${id}`);
  };

  useEffect(() => {
    if (responseSendCredentials) {
      if (responseSendCredentials.status == "success") {
        toast.success(
          "Credentials has been sent successfully to employee's mail"
        );
      }
    }
  }, [responseSendCredentials]);
  return (
    <>
      <Sidebar />
      <div className="page_container">
        <div className="main_content">
          <div className="pagetopTitle">
            <h2 className="page-title">ID Management</h2>
          </div>
          <div className="dashDataTable dashboardCard">
            <div className="cardHeader">
              <div className="row">
                <div className="col-md-6">
                  <div className="cardLeftSide">
                    <h3 className="CardHeadTitle">All Employee’s</h3>
                    <Link to="/id/add" className="BlueThemeBtn">
                      New Id
                    </Link>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="cardrightSide">
                    <form className="CardTopSearch" role="search">
                      <input
                        className="form-control me-2"
                        type="search"
                        placeholder="Search *"
                        aria-label="Search"
                        id="search"
                        onChange={debouncedChangeHandler}
                        // {...register("search")}
                      />
                      <button
                        className="searchIconBtn"
                        type="button"
                        onClick={onSubmit}
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.6 17.2929L10.6536 11.3464L10.3371 11.03L9.98765 11.3096C9.53397 11.6725 9.00825 11.9632 8.40607 12.1794C7.81354 12.3921 7.17934 12.5 6.5 12.5C4.8128 12.5 3.40359 11.9215 2.24155 10.7594C1.0788 9.59669 0.5 8.18712 0.5 6.5C0.5 4.81288 1.0788 3.40331 2.24155 2.24055C3.40359 1.07852 4.8128 0.5 6.5 0.5C8.18711 0.5 9.59665 1.07846 10.7594 2.24046C11.9215 3.4032 12.5 4.8128 12.5 6.5C12.5 7.17934 12.3921 7.81354 12.1794 8.40607C11.9632 9.00825 11.6725 9.53397 11.3096 9.98765L11.03 10.3371L11.3464 10.6536L17.2929 16.6L16.6 17.2929ZM10.0414 10.0417L10.0417 10.0414C11.0111 9.07126 11.5 7.88103 11.5 6.5C11.5 5.11897 11.0111 3.92874 10.0417 2.95858L10.0414 2.95831C9.07126 1.9889 7.88103 1.5 6.5 1.5C5.11897 1.5 3.92874 1.9889 2.95858 2.95831L2.95831 2.95858C1.9889 3.92874 1.5 5.11897 1.5 6.5C1.5 7.88103 1.9889 9.07126 2.95831 10.0414L2.95858 10.0417C3.92874 11.0111 5.11897 11.5 6.5 11.5C7.88103 11.5 9.07126 11.0111 10.0414 10.0417Z"
                            fill="white"
                            stroke="#828282"
                          />
                        </svg>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-borderless viewall_table">
                <thead>
                  <tr>
                    <th scope="col">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                      />
                    </th>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">EMAIL</th>
                    <th scope="col">Registered</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {responseData &&
                    responseData.length > 0 &&
                    responseData.map((obj, index) => (
                      <tr key={index}>
                        <th scope="row">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                          />
                        </th>
                        <td>{obj.employee_id ? obj.employee_id : "-"}</td>
                        <td>{obj.full_name ? obj.full_name : "-"}</td>
                        <td>{obj.email ? obj.email : "-"}</td>
                        <td>
                          {obj.created_at
                            ? moment(obj.created_at).format("DD.MM.YY")
                            : "-"}
                        </td>
                        <td>{obj.is_active ? "Activated" : "Not Activated"}</td>
                        <td>
                          <div className="tableActionBtn text-end">
                            {obj.is_active === false ? (
                              <>
                              <a 
                                  href="javascript:void(0);"
                                  onClick={() => {
                                    changeStatusHandler(obj.id, obj.is_active);
                                  }} data-bs-toggle="tooltip" data-bs-placement="top"
                                  data-bs-custom-className="custom-tooltip"
                                  data-bs-title="Click to activate" data-trigger="hover" >
                                    <AiOutlineCheck size={25} color={"green"}/>
                                  </a>
                                
                              </>
                            ) : (
                              <>
                                <a
                                  href="javascript:void(0);"
                                  onClick={() => {
                                    changeStatusHandler(obj.id, obj.is_active);
                                  }}
                                  data-bs-toggle="tooltip" data-bs-placement="top"
                                  data-bs-custom-className="custom-tooltip"
                                  data-bs-title="click to deactivate"
                                >
                                  <svg
                                    width="18"
                                    height="17"
                                    viewBox="0 0 18 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.8 17L0 15.3L7.2 8.5L0 1.7L1.8 0L9 6.8L16.2 0L18 1.7L10.8 8.5L18 15.3L16.2 17L9 10.2L1.8 17Z"
                                      fill="#EB5757"
                                    />
                                  </svg>
                                </a>
                              </>
                            )}

                            {/* <Link to="#">
                              <svg
                                width="20"
                                height="17"
                                viewBox="0 0 20 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.447 2.34506C17.2463 1.66465 16.8299 1.06795 16.2604 0.644948C15.6909 0.221951 14.9994 -0.00439087 14.29 6.45375e-05C13.4679 0.000798322 12.6758 0.30903 12.0694 0.864169C11.463 1.41931 11.0861 2.1812 11.013 3.00006H8.988C8.91386 2.18034 8.53579 1.41799 7.9281 0.862856C7.32042 0.307725 6.52707 -3.642e-05 5.704 6.45375e-05C4.99558 -0.0034128 4.30521 0.223406 3.73689 0.646355C3.16857 1.0693 2.75308 1.66547 2.553 2.34506L0.0420001 10.7131C0.0144929 10.8063 0.000351839 10.9029 0 11.0001V12.0001C0.00158836 13.3258 0.52902 14.5968 1.46657 15.5342C2.40413 16.4716 3.67523 16.9987 5.001 17.0001C5.97268 16.9984 6.92285 16.7138 7.73547 16.1811C8.54809 15.6483 9.18798 14.8905 9.577 14.0001H10.423C10.812 14.8905 11.4519 15.6483 12.2645 16.1811C13.0771 16.7138 14.0273 16.9984 14.999 17.0001C16.3249 16.9987 17.5962 16.4714 18.5338 15.5338C19.4714 14.5963 19.9987 13.325 20 11.9991V11.0001C20 10.9021 19.985 10.8061 19.958 10.7131L17.447 2.34506ZM5.001 15.0001C4.20558 14.999 3.44303 14.6826 2.88049 14.1203C2.31796 13.5579 2.00132 12.7955 2 12.0001C2 11.9241 2.017 11.8531 2.022 11.7781C2.07671 11.0249 2.41394 10.3201 2.96616 9.805C3.51838 9.28987 4.24482 9.00237 5 9.00006C5.79565 9.00006 6.55871 9.31614 7.12132 9.87874C7.68393 10.4414 8 11.2044 8 12.0001V12.0091C7.99657 12.8027 7.67925 13.5626 7.11734 14.1231C6.55543 14.6835 5.7946 14.9988 5.001 15.0001ZM14.999 15.0001C14.2054 14.9988 13.4446 14.6835 12.8827 14.1231C12.3208 13.5626 12.0034 12.8027 12 12.0091V12.0001C11.9999 11.6061 12.0775 11.216 12.2282 10.852C12.3789 10.488 12.5998 10.1572 12.8783 9.8786C13.4408 9.3159 14.2039 8.9997 14.9995 8.99957C15.7951 8.99943 16.5583 9.31538 17.121 9.87789C17.6837 10.4404 17.9999 11.2034 18 11.9991C17.9989 12.7947 17.6824 13.5574 17.1199 14.1199C16.5573 14.6825 15.7946 14.999 14.999 15.0001Z"
                                  fill="#466FFF"
                                />
                              </svg>
                            </Link> */}
                            <a href="javascript:void(0)">
                              <svg
                                width="12"
                                height="14"
                                viewBox="0 0 12 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8 14V0H12V14H8ZM0 14V0H4V14H0Z"
                                  fill="black"
                                />
                              </svg>
                            </a>
                            <a
                              href="javascript:void(0)"
                              onClick={() => {
                                handleDelete(obj.id);
                              }}
                            >
                              <svg
                                width="16"
                                height="18"
                                viewBox="0 0 16 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.196 17.021 1 16.55 1 16V3C0.716667 3 0.479 2.90433 0.287 2.713C0.0956668 2.521 0 2.28333 0 2C0 1.71667 0.0956668 1.479 0.287 1.287C0.479 1.09567 0.716667 1 1 1H5C5 0.716667 5.096 0.479 5.288 0.287C5.47933 0.0956666 5.71667 0 6 0H10C10.2833 0 10.521 0.0956666 10.713 0.287C10.9043 0.479 11 0.716667 11 1H15C15.2833 1 15.5207 1.09567 15.712 1.287C15.904 1.479 16 1.71667 16 2C16 2.28333 15.904 2.521 15.712 2.713C15.5207 2.90433 15.2833 3 15 3V16C15 16.55 14.8043 17.021 14.413 17.413C14.021 17.8043 13.55 18 13 18H3ZM3 3V16H13V3H3ZM5 13C5 13.2833 5.096 13.5207 5.288 13.712C5.47933 13.904 5.71667 14 6 14C6.28333 14 6.521 13.904 6.713 13.712C6.90433 13.5207 7 13.2833 7 13V6C7 5.71667 6.90433 5.479 6.713 5.287C6.521 5.09567 6.28333 5 6 5C5.71667 5 5.47933 5.09567 5.288 5.287C5.096 5.479 5 5.71667 5 6V13ZM9 13C9 13.2833 9.096 13.5207 9.288 13.712C9.47933 13.904 9.71667 14 10 14C10.2833 14 10.521 13.904 10.713 13.712C10.9043 13.5207 11 13.2833 11 13V6C11 5.71667 10.9043 5.479 10.713 5.287C10.521 5.09567 10.2833 5 10 5C9.71667 5 9.47933 5.09567 9.288 5.287C9.096 5.479 9 5.71667 9 6V13Z"
                                  fill="#EB5757"
                                />
                              </svg>
                            </a>
                            {/* <Link to={"/id/change-password/"+obj.id} >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8 8C6.9 8 5.95833 7.60833 5.175 6.825C4.39167 6.04167 4 5.1 4 4C4 2.9 4.39167 1.95833 5.175 1.175C5.95833 0.391667 6.9 0 8 0C9.1 0 10.0417 0.391667 10.825 1.175C11.6083 1.95833 12 2.9 12 4C12 5.1 11.6083 6.04167 10.825 6.825C10.0417 7.60833 9.1 8 8 8ZM0 16V13.2C0 12.6333 0.146 12.1123 0.438 11.637C0.729334 11.1623 1.11667 10.8 1.6 10.55C2.63333 10.0333 3.68333 9.64567 4.75 9.387C5.81667 9.129 6.9 9 8 9C9.1 9 10.1833 9.129 11.25 9.387C12.3167 9.64567 13.3667 10.0333 14.4 10.55C14.8833 10.8 15.2707 11.1623 15.562 11.637C15.854 12.1123 16 12.6333 16 13.2V16H0ZM2 14H14V13.2C14 13.0167 13.9543 12.85 13.863 12.7C13.771 12.55 13.65 12.4333 13.5 12.35C12.6 11.9 11.6917 11.5623 10.775 11.337C9.85833 11.1123 8.93333 11 8 11C7.06667 11 6.14167 11.1123 5.225 11.337C4.30833 11.5623 3.4 11.9 2.5 12.35C2.35 12.4333 2.22933 12.55 2.138 12.7C2.046 12.85 2 13.0167 2 13.2V14ZM8 6C8.55 6 9.021 5.804 9.413 5.412C9.80433 5.02067 10 4.55 10 4C10 3.45 9.80433 2.97933 9.413 2.588C9.021 2.196 8.55 2 8 2C7.45 2 6.97933 2.196 6.588 2.588C6.196 2.97933 6 3.45 6 4C6 4.55 6.196 5.02067 6.588 5.412C6.97933 5.804 7.45 6 8 6Z"
                                  fill="#466FFF"
                                />
                              </svg>
                            </Link> */}
                            <Link
                              to="#"
                              onClick={() => handleSendCredentials(obj.id)}
                            >
                              <svg
                                width="21"
                                height="18"
                                viewBox="0 0 21 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillrule="evenodd"
                                  clip-rule="evenodd"
                                  d="M18.0169 0.117465C18.264 0.013456 18.5345 -0.022416 18.8002 0.0135823C19.0659 0.0495806 19.317 0.156133 19.5276 0.32215C19.7381 0.488167 19.9003 0.707579 19.9973 0.957548C20.0942 1.20752 20.1224 1.47891 20.0789 1.74347L17.8109 15.5005C17.5909 16.8275 16.1349 17.5885 14.9179 16.9275C13.8999 16.3745 12.3879 15.5225 11.0279 14.6335C10.3479 14.1885 8.26489 12.7635 8.52089 11.7495C8.74089 10.8825 12.2409 7.62446 14.2409 5.68747C15.0259 4.92647 14.6679 4.48747 13.7409 5.18747C11.4379 6.92547 7.74289 9.56847 6.52089 10.3125C5.44289 10.9685 4.88089 11.0805 4.20889 10.9685C2.98289 10.7645 1.84589 10.4485 0.917888 10.0635C-0.336112 9.54347 -0.275112 7.81947 0.916888 7.31747L18.0169 0.117465Z"
                                  fill="#466FFF"
                                />
                              </svg>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAll;
