import { useEffect, useState } from "react";
import "./../css/terms.css";
import useRequest from "../hooks/useRequest";
import PreloaderOuter from "./components/preloaderOuter";
import { useSelector } from "react-redux";
import { MultiLang } from "./MultiLang";

export default function Terms() {
  const { request, response } = useRequest();
  const { loading } = useSelector(state => state.Auth)

  const [tearmsConditionData, settearmsConditionData] = useState("");
  const { language } = useSelector((state) => state.reducer);

  useEffect(() => {
    window.scrollTo(0, 0);
    request("GET", "/terms-and-conditions");
  }, []);

  useEffect(() => {
    if (response) {
      settearmsConditionData(response?.tearmsCondition);
    }
  }, [response]);

  return (
    loading ? <PreloaderOuter /> :
      <div className="_terms">
        <div className="content">
          {/* <div className="main-title">{tearmsConditionData?.page_title}</div> */}
          <div className="main-title">{MultiLang["terms-conditions"][language]}</div>
          <div className="p-main">
            <div
              dangerouslySetInnerHTML={{
                __html: tearmsConditionData?.description
              }}
            />
          </div>
        </div>
      </div>
  );
}
