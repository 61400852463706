import React, { useState } from "react";
import Webcam from "react-webcam";
import { useDispatch } from "react-redux"
import { activateFeedback } from "../../redux/actions";

const WebcamStreamCapture = ({ setVideofile }) => {

    const [startCapture, setStartCapture] = useState(false)
    const dispatch = useDispatch()

    const webcamRef = React.useRef(null);
    const mediaRecorderRef = React.useRef(null);
    const [capturing, setCapturing] = React.useState(false);
    const [recordedChunks, setRecordedChunks] = React.useState([]);

    const [videosrc, setVideosrc] = useState(null)
    const [mode, setMode] = useState('capture')

    const handleStartCaptureClick = React.useCallback(() => {
        setCapturing(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: "video/webm"
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorderRef.current.start();
    }, [webcamRef, setCapturing, mediaRecorderRef]);

    const handleDataAvailable = React.useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );

    const handleStopCaptureClick = React.useCallback(() => {
        mediaRecorderRef.current.stop();
        setCapturing(false);
    }, [mediaRecorderRef, webcamRef, setCapturing, recordedChunks]);

    const handleConvert = React.useCallback(() => {
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                type: "video/webm"
            });

            let file = new File([blob], "filename.mp4", { type: 'video/mp4' })
            setVideofile(file)

            const url = URL.createObjectURL(blob);
            setVideosrc(url)

            setMode('view')
            setRecordedChunks([]);
        }
    }, [recordedChunks]);

    const handleRetake = () => {
        setMode('capture')
        setVideosrc(null)
        setRecordedChunks([])
        setCapturing(false)
        setVideofile(null)
    }

    const webcamErrorHandler = (e) => {
        setStartCapture(false)
        dispatch(activateFeedback({ type: 'error', message: 'Unable to capture the video' }))
    }

    return (
        <>
            <div>
                {
                    startCapture ?
                        <div className="challenge-video">
                            <div className="video-container">
                                {
                                    mode === 'view' && videosrc ?
                                        <>
                                            <video src={videosrc} controls />
                                            <div className="camera-btn-con">
                                                <button className="btn blue-btn video-btn" onClick={handleRetake}>Retake</button>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <Webcam
                                                onUserMediaError={webcamErrorHandler}
                                                audio={false}
                                                ref={webcamRef}
                                                // height={500}
                                                // width={1280}
                                                // videoConstraints={{
                                                //     height: 500,
                                                //     width: 1280
                                                // }}
                                            />
                                            {capturing ? (
                                                <div className="camera-btn-con">
                                                    <button className="btn blue-btn video-btn" onClick={handleStopCaptureClick}>Stop Recording</button>
                                                </div>
                                            ) : (
                                                <div className="camera-btn-con">
                                                    <button className="btn blue-btn video-btn" onClick={handleStartCaptureClick}>Start Recording</button>
                                                </div>
                                            )}
                                            {recordedChunks.length > 0 && (
                                                <div className="camera-btn-con">
                                                    <button className="btn blue-btn video-btn" onClick={handleConvert}>View</button>
                                                </div>
                                            )}
                                        </>
                                }
                            </div>
                        </div> :
                        <div className="challenge-start02-bottom">
                            <button className="blue-btn btn" onClick={() => { setStartCapture(true) }}>Start Capture</button>
                        </div>
                }
            </div>


        </>
    );
};

export default WebcamStreamCapture;



