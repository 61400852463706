import React, { useEffect } from 'react'
import { useFormik } from "formik"

import * as Yup from 'yup';
import { useSelector, useDispatch } from "react-redux"
import { activateFeedback } from '../../redux/actions';
import useRequest from "../../hooks/useRequest";
import Preloader from '../components/preloader';
import { countries } from "../../helpers/countries"
import { MultiLang } from "../MultiLang";


const PersonalInfo = () => {
    const { loading, user } = useSelector(state => state.Auth)
    const dispatch = useDispatch()
    const { language } = useSelector((state) => state.reducer);
    const { request, response } = useRequest()

    const formik = useFormik({
        initialValues: {
            first_name: user?.first_name ?? "",
            profile_first_name: user?.profile_first_name ?? "",
            profile_last_name: user?.profile_last_name ?? "",
            email: user?.email ?? "",
            age: user?.age ?? "",
            address: user?.address ?? "",
            gender: user?.gender ?? "",
            country: user?.country ?? ""
        },
        validationSchema: Yup.object({
            first_name: Yup.string()
                .required('Required')
                .matches(/^[a-zA-Z0-9._@#]+$/, 'Only alphabets numbers and @,#,.,_ allowed.'),
            profile_first_name: Yup.string()
                .required('Required')
                .matches(/^[A-Za-z]+$/, 'Only alphabets allowed.'),
            profile_last_name: Yup.string()
                .required('Required')
                .matches(/^[A-Za-z]+$/, 'Only alphabets allowed.'),
            age: Yup.number()
                .min(1, "min 1")
                .max(85, "max 85")
                .required('Required'),
            address: Yup.string()
                .required('Required'),
            country: Yup.string()
                .required('Required')
        }),
        onSubmit: values => {
            request("PUT", "/secure/edit-profile/", values)
        }
    });

    useEffect(() => {
        if (response) {
            dispatch(activateFeedback({ type: response?.status, message: response?.message }))
        }
    }, [response])

    return (
        <>
            {loading && <Preloader />}
            <form className="setting-content-con" onSubmit={formik.handleSubmit}>
                <div className="setting-content-con-header">Profile Info (User ID : {user?.id})</div>
                <div className="setting-input flex">
                    <div className="setting-input-con small">
                        <label htmlFor="">{MultiLang["Username"][language]}</label>
                        <input type="text" name="first_name" {...formik.getFieldProps('first_name')} />
                        {formik.touched.first_name && formik.errors.first_name && <div className="input-error">{formik.errors.first_name}</div>}
                    </div>
                    <div className="setting-input-con small">
                        <label htmlFor="">{MultiLang["First Name"][language]}</label>
                        <input type="text" name="profile_first_name" {...formik.getFieldProps('profile_first_name')} />
                        {formik.touched.profile_first_name && formik.errors.profile_first_name && <div className="input-error">{formik.errors.profile_first_name}</div>}
                    </div>
                    <div className="setting-input-con small">
                        <label htmlFor="">{MultiLang["Last Name"][language]}</label>
                        <input type="text" name="profile_last_name" {...formik.getFieldProps('profile_last_name')} />
                        {formik.touched.profile_last_name && formik.errors.profile_last_name && <div className="input-error">{formik.errors.profile_last_name}</div>}
                    </div>
                    <div className="setting-input-con small">
                        <label htmlFor="">{MultiLang["Age"][language]} (y.o.)</label>
                        <input type="number" name="age" {...formik.getFieldProps('age')} />
                        {formik.touched.age && formik.errors.age && <div className="input-error">{formik.errors.age}</div>}
                    </div>
                </div>
                <div className="setting-input">
                    <div className="setting-input-con">
                        <label htmlFor="">{MultiLang["Address"][language]}</label>
                        <input type="text" name="address" {...formik.getFieldProps('address')} />
                        {formik.touched.address && formik.errors.address && <div className="input-error">{formik.errors.address}</div>}
                    </div>
                    <div className="setting-input-con">
                        <label htmlFor="">{MultiLang["Country"][language]}</label>
                        <select
                            name="country"
                            {...formik.getFieldProps('country')}
                        >
                            <option value="">Select country</option>
                            {
                                countries.map((val) => {
                                    return <option key={val.code} value={val.name}>{val.name}</option>
                                })
                            }
                        </select>
                        {formik.touched.country && formik.errors.country && <div className="input-error">{formik.errors.country}</div>}
                    </div>

                    <div className="setting-input-con">
                        <label htmlFor="">{MultiLang["Gender"][language]}</label>
                        <div className="custom-select">
                            <div
                                className={formik.values.gender === "male" ? "active" : ""}
                                onClick={() => {
                                    formik.setFieldValue('gender', "male")
                                }}
                            >
                                {MultiLang["Male"][language]}
                            </div>
                            <div
                                className={formik.values.gender === "female" ? "active" : ""}
                                onClick={() => {
                                    formik.setFieldValue('gender', "female")
                                }}
                            >
                                {MultiLang["Female"][language]}
                            </div>
                            <div
                                className={formik.values.gender === "other" ? "active" : ""}
                                onClick={() => {
                                    formik.setFieldValue('gender', "other")
                                }}
                            >
                                {MultiLang["Non-binary"][language]}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="setting-bottom">
                    <button type='submit' className="blue-btn btn setting-btn">Save</button>
                </div>
            </form>
        </>
    )
}

export default PersonalInfo;