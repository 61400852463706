import { useEffect, useState } from "react";
import PreloaderOuter from "../pages/components/preloaderOuter";
import "./../css/home_new.css";
import "./../css/common.css";
import AIsvg from "./components/AIsvg";

import DottedImg from "../images/home_new/dotted-img.svg";
import UserImg1 from "../images/home_new/user-img-1.jpg";
import UserImg2 from "../images/home_new/user-img-2.jpg";
import UserImg3 from "../images/home_new/user-img-3.jpg";
import UserImg4 from "../images/home_new/user-img-4.jpg";
import UserImg5 from "../images/home_new/user-img-5.jpg";
import UserImg6 from "../images/home_new/user-img-6.jpg";

import useRequest from "../hooks/useRequest";
import { useSelector } from "react-redux";

import AOS from "aos";

const LeaderBoard = () => {
    const { user } = useSelector(state => state.Auth)
    const [topParticipants, setTopParticipants] = useState([])
    const [otherParticipants, setOtherParticipants] = useState([])
    const [myData, setMyData] = useState(null)

    const { request, response } = useRequest();



    useEffect(() => {
        window.scrollTo(0, 0);
        request('GET', `/challenges/leader-data`)
    }, []);

    useEffect(() => {
        if (response?.status === 'success') {
            let users = response?.data.filter((val) => val?.user?.current_rank !== 0)
            users.forEach((val, i) => {
                if (val?.user?.id === user?.id) {
                    setMyData(val)
                }
                if (i <= 2) {
                    setTopParticipants((prev) => {
                        return [...prev, val]
                    })
                } else {
                    setOtherParticipants((prev) => {
                        return [...prev, val]
                    })
                }
            })
        }
    }, [response])

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <section>
            <div className="sectionSpace leaderBoardSection">
                <div className="rightDottedImg">
                    <img src={DottedImg} alt="..." />
                </div>
                <div className="container">

                    <div className="row gy-3 align-items-center">
                        <div className="col-lg-6" data-aos="fade-up" data-aos-delay="500">
                            <div className="prizeLeftCol leaderLeftNewcol">
                                <h2 className="xlTitle pb-3 pb-xl-4 text-center text-lg-start" data-aos="fade-up" data-aos-delay="200">
                                    <span className="outlineText d-block">Leaderboard</span>
                                </h2>
                                <div className="cs2ContentCol newLeaderSubtitle">
                                    <h4>best performers positions</h4>
                                </div>

                                {/* prize col */}

                                <div className="prizePool_parent">
                                    {
                                        topParticipants[1] &&
                                        <div className="sliverPool">
                                            <div className="rankUser">
                                                <div
                                                    // className={`rankUserImg ${topParticipants[1]?.user?.is_kycVerified ? 'verifiedStatus' : ""} ${topParticipants[1]?.user?.is_online ? 'onlineStatus' : "offlineStatus"}`}
                                                    className={`rankUserImg ${topParticipants[1]?.user?.is_online ? 'onlineStatus' : "offlineStatus"}`}
                                                >
                                                    <img src={
                                                        topParticipants[1]?.user?.profile_image ?
                                                            topParticipants[1]?.user?.profile_image :
                                                            './images/profile/noAvatar.png'
                                                    }
                                                        alt=""
                                                    />
                                                    <div className="rankUserLevel">{topParticipants[1]?.user?.user_level} Lvl</div>
                                                    <div className="rankUserMedal">
                                                        <span>2</span>
                                                        <svg width="32" height="41" viewBox="0 0 32 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4.95947 40.1371L9.97202 26.7703H22.7819L27.2375 40.1371L16.0985 35.2764L4.95947 40.1371Z" fill="#737E98" />
                                                            <path d="M28.5196 10.2059C28.5555 9.93457 28.5742 9.66323 28.5742 9.39344C28.5742 5.68349 25.2323 2.70648 21.5239 3.21018C20.4432 1.28737 18.3847 0.0366821 16.0985 0.0366821C13.8123 0.0366821 11.7538 1.28737 10.6731 3.21018C6.95695 2.70648 3.62283 5.68349 3.62283 9.39344C3.62283 9.66323 3.64154 9.93457 3.67741 10.2059C1.75459 11.2882 0.503906 13.3467 0.503906 15.6313C0.503906 17.9159 1.75459 19.9744 3.67741 21.0566C3.64143 21.326 3.6232 21.5974 3.62283 21.8691C3.62283 25.5791 6.95695 28.5483 10.6731 28.0524C11.7538 29.9752 13.8123 31.2259 16.0985 31.2259C18.3847 31.2259 20.4432 29.9752 21.5239 28.0524C25.2323 28.5483 28.5742 25.5791 28.5742 21.8691C28.5742 21.5993 28.5555 21.328 28.5196 21.0566C30.4424 19.9744 31.6931 17.9159 31.6931 15.6313C31.6931 13.3467 30.4424 11.2882 28.5196 10.2059Z" fill="#A7B1CA" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="rankUserName">
                                                    {topParticipants[1]?.user?.first_name} {topParticipants[1]?.user?.last_name}
                                                </div>

                                            </div>
                                            <div className="silverStage">
                                                {/* <div className="poolTExt">24 W  -  2L  -  2000xp</div> */}
                                                <div className="poolTExt">{topParticipants[1]?.user?.other_data?.total_wins}W  -  {topParticipants[1]?.user?.other_data?.total_lose}L  -  {topParticipants[1]?.user?.user_xp}xp</div>

                                                <div className="rankeStage">
                                                    <div className="rankFrst">
                                                        <div className="rankCount frstRank">
                                                            <span>1</span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="none">
                                                                <path d="M2 16.8514L4.025 10.8514H9.2L11 16.8514L6.5 14.6696L2 16.8514Z" fill="white" />
                                                                <circle cx="6.5" cy="7.35138" r="6.5" fill="white" />
                                                            </svg>
                                                        </div>
                                                        <span>{topParticipants[1]?.user?.other_data?.first_medal_count}</span>
                                                    </div>
                                                    <div className="rankFrst">
                                                        <div className="rankCount scndRank">
                                                            <span>2</span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="none">
                                                                <path d="M2 16.8514L4.025 10.8514H9.2L11 16.8514L6.5 14.6696L2 16.8514Z" fill="#E3E8EE" />
                                                                <circle cx="6.5" cy="7.35138" r="6.5" fill="#E3E8EE" />
                                                            </svg>
                                                        </div>
                                                        {/* <span>11</span> */}
                                                        <span>{topParticipants[1]?.user?.other_data?.second_medal_count}</span>
                                                    </div>
                                                    <div className="rankFrst">
                                                        <div className="rankCount thirdRank">
                                                            <span>3</span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="none">
                                                                <path d="M2 16.8514L4.025 10.8514H9.2L11 16.8514L6.5 14.6696L2 16.8514Z" fill="#D4B692" />
                                                                <circle cx="6.5" cy="7.35138" r="6.5" fill="#D4B692" />
                                                            </svg>
                                                        </div>
                                                        {/* <span>56</span> */}
                                                        <span>{topParticipants[1]?.user?.other_data?.third_medal_count}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        topParticipants[0] &&
                                        <div className="goldPool">
                                            <div className="rankUser">
                                                <div
                                                    // className={`rankUserImg ${topParticipants[0]?.user?.is_kycVerified ? 'verifiedStatus' : ""} ${topParticipants[0]?.user?.is_online ? 'onlineStatus' : "offlineStatus"}`}
                                                    className={`rankUserImg ${topParticipants[0]?.user?.is_online ? 'onlineStatus' : "offlineStatus"}`}

                                                >
                                                    <img
                                                        src={
                                                            topParticipants[0]?.user?.profile_image ?
                                                                topParticipants[0]?.user?.profile_image :
                                                                './images/profile/noAvatar.png'
                                                        }
                                                        alt=""
                                                    />
                                                    <div className="rankUserLevel">{topParticipants[0]?.user?.user_level} Lvl</div>
                                                    <div className="rankUserMedal">
                                                        <span>1</span>
                                                        <svg width="32" height="41" viewBox="0 0 32 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4.55029 40.1004L9.56284 26.7336H22.3727L26.8283 40.1004L15.6893 35.2397L4.55029 40.1004Z" fill="#DDBF19" />
                                                            <path d="M28.1104 10.1692C28.1463 9.89789 28.165 9.62654 28.165 9.35676C28.165 5.6468 24.8231 2.66979 21.1147 3.1735C20.034 1.25069 17.9755 0 15.6893 0C13.4032 0 11.3447 1.25069 10.264 3.1735C6.54777 2.66979 3.21365 5.6468 3.21365 9.35676C3.21365 9.62654 3.23236 9.89789 3.26823 10.1692C1.34541 11.2515 0.0947266 13.31 0.0947266 15.5946C0.0947266 17.8792 1.34541 19.9377 3.26823 21.02C3.23225 21.2893 3.21402 21.5607 3.21365 21.8324C3.21365 25.5424 6.54777 28.5116 10.264 28.0157C11.3447 29.9385 13.4032 31.1892 15.6893 31.1892C17.9755 31.1892 20.034 29.9385 21.1147 28.0157C24.8231 28.5116 28.165 25.5424 28.165 21.8324C28.165 21.5626 28.1463 21.2913 28.1104 21.02C30.0332 19.9377 31.2839 17.8792 31.2839 15.5946C31.2839 13.31 30.0332 11.2515 28.1104 10.1692Z" fill="#FEDA16" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="rankUserName">
                                                    {topParticipants[0]?.user?.first_name} {topParticipants[0]?.user?.last_name}
                                                </div>
                                            </div>
                                            <div className="goldStage">
                                                {/* <div className="poolTExt">24 W  -  2L  -  2000xp</div> */}
                                                <div className="poolTExt">{topParticipants[0]?.user?.other_data?.total_wins}W  -  {topParticipants[0]?.user?.other_data?.total_lose}L  -  {topParticipants[0]?.user?.user_xp}xp</div>
                                                <div className="rankeStage">
                                                    <div className="rankFrst">
                                                        <div className="rankCount frstRank">
                                                            <span>1</span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="none">
                                                                <path d="M2 16.8514L4.025 10.8514H9.2L11 16.8514L6.5 14.6696L2 16.8514Z" fill="white" />
                                                                <circle cx="6.5" cy="7.35138" r="6.5" fill="white" />
                                                            </svg>
                                                        </div>
                                                        {/* <span>24</span> */}
                                                        <span>{topParticipants[0]?.user?.other_data?.first_medal_count}</span>
                                                    </div>
                                                    <div className="rankFrst">
                                                        <div className="rankCount scndRank">
                                                            <span>2</span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="none">
                                                                <path d="M2 16.8514L4.025 10.8514H9.2L11 16.8514L6.5 14.6696L2 16.8514Z" fill="#E3E8EE" />
                                                                <circle cx="6.5" cy="7.35138" r="6.5" fill="#E3E8EE" />
                                                            </svg>
                                                        </div>
                                                        {/* <span>11</span> */}
                                                        <span>{topParticipants[0]?.user?.other_data?.second_medal_count}</span>
                                                    </div>
                                                    <div className="rankFrst">
                                                        <div className="rankCount thirdRank">
                                                            <span>3</span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="none">
                                                                <path d="M2 16.8514L4.025 10.8514H9.2L11 16.8514L6.5 14.6696L2 16.8514Z" fill="#D4B692" />
                                                                <circle cx="6.5" cy="7.35138" r="6.5" fill="#D4B692" />
                                                            </svg>
                                                        </div>
                                                        {/* <span>56</span> */}
                                                        <span>{topParticipants[0]?.user?.other_data?.third_medal_count}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    }

                                    {
                                        topParticipants[2] &&
                                        <div className="bronzePool">
                                            <div className="rankUser">
                                                <div
                                                    // className={`rankUserImg ${topParticipants[2]?.user?.is_kycVerified ? 'verifiedStatus' : ""} ${topParticipants[2]?.user?.is_online ? 'onlineStatus' : "offlineStatus"}`}
                                                    className={`rankUserImg ${topParticipants[2]?.user?.is_online ? 'onlineStatus' : "offlineStatus"}`}

                                                >
                                                    <img
                                                        src={
                                                            topParticipants[2]?.user?.profile_image ?
                                                                topParticipants[2]?.user?.profile_image :
                                                                './images/profile/noAvatar.png'
                                                        }
                                                        alt=""
                                                    />
                                                    <div className="rankUserLevel">{topParticipants[2]?.user?.user_level} Lvl</div>
                                                    <div className="rankUserMedal">
                                                        <span>3</span>
                                                        <svg width="32" height="41" viewBox="0 0 32 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.14087 40.3764L10.1534 27.0096H22.9633L27.4189 40.3764L16.2799 35.5158L5.14087 40.3764Z" fill="#BE7E37" />
                                                            <path d="M28.701 10.4453C28.7369 10.174 28.7556 9.90261 28.7556 9.63282C28.7556 5.92286 25.4137 2.94586 21.7053 3.44956C20.6246 1.52675 18.5661 0.276062 16.2799 0.276062C13.9937 0.276062 11.9352 1.52675 10.8545 3.44956C7.13835 2.94586 3.80422 5.92286 3.80422 9.63282C3.80422 9.90261 3.82293 10.174 3.8588 10.4453C1.93599 11.5276 0.685303 13.586 0.685303 15.8707C0.685303 18.1553 1.93599 20.2138 3.8588 21.296C3.82283 21.5654 3.80459 21.8368 3.80422 22.1085C3.80422 25.8184 7.13835 28.7877 10.8545 28.2918C11.9352 30.2146 13.9937 31.4653 16.2799 31.4653C18.5661 31.4653 20.6246 30.2146 21.7053 28.2918C25.4137 28.7877 28.7556 25.8184 28.7556 22.1085C28.7556 21.8387 28.7369 21.5674 28.701 21.296C30.6238 20.2138 31.8745 18.1553 31.8745 15.8707C31.8745 13.586 30.6238 11.5276 28.701 10.4453Z" fill="#D89143" />
                                                        </svg>

                                                    </div>
                                                </div>
                                                <div className="rankUserName">
                                                    {topParticipants[2]?.user?.first_name} {topParticipants[2]?.user?.last_name}
                                                </div>
                                            </div>

                                            <div className="brongeStage">
                                                {/* <div className="poolTExt">24 W  -  2L  -  2000xp</div> */}
                                                <div className="poolTExt">{topParticipants[2]?.user?.other_data?.total_wins}W  -  {topParticipants[2]?.user?.other_data?.total_lose}L  -  {topParticipants[2]?.user?.user_xp}xp</div>
                                                <div className="rankeStage">
                                                    <div className="rankFrst">
                                                        <div className="rankCount frstRank">
                                                            <span>1</span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="none">
                                                                <path d="M2 16.8514L4.025 10.8514H9.2L11 16.8514L6.5 14.6696L2 16.8514Z" fill="white" />
                                                                <circle cx="6.5" cy="7.35138" r="6.5" fill="white" />
                                                            </svg>
                                                        </div>
                                                        {/* <span>24</span> */}
                                                        <span>{topParticipants[2]?.user?.other_data?.first_medal_count}</span>
                                                    </div>
                                                    <div className="rankFrst">
                                                        <div className="rankCount scndRank">
                                                            <span>2</span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="none">
                                                                <path d="M2 16.8514L4.025 10.8514H9.2L11 16.8514L6.5 14.6696L2 16.8514Z" fill="#E3E8EE" />
                                                                <circle cx="6.5" cy="7.35138" r="6.5" fill="#E3E8EE" />
                                                            </svg>
                                                        </div>
                                                        {/* <span>11</span> */}
                                                        <span>{topParticipants[2]?.user?.other_data?.second_medal_count}</span>
                                                    </div>
                                                    <div className="rankFrst">
                                                        <div className="rankCount thirdRank">
                                                            <span>3</span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="none">
                                                                <path d="M2 16.8514L4.025 10.8514H9.2L11 16.8514L6.5 14.6696L2 16.8514Z" fill="#D4B692" />
                                                                <circle cx="6.5" cy="7.35138" r="6.5" fill="#D4B692" />
                                                            </svg>
                                                        </div>
                                                        {/* <span>56</span> */}
                                                        <span>{topParticipants[2]?.user?.other_data?.third_medal_count}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            otherParticipants.length > 0 &&
                            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="800">
                                <div className="leaderRighttitle">
                                    <h4>Global Ranking</h4>
                                </div>
                                <div className="leaderBoardRight">
                                    <div className=" table-responsive">
                                        <table className="table table-striped tableStyle1">
                                            <tbody>
                                                {
                                                    otherParticipants.map((elem, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td width="80">
                                                                    <div
                                                                        // className={`${elem?.user?.is_kycVerified ? 'verifiedStatus' : ""} ${elem?.user?.is_online ? 'onlineStatus' : "offlineStatus"}`}
                                                                        className={`${elem?.user?.is_online ? 'onlineStatus' : "offlineStatus"}`}

                                                                    >
                                                                        <img
                                                                            src={elem?.user?.profile_image ? elem?.user?.profile_image : './images/profile/noAvatar.png'}
                                                                            alt="..."
                                                                            className="pUserImg"
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className="pUserName">{elem?.user?.first_name} {elem?.user?.last_name}</span>
                                                                    <div className="leaderMadels">
                                                                        <span>{elem?.user?.other_data?.total_medal_count} Medals</span>
                                                                        <span>{elem?.user?.other_data?.total_wins} Wins</span>
                                                                        <span>{elem?.user?.other_data?.total_lose} Losings</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="stocksMarket">
                                                                        {/* {i + topParticipants.length + 1} */}
                                                                        {elem?.user?.current_rank}
                                                                        {
                                                                            elem?.user?.current_rank >= elem?.user?.old_rank ?
                                                                                <span className="stocksCircle upStocks">
                                                                                    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M4.589 0.593224C4.78784 0.306221 5.21216 0.30622 5.411 0.593224L9.65246 6.71525C9.8822 7.04685 9.64488 7.5 9.24147 7.5H0.758534C0.355124 7.5 0.117797 7.04685 0.347537 6.71525L4.589 0.593224Z" fill="currentColor" />
                                                                                    </svg>
                                                                                </span>
                                                                                :
                                                                                <span className="stocksCircle downStocks">
                                                                                    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M4.589 0.593224C4.78784 0.306221 5.21216 0.30622 5.411 0.593224L9.65246 6.71525C9.8822 7.04685 9.64488 7.5 9.24147 7.5H0.758534C0.355124 7.5 0.117797 7.04685 0.347537 6.71525L4.589 0.593224Z" fill="currentColor" />
                                                                                    </svg>
                                                                                </span>
                                                                        }
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        }
                        {
                            myData &&
                            <div className="col-lg-12" data-aos="fade-up" data-aos-delay="500">
                                <div className="yourRanking_leaderBoard">
                                    <div className="yourRankingtitle">
                                        Your Ranking
                                    </div>
                                    <div className="yourRanking_detail">
                                        <div className="leadeBoard_card">
                                            <div className="leaderBoard_img_card onlineStatus">
                                                <img
                                                    src={myData?.user?.profile_image ? myData?.user?.profile_image : './images/profile/noAvatar.png'}
                                                    alt="..."
                                                    className="pUserImg "
                                                />
                                            </div>
                                            <div>
                                                <span className="pUserName">{myData?.user?.first_name} {myData?.user?.last_name}</span>
                                                <div className="leaderMadels">
                                                    <span>{myData?.user?.other_data?.total_medal_count} Medals</span>
                                                    <span>{myData?.user?.other_data?.total_wins} Wins</span>
                                                    <span>{myData?.user?.other_data?.total_lose} Losings</span>
                                                </div>
                                            </div>
                                            <div className="stocksMarket ms-auto">
                                                {myData?.user?.current_rank}
                                                {
                                                    myData?.user?.current_rank >= myData?.user?.old_rank ?
                                                        <span span className="stocksCircle upStocks">
                                                            <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4.589 0.593224C4.78784 0.306221 5.21216 0.30622 5.411 0.593224L9.65246 6.71525C9.8822 7.04685 9.64488 7.5 9.24147 7.5H0.758534C0.355124 7.5 0.117797 7.04685 0.347537 6.71525L4.589 0.593224Z" fill="currentColor" />
                                                            </svg>
                                                        </span>
                                                        :
                                                        <span className="stocksCircle downStocks">
                                                            <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4.589 0.593224C4.78784 0.306221 5.21216 0.30622 5.411 0.593224L9.65246 6.71525C9.8822 7.04685 9.64488 7.5 9.24147 7.5H0.758534C0.355124 7.5 0.117797 7.04685 0.347537 6.71525L4.589 0.593224Z" fill="currentColor" />
                                                            </svg>
                                                        </span>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LeaderBoard