import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from 'yup';
import "./../../css/auth.css";
import { loginUserSuccess, activateFeedback, setOnlineUsers } from "../../redux/actions";
import Preloader from "../components/preloader";
import useRequest from "../../hooks/useRequest"
import { useSelector, useDispatch } from "react-redux"
import { toast } from "react-toastify";

function Login() {
    const navigate = useNavigate();
    const { loading } = useSelector(state => state.Auth)    
    const dispatch = useDispatch()
    const { request, response } = useRequest()
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Email Must be a Valid Email").required('Please Enter Your email'),
            password: Yup.string()
                .required('Please Enter Your Password')
                .min(8, 'Password is too short - should be 8 chars minimum.')
        }),
        onSubmit: values => {
            request("POST", "/auth/login/", { email: values.email, password: values.password })
        }
    });

    useEffect(() => {
        if (response) { 
            dispatch(activateFeedback({ type: response?.status, message: response?.message }))
            if (response.status === "success") {
                if (response?.data?.user?.is_verified) {
                    dispatch(loginUserSuccess(response.data))
                    dispatch(setOnlineUsers(response?.data?.user?.total_count + 1))
                    navigate("/profile")
                    // localStorage.setItem("user_role_id" , response?.data?.user?.user_role_id)
                } else if(response.status === "fail") {
                    toast.error(response.message)
                    
                }
            }
        }
    }, [response])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {loading && <Preloader />}
            <div className="_auth">
                <div className="auth-main _login">
                    <div className="auth-back-img">
                        <div className="auth-back-img-cover">
                        </div>
                        <img src="./images/auth/auth-logo.png" alt="" className="auth-logo" />
                        You’re born <br className="_sp" /> for <br className="_pc" /> this
                    </div>
                    <form className="auth-input" onSubmit={formik.handleSubmit}>
                        <div className="auth-input-con">
                            <div className="auth-input-header">
                                Sign In
                                <Link to="/fitness-competition" className="auth-close">

                                    <img src="./images/auth/auth-close.png" alt="" />
                                </Link>                                
                            </div>

                            <div className="auth-input-list">
                                <label htmlFor="">Email</label>
                                <input
                                    name="email"
                                    {...formik.getFieldProps('email')}
                                    type="text" placeholder="Example@example.com" />
                            </div>
                            {formik.touched.email && formik.errors.email && <div className="input-error">{formik.errors.email}</div>}

                            <div className="auth-input-list">
                                <div className="recover-pwd">
                                    <label htmlFor="">Password</label> <Link to="/forgetPassword" className="auth-link">Recovery Password</Link>
                                </div>
                                <input
                                    name="password"
                                    {...formik.getFieldProps('password')}
                                    type="password" placeholder="-" />
                            </div>
                            {formik.touched.password && formik.errors.password && <div className="input-error">{formik.errors.password}</div>}

                            <div className="auth-input-list">
                                <button type="submit" className="btn auth-main-btn">Sign In</button>
                            </div>
                            <div className="auth-input-list">
                                <div className="auth-input-list-text">
                                    Don’t Have An Account?&nbsp;&nbsp;<Link to="/register" className="auth-link">Sign Up</Link>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>


    )
}

export default Login;