import { useState, useEffect } from "react";
import "./../../css/setting.css";

import Security from "./Security";
import PersonalInfo from "./PersonalInfo";
import DepositMoney from "./DepositMoney";

import TransactionHistory from "./TransactionHistory";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AccVerification from "./AccVerification";

import WithdrawMoney from "./WithdrawMoney";
import Preloader from "../components/preloader";
import WithdrawPage from "./WithdrawPage";
import { MultiLang } from "../MultiLang";
import { getQueryObj } from "../../helpers/getQueryObj"

const tabs = [
  "profile",
  "security",
  "payment",
  "transaction_history",
  "withdrawal",
  "kyc",
];

export default function Setting() {
  const { loading } = useSelector(state => state.Auth)
  const { search } = useLocation();
  const queryObj = getQueryObj(search)

  let tab = queryObj.tab

  const { language } = useSelector((state) => state.reducer);

  if (!tabs.includes(tab)) {
    tab = "profile";
  }
  const [navState, setNavState] = useState(tab ? tab : "profile");
  const navigate = useNavigate();

  useEffect(() => {
    setNavState(tab ? tab : "profile");
  }, [tab]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {loading && <Preloader />}
      <div className="_setting">
        <div className="content">
          <div className="setting-header-con">
            <div className="setting-header">{MultiLang["Account"][language]}</div>
            <div className="setting-nav-cover">
              <div className="setting-nav _sp">
                <div
                  className={
                    navState === "profile"
                      ? "setting-nav-list active"
                      : "setting-nav-list"
                  }
                  onClick={() => {
                    setNavState("profile");
                    navigate("/setting?tab=profile");
                  }}
                >
                  <img
                    className="gray"
                    src="./images/setting/icon-profile.png"
                    alt=""
                  />
                  <img
                    className="blue"
                    src="./images/setting/icon-profile-blue.png"
                    alt=""
                  />
                  {MultiLang["Personal Information"][language]}
                </div>
                <div
                  className={
                    navState === "security"
                      ? "setting-nav-list active"
                      : "setting-nav-list"
                  }
                  onClick={() => {
                    setNavState("security");
                    navigate("/setting?tab=security");
                  }}
                >
                  <img
                    className="gray"
                    src="./images/setting/icon-privacy.png"
                    alt=""
                  />
                  <img
                    className="blue"
                    src="./images/setting/icon-privacy-blue.png"
                    alt=""
                  />
                  {MultiLang["Security"][language]}
                </div>
                <div
                  className={
                    navState === "payment"
                      ? "setting-nav-list active"
                      : "setting-nav-list"
                  }

                  onClick={() => {
                    setNavState("payment");
                    navigate("/setting?tab=payment");
                  }}
                >
                  <img
                    className="gray"
                    src="./images/deposite-black.png"
                    alt=""
                  />
                  <img
                    className="blue"
                    src="./images/profile/deposite-blue.png"
                    alt=""
                  />
                  {MultiLang["Deposit Money"][language]}
                </div>
                <div
                  className={
                    navState === "transaction_history"
                      ? "setting-nav-list active"
                      : "setting-nav-list"
                  }
                  onClick={() => {
                    setNavState("transaction_history");
                    navigate("/setting?tab=transaction_history");
                  }}
                >
                  <img
                    className="gray"
                    src="./images/setting/icon-card.png"
                    alt=""
                  />
                  <img
                    className="blue"
                    src="./images/setting/icon-card-blue.png"
                    alt=""
                  />
                  {MultiLang["Transaction History"][language]}
                </div>
                <div
                  className={
                    navState === "withdrawal"
                      ? "setting-nav-list active"
                      : "setting-nav-list"
                  }
                  onClick={() => {
                    setNavState("withdrawal");
                    navigate("/setting?tab=withdrawal");
                  }}
                >
                  <img
                    className="gray"
                    src="./images/withdraw-black.png"
                    alt=""
                  />
                  <img
                    className="blue"
                    src="./images/profile/withdraw-blue.png"
                    alt=""
                  />
                  {MultiLang["Withdrawal Money"][language]}
                </div>
                <div
                  className={
                    navState === "kyc"
                      ? "setting-nav-list active"
                      : "setting-nav-list"
                  }
                  onClick={() => {
                    setNavState("kyc");
                    navigate("/setting?tab=kyc");
                  }}
                >
                  <img
                    className="gray"
                    src="./images/setting/icon-card.png"
                    alt=""
                  />
                  <img
                    className="blue"
                    src="./images/setting/icon-card-blue.png"
                    alt=""
                  />
                  {MultiLang["Account Verification"][language]}
                </div>
              </div>
            </div>
          </div>
          <div className="setting-main">
            <div className="setting-nav _pc">
              <div
                className={
                  navState === "profile"
                    ? "setting-nav-list active"
                    : "setting-nav-list"
                }
                onClick={() => {
                  setNavState("profile");
                  navigate("/setting?tab=profile");
                }}
              >
                <img
                  className="gray"
                  src="./images/setting/icon-profile.png"
                  alt=""
                />
                <img
                  className="blue"
                  src="./images/setting/icon-profile-blue.png"
                  alt=""
                />
                {MultiLang["Personal Information"][language]}
              </div>
              <div
                className={
                  navState === "security"
                    ? "setting-nav-list active"
                    : "setting-nav-list"
                }
                onClick={() => {
                  setNavState("security");
                  navigate("/setting?tab=security");
                }}
              >
                <img
                  className="gray"
                  src="./images/setting/icon-privacy.png"
                  alt=""
                />
                <img
                  className="blue"
                  src="./images/setting/icon-privacy-blue.png"
                  alt=""
                />
                {MultiLang["Security"][language]}
              </div>
              <div
                className={
                  navState === "payment"
                    ? "setting-nav-list active"
                    : "setting-nav-list"
                }
                onClick={() => {
                  setNavState("payment");
                  navigate("/setting?tab=payment");
                }}
              >
                <img
                  className="gray"
                  src="./images/deposite-black.png"
                  alt=""
                />
                <img
                  className="blue"
                  src="./images/profile/deposite-blue.png"
                  alt=""
                />
                {MultiLang["Deposit Money"][language]}
              </div>
              <div
                className={
                  navState === "transaction_history"
                    ? "setting-nav-list active"
                    : "setting-nav-list"
                }
                onClick={() => {
                  setNavState("transaction_history");
                  navigate("/setting?tab=transaction_history");
                }}
              >
                <img
                  className="gray"
                  src="./images/setting/icon-card.png"
                  alt=""
                />
                <img
                  className="blue"
                  src="./images/setting/icon-card-blue.png"
                  alt=""
                />
                {MultiLang["Transaction History"][language]}
              </div>
              <div
                className={
                  navState === "withdrawal"
                    ? "setting-nav-list active"
                    : "setting-nav-list"
                }
                onClick={() => {
                  setNavState("withdrawal");
                  navigate("/setting?tab=withdrawal");
                }}
              >
                <img
                  className="gray"
                  src="./images/withdraw-black.png"
                  alt=""
                />
                <img
                  className="blue"
                  src="./images/profile/withdraw-blue.png"
                  alt=""
                />
                {MultiLang["Withdrawal Money"][language]}
              </div>
              <div
                className={
                  navState === "kyc"
                    ? "setting-nav-list active"
                    : "setting-nav-list"
                }
                onClick={() => {
                  setNavState("kyc");
                  navigate("/setting?tab=kyc");
                }}
              >
                <img
                  className="gray"
                  src="./images/setting/verification.png"
                  alt=""
                />
                <img
                  className="blue"
                  src="./images/setting/verification-blue.png"
                  alt=""
                />
                {MultiLang["Account Verification"][language]}
              </div>
            </div>

            <div className="setting-content">
              <div
                className={
                  navState === "profile"
                    ? "setting-content-list active"
                    : "setting-content-list"
                }
              >
                <PersonalInfo />
              </div>
              <div
                className={
                  navState === "security"
                    ? "setting-content-list active"
                    : "setting-content-list"
                }
              >
                <Security />
              </div>
              <div
                className={
                  navState === "payment"
                    ? "setting-content-list active"
                    : "setting-content-list"
                }
              >
                <DepositMoney />

              </div>
              <div
                className={
                  navState === "transaction_history"
                    ? "setting-content-list active"
                    : "setting-content-list"
                }
              >
                <TransactionHistory />
              </div>
              <div
                className={
                  navState === "withdrawal"
                    ? "setting-content-list active"
                    : "setting-content-list"
                }
              >
                <WithdrawPage />
              </div>
              <div
                className={
                  navState === "kyc"
                    ? "setting-content-list active"
                    : "setting-content-list"
                }
              >
                <AccVerification />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
