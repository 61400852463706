import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { authSuccess } from "../../../redux/corporation-store/auth/action";
import useRequestV2 from "../../../hooks/useRequestV2";
import { Link , useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { countries } from "../../../helpers/countries";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Swal from 'sweetalert2'

const SignupClient = () => {
  const { request, response } = useRequestV2();
const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [type, setType] = useState("password");
  const [type1, setType1] = useState("password");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const onSubmit = (data) => {
    const {
      company_name,
      email,
      profile_first_name,
      profile_last_name,
      job_title,
      phone_number,
      address,
      country,
      password,
      confirm_password,
    } = data;

    if (password !== confirm_password) {
      setError("confirm_password", {
        type: "manual",
      });
      return;
    }
    let formdata = new FormData();
    formdata.append("company_name", data.company_name);
    formdata.append("email", data.email);
    formdata.append("profile_first_name", data.profile_first_name);
    formdata.append("profile_last_name", data.profile_last_name);
    formdata.append("job_title", data.job_title);
    formdata.append("phone_number", data.phone_number);
    formdata.append("address", data.address);
    formdata.append("country", data.country);
    formdata.append("password", data.password);
    formdata.append("confirm_password", data.confirm_password);
    formdata.append("price_id", location?.state?.hash);



    request("POST", "client-register",formdata
);
  };

  useEffect(() => {
    if (response) {
      if (response.status == "success") {
        toast.success(response.message ,{position:"bottom-center" , theme:"dark"});
        // console.log(response.data.access_token, "response.data.access_token");
        dispatch(
          authSuccess({
            token: response.data.access_token,
            uuid_string: response.data.user.uuid_string,
          })
        );
        navigate("/client-verify", {
          state: {
            id: response?.data?.user?.uuid_string,
            hash: response?.data?.access_token,
            search:location?.state?.hash,
          },
        });
      } else if (response.status == "fail") {
        toast.error(response.message);
      }
      
    }
  }, [response]);
  const changeType = () => {
    if (type === "password") {
      setType("text");
      return;
    }
    setType("password");
  };
  const changeType1 = () => {
    if (type1 === "password") {
      setType1("text");
      return;
    }
    setType1("password");
  };
useEffect(()=>
{
if(location?.state?.hash == undefined)
{
  Swal.fire({
    title: 'Please Select a Subscription Plan',
    showCancelButton: true,
    confirmButtonText: '<a href="/"> ok </a>',
  })
}
},[])

  return (
    <div
      className="sign-up-container sign-up-Splash beforeBg"
      style={{ backgroundImage: `url(/assets/img/signupBg.png)` }}
    >
      <div className="container">
        <div className="sign-up-inner-container splash-container-register">
          <div className="row justify-content-center">
            <div className="col-md-6 col-xl-5">
              <form
                className="row sign_in-form-box"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="logoDiv text-center">
                  <Link to="/">
                  <img src="/assets/img/full-logo.png" alt="" /></Link>
                </div>
                <h1 className="form-title text-center"> Sign up</h1>
                <div className="mb-3 col-md-12 ">
                  <label className="form-label">
                    Company Name <sup>*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Company Name"
                    aria-describedby="emailHelp"
                    name="company_name"
                    {...register("company_name", {
                      required: true,
                    })}
                  />

                  {errors.company_name?.type === "required" && (
                    <div className="text-danger fw-bold">
                      This field is required.
                    </div>
                  )}
                </div>
                <div className="mb-3 col-md-6 ">
                  <label className="form-label">
                    First Name <sup>*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter First Name"
                    aria-describedby="emailHelp"
                    name="profile_first_name"
                    {...register("profile_first_name", {
                      required: true,
                    })}
                  />

                  {errors.profile_first_name?.type === "required" && (
                    <div className="text-danger fw-bold">
                      This field is required.
                    </div>
                  )}
                </div>

                <div className="mb-3 col-md-6 ">
                  <label className="form-label">
                    Last Name <sup>*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Last Name"
                    name="profile_last_name"
                    {...register("profile_last_name", {
                      required: true,
                    })}
                  />
                  {errors.profile_last_name?.type === "required" && (
                    <div className="text-danger fw-bold">
                      This field is required.
                    </div>
                  )}
                </div>
                <div className="mb-3 col-md-6 ">
                  <label className="form-label">
                    Job Title <sup>*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Job Title"
                    aria-describedby="emailHelp"
                    name="job_title"
                    {...register("job_title", {
                      required: true,
                    })}
                  />
                  {errors.job_title?.type === "required" && (
                    <div className="text-danger fw-bold">
                      This field is required.
                    </div>
                  )}
                </div>
                <div className="mb-3 col-md-6 ">
                  <label className="form-label">
                    Phone Number <sup>*</sup>
                  </label>
                  {/* <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Phone Number"
                    aria-describedby="emailHelp"
                    name="phone_number"
                    {...register("phone_number", {
                      required: true,
                      pattern: /"^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"/gmi
                      ,
                    })}
                  /> */}
                  <PhoneInput
                    placeholder="Enter phone number"
                    className="form-control"
                    defaultCountry="US"
                    
                    // value={value}
                    {...register("phone_number", { required: true })}
                  />

                  {errors.phone_number?.type === "required" && (
                    <div className="text-danger fw-bold">
                      This field is required.
                    </div>
                  )}
                  {/* {errors.phone_number?.type === "pattern" && (
                    <div className="text-danger fw-bold">
                      Please enter valid phone number.
                    </div>
                  )} */}
                </div>
                <div className="mb-3 col-md-6 ">
                  <label className="form-label">
                    Address <sup>*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Address"
                    name="country"
                    {...register("address", {
                      required: true,
                    })}
                  />
                  {errors.address?.type === "required" && (
                    <div className="text-danger fw-bold">
                      This field is required.
                    </div>
                  )}
                </div>
                <div className="mb-3 col-md-6 ">
                  <label className="form-label">
                    Country <sup>*</sup>
                  </label>{" "}
                  <select
                    name="country"
                    className=" form-control form-select"
                    {...register("country", {
                      required: true,
                    })}
                  >
                    <option value="">Select country</option>
                    {countries.map((val) => {
                      return (
                        <option key={val.code} value={val.name}>
                          {val.name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.country?.type === "required" && (
                    <div className="text-danger fw-bold">
                      This field is required.
                    </div>
                  )}
                </div>
                <div className="mb-3 col-md-6 ">
                  <label className="form-label">
                    Email <sup>*</sup>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email@example.com"
                    aria-describedby="emailHelp"
                    name="email"
                    {...register("email", {
                      required: true,
                      pattern:
                        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i,
                    })}
                  />
                  {errors.email?.type === "required" && (
                    <div className="text-danger fw-bold">
                      This field is required.
                    </div>
                  )}

                  {errors.email?.type === "pattern" && (
                    <div className="text-danger fw-bold">
                      Please enter valid email
                    </div>
                  )}
                </div>
                <div className="mb-3 col-md-6 ">
                  <label className="form-label">
                    Password <sup>*</sup>
                  </label>

                  <span className="input_password_box">
                    <input
                      type={type1}
                      className="form-control"
                      id="password"
                      placeholder="Password"
                      aria-describedby="emailHelp"
                      name="password"
                      {...register("password", {
                        required: true,
                        pattern: {
                          value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
                        },
                      })}
                    />
                    <span className="eye-icon_box" onClick={changeType1}>
                      {type1 === "password" ? (
                        <i className="fas fa-eye-slash" id="togglePassword"></i>
                      ) : (
                        <i className="fas fa-eye" id="togglePassword"></i>
                      )}
                    </span>
                  </span>

                  {/* <i className="far fa-eye"></i> */}
                  {/* <i className="far fa-eye-slash"></i> */}
                  {errors.password?.type === "required" && (
                    <div className="text-danger fw-bold">
                      This field is required.
                    </div>
                  )}
                  {errors.password?.type === "pattern" && (
                    <div className="text-danger fw-bold">
                      Password must be of 8 or more characters long with a mix
                      of letters,numbers.
                    </div>
                  )}
                </div>
                <div className="mb-3 col-md-6 ">
                  <label className="form-label">
                    Confirm Password <sup>*</sup>
                  </label>
                  <span className="input_password_box">
                    <input
                      type={type}
                      className="form-control"
                      placeholder="Confirm Password"
                      aria-describedby="emailHelp"
                      name="confirm_password"
                      {...register("confirm_password", {
                        required: true,
                        pattern: {
                          value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
                        },
                      })}
                    />
                    <span className="eye-icon_box" onClick={changeType}>
                      {type === "password" ? (
                        <i className="fas fa-eye-slash" id="togglePassword"></i>
                      ) : (
                        <i className="fas fa-eye" id="togglePassword"></i>
                      )}
                    </span>
                  </span>

                  {errors.confirm_password?.type === "required" && (
                    <div className="text-danger fw-bold">
                      This field is required.
                    </div>
                  )}
                  {errors.confirm_password?.type === "pattern" && (
                    <div className="text-danger fw-bold">
                      Confirm password must be of 8 or more characters long with
                      a mix of letters,numbers.
                    </div>
                  )}
                  {errors.confirm_password?.type === "manual" && (
                    <div className="text-danger fw-bold">
                      Password and confirm password does not match.
                    </div>
                  )}
                </div>
                <div className="mt-3 col-12">
                  <div className="TcDIV">
                    <input
                      type="checkbox"
                      value="1"
                      id="agreeCheck"
                      {...register("agreeCheck", {
                        required: true,
                      })}
                    />
                    <label for="agreeCheck">
                      {" "}
                      I Agree to accept the terms and conditions of STRONGER and
                      maintain privacy
                    </label>
                    {errors.agreeCheck?.type === "required" && (
                      <div className="text-danger fw-bold">
                        This field is required.
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  {location?.state?.hash === undefined ? <button
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                    className="btn btn-secondary register-btn w-100" disabled
                  >
                    Please Select a Plan
                  </button>:   
                  <button
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                    className="btn btn-primary register-btn w-100"
                  >
                    Register
                  </button>}
               
                </div>
                <div className="col-12">
                  <div className=" Signin-info">
                    {" "}
                    Already Have An Account?
                    <Link to="/client-login"> Sign In</Link>{" "}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupClient;
