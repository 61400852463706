import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useRequestV2 from "../../../hooks/useRequestV2";
import { getQueryObj } from "../../../helpers/getQueryObj";
import { authSuccess } from "../../../redux/corporation-client/auth/action";
const Login = () => {
  const { request, response } = useRequestV2();
  const { request: clientPaymentRequest, response: clientPaymentResponse } =
    useRequestV2();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryObj = getQueryObj(search);
  const [type, setType] = useState("password");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please enter your email")
        .matches(
          /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
          "please enter a valid email"
        ),
      password: Yup.string()
        .required("Please enter your password")
        .min(8, "Password is too short - should be 8 characters minimum.")
        .matches(
          /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(){1,})(?=(.*[!@#$%^&*()\-__+.]){1,1}).{8,}$/,
          "Password must contain Uppercase, lowercase and special character."
        ),
    }),
    onSubmit: (values) => {
      //   const data = {};
      //   console.log(data);

      request("POST", "auth/clientlogin", {
        email: values.email,
        password: values.password,
      });
    },
  });

  useEffect(() => {
    if (
      queryObj?.success === "true" &&
      queryObj?.session_id &&
      queryObj?.p_id
    ) {
      clientPaymentRequest("POST", "save-client-payment", {
        session_id: queryObj?.session_id,
        price_id: queryObj?.p_id,
      });
    }
  }, []);

  useEffect(() => {
    if (clientPaymentResponse) {
      if (clientPaymentResponse.status == "success") {
        navigate("/client-login");
        toast.success(clientPaymentResponse.message[0], {
          position: "bottom-center",
          theme: "dark",
        });
      }
    }
  }, [clientPaymentResponse]);
  useEffect(() => {
    if (response) {
      console.log(response?.data?.url, "response?.data?.url");
      if (response.status === "success") {
        if (response?.data?.user?.is_verified) {
          dispatch(authSuccess(response.data));
          toast.success(response.message, {
            position: "bottom-center",
            theme: "dark",
          });
          navigate("/dashboard");
          localStorage.setItem("token", response?.data?.access_token);
          localStorage.setItem(
            "auth",
            JSON.stringify({
              access_token: response?.data?.access_token,
              user: response?.data?.user,
              user_role_id: response?.data?.user?.user_role_id,
            })
          );
        } else if (response?.is_subscribe == false) {
          window.location.href = `${response?.data?.url}`;

          toast.success(response?.message, {
            position: "bottom-center",
            theme: "dark",
          });
        } else {
          navigate("/client-verify", {
            state: {
              id: response?.data?.user?.uuid_string,
            },
          });
          toast.success("You are not verified! Please verify first", {
            position: "bottom-center",
            theme: "dark",
          });
        }

        localStorage.setItem("access_token", response?.data?.access_token);
      } else if (response.status === "fail") {
        toast.error(response.message, {
          position: "bottom-center",
          theme: "dark",
        });
      }
    }
  }, [response]);
  const changeType = () => {
    if (type === "password") {
      setType("text");
      return;
    }
    setType("password");
  };
  return (
    <>
      {/* <div
        className="sign-up-container sign-up-Splash beforeBg "
        style={{ backgroundImage: " url(./loginBg.png) !important" }}
      >
        <div className="container">
          <div className="sign-up-inner-container splash-container">
            <div className="row justify-content-center">
              <div className="col-md-4 col-xl-4 mt-5">
                <form
                  className="row sign_in-form-box"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="logoDiv text-center">
                    <img src="img/full-logo.png" alt="" />
                  </div>
                  <h1 className="form-title text-center mt-5">Corporate</h1>
                  <div className="mb-3 col-md-12 ">
                    <label className="form-label">
                      Login<sup>*</sup>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Name"
                      aria-describedby="emailHelp"
                      name="Email@example.com"
                      value=""
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="input-error text-danger fw-bold">
                        {formik.errors.email}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 col-md-12 ">
                    <label className="form-label">
                      Password<sup>*</sup>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter Password"
                      aria-describedby="emailHelp"
                      name="Enter Your Password"
                      value=""
                      {...formik.getFieldProps("password")}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="input-error text-danger fw-bold">
                        {formik.errors.email}
                      </div>
                    )}
                  </div>
                  <div className="mt-3 text-center col-12 text">
                    <button type="submit" className="BlueThemeBtn themeRoundedBtn">
                      Sign In
                    </button>
                  </div>
                  <div className="col-12">
                    <div className=" Signin-info">
                      <a href="javascript:void(0)" className="text-white">
                        Forget password ?{" "}
                      </a>{" "}
                      Don’t Have An Account?
                      <Link to="/corporation/client/register"> Sign Up</Link>{" "}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        className="sign-up-container sign-up-Splash beforeBg"
        style={{ backgroundImage: `url(assets/img/loginBg.png)` }}
      >
        <Link to="/">
        <div className="logoDiv clientLoginLogo text-center">
          
            <img src="/assets/img/full-logo.png" alt="" />
        </div>
        </Link>

        <div className="container">
          <div className="sign-up-inner-container splash-container">
            <div className="row justify-content-center h-100 align-items-center">
              <div className="col-md-4 col-xl-4">
                <form
                  className="row sign_in-form-box"
                  onSubmit={formik.handleSubmit}
                >
                  <h1 className="form-title text-center">Corporate</h1>
                  <div className="mb-3 col-md-12 ">
                    <label className="form-label">
                      Email<sup>*</sup>
                    </label>
                    <span className="input_password_box">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Email"
                        aria-describedby="emailHelp"
                        name="Email@example.com"
                        value=""
                        {...formik.getFieldProps("email")}
                      />
                      <span className="eye-icon_box">
                        <i className="fal fa-envelope"></i>
                      </span>
                    </span>

                    {formik.touched.email && formik.errors.email && (
                      <div className="input-error text-danger fw-bold">
                        {formik.errors.email}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 col-md-12 ">
                    <label className="form-label">
                      Password<sup>*</sup>
                    </label>
                    <span className="input_password_box">
                      <input
                        type={type}
                        className="form-control"
                        placeholder="Enter Password"
                        aria-describedby="emailHelp"
                        name="Enter Your Password"
                        value=""
                        {...formik.getFieldProps("password")}
                      />
                      <span className="eye-icon_box" onClick={changeType}>
                        {type === "password" ? (
                          <i className="fas fa-eye-slash" id="togglePassword"></i>
                        ) : (
                          <i className="fas fa-eye" id="togglePassword"></i>
                        )}
                      </span>
                    </span>

                    {formik.touched.password && formik.errors.password && (
                      <div className="input-error text-danger fw-bold">
                        {formik.errors.password}
                      </div>
                    )}
                  </div>
                  <div className="mt-3 text-center col-12 text">
                    <button type="submit" className="BlueThemeBtn themeRoundedBtn">
                      Sign In
                    </button>
                  </div>
                  <div className="col-12">
                    <div className=" Signin-info">
                      <Link to={"/client-forgot-password"} className="text-white">
                        Forget password?{" "}
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
