import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Link, useParams } from "react-router-dom";
import useRequestV2 from "../../../hooks/useRequestV2";
import PreloaderOuter from "../../../pages/components/preloaderOuter";

const Articles = () => {
  const params = useParams();
  const { request: getplansreq, response: getplansres } = useRequestV2();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    scrollTo(0, 0);
    getplansreq("GET", `view-activity/${params.id}`);
  }, [params.id]);
  useEffect(() => {
    if (getplansres) {
      setData(getplansres?.data);
      // console.log(getplansres?.data);
      setLoading(false);
    }
  }, [getplansres]);
  return (
    <>
      <Header />
      {loading ? (
        <PreloaderOuter />
      ) : (
        <>
          <section className="articles_page_section">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12 col-md-2 col-lg-2">
                  <div className="back_link">
                    <Link to="/activities">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 20L0 10L10 0L11.7812 1.75L4.78125 8.75H20V11.25H4.78125L11.7812 18.25L10 20Z"
                          fill="white"
                        />
                      </svg>
                      BACK
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <div className="inner_img_heading">
                    <img src={data?.activity_image} alt="#" />
                    <h2>{data?.activity_title}</h2>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div className="articles_right_block">
                    <h3>{data?.activity_title}</h3>
                    <p>{data?.activity_description}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {data?.coaches ? (
            <>
              <section className="articles_bottom_section position-relative">
                <div className="container">
                  <div className="text-center mb-5">
                    <h2 className="Sessions-name">
                      our {data?.activity_title} coaches
                    </h2>
                  </div>
                  <div className="row g-4">
                    {data?.coaches.map((item, index) => {
                      return (
                        <div
                          className="col-sm-12 col-md-4 col-lg-4"
                          key={index}
                        >
                          <div className="inner_card_block">
                            <img
                              src={item?.id_user.profile_image}
                              alt="#"
                              style={{ objectFit: "cover" }}
                            />
                            <h3>
                              {item?.id_user.profile_first_name}{" "}
                              {item?.id_user.profile_last_name}
                            </h3>
                            <Link
                              className="theme-btn ms-auto"
                              to={`/coaches-version2/${item?.id}`}
                            >
                              {" "}
                              View Profile
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default Articles;
