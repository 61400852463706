import react, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "swiper/swiper-bundle.css";
import "swiper/swiper-bundle.min.css";
import { Navigation } from "swiper";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/corporation-store/auth/action";
import useRequestV2 from "../../../hooks/useRequestV2";
import moment from "moment";

const Header = () => {
  const navigate = useNavigate();
  let company_name = localStorage.getItem("company_name")
  let first_name = localStorage.getItem("first_name")
  let last_name = localStorage.getItem("last_name")


  const sidebarToggleHandler = () => {
    const elem = document.body;
    const elemTwo = document.getElementsByClassName("navbar-collapse")[0];
    if (elem.className.includes("scroll-off")) {
      elem.classList.remove("scroll-off");
      elemTwo.classList.remove("menu-show");
    } else {
      elem.classList.add("scroll-off");
      elemTwo.classList.add("menu-show");
    }
  };
  const logOut = () => {
    dispatch(logout());
    toast.success("Now you are logout!");
    navigate("/");
  };

  return (
    <>
      <header id="header">
        <div className="container-fluid">
          <div className="row">
            <div classNameName="col-12">
              <nav className="navbar navbar-expand-lg">
                <Link className="navbar-brand logo-margin" to="/calander-coach-booking">
                  <img src="img/logo.png" alt="" />
                  <span className="brand-info">
                    <span className="brand-name"> {company_name} </span>
                    <span className="brand-holder-name"> {first_name} {last_name} </span>
                  </span>
                </Link>

                <button
                  className="navbar-toggler"
                  id="newClass"
                  type="submit"
                  onClick={sidebarToggleHandler}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse">
                  <ul className="navbar-nav ms-auto">
                    <li className="nav-item ">
                      <Link className="nav-link" to="/calender-coach-booking">
                        Schedule
                      </Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link" to="/activities">
                        Activities
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/coaches-employee">
                        Coaches
                      </Link>
                    </li>
                  </ul>
                  <a
                    className="log_outbtn"
                    onClick={logOut}
                    style={{ cursor: "pointer" }}
                    href="/"
                  >
                    Logout{" "}
                    <span>
                      <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.125 16.625H3.95833C3.53841 16.625 3.13568 16.4582 2.83875 16.1613C2.54181 15.8643 2.375 15.4616 2.375 15.0417V3.95833C2.375 3.53841 2.54181 3.13568 2.83875 2.83875C3.13568 2.54181 3.53841 2.375 3.95833 2.375H7.125M12.6667 13.4583L16.625 9.5M16.625 9.5L12.6667 5.54167M16.625 9.5H7.125"
                          stroke="white"
                          strokeWidth="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </div>

                {/* <div className="nav-item dropdown user_dropdown">
                            <a className="nav-link dropdown-toggle" href="javascript:void(0);" id="user-drop" role="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img src="img/user.png" alt=""/>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="user-drop">
                                <div className="user_info">
                                    <div className="user_name">
                                        <div>Sandeep Prajapati</div>
                                        <div className="user_email">
                                            <small>Sandeep@gmail.com</small>
                                        </div>
                                    </div>
                                    <ul>
                                        <li>
                                            <a href="#!"><i className="ion-android-person"></i> My Profile</a>
                                        </li>
                                        <li>
                                            <a href="#!"><i className="ion-log-out"></i> Logout</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
