import React, { useEffect, useState } from 'react'
import { useFormik } from "formik"

import * as Yup from 'yup';
import { useSelector, useDispatch } from "react-redux"
import { activateFeedback, logoutUser } from "../../redux/actions";
import useRequest from "../../hooks/useRequest";
import Preloader from '../components/preloader';
import OtpInput from 'react-otp-input';
import { useNavigate } from "react-router-dom";
import { MultiLang } from '../MultiLang';


const ChangePass = () => {
    const { loading } = useSelector(state => state.Auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { language } = useSelector((state) => state.reducer);

    const { request, response } = useRequest()

    const formik = useFormik({
        initialValues: {
            old_password: '',
            new_password: '',
            confirm_new_password: ''
        },
        validationSchema: Yup.object({
            old_password: Yup.string()
                .required('Old password required'),
            confirm_new_password: Yup.string()
                .required('Confirm required'),
            new_password: Yup.string()
                .required('New password required')
                .min(8, '8 chars minimum.')
                .matches(/^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$/, 'Must contain Uppercase, lowercase, number and special charecter.')
        }),
        onSubmit: values => {
            if (values.new_password !== values.confirm_new_password) {
                dispatch(activateFeedback({ type: 'error', message: 'Please confirm the password' }))
                return;
            }
            request("POST", "/secure/change-password/", values)
        }
    });

    useEffect(() => {
        if (response) {
            dispatch(activateFeedback({ type: response?.status, message: response?.message }))
            if (response?.status === "success") {
                formik.setFieldValue('old_password', "")
                formik.errors.old_password = null
                formik.touched.old_password = false
                formik.setFieldValue('new_password', "")
                formik.errors.new_password = null
                formik.touched.new_password = false
                formik.setFieldValue('confirm_new_password', "")
                formik.errors.confirm_new_password = null
                formik.touched.confirm_new_password = false
                dispatch(logoutUser())
                navigate("/login")
            }
        }
    }, [response])

    return (
        <>
            {loading && <Preloader />}
            <form className="setting-content-con" onSubmit={formik.handleSubmit}>
                <div className="setting-content-con-header">
                    {MultiLang["Change Password"][language]}
                </div>
                <div className="setting-input flex change-password">
                    <div className="setting-input-con small">
                        <label htmlFor="">{MultiLang["Current Password"][language]}</label>
                        <input type="password" placeholder="current password" name="old_password" {...formik.getFieldProps('old_password')} />
                        {formik.touched.old_password && formik.errors.old_password && <div className="input-error-small">{formik.errors.old_password}</div>}
                    </div>
                    <div className="setting-input-con small">
                        <label htmlFor="">{MultiLang["New Password"][language]}</label>
                        <input type="password" placeholder="new password" name="new_password" {...formik.getFieldProps('new_password')} />
                        {formik.touched.new_password && formik.errors.new_password && <div className="input-error-small">{formik.errors.new_password}</div>}
                    </div>
                    <div className="setting-input-con small">
                        <label htmlFor="">{MultiLang["Confirm Password"][language]}</label>
                        <input type="password" placeholder="confirm password" name="confirm_new_password" {...formik.getFieldProps('confirm_new_password')} />
                        {formik.touched.confirm_new_password && formik.errors.confirm_new_password && <div className="input-error-small">{formik.errors.confirm_new_password}</div>}
                    </div>
                </div>
                <div className="setting-bottom">
                    <button className="blue-btn btn setting-btn">
                    {MultiLang["Change Password"][language]}
                    </button>
                </div>
            </form>
        </>
    )
}

const ChangeEmail = () => {
    const { user } = useSelector(state => state.Auth)

    const [otpModelState, setOtpModelState] = useState(false)
    
    const [otp, setOtp] = useState("")
    const [otpError, setOtpError] = useState(null)
    const [newEmail, setNewEmail] = useState("")
    
    const { request: reqOtp, response: resOtp } = useRequest()
    const { request: reqChangeEmail, response: resChangeEmail } = useRequest()

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { language } = useSelector((state) => state.reducer);

    const formik = useFormik({
        initialValues: {
            new_email: '',
            password: ''
        },
        validationSchema: Yup.object({
            new_email: Yup.string()
                .required('Please Enter Your email')
                .matches(/^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, 'please enter a valid email'),
            password: Yup.string()
                .required('Please Enter Password'),
        }),
        onSubmit: values => {
            setNewEmail(values.new_email)
            reqOtp("POST", '/secure/update-email-request/', values)
        }
    });

    const submitOtpHandler = () => {
        if (!otp || otp.length !== 6) {
            setOtpError('Please enter a valid otp')
            return
        }
        reqChangeEmail("POST", '/secure/change-email/', { otp, new_email: newEmail })
    }

    useEffect(() => {
        if (resOtp) {
            if (resOtp?.status === 'success') {
                setOtpModelState(true)
                setOtp("")
                setOtpError(null)
                formik.setFieldValue('new_email', "")
                formik.errors.new_email = null
                formik.touched.new_email = false
                formik.setFieldValue('password', "")
                formik.errors.password = null
                formik.touched.password = false
            } else {
                dispatch(activateFeedback({ type: resOtp?.status, message: resOtp?.message }))
            }
        }
    }, [resOtp])

    useEffect(() => {
        if (resChangeEmail) {
            if (resChangeEmail?.status === 'success') {
                setOtpModelState(false)
                dispatch(activateFeedback({ type: resChangeEmail?.status, message: resChangeEmail?.message }))
                dispatch(logoutUser())
                navigate("/login")
            } else {
                setOtpError(resChangeEmail?.message)
            }
        }
    }, [resChangeEmail])

    return (
        <>
            <form className="setting-content-con" onSubmit={formik.handleSubmit} >
                <div className="setting-content-con-header">{MultiLang["Change Email"][language]}</div>
                <div className="setting-input">
                    <div className="setting-input-con">
                        <label htmlFor="new_email">{MultiLang["Current Email"][language]}</label>
                        <input

                            value={user?.email}
                            // placeholder={user?.email}
                            disabled={true}
                            type="email"
                        />
                    </div>
                    <div className="setting-input-con">
                        <label htmlFor="new_email">{MultiLang["New Email"][language]}</label>
                        <input
                            disabled={otpModelState ? true : false}
                            name="new_email"
                            {...formik.getFieldProps('new_email')}
                            type="text"
                            autoComplete="off"
                        />
                        {formik.touched.new_email && formik.errors.new_email && <div className="input-error">{formik.errors.new_email}</div>}
                    </div>
                    <div className="setting-input-con">
                        <label htmlFor="password">{MultiLang["Current Password"][language]}</label>
                        <input
                            disabled={otpModelState ? true : false}
                            name="password"
                            {...formik.getFieldProps('password')}
                            type="password"
                            autoComplete="off"
                        />
                        {formik.touched.password && formik.errors.password && <div className="input-error">{formik.errors.password}</div>}

                    </div>
                </div>
                <div className="setting-alert">An OTP will be send on this email</div>
                <div className="setting-bottom">
                    <button disabled={otpModelState ? true : false} type='submit' className="blue-btn btn setting-btn">
                        {MultiLang["Get OTP"][language]}
                    </button>
                </div>
            </form>
            <div
                className={
                    otpModelState
                        ? "finish-modal setting-modal active"
                        : "finish-modal setting-modal"
                }
            >
                <div
                    className="modal-close"
                    onClick={() => {
                        setOtpModelState(false);
                    }}
                >
                    <img src="images/auth/auth-close.png" alt="" />
                </div>
                <div className="modal-header">OTP</div>
                <div className="modal-body">
                    <div className="code-inputs_opt">
                        <div className="setting-success">Enter the OTP send to {newEmail}</div>
                        <OtpInput
                            className='otpBox'
                            value={otp}
                            onChange={(value) => { setOtp(value) }}
                            numInputs={6}
                            separator={<span></span>}
                        />
                        {otpError && <div className="input-error">{otpError}</div>}
                    </div>
                    <div className="setting-bottom">
                        <button type='button' onClick={submitOtpHandler} className="blue-btn btn setting-btn">submit</button>
                    </div>
                </div>
            </div>
        </>
    )
}

const Security = () => {
    return (
        <>
            <ChangePass />
            <ChangeEmail />
        </>
    )
}
export default Security;