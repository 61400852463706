import react, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useRequestV2 from "../../../hooks/useRequestV2";
import quotes from "./img/quotes.png";
import ReactPlayer from "react-player";
import { Rating } from "react-simple-star-rating";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
const CoachesV2 = () => {
  const params = useParams();
  const { request: getplansreq, response: getplansres } = useRequestV2();
  const { request: ratesreq, response: ratesres } = useRequestV2();
  const [res, setRes] = useState();
  const [res1, setRes1] = useState({});
  const [rating, setRating] = useState(0);
  const [vplay , setvPlay] = useState(false)
  const { request: ratingRequest, response: ratingResponse } = useRequestV2();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = useForm();

  useEffect(() => {
    scrollTo(0, 0);
    getplansreq("GET", `view-coach/${params.id}`);
  }, [params.id]);
  useEffect(() => {
    if (getplansres) {
      setRes(getplansres?.data);
      //   let formdata = new FormData();
      //   formdata.append("review_rates", getplansres?.data?.review_rates);
      // ratesreq("POST", `coach-rating/${params.id}` , formdata);
    }
  }, [getplansres]);

  // useEffect(() => {
  //   scrollTo(0,0)

  //   ratesreq("POST", `coach-rating/${params.id}` , formdata);
  // }, [params.id]);
  useEffect(() => {
    if (ratesres) {
      setRes1(ratesres?.data);
      console.log(ratesres?.data);
    }
  }, [ratesres]);

  const handleRating = (rate: number) => {
    setRating(rate);
    console.log(rate);
    setValue("ratings", rate);
    let formdata = new FormData();
    // formdata.append("id_employee", res?.employeeId);
    // formdata.append("id_session", id);
    // formdata.append("satisfaction_rate", rate);
    formdata.append("review_rates", rate);

    ratingRequest("POST", `coach-rating/${params.id}`, formdata);
  };
  useEffect(() => {
    if (ratingResponse) {
      if (ratingResponse.status == "success") {
        toast.success(ratingResponse.message);
        // console.log(ratingResponse?.data?.review_rates);
      } else if (ratingResponse.status == "error") {
        toast.error(ratingResponse.message);
      }
    }
  }, [ratingResponse]);

  const play = () => {
    setvPlay(true);
  };

  return (
    <>
      <section className="meet_our_coaches_section">
        <div className="container-fluid">
          <div className="text-center mb-5 position-relative">
            <h2 className="Sessions-name">Meet our coaches</h2>
            <p>Our Story</p>
            <div className="back_link left_set">
              <Link to="/coaches-employee">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 20L0 10L10 0L11.7812 1.75L4.78125 8.75H20V11.25H4.78125L11.7812 18.25L10 20Z"
                    fill="white"
                  ></path>
                </svg>
                BACK
              </Link>
            </div>
          </div>
          <div className="meet_our_coaches_inner_wrapper">
            <div className="row">
              <div className="col-sm-12 col-md-5 col-lg-5">
                <div>
                  <img
                    src={res?.id_user?.profile_image}
                    style={{ borderRadius: "20px" }}
                    alt="#"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-7 col-lg-7">
                <div className="meet_our_coaches_right_block">
                  <img src={quotes} />
                  <h3>
                    {res?.id_user?.profile_first_name}{" "}
                    {res?.id_user?.profile_last_name}
                  </h3>
                  {res?.activities.map((activities, index) => {
                      return (
                        <small key={index}>
                          {activities.activity_title} , &nbsp;&nbsp;
                        </small>
                      );
                    })}
                    <br></br><br></br>
                  <h5>
                    {res?.id_user?.profile_first_name} has been teaching
                    mindfulness for 5 years.
                  </h5>
                  <p>{res?.description}</p>
                  <h5>Are you satisfied with with my sessions?</h5>
                  {/* <div className="star_box">
                    {res?.review_rates === 5 ?  
                    <>
                    <i className="fas fa-star text-warning"></i>
                    <i className="fas fa-star text-warning"></i>
                    <i className="fas fa-star text-warning"></i>
                    <i className="fas fa-star text-warning"></i>
                    <i className="fas fa-star text-warning"></i></>
                    :  <></>}

                    {res?.review_rates === 4 ?  <>
                    <i className="fas fa-star text-warning"></i>
                    <i className="fas fa-star text-warning"></i>
                    <i className="fas fa-star text-warning"></i>
                    <i className="fas fa-star text-warning"></i>
                    <i className="fas fa-star "></i></>
                    :  <></>}

                     {res?.review_rates === 3 ?  <>
                    <i className="fas fa-star text-warning"></i>
                    <i className="fas fa-star text-warning"></i>
                    <i className="fas fa-star text-warning"></i>
                    <i className="fas fa-star "></i>
                    <i className="fas fa-star "></i></>
                    :  <></>}

                     {res?.review_rates === 2 ?  <>
                    <i className="fas fa-star text-warning"></i>
                    <i className="fas fa-star text-warning"></i>
                    <i className="fas fa-star "></i>
                    <i className="fas fa-star "></i>
                    <i className="fas fa-star "></i></>
                    :  <></>}

                     {res?.review_rates === 1 ?  <>
                    <i className="fas fa-star text-warning"></i>
                    <i className="fas fa-star "></i>
                    <i className="fas fa-star "></i>
                    <i className="fas fa-star "></i>
                    <i className="fas fa-star "></i></>
                    :  <></>}         

                      {res?.review_rates === 0 ?  <>
                    <i className="fas fa-star "></i>
                    <i className="fas fa-star "></i>
                    <i className="fas fa-star "></i>
                    <i className="fas fa-star "></i>
                    <i className="fas fa-star "></i></>
                    :  <></>}                    

                  
                  </div> */}
                  <Rating
                    onClick={handleRating}
                    ratingValue={res?.review_rates}
                    rating={res?.review_rates}
                  />
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {res?.presentation_video ?   
      <section className="vedio_section">
        <div className="container-fluid p-0">
          <div className="videocover">
            <span className="play-btn">
              <a
                href="javascript:void(0);"
                className="play-button"
                onClick={play}
              >
                <svg
                  width="36"
                  height="40"
                  viewBox="0 0 36 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M36 20L0.750002 39.9186L0.750004 0.0814136L36 20Z"
                    fill="white"
                  />
                </svg>
              </a>
            </span>
             <ReactPlayer
              url={res?.presentation_video}
              playing={vplay}
              width="100%"
              id="video1"
              height="100%"
              controls={true}
              playIcon
              
            />
        
          </div>
        </div>
      </section>: <>
            <div className="d-none"></div>
            </>}
    </>
  );
};

export default CoachesV2;
