import "./../../css/challenge.css"
import { Link } from "react-router-dom";
import { useEffect, useState } from "react"
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css"
import usePagination from "../../hooks/usePagination"
import useRequest from "../../hooks/useRequest"
import { useSelector } from "react-redux"
import { MultiLang } from './../MultiLang';
import moment from "moment";
// import "moment-timezone";

const OngoingChallenge = () => {
    const { settings } = useSelector(state => state.Site)

    const [challenges, setChallenges] = useState(null)
    const [challengeTypes, setChallengeTypes] = useState(null)
    const [totalResults, setTotalResults] = useState(0)
    const [cost, setCost] = useState({ min: settings?.min_cost, max: settings?.max_cost })
    const [participant, setParticipant] = useState({ min: settings?.min_participant, max: settings?.max_participant })

    const { request, response } = useRequest()
    const { request: reqTypes, response: resTypes } = useRequest()
    const { pagination, activePage, pageSize } = usePagination(totalResults)
    const { language } = useSelector((state) => state.reducer);

    const [queryData, setQueryData] = useState({
        cost: cost,
        participant: participant,
        type: 'Strength',
        sort_by: 'joining_fee',
        page_size: pageSize,
        page: activePage,
        isOngoing: true
    })

    useEffect(() => {
        setQueryData({ ...queryData, page_size: pageSize, page: activePage })
    }, [activePage, pageSize])

    useEffect(() => {
        request("GET", `/challenges?min_cost=${queryData.cost.min}&max_cost=${queryData.cost.max}&min_participant=${queryData.participant.min}&max_participant=${queryData.participant.max}&type=${queryData.type}&sort_by=${queryData.sort_by}&page-size=${queryData.page_size}&page=${queryData.page}&isOngoing=${queryData.isOngoing}`)
    }, [queryData])

    useEffect(() => {
        if (response) {
            if (response?.status === 'success') {
                setChallenges(response?.data?.result)
                setTotalResults(response?.data?.total_record)
            } else {
                setChallenges(null)
            }
        }
    }, [response])

    useEffect(() => {
        if (resTypes) {
            setChallengeTypes(resTypes?.challengeTypeData)
        }
    }, [resTypes])

    useEffect(() => {
        window.scrollTo(0, 0)
        reqTypes("GET", "/challenges/challenges-type")

    }, [])

    return (

        <div className="profile-main challenge-main">
            <div className="profile-tab-content  active">

                <div className="challenge-filter">
                    <div className="content">
                        <div className="filter-cost">
                            <div style={{ marginBottom: "30px" }}>{MultiLang["Cost"][language]}</div>
                            <InputRange
                                maxValue={settings?.max_cost}
                                minValue={settings?.min_cost}
                                formatLabel={value => `${value} €`}
                                value={cost}
                                onChange={(value) => { setCost(value) }}
                                onChangeComplete={(value) => { setQueryData({ ...queryData, cost: cost }) }}
                            />
                        </div>
                        <div className="filter-cost filter-participant">
                            <div style={{ marginBottom: "30px" }}>{MultiLang["Participant"][language]}</div>
                            <InputRange
                                maxValue={settings?.max_participant}
                                minValue={settings?.min_participant}
                                formatLabel={value => `${value}`}
                                value={participant}
                                onChange={(value) => { setParticipant(value) }}
                                onChangeComplete={(value) => { setQueryData({ ...queryData, participant: participant }) }}
                            />
                        </div>
                        {
                            challengeTypes && challengeTypes.length > 0 &&
                            <div className="filter-type">
                                <div>
                                    {MultiLang["Type"][language]}
                                </div>
                                <div className="filter-type-main">

                                    {
                                        challengeTypes.map((value, i) => {
                                            return (
                                                <div key={i} className={`filter-type-con ${queryData.type === value?.name ? "active" : ""}`}
                                                    onClick={() => { setQueryData({ ...queryData, type: value?.name }) }}
                                                >
                                                    <img src={value?.icon} alt="" />
                                                    {/* {value?.name} */}
                                                    {value?.name == "Strength" ? MultiLang["Strength"][language] : value?.name == "Cardio" ? MultiLang["Cardio"][language] : value?.name}
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="challenge-content challengePage-block" >
                    <div className="challenge-content-header content   ">
                        {totalResults} {MultiLang["Challenges"][language]}
                        <div>
                            <label htmlFor="">Sort By:</label>
                            <select name="" id="" onChange={(e) => { setQueryData({ ...queryData, sort_by: e.target.value }) }}>
                                <option value="joining_fee">{MultiLang["Cost"][language]}</option>
                                <option value="participant_count">{MultiLang["Participants"][language]}</option>
                            </select>
                        </div>
                    </div>
                    <div className="content">
                        {
                            challenges && challenges.length > 0 ?
                                <div className="cont_bx">
                                    {
                                        challenges.map((value, i) => {
                                            return (
                                                <Link key={i} to={`/challenge/${value.id}`} className="profile-main-con challenge-main-con linkDiv" >
                                                    <div className="profile-main-con-header">
                                                        <img src={value?.cover_image} alt="" />
                                                        <div className="profile-main-con-badge2">
                                                            {MultiLang["Registration"][language]}: <br /><span>{value?.joining_fee}€</span>
                                                            {/* {
                                                                value?.joining_fee_commission ?
                                                                    <>
                                                                        Bank: <br /> <span>{+value?.joining_fee - +value?.joining_fee_commission}€ + {value?.joining_fee_commission}€</span>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        Bank: <br /> <span>{+value?.joining_fee}€</span>
                                                                    </>
                                                            } */}
                                                        </div>
                                                    </div>
                                                    <div className="profile-main-con-bottom">
                                                        <div>
                                                            {value?.title}
                                                        </div>
                                                        {
                                                            value?.isChallenge_type === 'timing' &&
                                                            <div className="profile-main-con-text">
                                                                <div>
                                                                    <img src="./images/challenge/calender-light.png" alt="" />
                                                                    {MultiLang["Date"][language]}: <strong>
                                                                        {/* {moment(value?.end_time).format('DD.MM.YYYY')} */}
                                                                        {
                                                                            value?.challenge_start_date ?
                                                                                <>{value.challenge_start_date}</>
                                                                                :
                                                                                <>{moment(value?.end_time).tz("Africa/Cairo").format('DD.MM.YYYY')}</>
                                                                        }
                                                                    </strong>
                                                                    <br />
                                                                    {/* <span style={{ fontSize: '12px' }}>(UTC + 2, Central European Time)</span> */}
                                                                </div>
                                                                <div>
                                                                    <img src="./images/challenge/clock-light.png" alt="" />
                                                                    {MultiLang["Start Time"][language]}: <strong>
                                                                        {/* {moment(value?.end_time).format('h:mm a')} */}
                                                                        {
                                                                            value?.challenge_start_time ?
                                                                                <>{value.challenge_start_time}</>
                                                                                :
                                                                                <>{moment(value?.end_time).tz("Africa/Cairo").format('h:mm a')}</>
                                                                        }
                                                                    </strong>
                                                                    <br />
                                                                    <span style={{ fontSize: '12px' }}>UTC + 1, Central European Time</span>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="profile-main-con-text margin-bottom0">
                                                            <div>
                                                                <img src="./images/challenge/icon-profile.png" alt="" />
                                                                {MultiLang["Participants"][language]}: <strong>{value?.total_participate}/{value?.participant_count}</strong>
                                                            </div>
                                                            <div>
                                                                <img src="./images/challenge/icon-prize-fill.png" alt="" />
                                                                {MultiLang["First prize"][language]}: <strong>{value?.first_winner_price}€</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>

                                            )
                                        })
                                    }
                                    {pagination}
                                </div>
                                : <div className="challenge-nodata"> {MultiLang["No data found"][language]} </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OngoingChallenge;