import axios from "axios";
import { useState } from "react";
import useRequest from "./useRequest";

const useTranslate = () => {
    const { request, response } = useRequest();
    const [translateResponse, setTranslateResponse] = useState(null)

    const translateRequest = (sourceHtml, destLang) => {
        if (destLang == "GB")
        {
            setTranslateResponse(sourceHtml);
        }
        else 
        {
            if (destLang == "FR") destLang = "fr";
            else if (destLang == "DE") destLang = "de";
            const options = {
                method: 'GET',
                url: 'https://nlp-translation.p.rapidapi.com/v1/translate',
                params: { text: sourceHtml, to: destLang, from: 'en' },
                headers: {
                    'X-RapidAPI-Key': process.env.REACT_APP_TRANSLATOR_API_KEY,
                    'X-RapidAPI-Host': 'nlp-translation.p.rapidapi.com'
                }
            };
            
            axios.request(options).then(function (response) {
                setTranslateResponse(response.data["translated_text"][destLang]);
            }).catch(function (error) {
                setTranslateResponse(sourceHtml);
            });
        }
    }

    return {
        translateRequest,
        translateResponse
    }
}

export default useTranslate;