import React, { useEffect } from "react";
import Sidebar from "./Sidebar/Sidebar";
import { useFormik } from "formik";
import * as Yup from "yup";
import useRequestV2 from "../../../hooks/useRequestV2";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const GetInTouch = () => {
  const { request, response } = useRequestV2();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      company_name: "",
      phone_no: "",
      messages: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("This Field Is Required")
        .matches(
          /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
          "please enter a valid email"
        ),
      company_name: Yup.string().required("This Field Is Required"),
      phone_no: Yup.string().required("This Field Is Required").matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Phone number is not valid')
      .min(10, "too short")
      .max(10, "too long"),
      messages: Yup.string().required("This Field Is Required"),
    }),
    onSubmit: (values) => {
      request("POST", "get-in-touch", {
        email: values.email,
        company_name: values.company_name,
        phone_no: values.phone_no,
        messages: values.messages,
      });
  
    },
  });
  useEffect(() => {
    if (response) {
      if (response.status == "success") {
        toast.success(response.message);
        navigate("/dashboard");
      } else if (response.status == "fail") {
        toast.error(response.message);
      }
    }
  }, [response]);
  return (
    <>
      <Sidebar />
      <div className="page_container">
        <div className="main_content get_inTouchContent">
          <div className="get_inTouchImg">
            <div
              className="col-sm-10 ms-auto me-auto"
              style={{position: "relative", zIndex: "9"}}
            >
              <div className="row bg-white p-4"
                   style={{ borderRadius: "10px"}}
              >
                <div className="col-sm-6">
                  <form onSubmit={formik.handleSubmit}>
                    <h3 className="text-dark text-center">Get in touch</h3>
                    <p className="text-dark text-center">
                      We are here for you! How can we help?{" "}
                    </p>
                    <div className="row">
                      <div className="col-sm-6 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Company*"
                          {...formik.getFieldProps("company_name")}
                        />
                        {formik.touched.company_name &&
                          formik.errors.company_name && (
                            <div className="text-danger fw-bold">
                              {formik.errors.company_name}
                            </div>
                          )}
                      </div>
                      <div className="col-sm-6 mb-3">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Number*"
                          {...formik.getFieldProps("phone_no")}
                        />
                        {formik.touched.phone_no && formik.errors.phone_no && (
                          <div className="text-danger fw-bold">
                            {formik.errors.phone_no}
                          </div>
                        )}
                      </div>
                      <div className="col-sm-12 mb-3">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email*"
                          {...formik.getFieldProps("email")}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="text-danger fw-bold">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                      <div className="col-sm-12 mb-3">
                        <textarea
                          placeholder="Message Here..."
                          className="border bg-white"
                          {...formik.getFieldProps("messages")}
                        ></textarea>
                        {formik.touched.messages && formik.errors.messages && (
                          <div className="text-danger fw-bold">
                            {formik.errors.messages}
                          </div>
                        )}
                        \
                      </div>
                      <button
                        className="btn text-light rounded-pill p-0 p-3"
                        style={{ backgroundColor: "#466FFF" }}
                        type="submit"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-sm-6 text-center">
                  <img
                    src="https://img.freepik.com/free-vector/contact-center-abstract-concept_335657-3032.jpg"
                  />
                  <ul className="social_listing">
                    <li>
                      <span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <mask id="mask0_830_5971" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                          <rect width="24" height="24" fill="#D9D9D9"/>
                          </mask>
                          <g mask="url(#mask0_830_5971)">
                          <path d="M12 12C12.55 12 13.021 11.804 13.413 11.412C13.8043 11.0207 14 10.55 14 10C14 9.45 13.8043 8.979 13.413 8.587C13.021 8.19567 12.55 8 12 8C11.45 8 10.9793 8.19567 10.588 8.587C10.196 8.979 10 9.45 10 10C10 10.55 10.196 11.0207 10.588 11.412C10.9793 11.804 11.45 12 12 12ZM12 19.35C14.0333 17.4833 15.5417 15.7873 16.525 14.262C17.5083 12.7373 18 11.3833 18 10.2C18 8.38333 17.4207 6.89567 16.262 5.737C15.104 4.579 13.6833 4 12 4C10.3167 4 8.89567 4.579 7.737 5.737C6.579 6.89567 6 8.38333 6 10.2C6 11.3833 6.49167 12.7373 7.475 14.262C8.45833 15.7873 9.96667 17.4833 12 19.35ZM12 22C9.31667 19.7167 7.31267 17.5957 5.988 15.637C4.66267 13.679 4 11.8667 4 10.2C4 7.7 4.80433 5.70833 6.413 4.225C8.021 2.74167 9.88333 2 12 2C14.1167 2 15.979 2.74167 17.587 4.225C19.1957 5.70833 20 7.7 20 10.2C20 11.8667 19.3377 13.679 18.013 15.637C16.6877 17.5957 14.6833 19.7167 12 22Z" fill="#1C1B1F"/>
                          </g>
                        </svg>
                      </span>
                        L-1911 Luxembourg
                    </li>
                    <li>
                      <span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <mask id="mask0_830_5958" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                          <rect width="24" height="24" fill="#D9D9D9"/>
                          </mask>
                          <g mask="url(#mask0_830_5958)">
                          <mask id="mask1_830_5958" maskUnits="userSpaceOnUse" x="-1" y="-1" width="25" height="25">
                          <rect x="-0.200195" y="-0.150879" width="24" height="24" fill="#D9D9D9"/>
                          </mask>
                          <g mask="url(#mask1_830_5958)">
                          <path d="M3.7998 19.8491C3.2498 19.8491 2.77914 19.6535 2.3878 19.2621C1.9958 18.8701 1.7998 18.3991 1.7998 17.8491V5.84912C1.7998 5.29912 1.9958 4.82845 2.3878 4.43712C2.77914 4.04512 3.2498 3.84912 3.7998 3.84912H19.7998C20.3498 3.84912 20.8208 4.04512 21.2128 4.43712C21.6041 4.82845 21.7998 5.29912 21.7998 5.84912V17.8491C21.7998 18.3991 21.6041 18.8701 21.2128 19.2621C20.8208 19.6535 20.3498 19.8491 19.7998 19.8491H3.7998ZM11.7998 12.8491L3.7998 7.84912V17.8491H19.7998V7.84912L11.7998 12.8491ZM11.7998 10.8491L19.7998 5.84912H3.7998L11.7998 10.8491ZM3.7998 7.84912V5.84912V17.8491V7.84912Z" fill="#1C1B1F"/>
                          </g>
                          </g>
                        </svg>
                      </span>
                        info@stronger.lu
                    </li>
                    <li>
                      <span>
                        <svg 
                          width="24" 
                          height="24" 
                          viewBox="0 0 24 24" 
                          fill="none" 
                          xmlns="http://www.w3.org/2000/svg"
                        >
                        <mask 
                          id="mask0_830_5965" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                          <rect 
                            width="24" 
                            height="24" 
                            fill="#D9D9D9"
                          />
                        </mask>
                        <g mask="url(#mask0_830_5965)">
                        <path d="M19.95 21C17.8 21 15.7043 20.5207 13.663 19.562C11.621 18.604 9.81267 17.3373 8.238 15.762C6.66267 14.1873 5.396 12.379 4.438 10.337C3.47933 8.29567 3 6.2 3 4.05C3 3.75 3.1 3.5 3.3 3.3C3.5 3.1 3.75 3 4.05 3H8.1C8.33333 3 8.54167 3.075 8.725 3.225C8.90833 3.375 9.01667 3.56667 9.05 3.8L9.7 7.3C9.73333 7.53333 9.72933 7.74567 9.688 7.937C9.646 8.129 9.55 8.3 9.4 8.45L6.975 10.9C7.675 12.1 8.55433 13.225 9.613 14.275C10.671 15.325 11.8333 16.2333 13.1 17L15.45 14.65C15.6 14.5 15.796 14.3873 16.038 14.312C16.2793 14.2373 16.5167 14.2167 16.75 14.25L20.2 14.95C20.4333 15 20.625 15.1123 20.775 15.287C20.925 15.4623 21 15.6667 21 15.9V19.95C21 20.25 20.9 20.5 20.7 20.7C20.5 20.9 20.25 21 19.95 21ZM6.025 9L7.675 7.35L7.25 5H5.025C5.10833 5.68333 5.225 6.35833 5.375 7.025C5.525 7.69167 5.74167 8.35 6.025 9ZM14.975 17.95C15.625 18.2333 16.2877 18.4583 16.963 18.625C17.6377 18.7917 18.3167 18.9 19 18.95V16.75L16.65 16.275L14.975 17.95Z" fill="#1C1B1F"/>
                        </g>
                        </svg>
                      </span>
                        877-255-7945
                    </li>
                  </ul>
                </div>
                <ul className="fixed-socialIcons">
                    <li>
                      <a href="#!"><svg width="16" height="31" viewBox="0 0 16 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.6667 17.825H14.4762L16 11.625H10.6667V8.525C10.6667 6.9285 10.6667 5.425 13.7143 5.425H16V0.217C15.5032 0.15035 13.6274 0 11.6465 0C7.50933 0 4.57143 2.56835 4.57143 7.285V11.625H0V17.825H4.57143V31H10.6667V17.825Z" fill="white"/>
                      </svg></a>
                    </li>
                    <li>
                    <a href="#!"><svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23 2.23529C22.1534 2.62647 21.2409 2.88353 20.2954 3.00647C21.2629 2.41412 22.0105 1.47529 22.3623 0.346471C21.4498 0.905294 20.4383 1.29647 19.3719 1.52C18.5033 0.558824 17.283 0 15.8977 0C13.3141 0 11.2032 2.14588 11.2032 4.79471C11.2032 5.17471 11.2471 5.54353 11.3241 5.89C7.41013 5.68882 3.92495 3.77765 1.60516 0.882941C1.19837 1.58706 0.967495 2.41412 0.967495 3.28588C0.967495 4.95118 1.79207 6.42647 3.0674 7.26471C2.28681 7.26471 1.56119 7.04118 0.923518 6.70588V6.73941C0.923518 9.06412 2.55067 11.0088 4.70555 11.4447C4.01371 11.6372 3.28739 11.664 2.58365 11.5229C2.88226 12.4757 3.46708 13.3094 4.2559 13.9068C5.04471 14.5042 5.99786 14.8353 6.98136 14.8535C5.31422 16.1952 3.2477 16.9204 1.12142 16.91C0.74761 16.91 0.373805 16.8876 0 16.8429C2.08891 18.2065 4.57361 19 7.23423 19C15.8977 19 20.6582 11.6906 20.6582 5.35353C20.6582 5.14118 20.6582 4.94 20.6472 4.72765C21.5707 4.05706 22.3623 3.20765 23 2.23529Z" fill="white"/>
                    </svg></a>

                    </li>
                    <li>
                    <a href="#!"><svg width="18" height="26" viewBox="0 0 18 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.52912 23.3599V13.8677C8.80593 13.8927 9.07188 13.9057 9.35195 13.9057C10.6795 13.9057 11.929 13.559 12.9884 12.9382V23.3577C12.9884 24.2482 12.7767 24.9058 12.3643 25.3446C11.9539 25.7812 11.409 26 10.7457 26C10.0944 26 9.57665 25.7812 9.15221 25.3305C8.74189 24.8939 8.52912 24.2363 8.52912 23.3599ZM8.55409 0.61315C11.3167 -0.354267 14.4615 -0.302267 16.8246 1.6824C17.2643 2.08215 17.7658 2.58482 17.9655 3.1774C18.2043 3.92707 17.1297 3.09832 16.982 2.99649C16.2113 2.50573 15.4428 2.09407 14.5809 1.81132C9.93378 0.419234 5.53961 2.93474 2.80737 6.84124C1.6654 8.56807 0.921825 10.3859 0.310679 12.3847C0.245548 12.6035 0.192358 12.8884 0.0718661 13.0802C-0.0497118 13.3001 0.0197612 12.4887 0.0197612 12.4627C0.110945 11.6361 0.284627 10.8377 0.498474 10.0392C1.75984 5.78174 4.54636 2.23815 8.55518 0.61315H8.55409ZM13.9035 8.2604C13.9035 8.69005 13.8187 9.11548 13.654 9.51242C13.4892 9.90936 13.2477 10.27 12.9433 10.5738C12.6389 10.8776 12.2775 11.1186 11.8798 11.283C11.4821 11.4474 11.0558 11.5321 10.6253 11.5321C10.1947 11.5321 9.76846 11.4474 9.37072 11.283C8.97298 11.1186 8.61159 10.8776 8.30718 10.5738C8.00276 10.27 7.76129 9.90936 7.59654 9.51242C7.43179 9.11548 7.347 8.69005 7.347 8.2604C7.347 7.83076 7.43179 7.40533 7.59654 7.00839C7.76129 6.61145 8.00276 6.25079 8.30718 5.94699C8.61159 5.64318 8.97298 5.40219 9.37072 5.23778C9.76846 5.07336 10.1947 4.98874 10.6253 4.98874C11.0558 4.98874 11.4821 5.07336 11.8798 5.23778C12.2775 5.40219 12.6389 5.64318 12.9433 5.94699C13.2477 6.25079 13.4892 6.61145 13.654 7.00839C13.8187 7.40533 13.9035 7.83076 13.9035 8.2604Z" fill="white"/>
                    </svg></a>

                    </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetInTouch;
