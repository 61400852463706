import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    API_FAILED,
    LOGIN_ERROR,
    VERIRY_CODE,
    LOADING,
    AUTO_LOGIN_SUCCESS,
    ADD_USER_DATA,
    UPDATE_WALLET,
    UPDATE_KYC_STATUS,
    SET_STRIPE,
    TRAINING,
    ACTIVE_TIMING_CHALLENGES,
    NOTIFICATION
} from './constants';

import {useNavigate} from 'react-router-dom';



export const loginUserSuccess = (user) => {
    localStorage.setItem("auth", JSON.stringify({ access_token: user.access_token, user: user.user }))
    return {
        type: LOGIN_USER_SUCCESS,
        payload: user
    }
};

export const setLoading = (bool) => ({
    type: LOADING,
    payload: bool
});

export const autoLoginSuccess = (user) => {
    localStorage.setItem("auth", JSON.stringify({ access_token: user.access_token, user: user.data }))
    return {
        type: AUTO_LOGIN_SUCCESS,
        payload: user
    }
}

export const addUserData = (data) => {
    return {
        type: ADD_USER_DATA,
        payload: data
    }
}

export const logoutUser = () => {
    localStorage.clear();
    return {
        type: LOGOUT_USER,
    }
};

export const updateWallet = (balance) => {
    return {
        type: UPDATE_WALLET,
        payload: balance
    }
};

export const updateKycStatus = (status) => {
    return {
        type: UPDATE_KYC_STATUS,
        payload: status
    }
};
export const setStripe = (value) => {
    return {
        type: SET_STRIPE,
        payload: value
    }
};

export const setTraining = (time) => {
    return {
        type: TRAINING,
        payload: time
    }
};

export const setActiveTimingChallenges = (data) => {
    return {
        type: ACTIVE_TIMING_CHALLENGES,
        payload: data
    }
};

export const setNotification = (data) => {
    return {
        type: NOTIFICATION,
        payload: data
    }
};


