import react, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";
import useRequest from "../../../hooks/useRequest"
import { useNavigate, Link } from "react-router-dom";
import useRequestV2 from "../../../hooks/useRequestV2";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { authSuccess } from "../../../redux/corporation-store/auth/action";

// import { toast } from "react-toastify";

const Verifycode = () => {
  const [otp, setOtp] = useState("");
  const { request, response } = useRequestV2();
  const location = useLocation();
  const navigate = useNavigate();
  const [isResendActive, setIsResendActive] = useState(false);
  const [resendAvailableCounter, setResendAvailableCounter] = useState(60);
  const [stateId,setStateId] = useState();
  const { request: resendOtpReq, response: resendOtpRes } = useRequest();
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    // const element = document.getElementsByClassName("otp-field");
    // let otp = '';
    // let otpArray = [];
    // if(element && element.length>0){
    //   for(let i=0;i<element.length;i++){
    //     if(element[i].value){
    //       otp += element[i].value;
    //       otpArray.push(element[i].value);
    //     }
    //   }
    // }
    // if(otpArray.length != 6){
    //   document.getElementById('otpErr').style.display = 'block';
    //   return;
    // }
    const data = {
      forgot_password_otp: otp,
      uuid_string: stateId,
    };

    request("POST", "verify-employee/", data);
  };


  useEffect(() => {
    if(location.state && location.state.id){
      setStateId(location.state.id);
    }else{
      navigate("/corporation/employee/register");
    }
  }, [location]);
  const resendOtpHandler = () => {
    if (isResendActive) {
      resendOtpReq("POST", `/auth/${stateId}/resend-otp/`);
    }
  };
  useEffect(() => {
    if (resendOtpRes) {
      if (resendOtpRes?.status === "success") {
        setStateId(resendOtpRes?.data?.uuid_string)
        // navigate(`v1/auth/verify-user/${resendOtpRes?.data?.uuid_string}`);
        toast.success(resendOtpRes?.message)
        setResendAvailableCounter(60);
        setIsResendActive(false);
      }
    }
  }, [resendOtpRes]);
  useEffect(() => {
    if (response) {
      console.log(response, "response");
      if (response.status === "success") {
        // console.log(response, "hello");
        toast.success(response.message);
        dispatch(
          authSuccess({
            token: response.data.access_token,
            // uuid_string: response.data.user.uuid_string,
          })
        );
        navigate("/companyupdate", {
          state: { id: location?.state?.id, hash: location?.state?.hash },
        });
      } else if (response.status === "fail") {
        toast.error(response.message);
      }
    }
  }, [response]);
  useEffect(() => {
    if (!isResendActive) {
      if (resendAvailableCounter <= 0) {
        setIsResendActive(true);
      } else {
        setTimeout(() => {
          setResendAvailableCounter(resendAvailableCounter - 1);
        }, 1000);
      }
    }
  }, [resendAvailableCounter]);

  // const handleOTP = () => {
  //   document.getElementById("otpErr").style.display = "none";
  // };

  // useEffect(() => {
  //   otpInput()
  // },[])

  // const otpInput = () => {
  //   const inputs = document.querySelectorAll('.otp-field');
  //   for (let i = 0; i < inputs.length; i++) {
  //     inputs[i].addEventListener('keydown', function(event) {
  //       if (event.key === "Backspace") {
  //         inputs[i].value = '';
  //         if (i !== 0)
  //           inputs[i - 1].focus();
  //       } else {
  //         if ((event.keyCode > 47 && event.keyCode < 58) || (event.keyCode > 96 && event.keyCode < 105)) {
  //           inputs[i].value = event.key;
  //           if (i !== inputs.length - 1)
  //             inputs[i + 1].focus();
  //           event.preventDefault();
  //         } else if (event.keyCode > 64 && event.keyCode < 91) {
  //           event.preventDefault();
  //         }else if (i === inputs.length - 1 && inputs[i].value !== '') {
  //           return true;
  //         }
  //       }
  //     });
  //   }
  // }

  return(
    <>
       <div className="loader-wrapper" style={{display:'none'}}>
            <div className="loader">
                <img src="img/logo.png" alt=""/>
            </div>
        </div>
        <div className="sign-up-container sign-up-Splash beforeBg" style={{backgroundImage: `url(/assets/img/yogabg.jpg)`}}>
        <div className="container-fluid">
            <div className="fixlogoDiv">
              <Link to="/">
                <img src="assets/img/full-logo.png" alt=""/>
                </Link>
            </div>
            <div className="sign-up-inner-container splash-container mainCenter">
                <div className="row align-items-center responsive-gap text-center justify-content-center marginTB">
                    <div className="col-md-12 p-0">                        
                        <h3 className="pageSec-title">proceed with email verificaiton</h3>
                        <p className="secSubHeading">A six digit OTP was sent to the registered email address</p>
                    </div>
                        <div className="col-md-12 p-0">
                            <form action="" className="otp-form" onSubmit={handleSubmit}>
                            <div className="otpInputBox">
                            <OtpInput
                                    className="new-otp-field"
                                    value={otp}
                                    onChange={(value) => {
                                        setOtp(value);
                                    }}
                                    numInputs={6}
                                    separator={<span>-</span>}
                                    isInputNum={true}

                                    // isInputSecure
                                    />
                                {/* <input className="otp-field" onKeyUp={() => handleOTP()} type="text" name="opt-field[]" maxlength="1" />
                                <input className="otp-field" onKeyUp={() => handleOTP()} type="text" name="opt-field[]" maxlength="1" />
                                <input className="otp-field" onKeyUp={() => handleOTP()} type="text" name="opt-field[]" maxlength="1" />
                                <input className="otp-field" onKeyUp={() => handleOTP()} type="text" name="opt-field[]" maxlength="1"/>
                                <input className="otp-field" onKeyUp={() => handleOTP()} type="text" name="opt-field[]" maxlength="1"/>
                                <input className="otp-field" onKeyUp={() => handleOTP()} type="text" name="opt-field[]" maxlength="1"/> */}

                               
                          
                            </div>
                            {/* <div className="text-danger fw-bold" id="otpErr" style={{display:'none'}}>
                              Please enter 6 digit OTP.
                            </div> */}
        
                            </form>
                            <div className="Splash-login-box">

                                <button className="Subscription-btn ds-theme-btn otpConfirm" type="button" onClick={handleSubmit}>Confirm</button>
                                {(resendAvailableCounter && resendAvailableCounter>0) ? (
                                          <div className="confirmTimer">
                                            {/*resendAvailableCounter+" "+resendAvailableCounter+" 20 20"*/}
                                          <svg width="20" height="20" viewBox={"0 0 20 20"} fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path opacity="0.7" d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18Z" fill="white"/>
                                              </svg>
                                              
                                          {!isResendActive && (
                                              <span className="TimerValue">{resendAvailableCounter} sec</span>
                                          )}
                                      </div>
                                                                        
                              ):('')}
                           
                           
                              <div className={`otpSendTitle resend-otp-text ${
                              isResendActive ? "otpSendTitle resend-active" : ""
                              }`}   onClick={resendOtpHandler} >Resend OTP</div>
                           
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
    </>
  
  )
};

export default Verifycode;
