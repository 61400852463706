import {
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  SIDEBAR_TOGGLE,
  LOADING,
} from "./actionTypes";
// import { toast } from "react-toastify";

export const authSuccess = (updates) => {
  // localStorage.setItem(
  //   "clientAuth",
  //   JSON.stringify({ access_token: updates.access_token })
  // );
  return {
    type: AUTH_SUCCESS,
    updates,
  };
  
};

export const sidebarToggle = (updates) => {
  return {
    type: SIDEBAR_TOGGLE,
    updates,
  };
};

export const logout = () => {
    localStorage.clear();
  // alert("You are now logged out!");
  return {
    type: AUTH_LOGOUT,
  };
};

export const updateLoading = (updates) => {
  return {
    type: LOADING,
    updates,
  };
};
