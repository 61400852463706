import React, { useState, useEffect, useRef } from "react";
import useRequest from "../../hooks/useRequest";
import { useSelector, useDispatch } from "react-redux";
import { activateFeedback, updateWallet, setLoading } from "../../redux/actions";
import { useNavigate, Link } from "react-router-dom";
import { useFormik } from "formik"
import * as Yup from 'yup';
import PreloaderInner from "../components/preloaderInner";
import { PayPalButton } from "react-paypal-button-v2";

import "./../../css/paypal.css";
import { MultiLang } from "../MultiLang";

const paypalFeePercents = 3.5;
const paypalFixedFee = 0.39;

const paypalOptions = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "EUR",
    components: "buttons",
    disableFunding: "card",
};

const PayPalDeposit = ({ setPaymentMethod }) => {
    const { language } = useSelector((state) => state.reducer);
    const { request, response } = useRequest();
    const { loading, user } = useSelector((state) => state.Auth);
    const dispatch = useDispatch();

    const [isShow, setIsShow] = useState(false);
    const [netAmt, setNetAmt] = useState(0);
    const [commId, setCommId] = useState(null);
    const [verificationModel, setVerificationModel] = useState(false);

    const formik = useFormik({
        initialValues: {
            amount: ""
        },
        // validationSchema: Yup.object({
        //     amount: Yup.number()
        //         .required('Please Enter Amount')
        //         .min(1, `Min Amount must be 1€`)
        //         .max(1000, 'Max Amount must be 1000€'),
        // }),
        onSubmit: values => {
            if (!user.first_name || !user.last_name) return;
            setCommId(
                `ID${Math.floor(Math.random() * 100000000) + 1} ${user.first_name}${user.last_name
                }`
            );

            if (paypalFeePercents && paypalFixedFee) {
                setNetAmt(
                    (
                        (+values.amount + +paypalFixedFee) /
                        (1 - +paypalFeePercents / 100)
                    ).toFixed(2)
                );
            }

            setIsShow(true);
        },
        validate: (values) => {
            let { amount } = values
            let errors = {};

            if (!amount) {
                errors.amount = "Please Enter Amount"
            }

            if (+amount < 1) {
                errors.amount = "Min Amount must be 1€"
            }
            if (+amount > 1000) {
                errors.amount = "Max Amount must be 1000€"
            }
            return errors;
        },
    });


    const handleDeposit = () => {
        if (!commId) {
            setFileErr("Communication ID is required");
            return;
        }
        let formdata = new FormData();
        formdata.append("amount", formik?.values?.amount);
        formdata.append("communication_id", commId);
        request("POST", "/paypal-deposit", formdata);
    };

    useEffect(() => {
        if (response) {
            setIsShow(false);
            dispatch(
                activateFeedback({
                    type: response?.status,
                    message: response?.message,
                })
            );
            if (response?.status === "success") {
                dispatch(updateWallet(response?.amount));
            }
        }
    }, [response]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="setting-input">
                    <div className="setting-input-con">
                        <div className="card_labelbx">
                            <label htmlFor="new_email">{MultiLang["Amount"][language]} (€)</label>
                        </div>
                        <input
                            disabled={isShow}
                            name="amount"
                            {...formik.getFieldProps('amount')}
                            type="number"
                        />
                        {formik.touched.amount && formik.errors.amount && <div className="input-error">{formik.errors.amount}</div>}
                    </div>
                </div>
                <div className="setting-bottom margin-btn">
                    <button
                        type='submit'
                        className="blue-btn btn setting-btn"
                    >
                        {MultiLang["Deposit"][language]}
                    </button>
                </div>
                <div className="setting-bottom">
                    <button
                        className="btn setting-btn"
                        onClick={() => {
                            setPaymentMethod(null);
                        }}
                    >
                        {MultiLang["Go Back"][language]}
                    </button>
                </div>
            </form>
            <div
                className={
                    isShow
                        ? "finish-modal setting-modal active"
                        : "finish-modal setting-modal"
                }
            >
                <div
                    className="modal-close"
                    onClick={() => {
                        setIsShow(false);
                    }}
                >
                    <img src="images/auth/auth-close.png" alt="" />
                </div>
                <div className="modal-header">{MultiLang["Payment"][language]}</div>
                <div className="modal-body">
                    <table>
                        <tbody>
                            <tr>
                                <td>{MultiLang["Credit to Wallet"][language]}</td>
                                <td>{formik?.values?.amount}€</td>
                            </tr>
                            <tr>
                                <td>{MultiLang["Service Charge"][language]}</td>
                                <td>{(+netAmt - +formik?.values?.amount).toFixed(2)}€</td>
                            </tr>
                            <tr>
                                <td>{MultiLang["Debit from Account"][language]}</td>
                                <td>{netAmt}€</td>
                            </tr>
                            <tr>
                                <td>Communication:</td>
                                <td>{commId}</td>
                            </tr>
                        </tbody>
                    </table>
                    <PayPalButton
                        options={paypalOptions}
                        amount={netAmt}
                        currency="EUR"
                        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                        onSuccess={(details, data) => {
                            handleDeposit();
                            setIsShow(false);
                        }}
                    />

                    {loading && isShow && <PreloaderInner />}
                </div>
            </div>
            <div
                className={
                    verificationModel === false
                        ? "finish-modal finish_modalspace"
                        : "finish-modal  finish_modalspace active"
                }
            >
                <div className="modal-close" onClick={() => { setVerificationModel(false) }}>
                    <img src="/images/auth/auth-close.png" alt="" />
                </div>
                <div className="finish-modal-main">
                    <div>
                        {MultiLang["not-verified"][language]}
                    </div>
                </div>
                <div>
                    <Link
                        to="/setting?tab=kyc"
                        onClick={() => {
                            setVerificationModel(false);
                        }}
                        className="btn blue-btn"
                    >
                        {MultiLang["Verify"][language]}
                    </Link>
                </div>
            </div>
        </>
    );
};

export default PayPalDeposit;
