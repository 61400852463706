import { useEffect, useState, useRef } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useParallels from "../hooks/useParallels";

import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import Footer from "../pages/components/Footer";

import HomeNew from "../pages/Home_new.js";
import Contact from "../pages/Contact";
import About from "../pages/About";
import Community from "../pages/Community";
import Pro from "../pages/Pro";

import Privacy from "../pages/Privacy";
import Terms from "../pages/Terms";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ForgetPassword from "../pages/auth/forgetPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import Profile from "../pages/Profile";

import ViewAllChallenge from "../pages/challenge/ViewAll";
import ViewOneChallenge from "../pages/challenge/ViewOne";
import Setting from "../pages/settings/Setting";

import StartChallenge from "../pages/challenge/StartChallenge";

import VerifyRegister from "../pages/auth/VerifyRegister";

import AuthedNavBar from "../pages/components/AuthedNavBar";
import NavBar from "../pages/components/NavBar";

import StripeRedirect from "../pages/settings/StripeRedirect";
import { FeedbackModel } from "../pages/components/FeedbackModal";
import Preloader from "../pages/components/preloader";
import PreloaderOuter from "../pages/components/preloaderOuter";

import LeaderBoard from "../pages/LeaderBoard";
import Result from "../pages/Result";

import SignupClient from "../pages/corporateClient/pages/SignupClient";
// import EmployeeLogin from "../pages/corporation/pages/client/EmployeeLogin";
import UpdateEmployeeCompany from "../pages/corporation/pages/UpdateEmployeeCompany";
import StartTrainingSdk from "../pages/challenge/StartTrainingSdk";
import EmployeeVerify from "../pages/corporation/pages/EmployeeVerify";

import Signup from "../pages/corporation/pages/Signup";
import ClientDashboard from "../pages/corporateClient/pages/Dashboard";
import ClientHeader from "../pages/corporateClient/component/Header/Header";
import ViewAllID from "../pages/corporateClient/component/ID/ViewAll";
import AddID from "../pages/corporateClient/component/ID/Add";
import EditID from "../pages/corporateClient/component/ID/Edit";
import ViewID from "../pages/corporateClient/component/ID/ViewOne";
import EmployeeChangePassword from "../pages/corporateClient/component/ID/ChangePassword";
import CorporateAccount from "../pages/corporateClient/component/CorporateAccount/CorporateAcctount";
import SendMessage from "../pages/corporateClient/component/SendMessage/SendMessage";
import VerifycodeClient from "../pages/corporateClient/pages/VeriftCode";
import ClientVerify from "../pages/corporateClient/pages/ClientVerify";
import ClientLoginPage from "../pages/corporateClient/pages/ClientLoginPage";
import CalanderCoachBooking from "../pages/corporation/pages/CalanderCoachBooking";
import { ToastContainer } from "react-toastify";
import LandingPage1 from "../pages/corporateClient/pages/LandingPage1";
import LandingPage2 from "../pages/corporateClient/pages/LandingPage2";
// import VideoDebugIOS from "../pages/challenge/VideoDebugIOS";
import CalanderCoachBookingCancel from "../pages/corporation/pages/CalanderCoachBookingCancel";

import Main from "../pages/Main";
import GetInTouch from "../pages/corporateClient/component/GetInTouch";
import ForgotPassword from "../pages/corporation/pages/ForgotPassword";
import ChangePassword from "../pages/corporation/pages/ChangePassword";
import ClientForgotPassword from "../pages/corporateClient/pages/ClientForgotPassword";
import ClientChangePassword from "../pages/corporateClient/pages/ClientChangePassword";
import Home from "../pages/Home_new.js";
import Navbar from "../pages/components/NavBar";
import SeeCalender from "../pages/corporation/SeeCalender";
import Articles from "../pages/corporation/pages/Articles";
import ArticlesVer from "../pages/corporation/pages/ArticlesVer";
import Coaches from "../pages/corporation/pages/Coaches";
import CoachesV2 from "../pages/corporation/pages/CoachesV2";

const commonRoutes = [
  {
    path: "/about",
    element: (
      <>
        <About />
      </>
    ),
  },
  {
    path: "/community",
    element: (
      <>
        <Community />
      </>
    ),
  },
  {
    path: "/contact",
    element: (
      <>
        <Contact />
        <Footer />
      </>
    ),
  },
  { path: "/pro", element: <Pro /> },
  {
    path: "/privacy",
    element: (
      <>
        <Privacy />
      </>
    ),
  },
  {
    path: "/terms",
    element: (
      <>
        <Terms />
      </>
    ),
  },
  { path: "/result/:challId", element: <Result /> },
  // { path: '/video-debug-ios', element: <VideoDebugIOS /> },
];

const authedRoutes = [
  ...commonRoutes,
  {
    path: "/profile",
    element: (
      <>
        <Profile />
      </>
    ),
  },
  { path: "/setting", element: <Setting /> },
  { path: "/challenge", element: <ViewAllChallenge /> },
  { path: "/challenge/:id", element: <ViewOneChallenge /> },
  { path: "/challenge/:id/start", element: <StartChallenge /> },
  { path: "/stripe-resonse", element: <StripeRedirect /> },
  { path: "/leaderboard", element: <LeaderBoard /> },
  { path: "/training", element: <StartTrainingSdk /> },
  { path: "*", element: <Navigate to="/profile" replace /> },
];

const mainRoute = [
  { path: "/", element: <LandingPage1 /> },
  { path: "/accounts", element: <Main /> },
];

const publicRoutes = [
  ...mainRoute,
  ...commonRoutes,
  // { path: "/", element: <Main /> },
  { path: "*", element: <Navigate to="/" replace /> },

  { path: "/login", element: <Login /> },
  { path: "/register", element: <Register /> },
  { path: "/forgetPassword", element: <ForgetPassword /> },
  { path: "/resetPassword/:uuid", element: <ResetPassword /> },
  { path: "/verifyRegister/:uuid", element: <VerifyRegister /> },

  { path: "/corporation/employee/register", element: <Signup /> },
  { path: "/corporation/client/register", element: <SignupClient /> },

  { path: "/client/verify-code", element: <VerifycodeClient /> },
  { path: "/client-verify", element: <ClientVerify /> },
  { path: "/companyupdate", element: <UpdateEmployeeCompany /> },
  // { path: "/employee-login", element: <EmployeeLogin /> },
  { path: "/client-login", element: <ClientLoginPage /> },
  { path: "/employee-verify", element: <EmployeeVerify /> },
  // { path: "/calander-coach-booking", element: <CalanderCoachBooking /> },
  // { path: "/landingpage1", element: <LandingPage1 /> },
  { path: "/coaches", element: <LandingPage2 /> },
  // { path: "/articles", element: <Articles /> },
  // { path: "/articles-ver", element: <ArticlesVer /> },
  // { path: "/coaches-employee", element: <Coaches /> },
  // { path: "/coaches-version2", element: <CoachesV2 /> },
  // { path: "/calander-coach-booking", element: <CalanderCoachBooking /> },

  {
    path: "/fitness-competition",
    element: (
      <>
        <Navbar /> <Home />
        <Footer />
      </>
    ),
  },
  // { path: "/calander-coach-booking", element: <CalanderCoachBooking /> },

  { path: "/employee-forgot-password", element: <ForgotPassword /> },
  { path: "/employee-change-password", element: <ChangePassword /> },
  { path: "/client-forgot-password", element: <ClientForgotPassword /> },
  { path: "/client-change-password", element: <ClientChangePassword /> },

  // { path: "*", element: <Navigate to="/" replace /> },
  // { path: "/dashboard", element: <ClientDashboard /> },
  // { path: "/ids", element: <ViewAllID /> },
  // { path: "/id/add", element: <AddID /> },
  // { path: "/id/edit/:id", element: <EditID /> },
];

const corporationPublicRoutes = [...commonRoutes];
const corporateClientRoutes = [
  { path: "/dashboard", element: <ClientDashboard /> },
  { path: "/ids", element: <ViewAllID /> },
  { path: "/id/add", element: <AddID /> },
  // { path: "/id/edit/:id", element: <EditID /> },
  // { path: "/ids/:id", element: <ViewID /> },
  { path: "/id/change-password/:id", element: <EmployeeChangePassword /> },
  { path: "/get-in-touch", element: <GetInTouch /> },
  { path: "/corporate-account", element: <CorporateAccount /> },
  { path: "/reviews", element: <SendMessage /> },
  { path: "*", element: <Navigate to="/dashboard" replace /> },
];

const corporateEmployeeRoutes = [
  { path: "/calander-coach-booking", element: <CalanderCoachBooking /> },
  { path: "*", element: <Navigate to="/calander-coach-booking" replace /> },
  { path: "/cancelbook", element: <CalanderCoachBookingCancel /> },

  { path: "/see-calender", element: <SeeCalender /> },
  { path: "/activities-details/:id", element: <Articles /> },
  { path: "/activities", element: <ArticlesVer /> },
  { path: "/coaches-employee", element: <Coaches /> },
  { path: "/coaches-version2/:id", element: <CoachesV2 /> },
];
// const corporationPublicRouts = [
//   { path: '/corporation/employee/register', element: <Signup /> },

// ]
function AppRouter() {
  const token = localStorage.getItem("token");
  const { loggedIn, user } = useSelector((state) => state.Auth);
  // gourav code
  const { loggedIn: clientLogin, user: clientUser } = useSelector(
    (state) => state.AuthClient
  );

  const { loggedIn: employeeLogin, user: employeeUser } = useSelector(
    (state) => state.AuthEmployee
  );

  const { setWebpageLoaded, isToken } = useParallels();
  const authRoleId = user?.user_role_id ? user.user_role_id : "";
  const clientRoleId = clientUser?.user_role_id ? clientUser.user_role_id : "";
  const employeeRoleId = employeeUser?.user_role_id
    ? employeeUser.user_role_id
    : "";

  let roleId;
  if (authRoleId) {
    roleId = authRoleId;
  } else if (employeeRoleId) {
    roleId = employeeRoleId;
  } else if (clientRoleId) {
    roleId = clientRoleId;
  }

  const tawkMessengerRef = useRef();
  const onLoad = () => {
    tawkMessengerRef.current.setAttributes({
      name: `${user?.first_name} ${user?.last_name}`,
      email: user?.email,
    });
  };
  useEffect(() => {
    // localStorage.clear();
    setWebpageLoaded(true);
  }, []);

  // console.log(clientLogin, roleId, "jjjjjj");
  return isToken === null ? (
    <>
      <PreloaderOuter />
      {/* <Routes>
        {publicRoutes.map((val, ind) => {
          return <Route key={ind} {...val} />;
        })}
      </Routes> */}
    </>
  ) : (
    <>
      {loggedIn && roleId == 2 ? (
        <>
          <AuthedNavBar />
          <div className="__layout">
            <Routes>
              {authedRoutes.map((val, ind) => {
                return <Route key={ind} {...val} />;
              })}
            </Routes>
          </div>
          <Footer />
        </>
      ) : clientLogin && roleId == 3 ? (
        <div className="app">
          <div className="dashBoard_overLay"></div>
          <div className="layout">
            <ClientHeader />
            <Routes>
              {corporateClientRoutes.map((val, ind) => {
                return <Route key={ind} {...val} />;
              })}
            </Routes>
          </div>
        </div>
      ) : roleId == 4 ? (
        <Routes>
          {corporateEmployeeRoutes.map((val, ind) => {
            return <Route key={ind} {...val} />;
          })}
        </Routes>
      ) : (
        <>
          {/* <NavBar /> */}
          {/* <div className='__layout'> */}
          <Routes>
            {publicRoutes.map((val, ind) => {
              return <Route key={ind} {...val} />;
            })}
          </Routes>
          {/* </div>  */}
          {/* <Footer /> */}
        </>
      )}

      <FeedbackModel />
      <TawkMessengerReact
        propertyId={process.env.REACT_APP_TWAK_PROPERTY_ID}
        widgetId={process.env.REACT_APP_TWAK_WIDGET_ID}
        onLoad={onLoad}
        ref={tawkMessengerRef}
      />

      <ToastContainer />
    </>
  );
}

export default AppRouter;
