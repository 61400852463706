import { useEffect, useState } from "react";
import useRequest from "../hooks/useRequest";
import PreloaderOuter from "./components/preloaderOuter";
import "./../css/pro.css";
import { useSelector } from "react-redux";

export default function Pro() {
  // console.log("Become-Pro component renders");
  const { request, response } = useRequest();
  const { loading } = useSelector((state) => state.Auth);

  // const [isData, setIsData] = useState(false);
  const [proHeaderData, setProHeaderData] = useState(null);
  const [proSubHeaderData, setProSubHeaderData] = useState(null);
  const [becomeProData, setBecomeProData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    request("GET", "/pro/");
  }, []);

  useEffect(() => {
    if (response) {
      // console.log(response);
      // setIsData(true);
      setProHeaderData(response?.proHeaderData);
      setProSubHeaderData(response?.proSubHeaderData);
      setBecomeProData(response?.becomeProData);
    }
  }, [response]);
  return loading ? (
    <PreloaderOuter />
  ) : (
    <div className="_pro">
      {proHeaderData && (
        <section
          className="pro-top"
          style={{
            background: `url(${proHeaderData?.image})`,
            backgroundSize: "100%",
          }}
          dangerouslySetInnerHTML={{
            __html: proHeaderData?.heading,
          }}
        />
      )}
      {proSubHeaderData && (
        <section className="stronger">
          <div className="content">
            <div className="stronger-description">
              <img src={proSubHeaderData?.image} alt=""></img>
              <div className="stronger-description-text pro">
                <div className="title">{proSubHeaderData?.heading}</div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: proSubHeaderData?.description,
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      )}
      {becomeProData && (
        <section className="how section-padding-top">
          <div className="content">
            <div className="main-title black">Why become a Pro?</div>
            <div className="how-main">
              {becomeProData &&
                becomeProData.map((data, index) => {
                  return (
                    <div key={index} className="how-item">
                      <img src={data?.icon} alt="" />
                      <div>{data?.heading}</div>
                      <div>{data?.description}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
