import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useRequest from "../../hooks/useRequest"
import { activateFeedback } from '../../redux/actions';
import { useDispatch, useSelector } from "react-redux"
import OtpInput from 'react-otp-input';
import Preloader from '../components/preloader';

const Verify = () => {
    const { loading } = useSelector(state => state.Auth)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [otp, setOtp] = useState("")
    const { uuid } = useParams()

    const [resendAvailableCounter, setResendAvailableCounter] = useState(60)
    const [isResendActive, setIsResendActive] = useState(false)

    const { request, response } = useRequest()
    const { request: resendOtpReq, response: resendOtpRes } = useRequest()



    const handleSubmit = (e) => {
        e.preventDefault()
        request("POST", "/auth/verify-user/", { forgot_password_otp: otp, uuid_string: uuid })
    }

    const resendOtpHandler = () => {
        if (isResendActive) {
            resendOtpReq('POST', `/auth/${uuid}/resend-otp/`)
        }
    }

    useEffect(() => {
        if (resendOtpRes) {
            dispatch(activateFeedback({ type: resendOtpRes?.status, message: resendOtpRes?.message }))
            if (resendOtpRes?.status === 'success') {
                navigate(`/verifyRegister/${resendOtpRes?.data?.uuid_string}`)
                setResendAvailableCounter(60)
                setIsResendActive(false)
            }
        }
    }, [resendOtpRes])

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!uuid) {
            navigate('/register')
        }
    }, []);

    useEffect(() => {
        if (response) {
            dispatch(activateFeedback({ type: response?.status, message: response?.message }))
            if (response?.status === "success") {
                navigate("/login")
            }
        }
    }, [response])

    useEffect(() => {
        if (!isResendActive) {
            if (resendAvailableCounter <= 0) {
                setIsResendActive(true)
            } else {
                setTimeout(() => {
                    setResendAvailableCounter(resendAvailableCounter - 1)
                }, 1000)
            }
        }
    }, [resendAvailableCounter]);


    return (
        <>
            {loading && <Preloader />}
            <div className="verify-code">
                <div className="verify-code-main">
                    <div>
                        <div className="verify-code-header">
                            <h1>Please input verify code</h1>
                            <div className="auth-alert">
                                A six digit OTP was sent to the registered email address.
                            </div>

                            <form onSubmit={handleSubmit}>
                                <div className="mb-3 rounded-3 verify-input-group">
                                    <div className="code-input">
                                        <div className="code-inputs">
                                            <OtpInput
                                                className='otpBox'
                                                value={otp}
                                                onChange={(value) => { setOtp(value) }}
                                                numInputs={6}
                                                separator={<span></span>}
                                                isInputNum={true}
                                                
                                            // isInputSecure
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="verify-code-btn">
                                    <button className="btn auth-main-btn" type="submit">Confirm</button>
                                </div>
                            </form>


                            <div className='resend-otp'>
                                {
                                    !isResendActive &&
                                    <div>{resendAvailableCounter}</div>
                                }
                                <span className={`resend-otp-text ${isResendActive ? 'resend-active' : ""}`} onClick={resendOtpHandler}>Resend OTP </span>
                                {/* {
                                    !isResendActive &&
                                    <span> {resendAvailableCounter}</span>
                                } */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Verify;