import {
    SET_SETTING,
    ACTIVATE_FEEDBACK,
    DEACTIVATE_FEEDBACK,
    SET_SOCKET,
    SOCKET_ACTIVE_CHALLENGE,
    ACTIVE_CHALLENGE,
    ONLINE_USERS
} from './constants';


const INIT_STATE = {
    settings: null,
    feedbackModel: {
        active: false,
        type: null,
        message: null
    },
    socket: null,
    socketActiveChallenge: null,
    activeChallenge: null,
    onlineUsers: 0
};


const Site = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_SETTING: return {
            ...state, settings: action.payload
        }
        case ACTIVATE_FEEDBACK: return {
            ...state, feedbackModel: { ...state.feedbackModel, active: true, type: action.payload.type, message: action.payload.message }
        }
        case DEACTIVATE_FEEDBACK: return {
            ...state, feedbackModel: { ...INIT_STATE.feedbackModel }
        }
        case SET_SOCKET: return {
            ...state, socket: action.payload
        }
        case SOCKET_ACTIVE_CHALLENGE: return {
            ...state, socketActiveChallenge: { ...state.socketActiveChallenge, ...action.payload }
        }
        case ACTIVE_CHALLENGE: return {
            ...state, activeChallenge: { ...state.activeChallenge, ...action.payload }
        }
        case ONLINE_USERS: return {
            ...state, onlineUsers: action.payload
        }
        default: return { ...state };
    }
}

export default Site;