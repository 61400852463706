import "./../../css/challenge.css"
import { useEffect, useState } from "react"
import { Link, useNavigate, useRou } from "react-router-dom"
import "react-input-range/lib/css/index.css"
import usePagination from "../../hooks/usePagination"
import { useDispatch } from "react-redux";
import { setTraining, activateFeedback } from "../../redux/actions"
import useRequest from "../../hooks/useRequest"
import { MultiLang } from "../MultiLang"
import { useSelector } from "react-redux"

const TrainingChallenge = () => {
    const { language } = useSelector((state) => state.reducer);

    const [challenges, setChallenges] = useState(null)
    const [totalResults, setTotalResults] = useState(0)
    const navigate = useNavigate()
    const dispatch = useDispatch()


    const { request, response } = useRequest()
    const { request: reqStart, response: resStart } = useRequest()
    const { pagination, activePage, pageSize } = usePagination(totalResults)

    const [queryData, setQueryData] = useState({
        page_size: pageSize,
        page: activePage,
    })

    useEffect(() => {
        setQueryData({ ...queryData, page_size: pageSize, page: activePage })
    }, [activePage, pageSize])

    useEffect(() => {
        request("GET", `/challenge-trainings?page-size=${queryData.page_size}&page=${queryData.page}`)
    }, [queryData])

    useEffect(() => {
        if (response) {
            if (response?.status === 'success') {
                setChallenges(response?.data?.result)
                setTotalResults(response?.data?.total_record)
            } else {
                setChallenges(null)
            }
        }
    }, [response])

    const handleStartTraining = (id) => {
        // navigate('/training')

        reqStart('POST', '/join-training', { training_id: id })
    }

    useEffect(() => {
        if (resStart) {
            if (resStart?.status === 'success') {
                dispatch(setTraining({ ...resStart?.time }))
                navigate('/training')
            } else {
                dispatch(activateFeedback({ status: 'error', message:resStart?.message}))
            }
        }
    }, [resStart])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="profile-main challenge-main">
            <div className="profile-tab-content  active">

                <div className="challenge-content challengePage-block" >
                    <div className="challenge-content-header content   ">
                        {totalResults} {MultiLang["Trainings"][language]}
                    </div>
                    <div className="content">
                        {
                            challenges && challenges.length > 0 ?
                                <div className="cont_bx">
                                    {
                                        challenges.map((value, i) => {
                                            return (
                                                <div key={i} className="profile-main-con challenge-main-con" >
                                                    <div className="profile-main-con-header">
                                                        <img src={value?.cover_image} alt="" />

                                                        {/* <div className="profile-main-con-badge2">
                                                            {
                                                                value?.joining_fee_commission ?
                                                                    <>
                                                                        Bank: <br /> <span>{+value?.joining_fee - +value?.joining_fee_commission}€ + {+value?.joining_fee_commission}€</span>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        Bank: <br /> <span>{+value?.joining_fee}€</span>
                                                                    </>
                                                            }
                                                        </div> */}

                                                    </div>
                                                    <div className="profile-main-con-bottom">
                                                        <div>
                                                            {value?.title}
                                                        </div>
                                                        <div className="profile-main-con-text">
                                                            <div>
                                                                {/* <img src="./images/challenge/icon-profile.png" alt="" /> */}
                                                                {MultiLang["Training"][language]}: <strong>{value?.training_time}s</strong>
                                                            </div>
                                                            <div>
                                                                {/* <img src="./images/challenge/icon-prize-fill.png" alt="" /> */}
                                                                {MultiLang["Prepration"][language]}: <strong>{value?.preparation_time}s</strong>
                                                            </div>
                                                            <div>
                                                                {/* <img src="./images/challenge/icon-prize-fill.png" alt="" /> */}
                                                                {MultiLang["Attempts"][language]}: <strong>{value?.joined_attempts}/{value?.user_count}</strong>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <button className="profile-main-con-btn btn blue-btn" onClick={() => { handleStartTraining(value?.id) }}>
                                                                {/* <img src="./images/challenge/icon-prize.png" alt="" /> */}
                                                                {MultiLang["Start Training"][language]}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {pagination}
                                </div>
                                : <div className="challenge-nodata"> {MultiLang["No data found"][language]} </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrainingChallenge
