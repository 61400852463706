import React, { useState } from 'react'
import PaypalWithdraw from './PaypalWithdraw';
import WithdrawMoney from './WithdrawMoney';

const WithdrawPage = () => {
    const [paymentMethod, setPaymentMethod] = useState(null)
    // const [paymentMethod, setPaymentMethod] = useState('creditCard')


    return (
        <>
            <div className="setting-content-con" >
                {/* <div className="setting-content-con-header">Withdraw Money</div> */}
                {
                    !paymentMethod &&
                    <div className="bank-container newBankBlock">
                        <div className="bank-list light"
                        onClick={() => { setPaymentMethod('bank')}}
                        >
                            <img src="./images/bank.png" width={'40px'} alt="" />
                            <div>Bank<br />Withdraw</div>
                        </div>
                        <div className="bank-list light" onClick={() => { setPaymentMethod('paypal') }}>
                            <img src="./images/paypal.png" width={'40px'} alt="" />
                            <div>
                                PayPal <br />Withdraw
                                </div>
                        </div>
                    </div>
                }
                {
                    paymentMethod === 'bank' &&
                    <WithdrawMoney setPaymentMethod={setPaymentMethod} />
                }
                {
                    paymentMethod === 'paypal' &&
                    <PaypalWithdraw setPaymentMethod={setPaymentMethod} />
                }
            </div>
        </>
    )
}

export default WithdrawPage
