import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import { useDispatch } from "react-redux";
import { activateFeedback } from "../../redux/actions";
import moment from "moment";
import { numberVoice } from "../../helpers/numberVoice.js";

const VideoRecorder = ({
  uploadVideo,
  videoTime,
  uploadType,
  time,
  setMode,
  nextMode,
  currentMode,
  audioSrc,
  audioNum,
}) => {
  const [startCapture, setStartCapture] = useState(false);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);
  const [recDone, setRecDone] = useState(false);

  const [tempError, setTempError] = useState("");

  const dispatch = useDispatch();
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);

  const [counter, setCounter] = useState(time);

  useEffect(() => {
    if (capturing) {
      // if(counter === 10 && audioSrc){
      //     let sound = new Audio(audioSrc)
      //     sound.play()
      // }
      if (audioNum && audioNum[counter] && numberVoice[audioNum[counter]]) {
        let sound = new Audio(numberVoice[audioNum[counter]]);
        sound.play();
      }

      if (counter <= 0) {
        handleStopCaptureClick();
      } else {
        setTimeout(() => {
          setCounter(counter - 1);
        }, 1000);
      }
    }
  }, [capturing, counter]);

  function muteMe(elem) {
    elem.muted = true;
  }

  const handleStartCaptureClick = React.useCallback(() => {
    document.querySelectorAll("video").forEach((elem) => muteMe(elem));

    //mimeType not supported
    let options = {};
    if (MediaRecorder.isTypeSupported("video/webm; codecs=vp9")) {
      options = { mimeType: "video/webm; codecs=vp9" };
    } else if (MediaRecorder.isTypeSupported("video/webm")) {
      options = { mimeType: "video/webm" };
    } else if (MediaRecorder.isTypeSupported("video/mp4")) {
      options = { mimeType: "video/mp4", videoBitsPerSecond: 100000 };
    } else {
      dispatch(
        activateFeedback({
          type: "error",
          message: "no suitable mimetype found for this device",
        })
      );
    }
    //-----------------------

    mediaRecorderRef.current = new MediaRecorder(
      webcamRef.current.stream,
      options
    );

    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();

    setCapturing(true);
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    setRecDone(true);
  }, [mediaRecorderRef, webcamRef, setCapturing, recordedChunks]);

  const handleConvert = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      let file = new File([blob], "filename.mp4", { type: "video/mp4" });

      if (uploadType) {
        uploadVideo(file, uploadType);
      }

      setStartCapture(false);
      setRecordedChunks([]);

      if (nextMode) {
        setMode(nextMode);
      }
    }
  }, [recordedChunks]);

  useEffect(() => {
    if (recordedChunks.length > 0 && recDone) {
      handleConvert();
    }
  }, [recordedChunks]);

  const webcamErrorHandler = (e) => {
    // console.log("videoError", e);
    // setTempError(e.toString());
    setStartCapture(false);
    dispatch(
      activateFeedback({
        type: "error",
        message: "Unable to capture the video",
      })
    );
  };

  useEffect(() => {
    setStartCapture(true);
  }, []);

  return (
    <>
      <div>
        {/* <div>{tempError}</div> */}
        {startCapture && (
          <div className="challenge-video">
            <div className="video-container">
              <div className="camera-btn-con camera_01">
                {currentMode === "prepare" &&
                  `Challenge will start after ${counter} seconds.`}
                {currentMode === "gap" && `Starting the challenge.`}
                {currentMode === "start" &&
                  `Recording.. for ${counter} seconds.`}
              </div>
              <Webcam
                onUserMediaError={webcamErrorHandler}
                audio={true}
                ref={webcamRef}
                onUserMedia={() => {
                  handleStartCaptureClick();
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default VideoRecorder;
