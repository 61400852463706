import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from 'yup';

import "./../../css/auth.css";

import { activateFeedback } from "../../redux/actions";

import Preloader from "../components/preloader";

import useRequest from "../../hooks/useRequest"
import { useSelector, useDispatch } from "react-redux"

import OtpInput from 'react-otp-input';



function ResetPassword() {
    const { loading } = useSelector(state => state.Auth)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { uuid } = useParams()

    const [otp, setOtp] = useState("")
    const [otpError, setOtpError] = useState(null)

    const { request, response } = useRequest()

    const formik = useFormik({
        initialValues: {
            new_password: '',
            confirm_new_password: ''
        },
        validationSchema: Yup.object({
            new_password: Yup.string()
                .required('Please Enter Your Password')
                .min(8, 'Password is too short - should be 8 chars minimum.')
                .matches(/^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$/, 'Password must contain Uppercase, lowercase, number and special charecter.')
        }),
        onSubmit: values => {
            if (values.new_password !== values.confirm_new_password) {
                dispatch(activateFeedback({ type: 'error', message: 'Please confirm the password' }))
                return;
            }
            if (!otp || otp.length !== 6) {
                setOtpError('Please Enter a valid OTP')
                return;
            }
            let data = {
                forgot_password_otp: otp,
                new_password: values.new_password,
                confirm_new_password: values.confirm_new_password,
                uuid_string: uuid
            }
            request("POST", "/auth/reset-password/", data)
        }
    });

    useEffect(() => {
        if (response) {
            dispatch(activateFeedback({ type: response?.status, message: response?.message }))
            if (response.status === "success") {
                navigate("/login")
            }
        }
    }, [response])

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!uuid) {
            navigate("/login")
        }
    }, []);

    return (
        <>
            {loading && <Preloader />}
            <div className="_auth">
                <div className="auth-main _login">
                    <div className="auth-back-img">
                        <div className="auth-back-img-cover">
                        </div>
                        <img src="./images/auth/auth-logo.png" alt="" className="auth-logo" />
                        You’re born <br className="_sp" /> for <br className="_pc" /> this
                    </div>
                    <form className="auth-input" onSubmit={formik.handleSubmit}>
                        <div className="auth-input-con">
                            <div className="auth-input-header">
                                Reset Password
                                <Link to="/" className="auth-close">
                                    <img src="./images/auth/auth-close.png" alt="" />
                                </Link>
                            </div>

                            <div className="auth-input-list resetpassword_otp">
                                <label htmlFor="">Forgot Password OTP (sent on registered email address)</label>
                                <OtpInput
                                    className='otpBox'
                                    value={otp}
                                    onChange={(value) => { setOtp(value); setOtpError(null) }}
                                    numInputs={6}
                                    separator={<span></span>}
                                    autoComplete={false}
                                />
                            </div>
                            {otpError && <div className="input-error">{otpError}</div>}

                            <div className="auth-input-list">
                                <div className="recover-pwd">
                                    <label htmlFor="">New Password</label>
                                </div>
                                <input
                                    name="new_password"
                                    {...formik.getFieldProps('new_password')}
                                    type="password" placeholder="-"
                                    autoComplete="off"
                                />
                            </div>
                            {formik.touched.new_password && formik.errors.new_password && <div className="input-error">{formik.errors.new_password}</div>}

                            <div className="auth-input-list">
                                <div className="recover-pwd">
                                    <label htmlFor="">Confirm New Password</label>
                                </div>
                                <input
                                    name="confirm_new_password"
                                    {...formik.getFieldProps('confirm_new_password')}
                                    type="password" placeholder="-"
                                    autoComplete="off"
                                />
                            </div>
                            {formik.touched.confirm_new_password && formik.errors.confirm_new_password && <div className="input-error">{formik.errors.confirm_new_password}</div>}

                            <div className="auth-input-list">
                                <button type="submit" className="btn auth-main-btn">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>


    )
}

export default ResetPassword;