import React, { useEffect } from 'react'
import Preloader from "../components/preloader"
import { useLocation, useNavigate } from "react-router-dom"
import useRequest from "../../hooks/useRequest";
import { activateFeedback, setStripe } from "../../redux/actions"
import { useDispatch } from "react-redux";


const StripeRedirect = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { search } = useLocation();
    let code = search.split("?")[1];

    console.log("code => ", code)
    console.log("search => ", search)

    // console.log(`uri => /verify-stripe?${code}`)

    const { request, response } = useRequest()
    useEffect(() => {
        // debugger
        if (code) {
            request('POST', `/verify-stripe?${code}`)
        }
    }, [])

    useEffect(() => {
        if (response) {
            // console.log(response)
            dispatch(activateFeedback({ type: response?.status, message: response?.message }))
            if(response?.status === 'success'){
                dispatch(setStripe('acct_1LaYEJSIz0dyYI07'))
                navigate('/setting?tab=withdrawal')
            }
            // window.location.href = 'https://stronger.stage04.obdemo.com/setting?tab=withdrawal'
            
        }
    }, [response])
    return (
        <Preloader />
    )
}
export default StripeRedirect
