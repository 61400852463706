import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, Field } from "formik";
import * as Yup from "yup";
import "./../../css/auth.css";

import { activateFeedback } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";

import Preloader from "../components/preloader";
import useRequest from "../../hooks/useRequest";
import { countries } from "../../helpers/countries";
import SelfieModel from "../components/SelfieModel";

function Register() {
  const navigate = useNavigate();

  const { loading } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  const { request, response } = useRequest();

  const [ageValue, setAgeValue] = useState("");
  const [ageError, setAgeError] = useState(null);

  const [selfie, setSelfie] = useState(null);
  const [selfieSrc, setSelfieSrc] = useState("");
  // const [selfieErr, setSelfieErr] = useState(null)
  const [showSelfieModel, setShowSelfieModel] = useState(false);
  const [type, setType] = useState("password");
  const [type1, setType1] = useState("password");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      gender: "",
      confirm_password: "",
      terms: false,
      age: "",
      country: "",
      promocode: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please enter your email")
        .matches(
          /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
          "please enter a valid email"
        ),
      password: Yup.string()
        .required("Please enter your password")
        .min(8, "Password is too short - should be 8 characters minimum.")
        .matches(
          /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,1}).{8,}$/,
          "Password must contain Uppercase, lowercase, number and special character."
        ),
      confirm_password: Yup.string().required("Please enter your password"),
      first_name: Yup.string()
        .required("Please enter your username")
        .matches(
          /^[a-zA-Z0-9._@#]+$/,
          "Only alphabets numbers and @,#,.,_ allowed."
        ),
      // last_name: Yup.string().required('Please enter your lastName').matches(/^[A-Za-z]+$/, 'Only alphabets allowed.'),
      gender: Yup.string().required("Please select your Gender"),
      terms: Yup.boolean().test(
        "terms",
        "Please accept Terms And Conditions",
        (val) => {
          return val;
        }
      ),
      // age: Yup.number().min(1, "Input positive number").max(200, "Too big").required('Please Enter Your Age.'),
      country: Yup.string().required("Please Select Your Country"),
      // promocode: Yup.string().matches
    }),
    onSubmit: (values) => {
      values.age = ageValue;
      if (values.password !== values.confirm_password) {
        dispatch(
          activateFeedback({
            type: "error",
            message: "Please confirm the password",
          })
        );
        return;
      }
      if (!values.terms) {
        dispatch(
          activateFeedback({
            type: "error",
            message: "Please accept Terms And Conditions",
          })
        );
        return;
      }
      if (!values.age) {
        setAgeError("Please enter your age.");
      }

      let formdata = new FormData();
      if (selfie) {
        formdata.append("selfie_image", selfie);
      }
      formdata.append("first_name", values.first_name);
      // formdata.append('last_name', values.last_name)
      formdata.append("last_name", null);
      formdata.append("email", values.email);
      formdata.append("password", values.password);
      formdata.append("confirm_password", values.confirm_password);
      formdata.append("gender", values.gender);
      formdata.append("age", values.age);
      formdata.append("country", values.country);
      formdata.append("promocode", values.promocode.trim());
      formdata.append("terms", values.terms);

      // formdata.append('data', JSON.stringify(values))
      // console.log(formdata)
      request("POST", "/auth/register/", formdata);
    },
  });

  const handleAgeChange = (e) => {
    let val = e.target.value;
    if (isNaN(val)) {
      setAgeValue(ageValue);
      return;
    } else if (+val < 1 || +val > 200) {
      setAgeValue(val);
      setAgeError("Age must be between 1-85");
      return;
    }
    setAgeValue(val);
    setAgeError(null);
    formik.values.age = ageValue;
  };

  useEffect(() => {
    if (response) {
      dispatch(
        activateFeedback({ type: response?.status, message: response?.message })
      );
      if (response?.status === "success" && response?.data?.user?.uuid_string) {
        navigate(`/verifyRegister/${response?.data?.user?.uuid_string}`);
      }
    }
  }, [response]);

  const changeType = () => {
    if (type === "password") {
      setType("text");
      return;
    }
    setType("password");
  };
  const changeType1 = () => {
    if (type1 === "password") {
      setType1("text");
      return;
    }
    setType1("password");
  };
  return (
    <>
      {loading && <Preloader />}
      <div className="_auth">
        <div className="auth-main _register">
          <div className="auth-back-img">
            <img
              src="./images/auth/auth-logo.png"
              alt=""
              className="auth-logo"
            />
            You’re born <br className="_sp" /> for <br className="_pc" /> this
          </div>
          <form className="auth-input" onSubmit={formik.handleSubmit}>
            <div className="auth-input-con">
              <div className="auth-input-header">
                Sign Up
                <Link to="/" className="auth-close">
                  <img src="./images/auth/auth-close.png" alt="" />
                </Link>
              </div>

              <div
                // className="auth-input-list flex"
                className="auth-input-list"
              >
                {/* <div className="half-auth-input"> */}
                <label htmlFor="">Username</label>
                <input
                  name="first_name"
                  {...formik.getFieldProps("first_name")}
                  type="text"
                  placeholder="-"
                />
                {formik.touched.first_name && formik.errors.first_name && (
                  <div className="input-error">{formik.errors.first_name}</div>
                )}
                {/* </div> */}
                {/* <div className="half-auth-input">
                                    <label htmlFor="">Last Name</label>
                                    <input
                                        name="last_name"
                                        {...formik.getFieldProps('last_name')}
                                        type="text"
                                        placeholder="-" />
                                    {formik.touched.last_name && formik.errors.last_name && <div className="input-error">{formik.errors.last_name}</div>}

                                </div> */}
              </div>
              <div className="auth-input-list">
                <label htmlFor="">Email</label>
                <input
                  name="email"
                  {...formik.getFieldProps("email")}
                  type="text"
                  placeholder="Example@example.com"
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="input-error">{formik.errors.email}</div>
                )}
              </div>
              <div className="auth-input-list flex">
                <div className="half-auth-input">
                  <label htmlFor="">Age</label>
                  <input
                    name="age"
                    onChange={handleAgeChange}
                    value={ageValue}
                    type="text"
                    id=""
                    placeholder=""
                  />
                  {ageError && <div className="input-error">{ageError}</div>}
                </div>
                <div className="half-auth-input">
                  <label htmlFor="">Gender</label>
                  <select name="gender" {...formik.getFieldProps("gender")}>
                    <option value="">Select gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                  {formik.touched.gender && formik.errors.gender && (
                    <div className="input-error">{formik.errors.gender}</div>
                  )}
                </div>
              </div>

              <div className="auth-input-list flex">
                <div className="half-auth-input">
                  <label htmlFor="">Password</label>
                  <span className="input_password_box">
                    <input
                      name="password"
                      {...formik.getFieldProps("password")}
                      type={type}
                      placeholder="-"
                    />

                    <span className="eye-icon_box" onClick={changeType}>
                      {type === "password" ? (
                        <i
                          className="fas fa-eye-slash mt-5"
                          id="togglePassword"
                        ></i>
                      ) : (
                        <i className="fas fa-eye" id="togglePassword"></i>
                      )}
                    </span>
                  </span>

                  {formik.touched.password && formik.errors.password && (
                    <div className="input-error">{formik.errors.password}</div>
                  )}
                </div>
                <div className="half-auth-input">
                  <label htmlFor="">Confirm Password</label>
                  <span className="input_password_box">
                    <input
                      name="confirm_password"
                      {...formik.getFieldProps("confirm_password")}
                      type={type1}
                      placeholder="-"
                    />

                    <span className="eye-icon_box" onClick={changeType1}>
                      {type1 === "password" ? (
                        <i className="fas fa-eye-slash" id="togglePassword"></i>
                      ) : (
                        <i className="fas fa-eye" id="togglePassword"></i>
                      )}
                    </span>
                  </span>
                  {formik.touched.confirm_password &&
                    formik.errors.confirm_password && (
                      <div className="input-error">
                        {formik.errors.confirm_password}
                      </div>
                    )}
                </div>
              </div>

              <div className="auth-input-list flex">
                <div className="half-auth-input">
                  <label htmlFor="">Country</label>
                  <select name="country" {...formik.getFieldProps("country")}>
                    <option value="">Select country</option>
                    {countries.map((val) => {
                      return (
                        <option key={val.code} value={val.name}>
                          {val.name}
                        </option>
                      );
                    })}
                  </select>
                  {formik.touched.country && formik.errors.country && (
                    <div className="input-error">{formik.errors.country}</div>
                  )}
                </div>

                <div className="half-auth-input">
                  <label htmlFor="">Promo code (if any)</label>
                  <input
                    name="promocode"
                    {...formik.getFieldProps("promocode")}
                    type="text"
                    placeholder="-"
                  />
                  {formik.touched.promocode && formik.errors.promocode && (
                    <div className="input-error">{formik.errors.promocode}</div>
                  )}
                </div>

                {/* <div className="half-auth-input">
                                    <label htmlFor="">Selfie</label>
                                    <div className="add_profileWall">
                                        <div className="add_profile_More">
                                            {
                                                selfie ?
                                                    <div className="add_profile_More seletimg"
                                                    >
                                                        <div className="imgwall">
                                                            <img src={selfieSrc} alt="" />
                                                        </div>
                                                        <div className="img_remove">
                                                            <img src="./images/setting/close.png" onClick={() => { setSelfie(null) }} />
                                                        </div>
                                                    </div>
                                                    :
                                                    <img src="./images/setting/camera.png" alt="" className="input-icon" onClick={() => { setShowSelfieModel(true); setSelfieErr(null) }} style={{ cursor: 'pointer' }} />
                                            }
                                        </div>
                                    </div>
                                    {selfieErr && <div className="input-error">{selfieErr}</div>}
                                </div>
                                <div className="imag_block">
                                </div> */}
              </div>
              <div className="auth-input-list flex">
                <div className="half-auth-input">
                  <label htmlFor="">Selfie (optional)</label>
                  <div className="add_profileWall">
                    <div className="add_profile_More">
                      {selfie ? (
                        <div className="add_profile_More seletimg">
                          <div className="imgwall">
                            <img src={selfieSrc} alt="" />
                          </div>
                          <div className="img_remove">
                            <img
                              src="./images/setting/close.png"
                              onClick={() => {
                                setSelfie(null);
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <img
                          src="./images/setting/camera.png"
                          alt=""
                          className="input-icon"
                          onClick={() => {
                            setShowSelfieModel(true);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                  </div>
                  {/* {selfieErr && <div className="input-error">{selfieErr}</div>} */}
                </div>
                <div className="imag_block"></div>
              </div>
              <div className="auth-input-list flex j-c-l">
                <input
                  type="checkbox"
                  name="terms"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="terms"
                />
                <label htmlFor="terms">
                  I accept Stronger{" "}
                  <Link to="/terms" className="auth-link">
                    Terms & Conditions
                  </Link>{" "}
                  and{" "}
                  <Link to="/privacy" className="auth-link">
                    Privacy Policy.
                  </Link>
                </label>
              </div>
              {formik.errors.terms ? (
                <div className="input-error">{formik.errors.terms}</div>
              ) : null}
              <div className="auth-input-list">
                <button type="submit" className="btn auth-main-btn">
                  Sign Up
                </button>
              </div>
              <div className="auth-input-list">
                <div className="auth-input-list-text">
                  Already Have An Account?&nbsp;&nbsp;
                  <Link to="/login" className="auth-link">
                    Sign In
                  </Link>
                </div>
              </div>
            </div>
          </form>
          <SelfieModel
            show={showSelfieModel}
            setShow={setShowSelfieModel}
            setImgSrc={setSelfieSrc}
            setSelfie={setSelfie}
          />
        </div>
      </div>
    </>
  );
}

export default Register;
