import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
height:80px;
background: #101016;
display: flex;
justify-content: space-between;
z-index: 12;
/* Third Nav */
/* justify-content: flex-start; */
`;

export const NavLink = styled(Link)`
color: #FFFFFF;
opacity: .8;
text-decoration: none;
padding: 0 25px;
height: 100%;
cursor: pointer;
&.active {
	color: #1443E8;
	opacity: 1;
}
&:hover {
	
	text-decoration: underline;
}
}
`;


export const NavMenu = styled.div`

display: flex;
align-items: center;
margin-right: -24px;
/* Second Nav */
/* margin-right: 24px; */
/* Third Nav */
/* width: 100vw;
white-space: nowrap; */
`;

export const NavBtn = styled.nav`
display: flex;

align-items: center;
margin-right: 24px;
`;

export const NavBtnLink = styled(Link)`
width:182px;
height:52px;
display: flex;
align-items: center;
justify-content: center;
font-size: 16px;
border-radius: 69px;
background: #1443E8;
color: #FFFFFF;
outline: none;
border: 1px solid #1443E8;
cursor: pointer;
transition: all 0.2s ease-in-out;
text-decoration: none;

&:hover {
	filter: brightness(1.3);
}
`;
