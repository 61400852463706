export const getQueryObj = (str) => {
  let queryObj = {};
  if (!str) {
    return queryObj;
  }
  str
    .split("?")[1]
    .split("&")
    .forEach((query) => {
      queryObj[query.split("=")[0]] = query.split("=")[1];
    });
  return queryObj;
};
