import React, { useEffect, useRef } from 'react'
import { useDispatch } from "react-redux"
import { activateFeedback } from "../../redux/actions";
import Webcam from 'react-webcam'

const VideoModel = ({ show, setShow, videoSrc, setVideo }) => {
    console.log(videoSrc)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!videoSrc) {
            dispatch(activateFeedback({ status: 'error', message: 'unable to get the video' }))
            setShow(false)
        }
        return (() => {
            setVideo(null)
        })
    }, [])

    return (
        <div
            className={
                show
                    ? "finish-modal setting-modal active"
                    : "finish-modal setting-modal"
            }
        >
            <div
                className="modal-close"
                onClick={() => {
                    setShow(false);
                }}
            >
                <img src="images/auth/auth-close.png" alt="" />
            </div>
            {/* <div className="modal-header">Video</div> */}
            <div className="modal-body">
                <video muted autoPlay loop controls
                    style={{
                        width: 'auto',
                        height: '400px'
                    }}
                >
                    <source
                        src={videoSrc}
                        type="video/mp4" />
                </video>
            </div>
        </div>
    )
}

export default VideoModel
