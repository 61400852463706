export const MultiLang = {
    "About Us": {GB: "About Us", FR: "A propos de nous ", DE:"Über uns"},
    "Contact Us": {GB: "Contact Us", FR: "Nous contacter", DE:"Kontakt"},
    "Sweat,": {GB: "Sweat,", FR: "Transpire,", DE:"Schwitzt,"},
    "Win,Get paid": {GB: "Win, Get paid", FR: "Gagne, soit Payé", DE:"Gewinnt, Entweder Bezahlt"},
    "The world of online fitness competition": {GB: "The world of online fitness competition", FR: "Le monde des compétitions de fitness en ligne", DE:"Die Welt der Online-Fitnesswettbewerbe"},
    "Be the first to test Stronger challenges and get rewarded. Registration for beta version is open.": {GB: "Be the first to test Stronger challenges and get rewarded. Registration for beta version is open.", FR: "Soyez le premier à tester les défis Stronger et à être récompensé. L'inscription à la version bêta est ouverte.", DE:"Seien Sie der Erste, der die Stronger-Herausforderungen ausprobiert und dafür belohnt wird. Die Anmeldung für die Beta-Version ist eröffnet."},
    "Register": {GB: "Register", FR: "S’enregistrer", DE:"Registrieren"},
    "Founded": {GB: "Founded", FR: "fondé", DE:"gegründet"},
    "Team": {GB: "Team", FR: "Équipe", DE:"Team"},
    "Users": {GB: "Users", FR: "Utilisateurs", DE:"Nutzer"},
    "Ratings": {GB: "Ratings", FR: "Évaluation", DE:"Bewertung"},
    "Prizes": {GB: "Prizes", FR: "Prix", DE:"Preise"},
    "Prize": {GB: "Prize", FR: "Prix", DE:"Preis"},
    "Challenge": {GB: "Challenge", FR: "Défis", DE:"Herausforderungen"},
    "Challenges": {GB: "Challenges", FR: "Défis", DE:"Herausforderungen"},
    "Ongoing": {GB: "Ongoing", FR: "Défis quotidiens", DE:"Tägliche Herausforderungen"},
    "Daily Challenges": {GB: "Daily Challenges", FR: "Défis quotidiens", DE:"Tägliche Herausforderungen"},
    "Challenge Someone": {GB: "Challenge Someone", FR: "Défiez quelqu'un", DE:"Fordern Sie jemanden heraus"},    
    "Past": {GB: "Past", FR: "passé", DE:"Vergangenheit"},
    "Training": {GB: "Training", FR: "Entrainement", DE:"Ausbildung"},
    "Trainings": {GB: "Trainings", FR: "Entrainement", DE:"Ausbildung"},
    "Cost": {GB: "Cost", FR: "Coûts", DE:"Kosten"},
    "Participant": {GB: "Participant", FR: "Participants", DE:"Teilnehmer"},
    "Participants": {GB: "Participants", FR: "Participants", DE:"Teilnehmer"},
    "Date": {GB: "Date", FR: "Date", DE:"Datum"},
    "Start Time": {GB: "Start Time", FR: "Heure de début", DE:"Startzeit"},
    "Type": {GB: "Type", FR: "Taper", DE:"Typ"},
    "First prize": {GB: "First prize", FR: "Premier prix", DE:"Erster Preis"},
    "Registration": {GB: "Registration", FR: "Inscription", DE:"Anmeldung"},
    "Completed": {GB: "Completed", FR: "Complété", DE:"Abgeschlossen"},
    "Bank": {GB: "Bank", FR: "Banque", DE:"Bank"},
    "See Result": {GB: "See Result", FR: "Voir le résultat", DE:"Siehe Ergebnis"},
    "Result not announced yet!": {GB: "Result not announced yet!", FR: "Résultat pas encore annoncé!", DE:"Ergebnis noch nicht bekannt gegeben!"},
    "No data found": {GB: "No data found", FR: "Aucune donnée disponible", DE:"Keine Daten gefunden"},
    "Prepration": {GB: "Prepration", FR: "Préparation", DE:"Vorbereitung"},
    "Attempts": {GB: "Attempts", FR: "Tentatives", DE:"Versuche"},
    "Leader Board": {GB: "Leader Board", FR: "Classement", DE:"Bestenliste"},
    "Start Training": {GB: "Start Training", FR: "Commencer l'entrainement", DE:"Mit dem Training beginnen"},
    "My Balance": {GB: "My Balance", FR: "Mon solde", DE:"Mein Kontostand"},
    "Deposit": {GB: "Deposit", FR: "Verser", DE:"Kaution"},
    "Withdraw": {GB: "Withdraw", FR: "Retrait", DE:"Abheben"},
    "Withdrawal": {GB: "Withdraw", FR: "Retrait", DE:"Rückzug"},
    "Profile": {GB: "Profile", FR: "Profil", DE:"Profil"},
    "Account": {GB: "Account", FR: "Compte", DE:"Konto"},
    "Logout": {GB: "Logout", FR: "Déconnexion", DE:"Abmeldung"},
    "Setting": {GB: "Setting", FR: "Paramètre", DE:"Einstellung"},
    "Medal": {GB: "Medal", FR: "Médaille", DE:"Medaille"},
    "Wins": {GB: "Wins", FR: "Victoires", DE:"Siege"},
    "Losings": {GB: "Losings", FR: "Pertes", DE:"Verluste"},
    "My Challenges": {GB: "My Challenges", FR: "Mes défis", DE:"Meine Herausforderungen"},
    "Success Stories": {GB: "Success Stories", FR: "Réussites", DE:"Erfolgsgeschichten"},
    "Security": {GB: "Security", FR: "Sécurité", DE:"Sicherheit"},
    "Personal Information": {GB: "Personal Information", FR: "Informations personnelles", DE:"Persönliche Informationen"},
    "Deposit Money": {GB: "Deposit Money", FR: "Dépôt d'argent", DE:"Geld einzahlen"},
    "Transaction History": {GB: "Transaction History", FR: "Historique des transactions", DE:"Transaktionsverlauf"},
    "Withdrawal Money": {GB: "Withdrawal Money", FR: "Retrait d'argent", DE:"Geld abheben"},
    "Account Verification": {GB: "Account Verification", FR: "Vérification de compte", DE:"Kontoüberprüfung"},
    "First Name": {GB: "First Name", FR: "Prénom", DE:"Vornamen"},
    "Username": {GB: "Username", FR: "Nom d'utilisateur", DE:"Nutzername"},
    "Last Name": {GB: "Last Name", FR: "Nom de famille", DE:"Nachname"},
    "Age": {GB: "Age", FR: "Age", DE:"Alter"},
    "Address": {GB: "Address", FR: "Adresse", DE:"Adresse"},
    "Country": {GB: "Country", FR: "Pays", DE:"Land"},
    "Gender": {GB: "Gender", FR: "Genre", DE:"Geschlecht"},
    "Male": {GB: "Male", FR: "Homme", DE:"Männlich"},
    "Female": {GB: "Female", FR: "Femme", DE:"Weiblich"},
    "Non-binary": {GB: "Non-binary", FR: "Nom-binaire", DE:"Nicht-binär"},

    "Change Password": {GB: "Change Password", FR: "Changer le mot de passe", DE:"Passwort ändern"},
    "Current Password": {GB: "Current Password", FR: "Mot de passe actuel", DE:"Aktuelles Passwort"},
    "New Password": {GB: "New Password", FR: "Nouveau mot de passe", DE:"Neues Passwort"},
    "Confirm Password": {GB: "Confirm Password", FR: "Confirmer le mot de passe", DE:"Bestätigen Sie Ihr Passwort"},
    "Change Email": {GB: "Change Email", FR: "Changer d'email", DE:"E-Mail ändern"},
    "Current Email": {GB: "Current Email", FR: "Email actuel", DE:"Aktuelle E-Mail"},
    "New Email": {GB: "New Email", FR: "Nouveau email", DE:"neue E-Mail"},
    "Get OTP": {GB: "Get OTP", FR: "Obtenir l'otp", DE:"Otp bekommen"},

    "Payment": {GB: "Payment", FR: "Paiement", DE:"Zahlung"},
    "Credit Card": {GB: "Credit Card", FR: "Carte de crédit", DE:"Kreditkarte"},
    "Credit to Wallet": {GB: "Credit to Wallet", FR: "Crédit au portefeuille", DE:"Kredit an Portfolio"},
    "Service Charge": {GB: "Service Charge", FR: "Frais de service", DE:"Unkostenbeitrag"},
    "Debit from Account": {GB: "Debit from Account", FR: "Débit du compte", DE:"Abbuchung vom Konto"},

    "Amount": {GB: "Amount", FR: "Montant", DE:"Betrag"},
    "Bank Name": {GB: "Bank Name", FR: "Nom de la banque", DE:"Name der Bank"},
    "Account Holder Name": {GB: "Account Holder Name", FR: "Nom du titulaire du compte", DE:"Name des Kontoinhabers"},
    "Routing Number": {GB: "Routing Number", FR: "Numéro d'identification", DE:"Routing-Nummer"},
    "Account Number": {GB: "Account Number", FR: "Numéro de compte", DE:"Kontonummer"},
    "Send Request": {GB: "Send Request", FR: "Envoyer la demande", DE:"Anfrage senden"},
    "Go Back": {GB: "Go Back", FR: "Retourner", DE:"Geh zurück"},
    "not-verified": {GB: "Your account is not verified yet! Please verify to continue.", FR: "Votre compte n'est pas encore vérifié ! Veuillez vérifier pour continuer.", DE:"Ihr Konto ist noch nicht verifiziert! Bitte bestätigen Sie, um fortzufahren."},
    "Verify": {GB: "Verify", FR: "Vérifier", DE:"Verifizieren"},
    "Optional": {GB: "Optional", FR: "facultatif", DE:"Optional"},
    "Verification Alert": {GB: "Verification Alert", FR: "Alerte de vérification", DE:"Bestätigungsalarm"},

    "what-is-stronger": {GB: "What is Stronger?", FR: "Qu'est-ce Stronger?", DE:"Was ist Stronger?"},
    "a-new-dimension": {GB: "A new dimension of fitness competition without limits. Fitness or sports competitions have long been designed so that only the best could participate and win prizes. Today, with our new formula and vision, anyone can participate, with a minimum of fitness level up to the highest, through challenges carefully created by our certified sports coaches.", FR: "Une nouvelle dimension de la compétition de fitness sans limites. Les compétitions de fitness ou de sport ont longtemps été conçues de telle sorte que seuls les meilleurs pouvaient y participer et gagner des prix. Aujourd'hui, avec notre nouvelle formule et notre nouvelle vision, tout le monde peut participer, avec un minimum de niveau de fitness jusqu'au plus haut, à travers des défis soigneusement créés par nos coachs sportifs certifiés.", DE:"Eine neue Dimension von Fitnesswettbewerben ohne Grenzen. Fitness- oder Sportwettbewerbe waren lange Zeit so konzipiert, dass nur die Besten daran teilnehmen und Preise gewinnen konnten. Heute, mit unserem neuen Konzept und unserer neuen Vision, kann jeder teilnehmen, mit einem Minimum an Fitnesslevel bis hin zu den höchsten Herausforderungen, die von unseren zertifizierten Sporttrainern sorgfältig erstellt wurden."},
    "everything-happens": {GB: "Everything happens via the web and mobile applications. The only requirement is to have a camera on your mobile, laptop or computer. The freedom to participate will reach heights never seen before. Safety is our top priority and it is advisable to follow the instructions carefully, especially if you are new to fitness or sports, to avoid unnecessary injuries. Training to improve your skills to win cash prizes is highly recommended.", FR: "Tout se passe via le web et l'application mobile. La seule exigence est d'avoir une caméra sur votre mobile, votre portable ou votre ordinateur. La liberté de participation atteindra des sommets jamais vus auparavant. La sécurité est notre priorité absolue et il est conseillé de suivre attentivement les instructions, surtout si vous êtes novice en matière de fitness ou de sport, afin d'éviter les blessures inutiles. Il est fortement recommandé de s'entraîner pour améliorer ses compétences afin de gagner des prix en espèces.", DE:"Alles geschieht über das Web und die mobile App. Die einzige Voraussetzung ist, dass Sie eine Kamera auf Ihrem Handy, Laptop oder Computer haben. Die Freiheit der Teilnahme wird nie dagewesene Höhen erreichen. Sicherheit hat oberste Priorität und Sie sollten die Anweisungen sorgfältig befolgen, besonders wenn Sie ein Fitness- oder Sportneuling sind, um unnötige Verletzungen zu vermeiden. Es wird dringend empfohlen, dass Sie trainieren, um Ihre Fähigkeiten zu verbessern, damit Sie Geldpreise gewinnen können."},

    "Features": {GB: "Features", FR: "Caractéristiques", DE:"Eigenschaften"},

    "choose-among": {GB: "Choose Among Many Challenges", FR: "Choisissez parmi de nombreux défis", DE:"Wählen Sie aus einer Vielzahl von Herausforderungen."},
    "our-curated": {GB: "Our curated challenges collection is for everyone. Pick yours to beat your friends, colleagues, or people around the world!", FR: "Notre collection de défis est destinée à tous. Choisissez le vôtre pour battre vos amis, vos collègues ou des personnes du monde entier !", DE:"Unsere Sammlung von Herausforderungen ist für alle gedacht. Wähle deine eigene aus, um deine Freunde, Kollegen oder Menschen aus der ganzen Welt zu besiegen!"},

    "do-it": {GB: "Do it anywhere", FR: "Faites-le n'importe où", DE:"Tun Sie dies an jedem beliebigen Ort"},
    "bring-your": {GB: "Bring your phone, tablet or laptop with you and do your challenge anywhere in the world where there is internet.", FR: "Apportez votre téléphone, votre tablette ou votre ordinateur portable avec vous et faites votre défi partout dans le monde où il y a de l'Internet.", DE:"Nehmen Sie Ihr Telefon, Ihr Tablet oder Ihren Laptop mit und machen Sie Ihre Herausforderung überall auf der Welt, wo es Internet gibt."},

    "no-equip": {GB: "No equipment required", FR: "Aucun équipement requis", DE:"Keine Ausrüstung erforderlich"},
    "challenges-are": {GB: "The challenges are for all sports enthusiasts, regardless of location or fitness level. All you need is a camera and a little competitive spirit to get started, no fancy equipment is required.", FR: "Les défis sont destinés à tous les amateurs de sport, quel que soit le lieu ou le niveau de forme physique. Vous n'avez besoin que d'un appareil photo et d'un peu d'esprit de compétition pour commencer, sans équipement sophistiqué.", DE:"Die Herausforderungen sind für alle Sportbegeisterten gedacht, unabhängig von Ort oder Fitnessniveau. Sie brauchen nur eine Kamera und ein wenig Kampfgeist, um loszulegen, und keine ausgeklügelte Ausrüstung."},

    "play-safe": {GB: "Play Safe", FR: "Jouez en toute sécurité", DE:"Spielen Sie sicher"},
    "every-challenge": {GB: "Every challenge starts with instructions and coaching to avoid injury. After all, a challenge should not only be fun but also safe.", FR: "Chaque défi commence par des instructions et l'accompagnement d'un coach pour éviter les blessures. Après tout, un défi doit être non seulement amusant mais aussi sûr.", DE:"Jede Herausforderung beginnt mit Anweisungen und der Begleitung durch einen Coach, um Verletzungen zu vermeiden. Schließlich soll eine Challenge nicht nur Spaß machen, sondern auch sicher sein."},

    "withdraw-money": {GB: "Withdraw Money", FR: "Retirer de l'argent", DE:"Geld abheben"},
    "this-is-our": {GB: "This is our favorite part. The strongest objectives are to introduce a new way of income through the rewards of fitness competitions. Withdraw money 7 days a week with no limits at your bank.", FR: "C'est la partie que nous préférons. Les objectifs les plus forts sont d'introduire un nouveau moyen de revenus à travers les récompenses des compétitions de fitness. Retirez de l'argent 7/7 sans limite à votre banque.", DE:"Dieser Teil gefällt uns am besten. Die stärksten Ziele sind die Einführung einer neuen Einnahmequelle durch die Belohnungen von Fitnesswettbewerben. Heben Sie 7/7 ohne Limit Geld von Ihrer Bank ab."},

    "be-a-part": {GB: "Be part of a community", FR: "Faites partie d'une communauté", DE:"Werden Sie Teil einer Gemeinschaft"},
    "the-idea-of": {GB: "The idea behind Stronger is sports challenges without boundaries. Be part of a new community of warriors, sisters and brothers who share the same passion for competition... a new era has begun!", FR: "L'idée de Stronger, ce sont des défis sportifs sans frontières. Faites partie d'une nouvelle communauté de guerriers, de sœurs et de frères qui partagent la même passion de la compétition... une nouvelle ère a commencé!", DE:"Die Idee von Stronger sind sportliche Herausforderungen ohne Grenzen. Werden Sie Teil einer neuen Gemeinschaft von Kriegern, Schwestern und Brüdern, die die gleiche Leidenschaft für Wettkämpfe teilen... eine neue Ära hat begonnen!"},

    "signup": {GB: "Signup", FR: "Inscription", DE:"Anmeldung"},
    "beta-version": {GB: "Beta version will start in October 2022.", FR: "La version bêta commencera en octobre 2022.", DE:"Die Betaversion wird im Oktober 2022 beginnen."},

    "first-deposit": {GB: "Make A First Deposit", FR: "Effectuer un premier dépôt", DE:"Machen Sie eine erste Einzahlung"},
    "make-your-first": {GB: "Make Your First Deposit In Cash", FR: "Effectuez votre premier dépôt en espèces", DE:"Tätigen Sie Ihre erste Einzahlung in bar."},

    "pick-your": {GB: "Pick Your Challenge", FR: "Choisissez votre défi", DE:"Wählen Sie Ihre Herausforderung"},
    "pick-among": {GB: "Choose from a legion of mind-blowing challenges. We charge a small fee for participation using only cash. Follow Instruction and shoot for the stars", FR: "Choisissez parmi une légion de défis époustouflants. Nous facturons des frais minimes pour la participation en utilisant uniquement des espèces. Suivez les instructions et visez les étoiles", DE:"Wählen Sie aus einer Legion von atemberaubenden Herausforderungen. Wir berechnen eine geringe Gebühr für die Teilnahme, wenn Sie nur Bargeld verwenden. Folgen Sie den Anweisungen und greifen Sie nach den Sternen."},

    "win-prizes": {GB: "Win Prizes And XP Points", FR: "Gagnez des prix et des points d'XP", DE:"Gewinnen Sie Preise und XP-Punkte."},
    "when-you-take": {GB: "When you take prize places, you earn money and experience (XP) points that lead you to a pro level", FR: "Lorsque vous remportez des prix, vous gagnez de l'argent et des points d'expérience (XP) qui vous permettent d'accéder à un niveau pro.", DE:"Wenn Sie Preise gewinnen, verdienen Sie Geld und Erfahrungspunkte (XP), mit denen Sie auf ein Pro-Level aufsteigen können."},

    "ai": {GB: "AI", FR: "La", DE:"Die"},
    "technology": {GB: "Technology", FR: "technologie de l'IA", DE:"KI-Technologie"},
    "ai-tech": {GB: "AI Tecnologies with", FR: "Les technologies d'IA avec", DE:"KI-Technologien mit"},
    "stronger-fitness": {GB: " - Stronger fitness challenges use popular exercises like push-ups, burpees, plank and more that consists in having precise movements and technique that are critical for the success of the challenge, which improves the health of the body both mentally and physically.", FR: " - Les défis de fitness plus forts utilisent des exercices populaires comme les pompes, les burpees, la planche et plus encore qui consistent à avoir des mouvements et une technique précise qui sont essentiels pour la réussite du défi, ce qui améliore la santé du corps à la fois mentalement et physiquement.", DE:" - Stärkere Fitness-Herausforderungen verwenden beliebte Übungen wie Liegestütze, Burpees, Planken und mehr, bei denen es darum geht, präzise Bewegungen und Techniken zu haben, die für den Erfolg der Herausforderung entscheidend sind, was die Gesundheit des Körpers sowohl geistig als auch körperlich verbessert."},
    "by-using": {GB: "By using the Stronger app, users can determine if they are performing the moves correctly by using AI to track their movements.", FR: "En utilisant l'application Stronger, les utilisateurs peuvent déterminer s'ils effectuent les mouvements correctement en utilisant l'IA pour suivre leurs mouvements.", DE:"Mithilfe der Stronger-App können die Nutzer feststellen, ob sie die Bewegungen korrekt ausführen, indem sie die KI zur Verfolgung ihrer Bewegungen einsetzen."},    

    "our": {GB: "Our", FR: "Nos", DE:"Unsere"},
    "partners": {GB: "Partners", FR: "partenaires", DE:"Partner"},

    "what-xp": {GB: "What are XP points?", FR: "Que sont les points XP?", DE:"Was sind XP-Punkte?"},
    "xp-are": {GB: "XP points are points of experience that users get when they participate in a challenge.", FR: "Les points XP sont des points d'expérience que les utilisateurs obtiennent lorsqu'ils participent à un défi.", DE:"XP-Punkte sind Erfahrungspunkte, die Nutzer erhalten, wenn sie an einer Herausforderung teilnehmen."},

    "what-stronger": {GB: "What is Stronger mission?", FR: "Quelle est la mission de Stronger?", DE:"Was ist die Mission von Stronger?"},
    "stronger-team": {GB: "Stronger team has the mission to decentralise online competitions in order to give access to anyone around the world to participate in a competition and win prizes.", FR: "L'équipe de Stronger a pour mission de décentraliser les compétitions en ligne afin de permettre à toute personne dans le monde de participer à des challenges en fitness et de gagner des prix.", DE:"Das Stronger-Team hat es sich zur Aufgabe gemacht, Online-Wettbewerbe zu dezentralisieren, damit jeder Mensch auf der Welt an Fitness-Challenges teilnehmen und Preise gewinnen kann."},

    "what-levels": {GB: "What are levels used for?", FR: "A quoi servent les niveaux?", DE:"Wozu dienen die Levels?"},
    "levels-are-used": {GB: "Levels are used to k your performance in the challenges. They also limit the number of participants. For example, in level 1, the user can only compete with 10 people in a challenge. In level 2, the user can choose to compete with 20 people in the same challenge. XP points are used in combination with Levels. When the user wins a challenge in the 3 first ranking positions, he gets XP points to complete level 1.", FR: "Les niveaux sont utilisés pour suivre vos performances dans les défis. Ils limitent également le nombre de participants. Par exemple, au niveau 1, l'utilisateur ne peut affronter que 10 personnes dans un défi. Au niveau 2, l'utilisateur peut choisir d'affronter 20 personnes dans le même défi. Les points XP sont utilisés en combinaison avec les niveaux. Lorsque l'utilisateur gagne un défi dans les 3 premières positions du classement, il obtient des points XP pour compléter le niveau 1.", DE:"Levels werden verwendet, um Ihre Leistungen in den Herausforderungen zu verfolgen. Außerdem begrenzen sie die Anzahl der Teilnehmer. Auf Stufe 1 kann der Nutzer z. B. nur gegen 10 Personen in einer Herausforderung antreten. Auf Stufe 2 kann der Nutzer wählen, ob er gegen 20 Personen in derselben Herausforderung antreten möchte. XP-Punkte werden in Kombination mit den Levels verwendet. Wenn der Nutzer eine Herausforderung in den ersten drei Positionen der Rangliste gewinnt, erhält er XP-Punkte, um Level 1 abzuschließen."},

    "what-training": {GB: "What is the training room?", FR: "Qu'est-ce que la salle d'entraînement?", DE:"Was ist der Trainingsraum?"},
    "we-use-the": {GB: "We use the training room to train for challenges; they are free of use for a limited number of 3 times per day. Training is essential to improve the skills during the challenges. The more you train more muscular and fast you will be", FR: "Nous utilisons la salle d'entraînement pour nous former aux défis ; elle est libre d'utilisation pour un nombre limité de 3 fois par jour. L'entraînement est essentiel pour améliorer les compétences lors des challenges. Plus vous vous entraînez, plus vous serez musclé et rapide.", DE:"Wir nutzen den Trainingsraum, um uns für die Herausforderungen zu trainieren; er ist für eine begrenzte Anzahl von 3 Mal pro Tag frei nutzbar. Das Training ist entscheidend, um die Fähigkeiten bei den Herausforderungen zu verbessern. Je mehr Sie trainieren, desto muskulöser und schneller werden Sie."},

    "how-to": {GB: "How to participate in a challenge?", FR: "Comment participer à un défi?", DE:"wie kann ich an einer Herausforderung teilnehmen?"},
    "first-challenge": {GB: "First challenge have participation fees for registration. You have to make a deposit in your account with a credit card or paypal or direct bank transfer. Once you have the money deposited in your account you can go the challenge page on \"Daily challenge\" to register and start a challenge", FR: "Le premier défi est celui des frais de participation à l'inscription. Vous devez faire un dépôt sur votre compte avec une carte de crédit ou paypal ou un transfert bancaire direct. Une fois l'argent déposé sur votre compte, vous pouvez vous rendre sur la page du défi dans la rubrique \"Défi quotidien\" pour vous inscrire et commencer un défi.", DE:"Bei der ersten Herausforderung fallen Teilnahmegebühren für die Anmeldung an. Sie müssen eine Einzahlung auf Ihr Konto mit einer Kreditkarte oder Paypal oder direkte Banküberweisung machen. Sobald Sie das Geld auf Ihr Konto eingezahlt haben, können Sie die Seite \"Tägliche Herausforderung\" aufrufen, um sich zu registrieren und eine"},

    "privacy-policy": {GB: "Privacy Policy", FR: "Politique de confidentialité", DE:"Datenschutzbestimmungen"},
    "terms-conditions": {GB: "Terms & Conditions", FR: "Termes et Conditions", DE:"Bedingungen und Konditionen"},

    "stronger-is-a": {GB:"Stronger is a fitness software company and open-minded place, created for and by people who believe they can make an impact.", FR:"Stronger est une société de logiciels de fitness et un lieu ouvert d'esprit, créé pour et par des personnes qui pensent pouvoir avoir un impact.", DE:"Stronger ist ein Unternehmen für Fitnesssoftware und ein aufgeschlossener Ort, geschaffen für und von Menschen, die glauben, dass sie etwas bewirken können."},
    "our-mission-is": {GB:"Our mission is to change millions of lifes by giving access to everyone who wants to reach his full potential in fitness and get rewarded.", FR:"Notre mission est de changer des millions de vies en donnant accès à tous ceux qui veulent atteindre leur plein potentiel en fitness et être récompensés.", DE:"Unsere Mission ist es, Millionen von Leben zu verändern, indem wir jedem, der sein volles Potenzial im Bereich Fitness ausschöpfen und belohnt werden möchte, den Zugang dazu ermöglichen."},

    
    "great-joys": {GB:"\"great joys come from heaven,", FR:"\"Les grandes joies viennent du ciel,", DE:"\"große freuden kommen vom himmel,"},
    "small-joys": {GB:"small joys come from effort\"", FR:"les petits bonheurs viennent de l'effort\"", DE:"kleine Freuden kommen von der Anstrengung\""},

    "privacy-policy-contents": {GB:"", FR:'', DE:''},
    "terms-conditions-contents": {GB:"", FR:'', DE:''},

    "Strength": {GB:"Strength", FR:'Force', DE:'Stärke'},
    "Cardio": {GB:"Cardio", FR:'Cardio', DE:'Kardio'},

    "Description": {GB: "Description", FR: "Description", DE:"Beschreibung"},
    "Rules": {GB: "Rules", FR: "Règles", DE:"Regeln"},
    "Best performer": {GB: "Best performer", FR: "Meilleur performer", DE:"Bessere Leistung"},
    "Best Result": {GB: "Best Result", FR: "Meilleur résultat", DE:"Bessere Ergebnisse"},
    "Warm ups": {GB: "Warm ups", FR: "Échauffements", DE:"Aufwärmübungen"},
    "Finish Challenge": {GB: "Finish Challenge", FR: "Défi achevé", DE:"Herausforderung abschließen"},
};