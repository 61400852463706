import react, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";
import useRequestV2 from "../../../hooks/useRequestV2"
import { useNavigate } from "react-router-dom";
// import "../../../../public/assets/css/style.css";
// import { toast } from "react-toastify";

const Verifycode = () => {
  const [otp, setOtp] = useState("");
  const { request, response } = useRequestV2();
  const location = useLocation();
  const navigate = useNavigate();
  const [isResendActive, setIsResendActive] = useState(false);
  const [resendAvailableCounter, setResendAvailableCounter] = useState(60);
  const { request: resendOtpReq, response: resendOtpRes } = useRequestV2();
console.log(location)
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      forgot_password_otp: otp,
      uuid_string: location.state.id,
      price_id:location?.state?.hash,
    };
    request("POST", "verify-client/", data);
  };
  console.log(location?.state?.hash) 

  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log('location.state.id',location?.state?.id)

    if(location.state && location.state.id){

    }else{
      navigate("/corporation/client/register");
    }
  }, [location]);

  const resendOtpHandler = () => {
    if (isResendActive) {
      resendOtpReq("POST", `v1/auth/${location.state.id}/resend-otp`);
    }
  };

  useEffect(() => {
    if (resendOtpRes) {
      if (resendOtpRes?.status === "success") {
        navigate(`v1/auth/verify-user/${resendOtpRes?.data?.uuid_string}`);
        setResendAvailableCounter(60);
        setIsResendActive(false);
      }
    }
  }, [resendOtpRes]);
  
  useEffect(() => {
    if (response) {
      if (response.status === "success") {
        // alert(response.message);
        // window.location.href = response.data.url
        // navigate(response.data.url, {
        //   state: { id: location?.state?.id, hash: location?.state?.hash },
        // });
      } else if (response.status === "fail") {
        //alert(response.message);
      }
    }
  }, [response]);

  useEffect(() => {
    if (!isResendActive) {
      if (resendAvailableCounter <= 0) {
        setIsResendActive(true);
      } else {
        setTimeout(() => {
          setResendAvailableCounter(resendAvailableCounter - 1);
        }, 1000);
      }
    }
  }, [resendAvailableCounter]);

  return (
    <>
      <div className="loader-wrapper" style={{ display: "none" }}>
        <div className="loader">
          <img src="img/logo.png" alt="" />
        </div>
      </div>
      <div
        className="verify_container"
        style={{ backgroundImage: `url(img/yogabg.png)` }}
      >
        <img className="right_doted" src="img/doted.png" alt="right img" />
        <div className="container-fluid">
          <div className="main_container">
            <div className="logo_block">
              <img src="img/full-logo.png" alt="logo white" />
            </div>
            <div className="center_blog">
              <div className="inner_form_block">
                <h1>proceed with email verificaiton</h1>
                <p>A six digit OTP was sent to the registered email address</p>
                <form onSubmit={handleSubmit}>
                  <div className="digit_code_input">
                    <OtpInput
                      className="otpBox"
                      style={{ width: "90px" }}
                      value={otp}
                      onChange={(value) => {
                        setOtp(value);
                      }}
                      numInputs={6}
                      separator={<span></span>}
                      isInputNum={true}

                      // isInputSecure
                    />
                  </div>
                  <button type="submit" className="btn conrim_btn">
                    Confirm
                  </button>
                </form>

                <div className="resend-otp mt-5 fw-bold">
                  {!isResendActive && (
                    <div className="timer_block">{resendAvailableCounter}</div>
                  )}
                  <span
                    className={`resend-otp-text ${
                      isResendActive ? "resend-active" : ""
                    }`}
                    onClick={resendOtpHandler}
                  >
                    Resend OTP
                  </span>
                  {/* {
                                    !isResendActive &&
                                    <span> {resendAvailableCounter}</span>
                                } */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verifycode;
