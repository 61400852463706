import react, { useState, useEffect } from "react";
import Header from "../components/Header";
import { Link , useNavigate } from "react-router-dom";
import useRequestV2 from "../../../hooks/useRequestV2";

const ArticlesVer = () => {
  const navigate = useNavigate()
  const [activities, setActivities] = useState();
  const [ActivitiesFirstRow,setActivitiesFirstRow]=useState('')
  const [ActivitiesFirstRow1,setActivitiesFirstRow1]=useState('')
  const [ActivitiesFirstRow2,setActivitiesFirstRow2]=useState('')
  const [ActivitiesFirstRow3,setActivitiesFirstRow3]=useState('')
  const [ActivitiesFirstRow4,setActivitiesFirstRow4]=useState('')
  const [ActivitiesFirstRow5,setActivitiesFirstRow5]=useState('')


  const { request, response } = useRequestV2();
  useEffect(() => {
    request("GET", `activities`);
  }, []);
  useEffect(() => {
    if (response) {
      setActivities(response);
      setActivitiesFirstRow(response?.data[0])
      setActivitiesFirstRow1(response?.data[1])
      setActivitiesFirstRow2(response?.data[2])
      setActivitiesFirstRow3(response?.data[3])
      setActivitiesFirstRow4(response?.data[4])
      setActivitiesFirstRow5(response?.data[5])
      // console.log(response);
    }
  }, [response]);

const small1 =()=>
{
  navigate(`/activities-details/${ActivitiesFirstRow1.id}`)
}
const small2 =()=>
{
  navigate(`/activities-details/${ActivitiesFirstRow2.id}`)
}
const small3 =()=>
{
  navigate(`/activities-details/${ActivitiesFirstRow3.id}`)
}
const small4 =()=>
{
  navigate(`/activities-details/${ActivitiesFirstRow4.id}`)
}

  return (
    <>
      {""} <Header />
      <section className="aticle_ver_section">
        <div className="container-fluid">
          <div className="mb-4">
            <h2 className="Sessions-name">Our activities</h2>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <Link  to={`/activities-details/${ActivitiesFirstRow.id}`}>
                <div
                  className="big_bg"
                  style={{ backgroundImage: `url(${ActivitiesFirstRow.activity_image})` }}
                >
                  <div className="inner_details_test">
                    <h2>{ActivitiesFirstRow.activity_title}</h2>
                    <p>{ActivitiesFirstRow.activity_description}</p>
                  </div>
                </div>
              </Link>
              <div className="small_img_block">
                <div className="small_bg_1" onClick={small1}  style={{ backgroundImage: `url(${ActivitiesFirstRow1.activity_image})`, cursor:"pointer" }} >
                

                  <div>
                  <div className="inner_small_details_test">
                  <h2>{ActivitiesFirstRow1.activity_title}</h2>
                    <p>{ActivitiesFirstRow1.activity_description}</p>
                  </div>

                  </div>

                </div>
                <div className="small_bg_2"  onClick={small2}  style={{ backgroundImage: `url(${ActivitiesFirstRow2.activity_image})`, cursor:"pointer" }}>
                  
                  <div className="inner_small_details_test">
                  <h2>{ActivitiesFirstRow2.activity_title}</h2>
                    <p>{ActivitiesFirstRow2.activity_description}</p>
                  </div>
                  </div>

              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="small_img_block bottom_space_img">
          

              <div  className="small_bg_1" onClick={small3} style={{ backgroundImage: `url(${ActivitiesFirstRow3.activity_image})`, cursor:"pointer" }} >

                  <div className="inner_small_details_test">
                  <h2>{ActivitiesFirstRow3.activity_title}</h2>
                    <p>{ActivitiesFirstRow3.activity_description}</p>
                  </div>


                </div>

                <div   className="small_bg_2" onClick={small4}  style={{ backgroundImage: `url(${ActivitiesFirstRow4.activity_image})`, cursor:"pointer" }}>
                  <div className="inner_small_details_test">
                  <h2>{ActivitiesFirstRow4.activity_title}</h2>
                    <p>{ActivitiesFirstRow4.activity_description}</p>
                  </div>
                  </div>
                

              </div>
              <Link  to={`/activities-details/${ActivitiesFirstRow5.id}`}>
                <div
                  className="big_bg"
                  style={{ backgroundImage: `url(${ActivitiesFirstRow5.activity_image})` }}
                >
                  <div className="inner_details_test">
                    <h2>{ActivitiesFirstRow5.activity_title}</h2>
                    <p>{ActivitiesFirstRow5.activity_description}</p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ArticlesVer;
