import "./../../css/challenge.css"
import { useEffect, useState } from "react"
import "react-input-range/lib/css/index.css"

import OngoingChallenge from "./OngoingChallenge";
import PastChallege from "./PastChallenge"
import TrainingChallenge from "./TrainingChallenge";
import { MultiLang } from './../MultiLang';
import { useSelector } from "react-redux";
import { getQueryObj } from "../../helpers/getQueryObj"
import { useLocation, useNavigate } from "react-router-dom";
import ChallengeSomeone from "./ChallengeSomeone";

const tabs = [
    "ongoing",
    "past",
    "training",
    "challenge-someone",
];

const ViewAll = () => {
    const navigate = useNavigate()
    const { search } = useLocation();
    let tab;
    if (!search) {
        navigate('/challenge?tab=ongoing')
    } else {
        const queryObj = getQueryObj(search)
        tab = queryObj.tab
    }

    const [activeTab, setActiveTab] = useState(tab ? tab : 'ongoing');
    const { language } = useSelector((state) => state.reducer);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="_profile _challenge">
            <div className="profile-header challenge-header">
                <div className="content challenge-header-main">
                    <div className="main-title">{MultiLang["Challenge"][language]}</div>
                </div>
                <div className="challenge-tab-con">
                    <div className="content">
                        <div
                            className={activeTab === 'ongoing' ? "challenge-tab active" : "challenge-tab"}
                            onClick={() => {
                                setActiveTab('ongoing')
                                navigate('/challenge?tab=ongoing')
                            }}
                        >
                            {MultiLang["Daily Challenges"][language]}
                        </div>
                        {/* <div
                            className={activeTab === 'challenge-someone' ? "challenge-tab active" : "challenge-tab"}
                            onClick={() => {
                                setActiveTab('challenge-someone')
                                navigate('/challenge?tab=challenge-someone')
                            }}
                        >
                            {MultiLang["Challenge Someone"][language]}
                        </div> */}
                        <div
                            className={activeTab === 'past' ? "challenge-tab active" : "challenge-tab"}
                            onClick={() => {
                                setActiveTab('past')
                                navigate('/challenge?tab=past')
                            }}
                        >
                            {MultiLang["Past"][language]}
                        </div>
                        <div
                            className={activeTab === 'training' ? "challenge-tab active" : "challenge-tab"}
                            onClick={() => {
                                setActiveTab('training')
                                navigate('/challenge?tab=training')
                            }}
                        >
                            {MultiLang["Training"][language]}
                        </div>
                    </div>
                </div>
            </div>
            {
                activeTab === 'ongoing' &&
                <OngoingChallenge />
            }
            {/* {
                activeTab === 'challenge-someone' &&
                <ChallengeSomeone />
            } */}
            {
                activeTab === 'past' &&
                <PastChallege />
            }
            {
                activeTab === 'training' &&
                <TrainingChallenge />
            }
        </div>
    )
}



export default ViewAll;