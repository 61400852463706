import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import useRequest from "../../../hooks/useRequest";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";

const ClientChangePassword = () => {
  const { request, response } = useRequest();

  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  let uuid_string = location?.state?.id;
  //   console.log(uuid_string , "string")
  const formik = useFormik({
    initialValues: {
      new_password: '',
      confirm_new_password: '',
    },
    validationSchema: Yup.object({
      new_password: Yup.string()
        .required("Please Enter Your Password")
        .min(8, "Password is too short - should be 8 chars minimum.")
        .matches(
          /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$/,
          "Password must contain Uppercase, lowercase, number and special charecter."
        ),
      confirm_new_password: Yup.string()
        .required("Please Enter Your Password")
        .oneOf(
          [Yup.ref("new_password"), null],
          "Both password need to be the same"
        ),
    }),
    onSubmit: (values) => {
      if (!otp || otp.length !== 6) {
        setOtpError("Please Enter a valid OTP");
        return;
      }
      let data = {
        forgot_password_otp: otp,
        new_password: values.new_password,
        confirm_new_password: values.confirm_new_password,
        uuid_string: uuid_string,
      };
      request("POST", "/auth/reset-password/", data);
    },
  });

  useEffect(() => {
    if (response) {
      if (response.status == "success") {
        toast.success(response.message);
        navigate("/client-login");
      } else if (response.status == "error") {
        toast.error(response.message);
      }
    }
  }, [response]);

  return (
    <>
      <div className="_auth">
        <div className="auth-main _login">
          <form className="auth-input" onSubmit={formik.handleSubmit}>
            <div className="auth-input-con">
              <div className="auth-input-header">Reset Password</div>

              <div className="auth-input-list resetpassword_otp ">
                <label htmlFor="">
                  Forgot Password OTP (sent on registered email address)
                </label>
                <OtpInput
                  className="otpBox"
                  value={otp}
                  onChange={(value) => {
                    setOtp(value);    
                    setOtpError(null);
                  }}
                  numInputs={6}
                  separator={<span></span>}
                  autoComplete={false}
                />
              </div>
              {otpError && <div className="input-error">{otpError}</div>}

              <div className="auth-input-list">
                <div className="recover-pwd">
                  <label htmlFor="">New Password</label>
                </div>
                <input
                  name="new_password"
                  {...formik.getFieldProps("new_password")}
                  type="password"
                  placeholder="-"
                  autoComplete="off"
                />
              </div>
              {formik.touched.new_password && formik.errors.new_password && (
                <div className="input-error">{formik.errors.new_password}</div>
              )}

              <div className="auth-input-list">
                <div className="recover-pwd">
                  <label htmlFor="">Confirm New Password</label>
                </div>
                <input
                  name="confirm_new_password"
                  {...formik.getFieldProps("confirm_new_password")}
                  type="password"
                  placeholder="-"
                  autoComplete="off"
                />
              </div>
              {formik.touched.confirm_new_password &&
                formik.errors.confirm_new_password && (
                  <div className="input-error">
                    {formik.errors.confirm_new_password}
                  </div>
                )}

              <div className="auth-input-list">
                <button type="submit" className="btn auth-main-btn">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ClientChangePassword;
