import { useEffect, useState } from "react";
import React from "react";
import "./../css/about.css";
// import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Swiper, SwiperSlide } from 'swiper/react';
// import "swiper/css/scrollbar";
import { Navigation, Pagination, Mousewheel, Keyboard, A11y } from "swiper";
import useRequest from "../hooks/useRequest";
import PreloaderOuter from "./components/preloaderOuter";
import { useSelector } from "react-redux";
import { MultiLang } from "./MultiLang";
import {Link} from "react-router-dom";

export default function About() {
  const { request, response } = useRequest();
  const { settings } = useSelector(state => state.Site)
  const { loading } = useSelector(state => state.Auth)
  // const [isData, setIsData] = useState(false);
  const [aboutUsData, setAboutUsData] = useState(null);
  const [teamsData, setTeamsData] = useState(null);
  const [partnerData, setPartnerData] = useState(null);
  const [successStorysData, setSuccessStorysData] = useState(null);
  const { language } = useSelector((state) => state.reducer);

  useEffect(() => {
    window.scrollTo(0, 0);
    request("GET", "/about-us");
  }, []);

  useEffect(() => {
    if (response) {
      // setIsData(true);
      setAboutUsData(response?.aboutUsData);
      setTeamsData(response?.teamsData);
      setPartnerData(response?.partnerData);
      setSuccessStorysData(response?.successStorysData);
    }
  }, [response]);

  return loading ? (
    <PreloaderOuter />
  ) : (
    <div className="_about">
      {aboutUsData && (
        <>
           <header className="landing-header" id="header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <nav className="navbar navbar-expand-lg">
                    <Link className="navbar-brand" to="/">
                      <img src="img/full-logo.png" alt="" />
                    </Link>

                    <button className="navbar-toggler" type="button">
                      <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse">
                      <ul className="navbar-nav ms-auto">
                        <li className="nav-item active">
                          <Link className="nav-link" to="/">
                            Corporate Wellness
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/coaches">
                            Coaches
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/fitness-competition">
                            Fitness Competition
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/about">
                            About
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/contact">
                            Contact Us
                          </Link>
                        </li>
                        {/* <li className="nav-item">
                        <a className="nav-link" href="javascript:void(0);">
                          School Competition
                        </a>
                      </li> */}

                        {/* <li className="nav-item">
                                    <a className="nav-link" href="javascript:void(0);">Login</a>
                                </li> */}
                        <li className="nav-item">
                          <Link className=" sign-up-btn" to="/accounts">
                            Login
                          </Link>
                        </li>
                      </ul>
                    </div>

                    {/* ======= User DropDown =========   */}

                    {/* <div className="nav-item dropdown user_dropdown">
                            <a className="nav-link dropdown-toggle" href="javascript:void(0);" id="user-drop" role="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img src="img/user.png" alt="" />
                            </a>
                            <div className="dropdown-menu" aria-labelledby="user-drop">
                                <div className="user_info">
                                    <div className="user_name">
                                        <div>Sandeep Prajapati</div>
                                        <div className="user_email">
                                            <small>Sandeep@gmail.com</small>
                                        </div>
                                    </div>
                                    <ul>
                                        <li>
                                            <a href="#!"><i className="ion-android-person"></i> My Profile</a>
                                        </li>
                                        <li>
                                            <a href="#!"><i className="ion-log-out"></i> Logout</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                  </nav>
                </div>
              </div>
            </div>
          </header>
          <section className="about-top" >
            <img src={aboutUsData?.image} alt="" />
          </section>
          <section className="stronger">
            <div className="content">
              <div className="stronger-team">
                <div className="stronger-description-text">
                  {/* <div className="title">{aboutUsData?.heading}</div> */}
                  <div className="title">{MultiLang["About Us"][language]}</div>
                  {/* <div
                    dangerouslySetInnerHTML={{
                      __html: aboutUsData?.description,
                    }}
                  /> */}
                  <div>
                  {MultiLang["stronger-is-a"][language]}
                  {MultiLang["our-mission-is"][language]}
                  </div>
                  <br />
                  <br />
                  {/* <div
                    dangerouslySetInnerHTML={{ __html: aboutUsData?.quote }}
                  /> */}
                  <div>
                  {MultiLang["great-joys"][language]}<br />
                  {MultiLang["small-joys"][language]}
                  </div>
                  <div>

                  </div>
                  <br />
                  <br />
                  <div>{aboutUsData?.quote_by}</div>
                </div>
                <div className="line"></div>
                <h1 className="team-title">{MultiLang["Team"][language]}</h1>
                <div className="member">
                  {teamsData &&
                    teamsData.map((member, i) => {
                      return (
                        <div key={i} className="team-member">
                          <div className="team-member-img">
                            <img src={member?.image} alt="" />
                          </div>
                          <div className="team-member-name">{member?.name}</div>
                          <div className="team-member-position">
                            {member?.designation}
                          </div>
                          <div>
                            <a
                              href={member?.social_link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                className="linkedin"
                                src="./images/about/linkedin.png"
                                alt=""
                              />
                            </a>
                          </div>
                          <div className="team-member-description">
                            {member?.description}
                          </div>
                        </div>
                      );
                    })}
                </div>

              </div>{/*
                <div className="stronger-info">
                  <div className="stronger-info-con">
                    <div className="">{settings?.Founded}</div>
                    <div>founded</div>
                  </div>
                  <div className="stronger-info-con">
                    <div>{settings?.totalteam}</div>
                    <div>Team Members</div>
                  </div>
                  <div className="stronger-info-con">
                    <div>{settings?.totalUser}</div>
                    <div>Users</div>
                  </div>
                  <div className="stronger-info-con">
                    <div>{settings?.aapStoreRating}</div>
                    <div>App Store Rating</div>
                  </div>
                    </div>*/}
            </div>
          </section>
        </>
      )}
      {/*}
        {partnerData && (
          <section className="success section-padding-top">
            <div className="main-title">Our Partners</div>
            <div className="partners">
              {partnerData.map((val, i) => {
                return <img key={i} src={val?.image} alt="" />;
              })}
            </div>
          </section>
            )}*/}
      {successStorysData && (
        <section className="success section-padding-top">
          <div className="content">
            <div className="main-title " style={{ color: 'white' }}>{MultiLang["Success Stories"][language]}</div>
          </div>
          <div className="about-swiper">
            <Swiper
              modules={[Navigation, Pagination, Mousewheel, Keyboard, A11y]}
              spaceBetween={100}
              cssMode={true}
              navigation={true}
              pagination={{ clickable: true }}
              // mousewheel={true}
              // keyboard={true}
              className="mySwiper"
            >
              {successStorysData.map((val, i) => {
                return (
                  <SwiperSlide key={i}>
                    <img src={val?.image} alt="" />
                    <div className="swiper-man-name" style={{ color: 'black' }}>{val?.heading}</div>
                    <div className="swiper-coach-name"></div>
                    <div className="swiper-coach-content"></div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </section>
      )}
    </div>
  );
}
