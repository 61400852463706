import react, { useEffect } from "react";
import Header from "../components/Header";
import useRequestV2 from "../../../hooks/useRequestV2";
import { Link } from "react-router-dom";

const Coaches = () => {
  const { request: getplansreq, response: getplansres } = useRequestV2();

  useEffect(() => {
    getplansreq("GET", `coaches`);
  }, []);
  // useEffect(() => {
  //   if (getplansres) {
  //     console.log(getplansres?.data);
  //   }
  // }, [getplansres]);
  return (
    <>
      <Header />
      <section className="our_coaches_section position-relative">
        <div className="container">
          <div className="text-center">
            <h2 className="Sessions-name">our coaches</h2>
          </div>
          <div className="row top_space g-4">
            {getplansres?.data.map((ele, index) => (
              <div
                className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3"
                key={index}
              >
                <div className="back_round_block"></div>

                <div className="coaches_inner_block">
                  <div
                    className="coaches_profile_img"
                    style={{
                      backgroundImage: `url(${ele?.id_user.profile_image})`,
                    }}
                  >
                    {/* <img src={ele?.id_user.profile_image} alt="#" /> */}
                  </div>
                  <div className="coaches_detais text-center">
                    <h3>
                      {ele?.id_user.profile_first_name}{" "}
                      {ele?.id_user.profile_last_name}
                    </h3>
                    <p className="Coach_profile_info">
                      {ele.activities.map((activities, index) => {
                        return (
                          <small key={index} className="">
                            {activities.activity_title} &nbsp;&nbsp;
                          </small>
                        );
                      })}
                    </p>
                    <div>
                      <Link
                        className="theme-btn ms-auto"
                        to={`/coaches-version2/${ele?.id}`}
                      >
                        {" "}
                        View Profile
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
              <div className="coaches_inner_block">
                <div className="coaches_profile_img">
                  <div className="back_round_block"></div>
                  <img src="img/coaches-img2.png" alt="#" />
                </div>
                <div className="coaches_detais text-center">
                  <h3>Jessica</h3>
                  <p>Pilates</p>
                  <a className="theme-btn ms-auto" href="/see-calender">
                    {" "}
                    View Profile
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
              <div className="coaches_inner_block">
                <div className="coaches_profile_img">
                  <div className="back_round_block"></div>
                  <img src="img/coaches-img3.png" alt="#" />
                </div>
                <div className="coaches_detais text-center">
                  <h3>james</h3>
                  <p>Yoga</p>
                  <a className="theme-btn ms-auto" href="/see-calender">
                    {" "}
                    View Profile
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
              <div className="coaches_inner_block">
                <div className="coaches_profile_img">
                  <div className="back_round_block"></div>
                  <img src="img/coaches-img4.png" alt="#" />
                </div>
                <div className="coaches_detais text-center">
                  <h3>andrew</h3>
                  <p>Hiit</p>
                  <a className="theme-btn ms-auto" href="/see-calender">
                    {" "}
                    View Profile
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
              <div className="coaches_inner_block">
                <div className="coaches_profile_img">
                  <div className="back_round_block"></div>
                  <img src="img/coaches-img5.png" alt="#" />
                </div>
                <div className="coaches_detais text-center">
                  <h3>zack</h3>
                  <p>Pilates</p>
                  <a className="theme-btn ms-auto" href="/see-calender">
                    {" "}
                    View Profile
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
              <div className="coaches_inner_block">
                <div className="coaches_profile_img">
                  <div className="back_round_block"></div>
                  <img src="img/coaches-img6.png" alt="#" />
                </div>
                <div className="coaches_detais text-center">
                  <h3>dave</h3>
                  <p>Gym</p>
                  <a className="theme-btn ms-auto" href="/see-calender">
                    {" "}
                    View Profile
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
              <div className="coaches_inner_block">
                <div className="coaches_profile_img">
                  <div className="back_round_block"></div>
                  <img src="img/coaches-img7.png" alt="#" />
                </div>
                <div className="coaches_detais text-center">
                  <h3>johnson</h3>
                  <p>Cardio</p>
                  <a className="theme-btn ms-auto" href="/see-calender">
                    {" "}
                    View Profile
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
              <div className="coaches_inner_block">
                <div className="coaches_profile_img">
                  <div className="back_round_block"></div>
                  <img src="img/coaches-img8.png" alt="#" />
                </div>
                <div className="coaches_detais text-center">
                  <h3>coulson</h3>
                  <span>
                    Coulson has been trainer for 6 years and is specialiszed in
                    cardio activities
                  </span>
                  <a className="theme-btn ms-auto" href="/see-calender">
                    {" "}
                    View Profile
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Coaches;
