import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { useForm } from "react-hook-form";
import useRequestV2 from "../../../../hooks/useRequestV2";
import { Rating } from "react-simple-star-rating";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const SendMessage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = useForm();
  const [rating, setRating] = useState(0);
  const navigate = useNavigate();
  const { request: requestReview, response: responseReview } = useRequestV2();

  const onSubmit = (data) => {
    const { comment, ratings } = data;

    if (ratings == 0) {
      setError("ratings", {
        type: "ratingErr",
      });
      return;
    }
    requestReview("POST", "corporation-review", {
      rating_count: ratings,
      messages: comment,
    });
  };

  useEffect(() => {
    if (responseReview) {
      if (responseReview.status == "success") {
        toast.success("Corporation review has been submitted successfully.");
        navigate("/dashboard");
      } else {
        toast.error(responseReview.message);
      }
    }
  }, [responseReview]);

  const handleRating = (rate: number) => {
    setRating(rate);
    setValue("ratings", rate);
  };
  return (
    <>
      <Sidebar />
      <div className="page_container chat-container">
        <div className="main_content">
          <h2 className="page-heading-form">Form</h2>
          <div className="row walletSearchRow container-center_row">
            <div className="col-12 col-lg-6  ">
              <div className="Get-touch_form">
                <h5 className="Get-touch-title">Message</h5>
                <div className="Get-touch-info">
                  We are here for you! How can we help?
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="message-rating-box">
                    <span className="rating-tilte"> Please rate us</span>
                    <Rating onClick={handleRating} initialValue={rating} />
                    <input
                      type="hidden"
                      defaultValue={rating}
                      {...register("ratings")}
                    />
                    <span>
                      {/* <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.145 0L13.1524 6.09317L19.8768 7.07122L15.0109 11.8125L16.1597 18.5098L10.145 15.3481L4.13316 18.5098L5.27918 11.8125L0.416016 7.07122L7.13774 6.09317L10.145 0Z" fill="#D2D2D2"/>
                                        </svg>
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.145 0L13.1524 6.09317L19.8768 7.07122L15.0109 11.8125L16.1597 18.5098L10.145 15.3481L4.13316 18.5098L5.27918 11.8125L0.416016 7.07122L7.13774 6.09317L10.145 0Z" fill="#D2D2D2"/>
                                        </svg>
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.145 0L13.1524 6.09317L19.8768 7.07122L15.0109 11.8125L16.1597 18.5098L10.145 15.3481L4.13316 18.5098L5.27918 11.8125L0.416016 7.07122L7.13774 6.09317L10.145 0Z" fill="#D2D2D2"/>
                                        </svg>
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.145 0L13.1524 6.09317L19.8768 7.07122L15.0109 11.8125L16.1597 18.5098L10.145 15.3481L4.13316 18.5098L5.27918 11.8125L0.416016 7.07122L7.13774 6.09317L10.145 0Z" fill="#D2D2D2"/>
                                        </svg>
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.145 0L13.1524 6.09317L19.8768 7.07122L15.0109 11.8125L16.1597 18.5098L10.145 15.3481L4.13316 18.5098L5.27918 11.8125L0.416016 7.07122L7.13774 6.09317L10.145 0Z" fill="#D2D2D2"/>
                                        </svg> */}

                      {/* <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.72904 0L12.7363 6.09317L19.4581 7.07122L14.5949 11.8125L15.7436 18.5098L9.72904 15.3481L3.71442 18.5098L4.86316 11.8125L0 7.07122L6.72173 6.09317L9.72904 0Z" fill="#FFD966"/>
                                        </svg> */}
                    </span>
                    {errors.ratings?.type === "ratingErr" && (
                      <div className="invalid-field text-danger fw-bold">
                        The rating field is required.
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control chat-textarea"
                          placeholder="Leave a comment here"
                          id="comment"
                          style={{ height: "100px" }}
                          {...register("comment", {
                            required: true,
                          })}
                        ></textarea>
                        <label for="comment">Write your comment...</label>
                      </div>
                      {/* <div className="send-message-btn_box">
                        <a href="#" className="">
                          <svg
                            width="15"
                            height="17"
                            viewBox="0 0 15 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_182_14419)">
                              <path
                                d="M1.25 4.25002C1.25 3.49857 1.51339 2.7779 1.98223 2.24655C2.45107 1.7152 3.08696 1.41669 3.75 1.41669H11.25C11.913 1.41669 12.5489 1.7152 13.0178 2.24655C13.4866 2.7779 13.75 3.49857 13.75 4.25002V12.75C13.75 13.5015 13.4866 14.2221 13.0178 14.7535C12.5489 15.2848 11.913 15.5834 11.25 15.5834H3.75C3.08696 15.5834 2.45107 15.2848 1.98223 14.7535C1.51339 14.2221 1.25 13.5015 1.25 12.75V4.25002Z"
                                stroke="#787878"
                                strokeWidth="1.4"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M5.3125 7.79167C6.17544 7.79167 6.875 6.99884 6.875 6.02083C6.875 5.04283 6.17544 4.25 5.3125 4.25C4.44955 4.25 3.75 5.04283 3.75 6.02083C3.75 6.99884 4.44955 7.79167 5.3125 7.79167Z"
                                stroke="#787878"
                                strokeWidth="1.4"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9.07875 8.93986L3.75 15.5833H11.3331C11.9741 15.5833 12.5889 15.2947 13.0421 14.781C13.4954 14.2674 13.75 13.5707 13.75 12.8442V12.75C13.75 12.4199 13.6406 12.2931 13.4437 12.0487L10.925 8.93561C10.8076 8.79048 10.6648 8.67468 10.5057 8.59559C10.3466 8.5165 10.1748 8.47586 10.001 8.47626C9.82733 8.47666 9.65559 8.51809 9.49679 8.59791C9.33799 8.67774 9.19562 8.7942 9.07875 8.93986V8.93986Z"
                                stroke="#787878"
                                strokeWidth="1.4"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_182_14419">
                                <rect width="15" height="17" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                        <a href="#">
                          <svg
                            width="17"
                            height="15"
                            viewBox="0 0 17 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.7493 0.700012H4.24935C2.68454 0.700012 1.41602 2.22224 1.41602 4.10001V10.9C1.41602 12.7778 2.68454 14.3 4.24935 14.3H12.7493C14.3142 14.3 15.5827 12.7778 15.5827 10.9V4.10001C15.5827 2.22224 14.3142 0.700012 12.7493 0.700012Z"
                              stroke="#787878"
                              strokeWidth="1.3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M10.6237 7.50001L7.08203 4.95001V10.05L10.6237 7.50001Z"
                              stroke="#787878"
                              strokeWidth="1.3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </a>
                        <a href="#" className="">
                          <svg
                            width="14"
                            height="17"
                            viewBox="0 0 14 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.93223 10.2666C4.97922 10.2234 5.0329 10.1923 5.09006 10.1749C5.14721 10.1576 5.20666 10.1545 5.26484 10.1657C5.32302 10.177 5.37873 10.2024 5.42863 10.2404C5.47853 10.2784 5.52159 10.3283 5.55523 10.387C5.65427 10.5193 5.76633 10.6364 5.88889 10.7355C6.21784 11.0007 6.60475 11.1398 6.99956 11.135C7.49831 11.135 7.86698 10.9345 8.11023 10.7369C8.23279 10.6378 8.34485 10.5207 8.44389 10.3884L8.45614 10.37C8.52615 10.2576 8.63007 10.1835 8.74504 10.1641C8.86002 10.1447 8.97663 10.1816 9.06923 10.2666C9.16182 10.3516 9.22281 10.4778 9.23878 10.6174C9.25475 10.757 9.2244 10.8986 9.15439 11.011L9.13864 11.0351C9.13106 11.0465 9.12114 11.062 9.10831 11.0798C9.08264 11.1152 9.04706 11.164 9.00097 11.22C8.87698 11.3695 8.74119 11.5037 8.59556 11.6209C8.12278 12.002 7.56699 12.2028 6.99956 12.1975C6.43212 12.2028 5.87634 12.002 5.40356 11.6209C5.25793 11.5037 5.12214 11.3695 4.99814 11.22C4.94954 11.1614 4.90358 11.0997 4.86048 11.0351C4.70939 10.8 4.73039 10.4515 4.93223 10.2673V10.2666Z"
                              fill="#787878"
                              stroke="#787878"
                              strokeWidth="0.3"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M6.9987 0.708313C3.45495 0.708313 0.582031 4.19686 0.582031 8.49998C0.582031 12.8031 3.45495 16.2916 6.9987 16.2916C10.5424 16.2916 13.4154 12.8031 13.4154 8.49998C13.4154 4.19686 10.5424 0.708313 6.9987 0.708313ZM1.45703 8.49998C1.45703 6.71529 2.04088 5.0037 3.08015 3.74174C4.11941 2.47978 5.52896 1.77081 6.9987 1.77081C8.46844 1.77081 9.87799 2.47978 10.9172 3.74174C11.9565 5.0037 12.5404 6.71529 12.5404 8.49998C12.5404 10.2847 11.9565 11.9963 10.9172 13.2582C9.87799 14.5202 8.46844 15.2291 6.9987 15.2291C5.52896 15.2291 4.11941 14.5202 3.08015 13.2582C2.04088 11.9963 1.45703 10.2847 1.45703 8.49998Z"
                              fill="#787878"
                              stroke="#787878"
                              strokeWidth="0.3"
                            />
                            <path
                              d="M5.24935 7.6146C5.24935 7.84943 5.17253 8.07464 5.03578 8.24069C4.89904 8.40674 4.71357 8.50002 4.52018 8.50002C4.3268 8.50002 4.14133 8.40674 4.00458 8.24069C3.86784 8.07464 3.79102 7.84943 3.79102 7.6146C3.79102 7.37978 3.86784 7.15457 4.00458 6.98852C4.14133 6.82247 4.3268 6.72919 4.52018 6.72919C4.71357 6.72919 4.89904 6.82247 5.03578 6.98852C5.17253 7.15457 5.24935 7.37978 5.24935 7.6146ZM9.47852 8.50002C9.6719 8.50002 9.85737 8.40674 9.99412 8.24069C10.1309 8.07464 10.2077 7.84943 10.2077 7.6146C10.2077 7.37978 10.1309 7.15457 9.99412 6.98852C9.85737 6.82247 9.6719 6.72919 9.47852 6.72919C9.28513 6.72919 9.09966 6.82247 8.96292 6.98852C8.82617 7.15457 8.74935 7.37978 8.74935 7.6146C8.74935 7.84943 8.82617 8.07464 8.96292 8.24069C9.09966 8.40674 9.28513 8.50002 9.47852 8.50002Z"
                              fill="#787878"
                              stroke="#787878"
                              strokeWidth="0.3"
                            />
                          </svg>
                        </a>
                        <a href="#" className="sent-icon-in">
                          <svg
                            width="16"
                            height="20"
                            viewBox="0 0 16 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.4787 4.38626L9.71204 16.4489C9.67478 16.5684 9.61431 16.6746 9.536 16.7582C9.45768 16.8417 9.36393 16.9 9.26308 16.9279C9.16223 16.9557 9.05739 16.9523 8.95786 16.9179C8.85833 16.8835 8.7672 16.8192 8.69253 16.7306L6.56939 14.213L5.26738 15.8263C5.22345 15.8806 5.16752 15.9176 5.10667 15.9325C5.04581 15.9474 4.98275 15.9396 4.92546 15.9101C4.86816 15.8807 4.8192 15.8308 4.78475 15.7669C4.75029 15.7029 4.7319 15.6277 4.73189 15.5508V12.034L1.47309 8.17004C1.39224 8.07414 1.33353 7.9532 1.30304 7.81973C1.27256 7.68626 1.2714 7.54511 1.2997 7.4109C1.328 7.27669 1.38472 7.1543 1.46399 7.05639C1.54326 6.95848 1.64221 6.88862 1.75057 6.85404L12.7367 3.35198C12.8484 3.31651 12.9658 3.31986 13.076 3.36165C13.1863 3.40345 13.2851 3.48208 13.3617 3.58892C13.4383 3.69576 13.4897 3.82669 13.5102 3.96734C13.5307 4.10798 13.5196 4.25292 13.4781 4.38626H13.4787ZM11.9513 5.27344L5.06586 10.8386L6.15506 12.1305L11.9513 5.27267V5.27344Z"
                              fill="white"
                            />
                          </svg>
                        </a>
                      </div> */}
                      {errors.comment?.type === "required" && (
                        <div className="invalid-field text-danger fw-bold">
                          The comment field is required.
                        </div>
                      )}
                    </div>
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="ds-theme-btn sent-btn-space"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Submit{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
              <div className="send-info-conainer">
                <div className="send-img mt-5">
                  <img src="assets/img/message-chat.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendMessage;
