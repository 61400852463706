import { useState, useEffect } from "react";
import Logo from "../../images/home_new/logo.svg";
import "../../css/home_new.css";
import ReactFlagsSelect from "react-flags-select";
import { setLanguage } from "../../redux/actions";
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { MultiLang } from "../MultiLang";

function Navbar() {
  const [menuState, setMenuState] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.reducer);
  const onSelectLanguage = (code) => dispatch(setLanguage(code));

  const toggleMenu = () => {
    setMenuState(!menuState);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;
      setIsScrolled(scroll >= 50);
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`${menuState ? "activeMenu" : ""} ${
        isScrolled ? "fixedHeader" : ""
      }`}
    >
      <div className="headerCol border_header">
        <div className="container-fluid">
          <div className="row gx-3 align-items-center">
            <div className="col">
              <div className="logoCol right_line">
                <Link to="/">
                  <img src={Logo} alt="..." />
                </Link>
              </div>
            </div>
            <div className="col-auto order-lg-last d-none d-sm-block">
              {/*
                                    <div className="headerLoginSignup">
                                        <ul>
                                        <li>
                                            <a href="/login">Login</a>
                                        </li>
                                        <li>
                                            <a
                                            href="/register"
                                            className="btn btnSm btnPrimary"
                                            >
                                            Sign up
                                            </a>
                                        </li>
                                        </ul>
                            </div>*/}
            </div>
            <div className="col-auto">
              <div className="navigationColMain">
                <div className="navInnerCol">
                  <ul className="menu_new ul_li_gap">            
                    {/*<li>
                      <a href="/coaches">Coaches</a>
                          </li> */}
                    <li>
                      <a href="/fitness-competition">Fitness Competition</a>
                    </li>
                    <li>
                     <a href="/">Corporate Wellness</a>
                    </li>
                    <li>
                      <a href="/about">About Us</a>
                    </li>
                    <li>
                     <a href="https://discord.gg/ny22hU9z92" >Discord</a>
                    </li>
                    {/*}
                                                <li>
                                                <a href="/pro">Be Our PRO</a>
                                            </li>*/}
                    <li>
                      <a href="/contact">{MultiLang["Contact Us"][language]}</a>
                    </li>
                    <li>
                      <ReactFlagsSelect
                        selected={language}
                        onSelect={onSelectLanguage}
                        countries={["GB", "FR", "DE"]}
                        showOptionLabel={false}
                        showSecondarySelectedLabel={false}
                        showSelectedLabel={false}
                        selectedSize={20}
                        className="select-language"
                        /*showSelectedLabel={showSelectedLabel}
                                                optionsSize={optionsSize}
                                                placeholder={placeholder}
                                                searchable={searchable}
                                                searchPlaceholder={searchPlaceholder}
                                                alignOptionsToRight={alignOptionsToRight}
                                                fullWidth={fullWidth}
                                                disabled={disabled} */
                      />
                    </li>
                    <li className="nav-item last_li_line">
                      <Link className="nav-link" to="/accounts">
                        Login
                      </Link>
                    </li> 
                    {/*
                    <li className="nav-item ">
                      <Link className=" sign-up-btn" to="/register">
                        Sign up
                      </Link>
                                          </li>*/}
                  </ul>
                  <div className="headerLoginSignup d-sm-none">
                    <ul>
                      <li>
                        {/*
                                                    <a
                                                    href="/login"
                                                    className="btn btnSm btn-light"
                                                    >
                                                    Login
                                                </a>*/}
                      </li>
                      <li>
                        {/*
                                                <a 
                                                href="/register"
                                                className="btn btnSm btnPrimary"
                                                >
                                                Sign up
                                                </a>*/}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="menuBackdrop"></div>
              <div
                className="menuToggle d-lg-none"
                onClick={() => toggleMenu()}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
