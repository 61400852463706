import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { activateFeedback } from "../../redux/actions";
import useRequest from "../../hooks/useRequest";
import Preloader from "../components/preloader";
import { MultiLang } from "../MultiLang";

const PaypalWithdraw = ({ setPaymentMethod }) => {
  const { loading, user } = useSelector((state) => state.Auth);
  const { settings } = useSelector((state) => state.Site);
  const [showModel, setShowModel] = useState(false);
  const { language } = useSelector((state) => state.reducer);

  const [account, setAccount] = useState("");
  const [accountErr, setAccountErr] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { request, response } = useRequest();

  const formik = useFormik({
    initialValues: {
      withdraw_amount: "",
      // bank_name: "",
      // account_holder_name: "",
      // routing_no: "",
      // ifsc_code: "",
      paypal_account: "",
      // account_no: ""
    },
    validationSchema: Yup.object({
      withdraw_amount: Yup.number()
        .required("Please Enter Amount")
        .min(+settings?.amount, `Min Amount must be ${+settings?.amount}€`)
        .max(10000, "Max Amount must be 10000€"),
      paypal_account: Yup.string()
        .email()
        .required("Please Enter Paypal Account"),
    }),
    onSubmit: (values) => {
      // if (user?.verification_status !== "approved") {
      //     setShowModel(true);
      //     return;
      // }

      request("POST", "/withdraw-paypal", values);
    },
  });

  useEffect(() => {
    if (response) {
      dispatch(
        activateFeedback({
          type: response?.status,
          message: response?.message,
        })
      );
      if (response?.status === "success") {
        for (let key in formik.values) {
          formik.setFieldValue(key, "");
          formik.errors[key] = null;
          formik.touched[key] = false;
        }
      }
    }
  }, [response]);

  return (
    <>
      {loading && <Preloader />}
      <div className="setting-content-con-header">Paypal {MultiLang["Withdrawal"][language]}</div>
      <form onSubmit={formik.handleSubmit}>
        <div className="setting-input">
          <div className="setting-input-con">
            <label htmlFor="withdraw_amount">{MultiLang["Amount"][language]} (€)</label>
            <input
              name="withdraw_amount"
              {...formik.getFieldProps("withdraw_amount")}
              type="number"
            />
            {formik.touched.withdraw_amount &&
              formik.errors.withdraw_amount && (
                <div className="input-error">
                  {formik.errors.withdraw_amount}
                </div>
              )}
          </div>
          <div className="setting-input-con">
            <label htmlFor="paypal_account">Paypal {MultiLang["Account"][language]}</label>
            <input
              name="paypal_account"
              {...formik.getFieldProps("paypal_account")}
              type="text"
            />
            {formik.touched.paypal_account && formik.errors.paypal_account && (
              <div className="input-error">{formik.errors.paypal_account}</div>
            )}
          </div>
          {/* <div className="setting-input-con">
                            <label htmlFor="bank_name">Bank Name</label>
                            <input
                                name="bank_name"
                                {...formik.getFieldProps("bank_name")}
                                type="text"
                            />
                            {formik.touched.bank_name &&
                                formik.errors.bank_name && (
                                    <div className="input-error">
                                        {formik.errors.bank_name}
                                    </div>
                                )}
                        </div> */}
          {/* <div className="setting-input-con">
                            <label htmlFor="account_holder_name">
                                Account Holder Name
                            </label>
                            <input
                                name="account_holder_name"
                                {...formik.getFieldProps("account_holder_name")}
                                type="text"
                            />
                            {formik.touched.account_holder_name &&
                                formik.errors.account_holder_name && (
                                    <div className="input-error">
                                        {formik.errors.account_holder_name}
                                    </div>
                                )}
                        </div>
                        <div className="setting-input-con">
                            <label htmlFor="routing_no">
                                Routing Number (optional)
                            </label>
                            <input
                                name="routing_no"
                                {...formik.getFieldProps("routing_no")}
                                // inputMode="numeric"
                                type="number"
                            />
                            {formik.touched.routing_no &&
                                formik.errors.routing_no && (
                                    <div className="input-error">
                                        {formik.errors.routing_no}
                                    </div>
                                )}
                        </div>
                        <div className="setting-input-con">
                            <label htmlFor="account_no">Account Number</label>
                            <input
                                name="account_no"
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                // {...formik.getFieldProps('account_no')}
                                // min={0}
                                value={account}
                                // onInput={(e) => { formik.setFieldValue('account_no', Math.abs(e.target.value)) }}
                                type="text"
                            />
                            {accountErr && (
                                <div className="input-error">{accountErr}</div>
                            )}
                        </div>
                        <div className="setting-input-con">
                            <label htmlFor="ifsc_code">Swift/IFSC Code</label>
                            <input
                                name="ifsc_code"
                                {...formik.getFieldProps("ifsc_code")}
                                type="text"
                            />
                            {formik.touched.ifsc_code &&
                                formik.errors.ifsc_code && (
                                    <div className="input-error">
                                        {formik.errors.ifsc_code}
                                    </div>
                                )}
                        </div> */}
        </div>
        <div className="setting-bottom margin-btn">
          <button type="submit" className="blue-btn btn setting-btn">
          {MultiLang["Send Request"][language]}
          </button>
        </div>
      </form>

      <div className="setting-bottom">
        <button
          className="btn setting-btn"
          onClick={() => {
            setPaymentMethod(null);
          }}
        >
          {MultiLang["Go Back"][language]}
        </button>
      </div>
      <div
        className={
          showModel
            ? "finish-modal setting-modal active"
            : "finish-modal setting-modal"
        }
      >
        <div
          className="modal-close"
          onClick={() => {
            setShowModel(false);
          }}
        >
          <img src="images/auth/auth-close.png" alt="" />
        </div>
        <div className="modal-header">{MultiLang["Verification Alert"][language]}</div>
        <div className="modal-body">
          <div>
          {MultiLang["not-verified"][language]}
          </div>

          <div className="setting-bottom">
            <button
              type="button"
              className="blue-btn btn setting-btn"
              onClick={() => {
                navigate("/setting?tab=kyc");
              }}
            >
              {MultiLang["Verify"][language]}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaypalWithdraw;
