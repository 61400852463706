
import { Link } from "react-router-dom"
import { useEffect } from "react"
import "./../../css/auth.css"
import { useState } from "react"
import useRequest from "../../hooks/useRequest"
import { useNavigate } from "react-router-dom"
import { activateFeedback } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux"
import Preloader from "../components/preloader"

export default function ForgetPassword() {
    const { loading } = useSelector(state => state.Auth)
    const dispatch = useDispatch()

    const [email, setEmail] = useState("")
    const { request, response } = useRequest()
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const submitForm = (e) => {
        e.preventDefault()
        request("POST", "/auth/forgot-password/", { email })
    }

    useEffect(() => {
        if (response) {
            dispatch(activateFeedback({ type: response?.status, message: response?.message }))
            if (response?.status === "success" && response?.data?.uuid_string) {
                navigate(`/resetPassword/${response?.data?.uuid_string}`)
            }
        }
    }, [response])

    return (
        <>
            {loading && <Preloader />}
            <div className="_auth">
                <div className="auth-main">
                    <div className="auth-back-img">
                        <img src="./images/auth/auth-logo.png" alt="" className="auth-logo" />
                    You’re born <br className="_sp" /> for <br className="_pc" /> this
                </div>
                    <div className="auth-input">
                        <form className="auth-input-con" onSubmit={submitForm}>
                            <div className="auth-input-header">
                                Forgot Password
                            <Link to="/" className="auth-close">
                                    <img src="./images/auth/auth-close.png" alt="" />
                                </Link>
                            </div>
                            <div className="auth-input-list">
                                <label htmlFor="">Email</label>
                                <input type="email" placeholder="Example@example.com" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                            </div>
                            <div className="auth-input-list">
                                <div className="auth-alert">
                                    A new password will be sent to the mail you enter during registration
                            </div>
                            </div>
                            <div className="auth-input-list">
                                <button type="submit" className="btn auth-main-btn">Send OTP</button>
                            </div>
                            <div className="auth-input-list">
                                <div className="auth-input-list-text">
                                    <Link to="/login" className="auth-link">Sign In</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}