import { useEffect, useState } from "react";
import PreloaderOuter from "../pages/components/preloaderOuter";
import { Swiper, SwiperSlide } from "swiper/react";
import CountUp from "react-countup";

import "./../css/home_new.css";
import "./../css/home.css";
import "./../css/common.css";

import AIsvg from "./components/AIsvg";

// Import images
import StrongerImg from "../images/home_new/img-1.jpg";
import DottedImg from "../images/home_new/dotted-img.svg";
import LineShape from "../images/home_new/line-shape.svg";
import LineShape2 from "../images/home_new/line-shape-2.svg";
import AnmCircleImg from "../images/home_new/animated-circle-img.svg";
import BannerBg from "../images/home_new/banner-bg.jpg";
import BannerVideoMp4 from "../images/home_new/banner-video.mp4";
import BannerVideoMov from "../images/home_new/banner-video.mov";
import AndroidLogo from "../images/home_new/android.png";
import DiscordLogo from "../images/home_new/discord1.jpg";
import Appstore from "../images/home_new/appstore.png";

import TopBlackShape from "../images/home_new/top-black-shape.svg";
import FounderIcon from "../images/home_new/founder-icon.svg";
import MemberIcon from "../images/home_new/members-icon.svg";
import UserIcon from "../images/home_new/users-icon.svg";
import RatingIcon from "../images/home_new/rating-icon.svg";
import BtmBlackShape from "../images/home_new/btm-black-shape.svg";

import PrizeImg from "../images/home_new/prize-img.svg";

import lb from "../images/lb.png";

import UserImg1 from "../images/home_new/user-img-1.jpg";
import UserImg2 from "../images/home_new/user-img-2.jpg";
import UserImg3 from "../images/home_new/user-img-3.jpg";
import UserImg4 from "../images/home_new/user-img-4.jpg";
import UserImg5 from "../images/home_new/user-img-5.jpg";
import UserImg6 from "../images/home_new/user-img-6.jpg";

import LeftArrow from "../images/home_new/left-arrows.svg";

import SignUpIcon from "../images/home_new/signup-icon.svg";
import DepositIcon from "../images/home_new/deposit-icon.svg";
import ChallengeIcon from "../images/home_new/pick-challenge-icon.svg";
import PrizeIcon from "../images/home_new/prize-icon.svg";

import AILogo from "../images/home_new/ai-logo.svg";
import AIImgCircle from "../images/home_new/ai-img-circles.svg";

import PartnerLogo from "../images/home_new/partner-logo.jpg";
import PartnerLogo1 from "../images/home_new/owebest_logo.png";
import PartnerLogo2 from "../images/home_new/LCI_Logo_UPDATED.png";
import PartnerLogo3 from "../images/home_new/xtravision_black.png";

import useRequest from "../hooks/useRequest";
import { useSelector } from "react-redux";

import AOS from "aos";
import { Link } from "react-router-dom";
import { MultiLang } from "./MultiLang";

export default function Home() {
  const { request, response } = useRequest();
  const { settings } = useSelector((state) => state.Site);
  const { loading, loggedIn } = useSelector((state) => state.Auth);

  const { language } = useSelector((state) => state.reducer);

  // const [isData, setIsData] = useState(false);
  const [headerSectionData, setHeaderSectionData] = useState(null);
  const [strongerSectionData, setStrongerSectionData] = useState(null);
  const [featuresSectionData, setFeaturesSectionData] = useState(null);
  const [howItWorksData, setHowItWorksData] = useState(null);
  const [technologyData, setTechnologyData] = useState(null);
  const [roadMapData, setRoadMapData] = useState(null);
  const [downloadData, setDownloadData] = useState(null);
  const [faqData, setFaqData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    request("GET", "/home");
  }, []);

  useEffect(() => {
    AOS.init();
  });

  useEffect(() => {
    if (response) {
      // setIsData(true);
      setHeaderSectionData(response?.headerSectionData);
      setStrongerSectionData(response?.strongerSectionData);
      setFeaturesSectionData(response?.featuresSectionData);
      setHowItWorksData(response?.howItWorksData);
      setTechnologyData(response?.technologyData);
      setRoadMapData(response?.roadMapData);
      setDownloadData(response?.downloadData);
      setFaqData(response?.faqData);
    }
  }, [response]);

  return loading ? (
    <PreloaderOuter />
  ) : (
    <div className="home">
      {headerSectionData && (
        <section>
          <div className="bannerSection">
            <div className="bannervideo">
              <video
                className="videoCover pointerNone"
                autoPlay
                loop
                muted
                playsInline
                poster={BannerBg}
              >
                <source src={BannerVideoMp4} type="video/mp4" />
                <source src={BannerVideoMov} />
              </video>
            </div>
            <div className="bannerInnerCol">
              <div className="container">
                <div className="bannnerContent">
                  <h1
                    className="xlTitle"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <span className="outlineText d-block">
                      {MultiLang["Sweat,"][language]}
                    </span>{" "}
                    <span>
                      {MultiLang["Win,Get paid"][language]}
                    </span>
                  </h1>
                  {/* its temporary until we launch with sign up and login */}
                   {/*<p data-aos="fade-up" data-aos-delay="500">

                   
                    {
                      MultiLang["The world of online fitness competition"][
                      language
                      ]
                    }
                  </p>
                  <p
                    style={{ fontSize: "15px" }}
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    {
                      MultiLang[
                      "Be the first to test Stronger challenges and get rewarded. Registration for beta version is open."
                      ][language]
                    }
                  </p>*/}

               
               
               
               
               

            
              
                <a
                    href="https://play.google.com/store/apps/details?id=lu.stronger"
                    className="btnShape"
                    data-aos="fade-up"
                    data-aos-delay="1000"
                  >
                  <img src={AndroidLogo} alt="..."/>
                  </a> 
                  <a
                  
                   href="https://apps.apple.com/us/app/stronger-challenge/id6448996979"
                   className="btnShape"
                   data-aos="fade-up"
                   data-aos-delay="1000"
                 >
                 <img src={Appstore} alt="..."/>
                 </a> 
  
  
                </div>
                {/*
                <div
                  className="bannerRightIcons"
                  data-aos="fade-in"
                  data-aos-delay="1000"
                >
                  <img
                    src={AnmCircleImg}
                    alt="..."
                    className="animatedCircle"
                  />
                  <span className="mouseIcon">
                    <svg
                      width="28"
                      height="45"
                      viewBox="0 0 28 45"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                >
                      <rect
                        x="1"
                        y="1"
                        width="26"
                        height="43"
                        rx="13"
                        stroke="white"
                        strokeWidth="2"
                      />
                      <rect
                        className="mouseScroll"
                        x="12"
                        y="8"
                        width="4"
                        height="9"
                        rx="2"
                        fill="url(#paint0_linear_110_496)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_110_496"
                          x1="14"
                          y1="8"
                          x2="14"
                          y2="17"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="white" />
                          <stop offset="1" stopColor="white" stopOpacity="0" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </span>
                </div>*/}
              </div>
            </div>
          </div>
        </section>
      )}

      {strongerSectionData && (
        <section className="stronger">
          <div>
            <div className="whatIsStrongerSection">
              <div className="leftDottedImg">
                <img src={DottedImg} alt="..." />
              </div>
              <div className="rightLineShape">
                <img src={LineShape} alt="..." />
              </div>
              <div className="container">
                <div className="row g-3 align-items-center">
                  <div className="col-lg-auto">
                    <div
                      className="aboutSImgCol"
                      data-aos="fade-in"
                      data-aos-delay="800"
                      data-aos-duration="2000"
                    >
                      <div className="imgStyle1">
                        <img src={StrongerImg} alt="..." />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg">
                    <div className="contentCol">
                      <h2
                        className="xlTitle pb-2 pb-xl-0"
                        data-aos-delay="500"
                        data-aos="fade-up"
                      >
                        {/* <span className="outlineText d-xl-block">What is</span>{" "}
                        <span>Stronger?</span> */}
                        {/* <span className="outlineText d-xl-block">{strongerSectionData?.heading}</span> */}
                        <span className="outlineText d-xl-block">
                          {MultiLang["what-is-stronger"][language]}
                        </span>
                      </h2>
                      <p
                        className="firstLetterStyle"
                        data-aos-delay="800"
                        data-aos="fade-up"
                      >
                        {MultiLang["a-new-dimension"][language]}
                      </p>
                      <p data-aos-delay="1200" data-aos="fade-up">
                        {MultiLang["everything-happens"][language]}
                      </p>
                      {/* <div
                       className="firstLetterStyle"
                        data-aos-delay="800"
                        data-aos="fade-up"
                        dangerouslySetInnerHTML={{
                          __html: strongerSectionData?.description,
                        }}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="countdownSection" id="counter">
              <div className="topBlackShape">
                <img src={TopBlackShape} alt="..." />
              </div>
              <div className="container">
                <div className="countdownInnerCol">
                  <div className="row gy-4">
                    <div
                      className="col-6 col-lg-3"
                      data-aos-delay="200"
                      data-aos="fade-up"
                    >
                      <div className="countdownCol">
                        <div className="cdIconCol">
                          <img src={FounderIcon} alt="..." />
                        </div>
                        <div className="cdCotentCol">
                          <span
                            className="counter count"
                            data-count={settings?.founded_in}
                          >
                            <CountUp
                              end={settings?.founded_in}
                              duration={5}
                              start={0}
                            />
                          </span>
                          <span className="counterLbl">
                            {MultiLang["Founded"][language]}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-6 col-lg-3"
                      data-aos-delay="500"
                      data-aos="fade-up"
                    >
                      <div className="countdownCol">
                        <div className="cdIconCol">
                          <img src={MemberIcon} alt="..." />
                        </div>
                        <div className="cdCotentCol">
                          <span
                            className="counter count"
                            data-count={settings?.totalteam}
                          >
                            <CountUp
                              end={settings?.totalteam}
                              duration={5}
                              start={0}
                            />
                          </span>
                          <span className="counterLbl">
                            {MultiLang["Team"][language]}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-6 col-lg-3"
                      data-aos-delay="800"
                      data-aos="fade-up"
                    >
                      <div className="countdownCol">
                        <div className="cdIconCol">
                          <img src={UserIcon} alt="..." />
                        </div>
                        <div className="cdCotentCol">
                          <span
                            className="counter count"
                            data-count={settings?.totalUser}
                          >
                            <CountUp
                              end={settings?.totalUser}
                              duration={5}
                              start={0}
                            />
                          </span>
                          <span className="counterLbl">
                            {MultiLang["Users"][language]}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-6 col-lg-3"
                      data-aos-delay="1200"
                      data-aos="fade-up"
                    >
                      <div className="countdownCol">
                        <div className="cdIconCol">
                          <img src={RatingIcon} alt="..." />
                        </div>
                        <div className="cdCotentCol">
                          <span className="counter">
                            <span
                              className="count"
                              data-count={settings?.rating}
                            >
                              <CountUp
                                start={0}
                                end={settings?.rating}
                                duration={5}
                              />
                            </span>
                            /{5}
                          </span>
                          <span className="counterLbl">
                            {MultiLang["Ratings"][language]}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btmBlackShape">
                <img src={BtmBlackShape} alt="..." />
              </div>
            </div>
          </div>
        </section>
      )}

      <section>
        <div className="sectionSpace">
          <div className="container">
            <h2
              className="xlTitle pb-3 pb-xl-4 text-center text-lg-start"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <span className="outlineText d-block">
                {MultiLang["Prizes"][language]}
              </span>
            </h2>
            <div className="row gy-3 align-items-center">
              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="500">
                <div className="prizeLeftCol">
                  <img src={lb} alt="..." />
                  

                  {/*
                  <a href="/register" className="btnShape prizeBtmBtn">
                    <span className="btn btn-light minWdBtn">Sign up now</span>
      </a>*/}
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="800">
                <div className="prizeRightCol table-responsive">
                  <table className="table table-striped tableStyle1">
                    <tbody>
                      <tr>
                        <td width="80">
                          <img src={UserImg1} alt="..." className="pUserImg" />
                        </td>
                        <td>
                          <span className="pUserName">James</span>
                        </td>
                        <td>
                          <span className="repCol">
                            <small>x</small>37
                          </span>
                        </td>
                        <td>
                          <span className="rankCol">
                            <span className="rText">Rank</span>
                            <span className="rNumber">1</span>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img src={UserImg2} alt="..." className="pUserImg" />
                        </td>
                        <td>
                          <span className="pUserName">Zhang</span>
                        </td>
                        <td>
                          <span className="repCol">
                            <small>x</small>34
                          </span>
                        </td>
                        <td>
                          <span className="rankCol">
                            <span className="rText">Rank</span>
                            <span className="rNumber">2</span>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img src={UserImg3} alt="..." className="pUserImg" />
                        </td>
                        <td>
                          <span className="pUserName">Ben</span>
                        </td>
                        <td>
                          <span className="repCol">
                            <small>x</small>32
                          </span>
                        </td>
                        <td>
                          <span className="rankCol">
                            <span className="rText">Rank</span>
                            <span className="rNumber">3</span>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img src={UserImg4} alt="..." className="pUserImg" />
                        </td>
                        <td>
                          <span className="pUserName">Daniel</span>
                        </td>
                        <td>
                          <span className="repCol">
                            <small>x</small>30
                          </span>
                        </td>
                        <td>
                          <span className="rankCol">
                            <span className="rText">Rank</span>
                            <span className="rNumber">4</span>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img src={UserImg5} alt="..." className="pUserImg" />
                        </td>
                        <td>
                          <span className="pUserName">Arthur</span>
                        </td>
                        <td>
                          <span className="repCol">
                            <small>x</small>29
                          </span>
                        </td>
                        <td>
                          <span className="rankCol">
                            <span className="rText">Rank</span>
                            <span className="rNumber">5</span>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img src={UserImg6} alt="..." className="pUserImg" />
                        </td>
                        <td>
                          <span className="pUserName">Alexis</span>
                        </td>
                        <td>
                          <span className="repCol">
                            <small>x</small>27
                          </span>
                        </td>
                        <td>
                          <span className="rankCol">
                            <span className="rText">Rank</span>
                            <span className="rNumber">6</span>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {featuresSectionData && (
        <section>
          <div className="sectionSpace featureSection">
            <img src={LeftArrow} alt="..." className="rightBtmArrow" />
            <div className="container">
              <div className="ourFeatureSection">
                <div className="featuredCards">
                  <div className="row g-3 g-xl-5">
                    {/* {featuresSectionData?.feature_list.map((feature, i) => {
                      return (
                        <div
                          className="col-md-6"
                          data-aos="fade-up"
                          data-aos-delay={i % 2 === 0 ? 200 : 400}
                          key={i}
                        >
                          <div className="cardStyle2">
                            <div className="cs2IconCol">
                              <img
                                src={
                                  require(`../images/home_new/feature-icon-${
                                    i + 1
                                  }.svg`).default
                                }
                                alt="..."
                              />
                            </div>
                            <div className="cs2ContentCol">
                              <h4>{feature?.title}</h4>
                              <p>{feature?.description}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })} */}
                    <div
                      className="col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={0 % 2 === 0 ? 200 : 400}
                    >
                      <div className="cardStyle2">
                        <div className="cs2IconCol">
                          <img
                            src={
                              require(`../images/home_new/feature-icon-${0 + 1
                                }.svg`).default
                            }
                            alt="..."
                          />
                        </div>
                        <div className="cs2ContentCol">
                          <h4>{MultiLang["choose-among"][language]}</h4>
                          <p>{MultiLang["our-curated"][language]}</p>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={1 % 2 === 0 ? 200 : 400}
                    >
                      <div className="cardStyle2">
                        <div className="cs2IconCol">
                          <img
                            src={
                              require(`../images/home_new/feature-icon-${1 + 1
                                }.svg`).default
                            }
                            alt="..."
                          />
                        </div>
                        <div className="cs2ContentCol">
                          <h4>{MultiLang["do-it"][language]}</h4>
                          <p>{MultiLang["bring-your"][language]}</p>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={2 % 2 === 0 ? 200 : 400}
                    >
                      <div className="cardStyle2">
                        <div className="cs2IconCol">
                          <img
                            src={
                              require(`../images/home_new/feature-icon-${2 + 1
                                }.svg`).default
                            }
                            alt="..."
                          />
                        </div>
                        <div className="cs2ContentCol">
                          <h4>{MultiLang["no-equip"][language]}</h4>
                          <p>{MultiLang["challenges-are"][language]}</p>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={3 % 2 === 0 ? 200 : 400}
                    >
                      <div className="cardStyle2">
                        <div className="cs2IconCol">
                          <img
                            src={
                              require(`../images/home_new/feature-icon-${3 + 1
                                }.svg`).default
                            }
                            alt="..."
                          />
                        </div>
                        <div className="cs2ContentCol">
                          <h4>{MultiLang["play-safe"][language]}</h4>
                          <p>{MultiLang["every-challenge"][language]}</p>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={4 % 2 === 0 ? 200 : 400}
                    >
                      <div className="cardStyle2">
                        <div className="cs2IconCol">
                          <img
                            src={
                              require(`../images/home_new/feature-icon-${4 + 1
                                }.svg`).default
                            }
                            alt="..."
                          />
                        </div>
                        <div className="cs2ContentCol">
                          <h4>{MultiLang["withdraw-money"][language]}</h4>
                          <p>{MultiLang["this-is-our"][language]}</p>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={5 % 2 === 0 ? 200 : 400}
                    >
                      <div className="cardStyle2">
                        <div className="cs2IconCol">
                          <img
                            src={
                              require(`../images/home_new/feature-icon-${5 + 1
                                }.svg`).default
                            }
                            alt="..."
                          />
                        </div>
                        <div className="cs2ContentCol">
                          <h4>{MultiLang["be-a-part"][language]}</h4>
                          <p>{MultiLang["the-idea-of"][language]}</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="featureTitle">
                  <h2
                    className="xxlTitle"
                    data-aos="fade-in"
                    data-aos-delay="500"
                  >
                    {/* <span className="outlineText d-xl-block">Our</span>{" "}
                    <span>Features</span> */}
                    {/* <span className="outlineText d-xl-block">{featuresSectionData?.heading}</span> */}
                    <span className="outlineText d-xl-block">
                      {MultiLang["Features"][language]}
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {howItWorksData && (
        <section>
          <div className="processCol">
            <div className="topBlackShape">
              <img src={TopBlackShape} alt="..." />
            </div>
            <div className="container">
              <div className="processInnerCol">
                <div className="row gy-3">
                  <div
                    className="col-md-6 col-lg-3"
                    data-aos="fade-in"
                    data-aos-delay="200"
                  >
                    <div className="cardStyle3">
                      <div className="cs3IconCol justify-content-center">
                        <img src={SignUpIcon} alt="..." />
                      </div>
                      <div className="cs3CotentCol">
                        <h4>{MultiLang["signup"][language]}</h4>
                        <p>{MultiLang["beta-version"][language]}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-6 col-lg-3"
                    data-aos="fade-in"
                    data-aos-delay="400"
                  >
                    <div className="cardStyle3">
                      <div className="cs3IconCol justify-content-center">
                        <img src={DepositIcon} alt="..." />
                      </div>
                      <div className="cs3CotentCol">
                        <h4>{MultiLang["first-deposit"][language]}</h4>
                        <p>{MultiLang["make-your-first"][language]}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-6 col-lg-3"
                    data-aos="fade-in"
                    data-aos-delay="600"
                  >
                    <div className="cardStyle3">
                      <div className="cs3IconCol justify-content-center">
                        <img src={ChallengeIcon} alt="..." />
                      </div>
                      <div className="cs3CotentCol">
                        <h4>{MultiLang["pick-your"][language]}</h4>
                        <p>{MultiLang["pick-among"][language]}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-6 col-lg-3"
                    data-aos="fade-in"
                    data-aos-delay="800"
                  >
                    <div className="cardStyle3">
                      <div className="cs3IconCol justify-content-center">
                        <img src={PrizeIcon} alt="..." />
                      </div>
                      <div className="cs3CotentCol">
                        <h4>{MultiLang["win-prizes"][language]}</h4>
                        <p>{MultiLang["when-you-take"][language]}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="btmBlackShape">
              <img src={BtmBlackShape} alt="..." />
            </div>
          </div>
        </section>
      )}

      {technologyData && (
        <section>
          <div className="sectionSpace aiTechSection">
            <div className="container">
              <h2 className="xlTitle" data-aos="fade-up" data-aos-delay="100">
                <span className="outlineText">{MultiLang["ai"][language]}</span> <span>{MultiLang["technology"][language]}?</span>
              </h2>
              <div
                className="aiTechText"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="row">
                  <div className="col-lg-8 text-lg-end">
                    <div className="rightLgBorderText">
                      <p>
                        <b>{MultiLang["ai-tech"][language]}</b>{MultiLang["stronger-fitness"][language]}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <p>
                      {MultiLang["by-using"][language]}
                    </p>
                  </div>
                </div>
              </div>
              <div className="aiImgCol" data-aos="fade-anm">
                <div className="aiSvgMain">
                  <div className="aiLogo">
                    <img src={AILogo} alt="..." />
                  </div>
                  <div className="aiCircles">
                    <span>
                      <img src={AIImgCircle} alt="..." />
                    </span>
                  </div>
                  <AIsvg />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <section>
        <div className="ourPartnerSection">
          <div className="topBlackShape">
            <img src={TopBlackShape} alt="..." />
          </div>
          <div className="container">
            <div className="partnerInnerCol">
              <div
                className="text-center pb-3"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h2 className="xlTitle">
                  <span className="outlineText">{MultiLang["our"][language]}</span> <span className="text-dark">{MultiLang["partners"][language]}</span>
                </h2>
              </div>
              <div
                className="swiper pSwiper"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <Swiper
                  slidesPerView={2}
                  spaceBetween={10}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 5,
                      spaceBetween: 30,
                    },
                  }}
                >
                  <SwiperSlide>
                    <div className="partnerLogo">
                      <img src={PartnerLogo} alt="..." />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partnerLogo">
                      <img src={PartnerLogo1} alt="..." />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partnerLogo">
                      <img src={PartnerLogo2} alt="..." />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="partnerLogo">
                      <img src={PartnerLogo3} alt="..." />
                    </div>
                  </SwiperSlide>
                </Swiper>
                <div className="swiper-pagination"></div>
              </div>
            </div>
          </div>
          <div className="btmBlackShape">
            <img src={BtmBlackShape} alt="..." />
          </div>
        </div>
      </section>

      {faqData && (
        <section>
          <div className="sectionSpace faqSection">
            <img src={LeftArrow} alt="..." className="rightBtmArrow" />
            <div className="leftLineShape">
              <img src={LineShape2} alt="..." />
            </div>
            <div className="rightDottedImg">
              <img src={DottedImg} alt="..." />
            </div>
            <div className="container">
              <h2 className="xlTitle" data-aos="fade-up" data-aos-delay="200">
                <span className="outlineText">FAQ</span>
              </h2>
              <div
                className="faqinnerCol"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <div
                  className="accordion accordion-flush accordionStyle1"
                  id="accordionFlushExample"
                >
                  {/*
                {faqData.map((data, index) => {
                  return (
                    <div key={index} className="faq-container accordion-item">
                      <div
                        className="accordion"
                        id={`faq_${index}`}
                        onClick={(e) => {
                          accordian(e);
                        }}
                      >
                        <div className="acc-down-btn"></div>
                        {data?.question}
                      </div>
                      <div className="panel">
                        <div className="panel-text">{data?.answer}</div>
                      </div>
                    </div>
                  );
                })}*/}

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="true"
                        aria-controls="flush-collapseOne"
                      >
                        {MultiLang["what-xp"][language]}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {MultiLang["xp-are"][language]}{" "}
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        {MultiLang["what-stronger"][language]}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {MultiLang["stronger-team"][language]}
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        {MultiLang["what-levels"][language]}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {MultiLang["levels-are-used"][language]}{" "}
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                      >
                        {MultiLang["what-training"][language]}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingFour"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {MultiLang["we-use-the"][language]}{" "}
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          {MultiLang["how-to"][language]}
                        </button>
                      </h2>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          {MultiLang["first-challenge"][language]}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
