import react, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate, Link } from "react-router-dom";
import useRequestV2 from "../../../hooks/useRequestV2";
import { toast } from "react-toastify";
// import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { authSuccess } from "../../../redux/corporation-store/auth/action";

const Password = () => {
  const location = useLocation();
  const { request, response } = useRequestV2();
  let token = localStorage.getItem("access_token");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      employee_id: "",
    },

    validationSchema: Yup.object({
      employee_id: Yup.string().required("This Field is required"),
    }),
    onSubmit: (values) => {
      const data = {
        employee_id: values.employee_id,
        uuid_string: location.state?.id,
      };
      request("POST", "auth/employee-login", data);
    },
  });
  useEffect(() => {
    if (response) {
      if (response.status == "success") {
        // console.log(response, "this is response");
        if (response?.data?.user?.is_verified) {
          dispatch(authSuccess(response.data));

          toast.success(response.message , {position: "bottom-center",theme:"dark"});
          navigate("/calander-coach-booking", {
            state: {
              id: response?.data?.user?.user_role_id,
            },
          });
          localStorage.setItem("token", response?.data?.access_token);
          localStorage.setItem("access_token", response?.data?.access_token);
          localStorage.setItem("company_name", response?.data?.user?.company_name);
          localStorage.setItem("first_name", response?.data?.user?.profile_first_name);
          localStorage.setItem("last_name", response?.data?.user?.profile_last_name);


          localStorage.setItem(
            "auth",
            JSON.stringify({
              access_token: response?.data?.access_token,
              user: response?.data?.user,
              

              // user_role_id: response?.data?.user?.user_role_id,
            })
          );
        } else {
          navigate("/employee-verify", {
            state: {
              id: response?.data?.user?.uuid_string,
            },
          });
        }
      } else if (response.status == "fail") {
        toast.error(response.message, {position: "bottom-center",theme:"dark"});
      }
    }
  }, [response]);
  return (
    <>
      <div className="loader-wrapper" style={{ display: "none" }}>
        <div className="loader">
          <img src="img/logo.png" alt="" />
        </div>
      </div>
      <div
        className="verify_container"
        style={{ backgroundImage: `url(img/Corporate-bg.jpg)` }}
      >
        <img className="right_doted" src="img/doted.png" alt="right img" />
        <div className="container-fluid">
          <div className="main_container">
            <div className="logo_block">
              <Link to="/">
              <img src="img/full-logo.png" alt="logo white" />
              </Link>
            </div>
            <div className="center_blog">
              <div className="company-id-box sign_in-form-box">
                <h2 className="Corporate-title"> Corporate</h2>
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-3 text-start">
                    <label className="form-label">Company ID</label>
                    <div className="lock-box">
                      <input
                        type="password"
                        className="form-control company-id"
                        placeholder="********"
                        {...formik.getFieldProps("employee_id")}
                      />
                      {formik.touched.employee_id &&
                        formik.errors.employee_id && (
                          <div className="input-error text-danger fw-bold">
                            {formik.errors.employee_id}
                          </div>
                        )}
                      <span className="lock-icon">
                        <i className="far fa-lock-alt text-muted"></i>
                      </span>
                    </div>
                    <div className="go-btn-box">
                      <button
                        className="btn  text-light p-3 px-4 fs-4 py-4 "
                        style={{
                          backgroundColor: "#1443E8",
                          borderRadius: "50px",
                          border: "10px solid #466eff7d",
                        }}
                      >
                        Go
                      </button>
                      <p className="mt-4">
                        Don’t have account?&nbsp;&nbsp;
                        <Link
                          to="/corporation/employee/register"
                          className="text-light"
                        >
                          Sign Up
                        </Link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Password;
