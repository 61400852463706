import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Main = () => {

  return (
    <div
      className="sign-up-container sign-up-Splash weqwe"
      style={{ backgroundImage: "url(assets/img/Splash-bg.jpg)" }}
    >
      <div className="container-fluid">
        <div className="sign-up-inner-container splash-container">
        <Link to="/"> 
      <img className="main_logo ms-auto me-auto" src="https://stronger.stage04.obdemo.com/assets/img/full-logo.png"/>
      </Link>
          <div className="row align-items-center h-100 responsive-gap">
            <div className="col-md-6 col-lg-3 login-shadow login-shadow1">
              <div className="Splash-login-box">
                <div className="Splash-login-icon">
                  <svg
                    width="46"
                    height="46"
                    viewBox="0 0 46 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.9987 0C26.0045 0 28.8872 1.19404 31.0126 3.31946C33.138 5.44487 34.332 8.32755 34.332 11.3333C34.332 14.3391 33.138 17.2218 31.0126 19.3472C28.8872 21.4726 26.0045 22.6667 22.9987 22.6667C19.9929 22.6667 17.1102 21.4726 14.9848 19.3472C12.8594 17.2218 11.6654 14.3391 11.6654 11.3333C11.6654 8.32755 12.8594 5.44487 14.9848 3.31946C17.1102 1.19404 19.9929 0 22.9987 0ZM22.9987 5.66667C21.4958 5.66667 20.0545 6.26369 18.9918 7.32639C17.9291 8.3891 17.332 9.83044 17.332 11.3333C17.332 12.8362 17.9291 14.2776 18.9918 15.3403C20.0545 16.403 21.4958 17 22.9987 17C24.5016 17 25.9429 16.403 27.0056 15.3403C28.0683 14.2776 28.6654 12.8362 28.6654 11.3333C28.6654 9.83044 28.0683 8.3891 27.0056 7.32639C25.9429 6.26369 24.5016 5.66667 22.9987 5.66667ZM22.9987 25.5C30.5637 25.5 45.6654 29.2683 45.6654 36.8333V45.3333H0.332031V36.8333C0.332031 29.2683 15.4337 25.5 22.9987 25.5ZM22.9987 30.8833C14.5837 30.8833 5.71536 35.02 5.71536 36.8333V39.95H40.282V36.8333C40.282 35.02 31.4137 30.8833 22.9987 30.8833Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <h3 className="account-title">Fitness competition</h3>
                <Link to="/login">
                  <button className="Subscription-btn ds-theme-btn">Login </button>
                </Link>
                {/* <div className="already-account">
                  Already Have An Account? <Link to="/register"> Sign up</Link>
                </div> */}
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="Splash-login-box">
                <div className="Splash-login-icon">
                  <svg
                    width="46"
                    height="48"
                    viewBox="0 0 46 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M41.3414 34.3386C37.3482 28.8859 32.3708 27.6222 29.744 26.7926V24.8642C31.5137 23.111 32.6119 20.6799 32.6119 17.9967C32.6119 16.9606 32.6119 8.77292 32.6119 7.74116C32.6119 4.02405 29.5879 1 25.8709 1H20.0366C16.3196 1 13.2955 4.02405 13.2955 7.74107C13.2955 8.77283 13.2955 16.9605 13.2955 17.9966C13.2955 20.6376 14.3596 23.0343 16.0806 24.7807V26.8471C13.6305 27.621 8.64775 28.8878 4.65599 34.3386C2.23605 37.643 0.957031 41.5552 0.957031 45.6523C0.957031 46.3966 1.56042 47 2.30469 47H43.6927C44.437 47 45.0404 46.3966 45.0404 45.6523C45.0403 41.5552 43.7613 37.643 41.3414 34.3386ZM27.0486 27.3865L23.1683 33.4598C23.003 33.4494 22.8847 33.4495 22.7112 33.462L18.7758 27.4339V26.7115C21.3813 27.9679 24.4307 27.9821 27.0486 26.7504V27.3865ZM16.9196 14.6722C15.8241 11.1602 16.0171 11.7783 15.9909 11.6963V11.2051H29.9166V11.6809C29.8878 11.7675 30.0935 11.1338 28.946 14.6724C22.4576 13.7172 23.5022 13.703 16.9196 14.6722ZM15.9909 7.74107C15.9909 5.51025 17.8058 3.69531 20.0366 3.69531H25.8709C28.1017 3.69531 29.9166 5.51025 29.9166 7.74107V8.50977H15.9909V7.74107ZM15.9909 17.2652C16.2713 17.3649 16.5533 17.4079 16.7444 17.4079C16.8852 17.4079 16.5748 17.4473 21.0224 16.7925C23.3778 16.446 24.6603 16.7658 28.9238 17.3934C29.2367 17.4395 29.6228 17.3604 29.9166 17.25V17.9966C29.9166 21.8407 26.7931 24.9682 22.9537 24.9682C19.1309 24.9682 15.9909 21.856 15.9909 17.9966V17.2652ZM16.8588 29.428L20.3183 34.7271C18.3742 37.1689 20.5383 40.757 23.6277 40.127V41.3953C23.6277 42.1395 24.2311 42.7429 24.9753 42.7429C25.7196 42.7429 26.323 42.1395 26.323 41.3953V36.8235C26.323 36.0213 26.041 35.2839 25.5717 34.7045L28.9757 29.3765C30.95 30 31.9951 30.2773 33.5404 31.0827V44.3047H12.457V31.0827C14.0012 30.2779 15.0725 29.9921 16.8588 29.428ZM9.76172 32.8431V44.3047H3.70643C4.07497 39.7319 6.30525 35.6222 9.76172 32.8431ZM36.2356 44.3047V32.8431C39.692 35.6222 41.9224 39.7319 42.2909 44.3047H36.2356Z"
                      fill="white"
                      stroke="white"
                    />
                  </svg>
                </div>
                <h3 className="account-title">Coach</h3>
                <button className="Subscription-btn ds-theme-btn">Login </button>
                {/* <div className="already-account">
                  Already Have An Account? <a href="#"> Sign up</a>
                </div> */}
              </div>
            </div>
            <div className="col-md-6 col-lg-3 login-shadow">
              <div className="Splash-login-box">
                <div className="Splash-login-icon">
                  <svg
                    width="46"
                    height="48"
                    viewBox="0 0 62 56"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M49.4994 37.25H43.3327V43.4167H49.4994M49.4994 24.9167H43.3327V31.0833H49.4994M55.666 49.5833H30.9994V43.4167H37.166V37.25H30.9994V31.0833H37.166V24.9167H30.9994V18.75H55.666M24.8327 12.5833H18.666V6.41667H24.8327M24.8327 24.9167H18.666V18.75H24.8327M24.8327 37.25H18.666V31.0833H24.8327M24.8327 49.5833H18.666V43.4167H24.8327M12.4993 12.5833H6.33268V6.41667H12.4993M12.4993 24.9167H6.33268V18.75H12.4993M12.4993 37.25H6.33268V31.0833H12.4993M12.4993 49.5833H6.33268V43.4167H12.4993M30.9994 12.5833V0.25H0.166016V55.75H61.8327V12.5833H30.9994Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <h3 className="account-title">corporate</h3>
                <Link to="/client-login">
                  <button className=" ds-theme-btn Subscription-btn">Login </button>
                </Link>
                {/* <div className="already-account">
                  Already Have An Account?{" "}
                  <Link to="/corporation/client/register"> Sign up</Link>
                </div> */}
              </div>
            </div>
            <div className="col-md-6 col-lg-3 ">
              <div className="Splash-login-box ">
                <div className="Splash-login-icon">
                <svg width="46" height="48" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="28.5" cy="28.5" r="27" fill="white" stroke="white" strokeWidth="3"/>
<path d="M27.5493 13.3002C29.439 13.3002 31.2513 14.0508 32.5875 15.387C33.9236 16.7232 34.6743 18.5355 34.6743 20.4252C34.6743 22.3148 33.9236 24.1271 32.5875 25.4633C31.2513 26.7995 29.439 27.5502 27.5493 27.5502C25.6596 27.5502 23.8474 26.7995 22.5112 25.4633C21.175 24.1271 20.4243 22.3148 20.4243 20.4252C20.4243 18.5355 21.175 16.7232 22.5112 15.387C23.8474 14.0508 25.6596 13.3002 27.5493 13.3002ZM27.5493 16.8627C26.6045 16.8627 25.6983 17.238 25.0302 17.9061C24.3622 18.5742 23.9868 19.4803 23.9868 20.4252C23.9868 21.37 24.3622 22.2761 25.0302 22.9442C25.6983 23.6123 26.6045 23.9877 27.5493 23.9877C28.4942 23.9877 29.4003 23.6123 30.0684 22.9442C30.7365 22.2761 31.1118 21.37 31.1118 20.4252C31.1118 19.4803 30.7365 18.5742 30.0684 17.9061C29.4003 17.238 28.4942 16.8627 27.5493 16.8627ZM27.5493 29.3314C32.3053 29.3314 41.7993 31.7005 41.7993 36.4564V41.8002H13.2993V36.4564C13.2993 31.7005 22.7934 29.3314 27.5493 29.3314ZM27.5493 32.7158C22.259 32.7158 16.6837 35.3164 16.6837 36.4564V38.4158H38.4149V36.4564C38.4149 35.3164 32.8396 32.7158 27.5493 32.7158Z" fill="#323D48"/>
</svg>
                </div>
                <h3 className="account-title">corporate User</h3>
                <Link to="/companyupdate">
                  <button className=" ds-theme-btn Subscription-btn">Login </button>
                </Link>
                {/* <div className="already-account">
                  Already Have An Account?{" "}
                  <Link to="/corporation/employee/register"> Sign up</Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
