import { useState, useEffect } from "react";

/**
 * Hook for handling closing when clicking outside of an element
 * @param {React.node} el
 * @param {boolean} initialState
 */
export const useDetectOutsideClick = (el, initialState) => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    // console.log(isActive);
    // console.log("--------------------------------")
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (!el.current.contains(e.target)) {
        // console.log("out")
        setIsActive(false);
      }
    }

    if (isActive) {
      // console.log("add event listener")
      window.addEventListener("mousedown", checkIfClickedOutside)
    }

    return () => {window.removeEventListener("mousedown", checkIfClickedOutside);  }

  }, [el, isActive])
  
  return [isActive, setIsActive];
};
