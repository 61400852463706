import { useEffect, useState } from 'react';
import React from 'react';
import "./../css/contact.css"
import { useSelector } from "react-redux"
import PreloaderOuter from './components/preloaderOuter';
import useRequest from "../hooks/useRequest";
import { MultiLang } from './MultiLang';
import {Link , useNavigate} from "react-router-dom"
import {GoLocation} from "react-icons/go"
import {BsTelephoneInbound} from "react-icons/bs";
import {AiOutlineMail} from "react-icons/ai";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

export default function Contact() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();
  const navigate = useNavigate()

    const { settings } = useSelector(state => state.Site)
    const { loading } = useSelector(state => state.Auth)
    const { language } = useSelector((state) => state.reducer);
    const [contactUsData, setContactUsData] = useState(null)
    const { request, response } = useRequest()
    const { request: contactRequest , response: contactResponse } = useRequest()

    useEffect(() => {
        window.scrollTo(0, 0)
        request("GET", "/contact-us")
    }, [])
    useEffect(() => {
        if (response) {
            setContactUsData(response?.contactUs)
        }
    }, [response])


const handleContact =(data)=>
{
  const {
    name,
    email,
    message
  } = data;
  console.log(data)

let formdata = new FormData();

formdata.append("name", name);
formdata.append("email", email);
formdata.append("message", message);
contactRequest("POST", "/add-contact-us" , formdata);
}
useEffect(() => {
 if(contactResponse)
 {
  if(contactResponse.status== "success")
  {
    toast.success(contactResponse.message , {position:"bottom-center" , theme:"dark"})
    navigate("/contact")
    
  }
  else if(contactResponse.status=="error" || contactResponse.status=="fail" )
  {
    toast.error(contactResponse.error , {position:"bottom-center" , theme:"dark"});
  }
 }
}, [contactResponse])

    return (loading && !contactUsData ? <PreloaderOuter /> :
        <>  

           <header className="" id="header">
        <div className="container-fluid  ">
          <div className="row">
            <div className="col-12">
              <nav className="navbar navbar-expand-lg">
                <Link className="navbar-brand" to="/">
                  <img src="img/full-logo.png" alt="" />
                </Link>

                <button className="navbar-toggler" type="button">
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse">
                  <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                      <Link className="nav-link" to="/">
                        Corporate Wellness
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/coaches">
                        Coaches
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/fitness-competition">
                        Fitness Competition
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/about">
                        About
                      </Link>
                    </li>
                    <li className="nav-item active">
                      <Link className="nav-link" to="/contact">
                        Contact Us
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                    <a className="nav-link" href="javascript:void(0);">
                      School Competition
                    </a>
                  </li> */}

                    {/* <li className="nav-item">
                                <a className="nav-link" href="javascript:void(0);">Login</a>
                            </li> */}
                    <li className="nav-item">
                      <Link className=" sign-up-btn" to="/accounts">
                        Login
                      </Link>
                    </li>
                  </ul>
                </div>

                {/* ======= User DropDown =========   */}

                {/* <div className="nav-item dropdown user_dropdown">
                        <a className="nav-link dropdown-toggle" href="javascript:void(0);" id="user-drop" role="button"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src="img/user.png" alt="" />
                        </a>
                        <div className="dropdown-menu" aria-labelledby="user-drop">
                            <div className="user_info">
                                <div className="user_name">
                                    <div>Sandeep Prajapati</div>
                                    <div className="user_email">
                                        <small>Sandeep@gmail.com</small>
                                    </div>
                                </div>
                                <ul>
                                    <li>
                                        <a href="#!"><i className="ion-android-person"></i> My Profile</a>
                                    </li>
                                    <li>
                                        <a href="#!"><i className="ion-log-out"></i> Logout</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
              </nav>
            </div>
          </div>
        </div>
           </header>          
            <div className='contact bg-white '>
                <div className="contact-back" style={{ backgroundImage: `url(${contactUsData?.image}`}}>
                </div>
                <div className='contact-info '  >
                    <div className='contact-info-item d-block '>
                    <div className='title' dangerouslySetInnerHTML={{ __html: contactUsData?.heading }} />

                        {/* <img src={contactUsData?.icon} alt="" /> */}
                        {/* <p style={{fontSize:"20px"}}><BsTelephoneInbound/>&nbsp;&nbsp;&nbsp; */}
                        {/* {settings?.mobile} */}
                        {/* 877-255-7945
                        </p> */}
                        {/*<p style={{fontSize:"20px"}}><GoLocation/>&nbsp;&nbsp;&nbsp;9 , rue du Laboratoire
                        </p>
                        <p style={{fontSize:"20px"}}><GoLocation/>&nbsp;&nbsp;&nbsp;
                        {/* {settings?.address}  L-1911 Luxembourg
                        </p>*/}
                        <p style={{fontSize:"20px"}}><AiOutlineMail/>&nbsp;&nbsp;&nbsp;info@stronger.lu</p>

                       

                        
                    </div>
                </div>
                <div className='contact-input bg-white'>
                    <div className='title text-dark'>{MultiLang["Contact Us"][language]}</div>
                    {/* <div>{settings?.Email}</div> */}

                  <form onSubmit={handleSubmit(handleContact)}>  
            <div className='contact-input-item'>
                <label htmlFor="name">Name</label><br></br>
                <input type="text" className='mb-0' placeholder='Your Name'  style={{border:"1px solid lightgray"}}   {...register("name", {
                      required: true,
                    })}/>
                     {errors.name?.type === "required" && (
                    <div className="text-danger fw-bold">
                      This field is required.
                    </div>
                  )}

                 
            </div>
            <div className='contact-input-item'>
                <label htmlFor="mail">Email</label><br></br>
                <input type="email" className='mb-0'  placeholder='example@example.com' style={{border:"1px solid lightgray"}}   {...register("email", {
                      required: true,
                      pattern:
                        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i,
                    })}/>
                     {errors.email?.type === "required" && (
                    <div className="text-danger fw-bold">
                      This field is required.
                    </div>
                  )}

                  {errors.email?.type === "pattern" && (
                    <div className="text-danger fw-bold">
                      Please enter valid email
                    </div>
                  )}
            </div>
            <div className='contact-input-item'>
                <label htmlFor="message">Message</label><br></br>
                <textarea  rows={"10"} className='mb-0' placeholder="Message" style={{border:"1px solid lightgray"}}   {...register("message", {
                      required: true,
                    })}></textarea>
                     {errors.message?.type === "required" && (
                    <div className="text-danger fw-bold">
                      This field is required.
                    </div>
                  )}

                 
            </div>
            <div className="contact-button">
                <button type='submit'>Submit</button>
    </div>
    </form>

                </div>
            </div>

        </>)
}