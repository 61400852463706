import React, { useEffect , useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import { useForm } from "react-hook-form";
import useRequestV2 from "../../../../hooks/useRequestV2";
import { toast } from "react-toastify";

const Add = () => {
  const navigate = useNavigate();
  const [empId , setEmpId] = useState()
  
  const { request: requestGenerateId, response: responseGenerateId } =
    useRequestV2();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  
  const handleGenerate = () => {
    const id = Math.random().toString(36).slice(2)
    setValue("employeeId", id);
    setValue("message",`Hi, Employee 
    
Please follow the instructions to access the Wellness Livestream sessions.

First sign up:https://stronger.lu/corporation/employee/register

After up, confirm your email and enter your ID company.

Your company id is : ${id}

Wish you a good day. Corporate` )
  };
  const onSubmit = (data) => {
    const { employeeId, message, email } = data;
    requestGenerateId("POST", "generate-id", {
      email,
      employee_company_id: employeeId,
      employee_message: message,
    });
  };

  useEffect(() => {
    if (responseGenerateId) {
      if (responseGenerateId.status == "success") {
        // toast.success('Employee id has been sent successfully on this email.');
        // navigate("/ids");
        navigate("/ids", { state: { matchValue: "add-employee" } });
      } else if (responseGenerateId.status == "fail") {
        toast.error(responseGenerateId.message);
      }
    }
  }, [responseGenerateId]);

  return (
    <>
      <Sidebar />
      <div className="page_container">
        <div className="main_content">
          <div className="pagetopTitle">
            <h2 className="page-title pageHeaderData">
              <span>Agenda</span>
              <span>Your next session starts in 2 D : 16 h : 20 min</span>
              <span>Pilates</span>
            </h2>
          </div>
          <div className="dashboardCard">
            <div className="cardHeader">
              <div className="row">
                <div className="col-md-6">
                  <div className="cardLeftSide">
                    <h3 className="CardHeadTitle">Id management</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-end">
              <div className="col-md-7">
                <div className="manageId">
                  <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
                    <div className="generateBox">
                      <div className="col-md-7 mb-5">
                        <label className="form-label">ID*</label>
                        <div className="inputRow">
                          <input
                            type="text"
                            readOnly={true}
                            className="form-control"
                            name="employeeId"
                            placeholder="ID*"
                            {...register("employeeId", {
                              required: true,
                            })}
                            onChange={(e) => setEmpId(e.target.value)}

                          />

                          <Link
                            to="#"
                            className="idGenerateBtn"
                            onClick={handleGenerate}
                          >
                            Generate
                          </Link>
                        </div>
                        {errors.employeeId?.type === "required" && (
                          <div className="invalid-field text-danger fw-bold">
                            This field is required.
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label className="form-label">Email*</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email*"
                        {...register("email", {
                          required: true,
                          pattern:
                            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i,
                        })}
                      />

                      {errors.email?.type === "required" && (
                        <div className="invalid-field text-danger fw-bold">
                          This field is required.
                        </div>
                      )}

                      {errors.email?.type === "pattern" && (
                        <div className="invalid-field">
                          Please enter valid email.
                        </div>
                      )}
                    </div>
                    <div className="col-12">
                      <label className="form-label">Message*</label>
                      <textarea
                        className="form-control"
                        placeholder=""
                        style={{ height: "240px" }}
                        {...register("message", {
                          required: true,
                        })}
                        // defaultValue={`Hi john Please follow the instructions to access the Wellness Livestream sessions.First sign up: http.www.link.com After sign up, confirm your email and enter your ID company.ID company: ${empId} Wish you a good day. Corporate`}
                      ></textarea>
                      {errors.message?.type === "required" && (
                        <div className="invalid-field text-danger fw-bold">
                          This field is required.
                        </div>
                      )}
                    </div>
                    <div className="col-12 mt-4">
                      <button
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                        className="BlueThemeBtn themeRoundedBtn"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-5">
                <div className="sideSectionimg">
                  <img src="assets/img/aboutImg.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add;
