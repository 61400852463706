import one from "../audio/1.mp3"
import two from "../audio/2.mp3"
import three from "../audio/3.mp3"
import four from "../audio/4.mp3"
import five from "../audio/5.mp3"
import six from "../audio/6.mp3"
import seven from "../audio/7.mp3"
import eight from "../audio/8.mp3"
import nine from "../audio/9.mp3"
import ten from "../audio/10.mp3"

export const numberVoice = {
    1: one,
    2: two,
    3: three,
    4: four,
    5: five,
    6: six,
    7: seven,
    8: eight,
    9: nine,
    10: ten,
}