import React, { useState, useEffect } from 'react'
import { numberVoice } from "../../helpers/numberVoice.js"

const Counter = ({ time, setMode, nextMode, audioSrc, audioNum }) => {

    const [counter, setCounter] = useState(time)
    // const [started, setStarted] = useState(false)

    useEffect(() => {

        if (audioNum && audioNum[counter] && numberVoice[audioNum[counter]]) {
            let sound = new Audio(numberVoice[audioNum[counter]])
            sound.play()
        }

        if (counter <= 0) {
            if (nextMode) {
                setMode(nextMode)
            }
        } else {
            // setStarted(true)
            setTimeout(() => {
                setCounter(counter - 1)
            }, 1000)
        }
    }, [counter]);

    // useEffect(() => {
    //     if (started && audioSrc) {
    //         let sound = new Audio(audioSrc)
    //         sound.play()
    //     }
    // }, [started])

    return (
        <div>{counter}</div>
    )
}

export default Counter