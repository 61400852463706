import { useState, useRef, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useSelector, useDispatch } from "react-redux"
import { activateFeedback, updateKycStatus } from "../../redux/actions";
import useRequest from "../../hooks/useRequest";
import Webcam from "react-webcam";
import Preloader from "../components/preloader";

const AccVerification = () => {
    const { user, loading } = useSelector(state => state.Auth)
    const dispatch = useDispatch()
    const { request, response } = useRequest()

    const [id_proof, setId_proof] = useState(null);
    const [address_proof, setAddress_proof] = useState(null);
    const [image, setImage] = useState(null)

    const [showModel, setShowModel] = useState(false)
    const webcamRef = useRef(null)
    const [imageSrc, setImageSrc] = useState("")

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    const takePicture = () => {
        let imgBase = webcamRef.current.getScreenshot()
        setImageSrc(imgBase)
        let file = dataURLtoFile(imgBase, `selfie_${user?.first_name}.jpeg`)
        setImage(file)
        setShowModel(false)
    }

    const submitData = () => {
        let formData = new FormData
        if (id_proof && address_proof && image) {

            // console.log(id_proof)
            // console.log(address_proof)
            // console.log(image)

            formData.append("id_proof", id_proof)
            formData.append("address_proof", address_proof)
            formData.append("image", image)
            request("POST", "/secure/user-kyc-verify/", formData)
        } else {
            dispatch(activateFeedback({ type: 'error', message: 'Please upload all data.' }))
        }
    }

    useEffect(() => {
        if (response) {
            dispatch(activateFeedback({ type: response?.status, message: response?.message }))
            if (response?.status === 'success') {
                setId_proof(null);
                setAddress_proof(null)
                setImage(null)
                dispatch(updateKycStatus(response?.data?.status))
            }
        }
    }, [response])

    const webcamErrorHandler = (e) => {
        setShowModel(false)
        dispatch(activateFeedback({ type: 'error', message: 'Unable to capture the image' }))
    }

    const openCamera = () => {
        if (process.env.NODE_ENV === "development") {
            setImage(id_proof)
            return;
        }
        setShowModel(true)
    }

    return (
        loading ? <Preloader /> :
            <>
                <div className="setting-content-con" >
                    <div className="setting-content-con-header">Account Verification</div>
                    {
                        user?.verification_status === null &&
                        <>
                            <div className="setting-input">
                                <div className="setting-input-con">
                                    <label htmlFor="new_email">ID Copy</label>
                                    <FileUploader
                                        multiple={false}
                                        handleChange={(data) => { setId_proof(data) }}
                                        name="file"
                                        types={['JPEG', 'PNG', 'JPG']}
                                    >
                                        <div className='dropSelect-div'>
                                            <p>{id_proof ? `File name: ${id_proof?.name}` : <><img src="./images/setting/camera.png" alt="" />&nbsp;&nbsp;Upload Photo</>}</p>
                                        </div>
                                    </FileUploader>
                                    <div className="setting-alert">Image must completely fit the document</div>
                                </div>
                                <div className="setting-input-con">
                                    <label htmlFor="new_email">Selfie</label>
                                    <div>
                                        <div className="imag_block selfieFlex">
                                            <div className="add_profileWall">
                                                <div className="add_profile_More">
                                                    {
                                                        image ?
                                                            <div className="add_profile_More seletimg"
                                                            >
                                                                <div className="imgwall">
                                                                    <img src={imageSrc} alt="" />
                                                                </div>
                                                                <div className="img_remove">
                                                                    <img src="./images/setting/close.png" onClick={() => { setImage(null) }} />
                                                                </div>
                                                            </div>
                                                            :
                                                            <img src="./images/setting/camera.png" alt="" className="input-icon" onClick={openCamera} style={{ cursor: 'pointer' }} />
                                                    }
                                                </div>
                                            </div>
                                            <div className="setting-alert">Please take a selfie of yourself with a paper next to you written the current date and your name.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="setting-input-con">
                                    <label htmlFor="new_email">Proof Of Residence</label>
                                    <FileUploader
                                        multiple={false}
                                        handleChange={(data) => { setAddress_proof(data) }}
                                        name="file"
                                        types={['JPEG', 'PNG', 'JPG']}
                                    >
                                        <div className='dropSelect-div'>
                                            <p>{address_proof ? `File name: ${address_proof?.name}` : <><img src="./images/setting/camera.png" alt="" />&nbsp;&nbsp;Upload Photo</>}</p>
                                        </div>
                                    </FileUploader>
                                    <div className="setting-alert">Image must completely fit the document</div>

                                </div>
                            </div>
                            <div className="setting-bottom">
                                <button type='submit' className="blue-btn btn setting-btn" onClick={submitData}>Submit</button>
                            </div>
                        </>

                    }
                    {
                        user?.verification_status === 'waiting' &&


                        <div className="verification_block">
                            <div className=" thumb_block thumb_process">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M64 352c17.69 0 32-14.32 32-31.1V64.01c0-17.67-14.31-32.01-32-32.01S32 46.34 32 64.01v255.1C32 337.7 46.31 352 64 352zM64 400c-22.09 0-40 17.91-40 40s17.91 39.1 40 39.1s40-17.9 40-39.1S86.09 400 64 400z" fill="Currentcolor" /></svg> </div>

                            <div className="verification-title" style={{ color: 'black' }}>  Your KYC verification is under process</div>
                        </div>

                    }
                    {
                        user?.verification_status === 'rejected' &&
                        <>
                            {/* <div className="setting-error">Verification is rejected</div> */}

                            <div className="verification_block">
                                <div className=" thumb_block thumb_rejected">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" fill="Currentcolor" /></svg>  </div>

                                <div className="verification-title" style={{ color: 'black' }}> Verification is rejected</div>
                            </div>


                            <div className="setting-alert">
                                <div className="alert_block">    <strong>Reject Reason : </strong>{user?.verification_reject_reason}</div>
                            </div>
                            <div className="setting-bottom">
                                <button type='submit' className="blue-btn btn setting-btn" onClick={() => { dispatch(updateKycStatus(null)) }}>Submit again</button>
                            </div>
                        </>
                    }
                    {
                        user?.verification_status === 'approved' &&
                        // <div className="setting-success">Your KYC verification is approved</div>


                        <div className="verification_block">
                            <div className="thumb_block thumb_approved">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" fill="Currentcolor" /></svg>    </div>

                            <div className="verification-title" style={{ color: 'black' }}>  Your KYC verification is approved</div>
                        </div>
                    }
                </div>
                <div
                    className={
                        showModel
                            ? "finish-modal setting-modal active"
                            : "finish-modal setting-modal"
                    }
                >
                    <div
                        className="modal-close"
                        onClick={() => {
                            setShowModel(false);
                        }}
                    >
                        <img src="images/auth/auth-close.png" alt="" />
                    </div>
                    <div className="modal-header">Selfie</div>
                    <div className="modal-body">
                        {showModel && <Webcam
                            ref={webcamRef}
                            style={{ height: '300px' }}
                            onUserMediaError={webcamErrorHandler}
                        />}
                        <div className="setting-bottom">
                            <button type='button' className="blue-btn btn setting-btn" onClick={takePicture} >Capture</button>
                        </div>
                    </div>
                </div>
            </>
    );
}

export default AccVerification;