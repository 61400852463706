import React from "react";
import "./../../css/common.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import TopBlackShape from "../../images/home_new/top-black-shape.svg";
import Twitter from "../../images/home_new/twitter.svg";
import Facebook from "../../images/home_new/facebook.svg";
import Instagram from "../../images/home_new/instagram.svg";
import Discord from "../../images/home_new/discord.svg";
import { MultiLang } from "../MultiLang";

export default function Footer() {
  const { settings } = useSelector((state) => state.Site);

  const { language } = useSelector((state) => state.reducer);

  return (
    <div>
      <footer>
        <div className="footerSection">
          <div className="topBlackShape">
            <img src={TopBlackShape} alt="..." />
          </div>
          <div className="footerInnerCol">
            <div className="container">
              <ul className="socialIcons">
                <li>
                  <a href={settings?.twitter} target="_blank">
                    <img src={Twitter} alt="..." />
                  </a>
                </li>
                <li>
                  <a href={settings?.facebook} target="_blank">
                    <img src={Facebook} alt="..." />
                  </a>
                </li>
                <li>
                  <a href={"https://www.instagram.com/strongerplanet10/"} target="_blank">
                    <img src={Instagram} alt="..." />
                  </a>
                </li>
                <li>
                  <a href={"https://discord.gg/ny22hU9z92"} target="_blank">
                    <img src={Discord} alt="..." />
                  </a>
                </li>
              </ul>
              <ul className="footerLinks">
                <li>
                  <a href="/about">{MultiLang["About Us"][language]}</a>
                </li>
                <li>
                  <a href="/contact">{MultiLang["Contact Us"][language]}</a>
                </li>
                {/* <li>
                  <a href="/pro">Be Our PRO</a>
                </li> */}
              </ul>
            </div>
            <div className="footerBtmCol">
              <div className="container">
                <div className="row">
                  <div className="col-sm-auto order-sm-last">
                    <ul className="footerBtmLinks">
                      <li>
                        <a href="/privacy">{MultiLang["privacy-policy"][language]}</a>
                      </li>
                      <li>
                        <a href="/terms">{MultiLang["terms-conditions"][language]}</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm">
                    <div className="copyrightCol">© Copyright 2022 Stronger.lu</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
