import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import AuthEmployee from "./corporation-store/auth/reducer";
import AuthClient from "./corporation-client/auth/reducer";
import Site from "./site/reducers";

const initialState = {
  wallet_address: "disconnected",
  language: "GB",
};
function reducer(state = initialState, action) {
  switch (action.type) {
    case "WALLETCONNECTION":
      return {
        ...state,
        wallet_address: action.payload,
      };
    case "LANGUAGE":
      return {
        ...state,
        language: action.payload,
      };
    default:
      return state;
  }
}

export default combineReducers({
  Auth,
  Site,
  reducer,
  AuthEmployee,
  AuthClient,
});
