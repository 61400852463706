import "./../../css/challenge_start.css"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux"

import XtraVision from "../../SDK/xtraVision"
import VideoBox2 from "./VideoBox2";

export default function StartTraining() {
    const { training } = useSelector(state => state.Auth)
    const [model, setModel] = useState(false)
    const navigate = useNavigate()

    const [preprationTime, setPreprationTime] = useState(training?.prepration_time)
    const [trainingTime, setTrainingTime] = useState(0)
    const [startTraining, setStartTraining] = useState(false)
    const [results, setResults] = useState(null)

    const [camControls, setCamControls] = useState(null)
    const [isEdu, setIsEdu] = useState(true)



    useEffect(() => {
        if (camControls) {
            camControls.startCamera()
        }
    }, [camControls])

    const handlePreprationTimer = () => {
        setPreprationTime(preprationTime - 1)
    }

    const handleTrainingTimer = () => {
        setTrainingTime(trainingTime + 1)
    }

    useEffect(() => {
        if (preprationTime <= 0) {
            setStartTraining(true)
            // camControls.startCamera()            
            setIsEdu(false)
            return
        }
        setTimeout(() => {
            handlePreprationTimer()
        }, 1000)

    }, [preprationTime])

    useEffect(() => {
        if (startTraining) {
            if (trainingTime >= training?.training_time) {
                setStartTraining(false)
                // camControls.setIsCamOn(false)
                camControls.stopCamera()

                setModel(true)
                return
            }
            setTimeout(() => {
                handleTrainingTimer()
            }, 1000)
        }
    }, [trainingTime, startTraining])

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!preprationTime || trainingTime === undefined) {
            navigate('/challenge')
        }
    }, [])


    return (
        <>
            <div className="_challenge-start _challenge-start02">
                <div className="content">
                    <div className="challenge-start02-header webcam_header">
                        {
                            !model ?
                            <div className="content">
                                {
                                    isEdu ?
                                        <>
                                            <div className="cameraParent">
                                                <XtraVision
                                                    isEdu={true}
                                                    setCamControls={setCamControls}
                                                    setResults={setResults}
                                                    assesmentType={training?.type}
                                                />
                                            </div>
                                            <div className={`warningCard ${results?.isPassed ? 'passed' : ""}`}>
                                                {
                                                    !results?.isPassed &&
                                                    <span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path fill="currentColor" d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z" /></svg>
                                                    </span>
                                                }
                                                <p>
                                                    {results?.message ? results?.message : "Take a few steps back until you see your full body in the screen!. "} Recording will start after {preprationTime}s
                                                </p>
                                                <button className="skipWarning" onClick={() => { setPreprationTime(0) }}>SKIP PREPRATION</button>
                                            </div>

                                        </>
                                        :
                                        <div className="cameraParent">
                                            <button type="button" className="leaveBtn" onClick={() => { setModel(true); setStartTraining(false) }}>Leave</button>
                                            <XtraVision
                                                isEdu={false}
                                                setCamControls={setCamControls}
                                                setResults={setResults}
                                                assesmentType={training?.type}
                                            />
                                            <div className="resutCam_parent">
                                                <div className="resultCard">
                                                    <div className="resultCard_title">
                                                        Exercise:
                                                    </div>
                                                    <div className="resultCard_count">
                                                        {results?.assessmentName ? results.assessmentName : training?.type}
                                                    </div>
                                                </div>
                                                <div className="resultCard">
                                                    <div className="resultCard_title">
                                                        Reps:
                                                    </div>
                                                    <div className="resultCard_count">
                                                        {results?.repCount ? results?.repCount : '0'}
                                                    </div>
                                                </div>
                                                <div className="resultCard">
                                                    <div className="resultCard_title">
                                                        Time (seconds):
                                                    </div>
                                                    <div className="resultCard_count">
                                                        {/* 05 :16 */}
                                                        {trainingTime}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                            :
                            <div style={{height:'100vh'}}></div>
                        }

                    </div>
                </div>
            </div>
            <div className={model === false ? "finish-modal " : "finish-modal newMeetingModal active"}>
                <div className="modalTitleNew">
                    Results: {results?.assessmentName}
                    <a href="#!" onClick={() => { setModel(false); window.location.reload() }}>&times;</a>
                </div>

                <div className="meetingModal_body">
                    <div className="meetingModal_card">
                        Reps
                        <span>{results?.repCount ? results?.repCount : "0"}</span>
                    </div>
                    <div className="meetingModal_card">
                        Exercise
                        <span>{results?.assessmentName ? results.assessmentName : training?.type}</span>
                    </div>
                    <div className="meetingModal_card">
                        Time Spent (in sec)
                        <span>{trainingTime}</span>
                    </div>
                </div>
            </div>
        </>
    )
}