import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useRequest from "./useRequest";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import {
  autoLoginSuccess,
  setSetting,
  makeSocket,
  activateFeedback,
  setSocketActiveChallenge,
  setOnlineUsers,
  updateWallet,
} from "../redux/actions";

import { authSuccess } from "../redux/corporation-client/auth/action";
// import { authSuccess as authEmployeeSuccess } from "../redux/corporation-store/auth/action";

const useParallels = () => {
  const [webpageLoaded, setWebpageLoaded] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loggedIn, user, activeTimingChallenges } = useSelector(
    (state) => state.Auth
  );

  // Auth and initial settings -------------------------------------
  const [isToken, setIsToken] = useState(null);
  const { request: authReq, response: authRes } = useRequest();
  const { request: settingReq, response: settingRes } = useRequest();

  let data = localStorage.getItem("auth")
    ? JSON.parse(localStorage.getItem("auth"))
    : JSON.parse(localStorage.getItem("clientAuth"));

  useEffect(() => {
    settingReq("GET", "/settings");
    if (!data) {
      setIsToken(false);
    }
  }, []);

  useEffect(() => {
    if (settingRes) {
      let data = { ...settingRes, settingData: null };
      settingRes?.settingData.forEach((ele) => {
        data[ele.key_name] = ele.value;
      });
      dispatch(setSetting(data));
    }
  }, [settingRes]);

  useEffect(() => {
    if (!loggedIn) {
      if (data?.access_token) {
        authReq("GET", "/secure/profile/", undefined, {
          Authorization: `Bearer ${data.access_token}`,
        });
      }
    }
  }, [loggedIn]);

  useEffect(() => {
    if (authRes) {
      if (data.user_role_id && data.user_role_id == 3) {
        dispatch(authSuccess(authRes.data));
      } else if (data.user_role_id && data.user_role_id == 4) {
        dispatch(authEmployeeSuccess(authRes.data));
      } else {
        dispatch(
          autoLoginSuccess({
            access_token: data.access_token,
            data: authRes.data,
          })
        );
        dispatch(setOnlineUsers(authRes?.data?.total_count + 1));
      }

      setIsToken(true);
    }
  }, [authRes]);

  // -----------------------------------------

  // Socket --------------------------------------
  const [socket, setSocket] = useState(null);
  useEffect(() => {
    if (loggedIn) {
      setSocket(io(process.env.REACT_APP_SOCKET_URI));
    }
  }, [loggedIn]);

  useEffect(() => {
    if (socket && loggedIn) {
      dispatch(makeSocket(socket));
      socket.emit("UserLogin", { user_id: user?.id });

      socket.on("ChallengeInfoResponse", (data) => {
        // console.log(data)
        const {
          total_count_join,
          total_count_in_challenge,
          challenge_id,
          password,
          challengeTimeSec,
          joinTimeSec,
          totalTimeSec,
        } = data;
        // console.log('recieved')
        dispatch(
          setSocketActiveChallenge({
            message: `${
              +total_count_in_challenge - +total_count_join
            } Participate is remaining for join this challange.`,
            start: false,
          })
        );

        if (total_count_join == total_count_in_challenge) {
          dispatch(
            activateFeedback({
              type: "success",
              message: "Your challenge is about to start !",
            })
          );
          dispatch(
            setSocketActiveChallenge({
              start: true,
              password,
              challengeTimeSec: parseInt(challengeTimeSec),
              joinTimeSec: parseInt(joinTimeSec),
              totalTimeSec: parseInt(totalTimeSec),
            })
          );
          navigate(`/challenge/${challenge_id}`);
        }
      });

      socket.on("UserStatusResponse", ({ total_count }) => {
        dispatch(setOnlineUsers(total_count));
        // console.log(total_count)
      });
    }
    // return () => {
    //   socket.off("ChallengeInfoResponse");
    //   socket.off("UserStatusResponse");
    // };
  }, [socket, loggedIn]);
  //---------------------------------------------

  // for timing challenge (start)-----------------
  const { request: reqPassword, response: resPassword } = useRequest();

  useEffect(() => {
    if (activeTimingChallenges) {
      const { startTime, challId } = activeTimingChallenges;

      if (startTime < 0) return;

      setTimeout(() => {
        reqPassword("GET", `/participate-status/${challId}`);
        navigate(`/challenge/${challId}`);
      }, (startTime + 1) * 1000);
    }
  }, [activeTimingChallenges]);

  useEffect(() => {
    if (resPassword) {
      if (resPassword?.start && resPassword?.challengeSeceret) {
        dispatch(
          setSocketActiveChallenge({
            start: true,
            password: resPassword.challengeSeceret,
            challengeTimeSec:
              parseInt(resPassword?.challengeTimeSec) -
              parseInt(resPassword?.joinTimeSec),
            joinTimeSec: parseInt(resPassword?.joinTimeSec),
            totalTimeSec: parseInt(resPassword?.challengeTimeSec),
          })
        );
        dispatch(
          activateFeedback({
            type: "success",
            message: "Your challenge is about to start !",
          })
        );
        return;
      } else {
        dispatch(
          activateFeedback({ type: "error", message: resPassword?.message })
        );
        dispatch(updateWallet(+resPassword?.updatedAmount));
        navigate("/challenge?tab=ongoing");
      }
    }
  }, [resPassword]);
  // ------------------------------------------------------------------

  // to remove the scroll effect from number input field -------------------
  useEffect(() => {
    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
  }, []);
  // ----------------------------------------------------------------------

  return {
    setWebpageLoaded,
    isToken,
  };
};

export default useParallels;
