import React, { useRef } from 'react'
import { useDispatch } from "react-redux"
import { activateFeedback } from "../../redux/actions";
import Webcam from 'react-webcam'

const SelfieModel = ({ show, setShow, setImgSrc, setSelfie }) => {    
    const webcamRef = useRef(null)
    const dispatch = useDispatch()

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    const takePicture = () => {
        let imgBase = webcamRef.current.getScreenshot()
        setImgSrc(imgBase)
        let file = dataURLtoFile(imgBase, `selfie.jpeg`)
        setSelfie(file)
        setShow(false)
    }

    const webcamErrorHandler = (e) => {
        setShow(false)
        dispatch(activateFeedback({ type: 'error', message: 'Unable to capture the image' }))
    }

    return (
        <div
            className={
                show
                    ? "finish-modal setting-modal active"
                    : "finish-modal setting-modal"
            }
        >
            <div
                className="modal-close"
                onClick={() => {
                    setShow(false);
                }}
            >
                <img src="images/auth/auth-close.png" alt="" />
            </div>
            <div className="modal-header">Selfie</div>
            <div className="modal-body">
                {show && <Webcam
                    ref={webcamRef}
                    style={{ height: '300px' }}
                    onUserMediaError={webcamErrorHandler}
                />}
                <div className="setting-bottom">
                    <button type='button' className="blue-btn btn setting-btn" onClick={takePicture} >Capture</button>
                </div>
            </div>
        </div>
    )
}

export default SelfieModel
