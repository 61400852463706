export const walletConnected = (address) => ({
    type: "WALLETCONNECTION",
    payload: address,
});

export const setLanguage = (language) => {
    return {
        type: "LANGUAGE",
        payload: language,
    };
};

export * from "./auth/actions";
export * from "./site/actions";
