import React from 'react'

const Header = () => {
  return (
             <div className="dash_header">
                <div className="dash_logo">
                    <a href="javascript:void(0);" className="d-flex">
                        <img src="assets/img/logoicon.svg" alt="" />
                        <img className="logo_fold" src="assets/img/logotext.svg" alt="" />
                    </a>
                </div>
                <div className="nav_dash_wrap">
                    <div className="nav_dash_wrpLeft">
                        <a href="javascript:void(0);" className="dashIconFold" id="foldBtn">
                            <div className="notFolded">
                                <svg viewBox="64 64 896 896" focusable="false" className="" data-icon="menu-fold"
                                    width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                    <path
                                        d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM115.4 518.9L271.7 642c5.8 4.6 14.4.5 14.4-6.9V388.9c0-7.4-8.5-11.5-14.4-6.9L115.4 505.1a8.74 8.74 0 0 0 0 13.8z">
                                    </path>
                                </svg>
                            </div>

                            <div className="folded ">
                                <svg viewBox="64 64 896 896" focusable="false" className="" data-icon="menu-unfold"
                                    width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                    <path
                                        d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z">
                                    </path>
                                </svg>
                            </div>
                        </a>
                    </div>
                    <div className="nav_dash_wrpRight">                   
{/*                       
                        <div className="nav_right_notification dropdown mr-15">
                            <a href="javascript:void(0);" className="rightMenuBtn newNotiMsg" data-bs-toggle="dropdown">
                                <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.638672 17V15H2.62048V8C2.62048 6.61667 3.03335 5.38733 3.8591 4.312C4.68485 3.23733 5.75833 2.53333 7.07953 2.2V1.5C7.07953 1.08333 7.22421 0.729333 7.51355 0.438C7.80223 0.146 8.15301 0 8.56589 0C8.97876 0 9.32954 0.146 9.61823 0.438C9.90757 0.729333 10.0522 1.08333 10.0522 1.5V2.2C11.3734 2.53333 12.4469 3.23733 13.2727 4.312C14.0984 5.38733 14.5113 6.61667 14.5113 8V15H16.4931V17H0.638672ZM8.56589 20C8.02089 20 7.55451 19.8043 7.16673 19.413C6.7783 19.021 6.58408 18.55 6.58408 18H10.5477C10.5477 18.55 10.3538 19.021 9.96603 19.413C9.5776 19.8043 9.11088 20 8.56589 20ZM4.60228 15H12.5295V8C12.5295 6.9 12.1414 5.95833 11.3652 5.175C10.589 4.39167 9.65588 4 8.56589 4C7.4759 4 6.5428 4.39167 5.76659 5.175C4.99038 5.95833 4.60228 6.9 4.60228 8V15Z" fill="currentcolor"/>
                                    </svg>
                                    
                            </a>
                            <div className="dropdown-menu dropdown-menu-end">
                                <div className="notiHeader_top">
                                    <div className="notiHeaderTopHeading">
                                        <i className="fal fa-bell"></i>
                                        <span className="">Notification</span>
                                    </div>
                                    <a className="viewBtn" href="javascript:void(0);">
                                        <small>View All</small>
                                    </a>
                                </div>
                                <ul className="notificationListDrop">
                                    <li>
                                        <a href="javascript:void(0);" className="dropdown-item">
                                            <div className="notiListCard">
                                                <div className="notiListImg">
                                                    <img src="assets/img/dash-userimg.jpg" alt="" />
                                                </div>
                                                <div className="notiListContent">
                                                    <p>Channel: <span>Social Relationship</span> request to join has be
                                                        accepted.</p>
                                                    <small>04 April, 2021 | 04:00 PM</small>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);" className="dropdown-item">
                                            <div className="notiListCard">
                                                <div className="notiListImg">
                                                    <img src="assets/img/dash-userimg.jpg" alt="" />
                                                </div>
                                                <div className="notiListContent">
                                                    <p>Channel: <span>Social Relationship</span> request to join has be
                                                        accepted.</p>
                                                    <small>04 April, 2021 | 04:00 PM</small>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);" className="dropdown-item">
                                            <div className="notiListCard">
                                                <div className="notiListImg">
                                                    <img src="assets/img/dash-userimg.jpg" alt="" />
                                                </div>
                                                <div className="notiListContent">
                                                    <p>Channel: <span>Social Relationship</span> request to join has be
                                                        accepted.</p>
                                                    <small>04 April, 2021 | 04:00 PM</small>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);" className="dropdown-item">
                                            <div className="notiListCard">
                                                <div className="notiListImg">
                                                    <img src="assets/img/dash-userimg.jpg" alt="" />
                                                </div>
                                                <div className="notiListContent">
                                                    <p>Channel: <span>Social Relationship</span> request to join has be
                                                        accepted.</p>
                                                    <small>04 April, 2021 | 04:00 PM</small>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);" className="dropdown-item">
                                            <div className="notiListCard">
                                                <div className="notiListImg">
                                                    <img src="assets/img/dash-userimg.jpg" alt="" />
                                                </div>
                                                <div className="notiListContent">
                                                    <p>Channel: <span>Social Relationship</span> request to join has be
                                                        accepted.</p>
                                                    <small>04 April, 2021 | 04:00 PM</small>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);" className="dropdown-item">
                                            <div className="notiListCard">
                                                <div className="notiListImg">
                                                    <img src="assets/img/dash-userimg.jpg" alt="" />
                                                </div>
                                                <div className="notiListContent">
                                                    <p>Channel: <span>Social Relationship</span> request to join has be
                                                        accepted.</p>
                                                    <small>04 April, 2021 | 04:00 PM</small>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);" className="dropdown-item">
                                            <div className="notiListCard">
                                                <div className="notiListImg">
                                                    <img src="assets/img/dash-userimg.jpg" alt="" />
                                                </div>
                                                <div className="notiListContent">
                                                    <p>Channel: <span>Social Relationship</span> request to join has be
                                                        accepted.</p>
                                                    <small>04 April, 2021 | 04:00 PM</small>
                                                </div>
                                            </div>
                                        </a>
                                    </li>

                                </ul>

                            </div>


                        </div>
                        <div className="dash_userProfile">
                            <img src="./img/dash-userimg.png" alt="" />
                            <div className="prfDash_userContent">
                                <div className="topPRfNameUser">Jack Reacher</div>
                                <div className="makeAvaiDash">
                                    <span>Maker</span>
                                </div>
                            </div>
                        </div> */}
                      


                    </div>
                </div>
            </div>
  )
}

export default Header
