import "./../css/profile.css"
import { useEffect, useState, useRef } from "react"
import { Link, useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { addUserData, activateFeedback, makeSocket } from "../redux/actions"
import useRequest from "../hooks/useRequest";
import usePagination from "../hooks/usePagination"
import PreloaderOuter from "./components/preloaderOuter";
import moment from 'moment';
import { MultiLang } from "./MultiLang";

import ResultModel from "./components/ResultModel"


export default function Profile() {
    const [activeTab, setActiveTab] = useState(0);
    const [profileId, setProfileId] = useState("");
    const [myChallenges, setMyChallenges] = useState(null)
    const [totalResults, setTotalResults] = useState(0)

    const { user, loading } = useSelector(state => state.Auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { request: reqImg, response: resImg } = useRequest()
    const { request, response } = useRequest()
    const { pagination, activePage, pageSize } = usePagination(totalResults)
    const { language } = useSelector((state) => state.reducer);

    const [queryData, setQueryData] = useState({
        page_size: pageSize,
        page: activePage,
    })
    useEffect(() => {
        setQueryData({ ...queryData, page_size: pageSize, page: activePage })
    }, [activePage, pageSize])

    useEffect(() => {
        request('GET', `/my-challenges?page=${queryData.page}&page-size=${queryData.page_size}`)
    }, [queryData])

    const modalRef = useRef(null);

    const openModal = (id) => {
        modalRef.current.setModalStateOut(true);
        modalRef.current.setChall(id)
    }

    const handleProfileImageChange = (e) => {
        if (e?.target?.files.length !== 0) {
            let image = e.target.files[0]
            const formData = new FormData();
            formData.append("profile_image", image)
            reqImg("POST", "/secure/update-profile-image/", formData)
            return
        }
        dispatch(activateFeedback({ type: 'error', message: 'file not choosen' }))
    }

    useEffect(() => {
        if (resImg) {
            dispatch(activateFeedback({ type: resImg?.status, message: resImg?.message }))
            if (resImg?.status === 'success') {
                dispatch(addUserData({ profile_image: resImg?.data?.profile_image }))
            }
        }
    }, [resImg])

    useEffect(() => {
        if (response) {
            // console.log(response)
            // dispatch(activateFeedback({ type: response?.status, message: response?.message }))
            if (response?.status === 'success') {
                setMyChallenges(response?.data?.result)
                setTotalResults(response?.data?.total_record)
            }
        }
    }, [response])

    useEffect(() => {
        window.scrollTo(0, 0)
        // request('GET', '/my-challenges')
    }, [])

    const badges = {
        "1": "./images/profile/badge-gold.png",
        "2": "./images/profile/badge-silver.png",
        "3": "./images/profile/badge-bronze.png"
    }
    return (
        <>
            {
                loading ? <PreloaderOuter /> :
                    <div className="_profile">
                        <div className="profile-header">
                            <div className="content">
                                <div className='profile-user-img onlineStatus'>
                                    <img src={user?.profile_image ? user.profile_image : "./images/profile/noAvatar.png"} alt="" />
                                    <div className="usered">
                                        <div className="userwall-edit">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z" fill="Currentcolor" />
                                            </svg>
                                            <input type="file" name='profile_image' onChange={handleProfileImageChange} />
                                        </div>
                                    </div>
                                    {/* <div>
                                        <span className="verified-span">verified <img src='/images/profile/icon-success.png' width='20px' height='20px' alt=''/></span>
                                    </div> */}
                                </div>
                                {
                                    user?.is_kycVerified &&
                                    <div className="veriedUser">
                                        <img src="./images/verifiedIcon.png" alt="" />
                                        <span>Verified</span>
                                    </div>
                                }

                                <div className="profile-user-infor">
                                    <div className="user-infor-header">
                                        <div className="user-infor-name">
                                            <div className="user-infor-name-name">
                                                {user.first_name}
                                                <span className='stocksMarket ms-auto' style={{ color: 'white' }}>
                                                    {user?.current_rank}
                                                    {
                                                        user?.current_rank >= user?.old_rank ?
                                                            <span span className="stocksCircle upStocks">
                                                                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M4.589 0.593224C4.78784 0.306221 5.21216 0.30622 5.411 0.593224L9.65246 6.71525C9.8822 7.04685 9.64488 7.5 9.24147 7.5H0.758534C0.355124 7.5 0.117797 7.04685 0.347537 6.71525L4.589 0.593224Z" fill="currentColor" />
                                                                </svg>
                                                            </span>
                                                            :
                                                            <span className="stocksCircle downStocks">
                                                                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M4.589 0.593224C4.78784 0.306221 5.21216 0.30622 5.411 0.593224L9.65246 6.71525C9.8822 7.04685 9.64488 7.5 9.24147 7.5H0.758534C0.355124 7.5 0.117797 7.04685 0.347537 6.71525L4.589 0.593224Z" fill="currentColor" />
                                                                </svg>
                                                            </span>
                                                    }
                                                    {/* <img src="/images/profile/triangle.png" alt="" /> */}
                                                </span>
                                            </div>
                                            <div className="user-infor-history">
                                                {user?.other_data?.total_medal_count} {MultiLang["Medal"][language]} <span className="dot">&nbsp;&middot;&nbsp;</span>{user?.other_data?.total_wins} {MultiLang["Wins"][language]}<span className="dot">&nbsp;&middot;&nbsp;</span> {user?.other_data?.total_lose} {MultiLang["Losings"][language]}
                                            </div>
                                        </div>
                                        <div className="user-infor-bottom _sp">
                                            <div className="user-infor-bottom-text">
                                                <div>
                                                    {user.user_level} Level
                                                </div>
                                                <div>
                                                    {user.user_xp} / {user?.other_data?.max_level_xp} xp
                                                    {/* <img src="./images/profile/mark01.png" alt="" /> */}
                                                    <div className="tooltip-custom">
                                                        <div className="transaction-icon ">
                                                            <img src="../images/profile/mark01.png" alt="" />
                                                        </div>
                                                        <span className="tooltiptext">{user.user_xp} / {user?.other_data?.max_level_xp} xp</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="user-infor-level">
                                                <div style={{ width: "30%" }}></div>
                                            </div>
                                        </div>
                                        <div className="user-badge-con">
                                            <div className="user-badge">
                                                <img src="./images/profile/badge-gold.png" alt="" />
                                                {user?.other_data?.first_medal_count}
                                            </div>
                                            <div className="user-badge">
                                                <img src="./images/profile/badge-silver.png" alt="" />
                                                {user?.other_data?.second_medal_count}
                                            </div>
                                            <div className="user-badge">
                                                <img src="./images/profile/badge-bronze.png" alt="" />
                                                {user?.other_data?.third_medal_count}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-infor-bottom _pc">
                                        <div className="user-infor-bottom-text">
                                            <div>
                                                {user.user_level} Level
                                            </div>
                                            <div>
                                                {user.user_xp} / {user?.other_data?.max_level_xp} xp
                                                {/* <img src="./images/profile/mark01.png" alt="" /> */}
                                                <div className="tooltip-custom">
                                                    <div className="transaction-icon ">
                                                        <img src="../images/profile/mark01.png" alt="" />
                                                    </div>
                                                    <span className="tooltiptext">{user.user_xp} / {user?.other_data?.max_level_xp} xp</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="user-infor-level">
                                            <div style={{ width: `${(user.user_xp / user?.other_data?.max_level_xp) * 100}%` }}></div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="profile-user-balance">
                            <div>
                                My Balance
                            </div>
                            <div>
                                <div className="value-balance"> {Math.round(+user?.wallet_balance * 100) / 100}€ </div>
                                <img style={{ cursor: "pointer" }} onClick={() => { navigate("/setting?tab=transaction_history") }} src="./images/profile/doc.png" alt="" />
                            </div>

                        </div> */}
                            </div>
                        </div>
                        <div className="profile-main">
                            <div className="text-center mb-4">
                                <h2 className="title">{MultiLang["My Challenges"][language]}</h2>
                            </div>
                            <div className="profile-tab-content active">
                                {/* <div className="content"> */}
                                {
                                    myChallenges && myChallenges.length > 0 ?
                                        <>
                                            <div className="content">
                                                <div className="cont_bx">
                                                    {
                                                        myChallenges.map((val, ind) => {
                                                            return (
                                                                <div key={ind} className="profile-main-con" >
                                                                    <div className="profile-main-con-header">
                                                                        <img src={val?.challengeData?.cover_image} alt="" />

                                                                        <div className="profile-main-con-badge2">

                                                                            {MultiLang["Prize"][language]}: <br /> <span>{+val?.price}€</span>

                                                                        </div>
                                                                    </div>
                                                                    <div className="profile-main-con-bottom">
                                                                        <div>
                                                                            {val?.challengeData?.title}
                                                                            <img src={badges[val?.rank]} alt="" />
                                                                        </div>
                                                                        <div className="profile-main-con-text">
                                                                            <div>
                                                                                <img src="./images/profile/icon-group.png" alt="" />
                                                                                {MultiLang["Participants"][language]}: <strong>{val?.total_participated}/{val?.challengeData?.participant_count}</strong>
                                                                            </div>
                                                                            {/* <div>
                                                                                <img src="./images/profile/icon-prize.png" alt="" />
                                                                                Result Date: <strong>
                                                                                    {moment(val?.challengeData?.challenge_end_date).format("D MMM")}
                                                                                </strong>
                                                                            </div> */}
                                                                        </div>
                                                                        <div>
                                                                            {
                                                                                val?.is_result ?
                                                                                    <button className="profile-main-con-btn" onClick={() => { openModal(val?.challenge); setProfileId("01"); }}>
                                                                                        <img src="./images/profile/icon-prize-blue.png" alt="" />
                                                                                        {MultiLang["See Result"][language]}
                                                                                    </button>
                                                                                    :
                                                                                    <button className="profile-main-con-btn" >
                                                                                        {/* <img src="./images/profile/icon-prize-blue.png" alt="" /> */}
                                                                                        {MultiLang["Result not announced yet!"][language]}
                                                                                    </button>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="paginationProfile">
                                                    {pagination}
                                                </div>
                                            </div>
                                        </>
                                        : <div className="challenge-nodata nodata-white"> {MultiLang["No data found"][language]} </div>
                                }
                            </div>
                        </div>
                    </div>
            }
            <ResultModel id={profileId} ref={modalRef} parent='profile' />
        </>

    )
}
