import { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDetectOutsideClick } from "./OutSideDetect";
import { logoutUser } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import ReactFlagsSelect from "react-flags-select";
import { setLanguage } from "../../redux/actions";
import { MultiLang } from "../MultiLang";
import useRequest from "../../hooks/useRequest";
import moment from "moment";
import {toast} from "react-toastify";

import { Nav, NavLink, NavMenu } from "./NavbarElements";

export default function AuthedNavbar() {
  const dropdownRefSp = useRef(null);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const [isActiveSp, setIsActiveSp] = useDetectOutsideClick(
    dropdownRefSp,
    false
  );
  const [menuState, setMenuState] = useState(false);
  const [tab, setTab] = useState("");

  const { socket } = useSelector((state) => state.Site);
  const { request: notifiReq, response: notifiRes } = useRequest();
  const { request: markReadReq, response: markReadRes } = useRequest();
  const { search } = useLocation();
  const { user, notification,loggedIn } = useSelector((state) => state.Auth);
  const { settings, onlineUsers } = useSelector((state) => state.Site);
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.reducer);
  const [notifications, setNotifications] = useState({
    earlier: [],
    latest: [],
  });
  const [seeAll, setSeeAll] = useState(false);
  const newNotifiCount = 5;

  useEffect(() => {
    // if(!loggedIn){
    //   navigate("/");
    // }
  },[])
  useEffect(() => {
    if (search) {
      setTab(search.split("=")[1]);
    }
  }, [search]);

  const logOut = () => {
    localStorage.clear();
    navigate("/");
  };
  const logOut1 = () => {
 
    dispatch(logoutUser())
    navigate("/");
    toast.success("Now your are logout!");
  };

  // const getNotifications = () => {
  //     notifiReq('GET', '/notifications')
  // }
  // useEffect(() => {
  //     getNotifications()
  // }, [])

  useEffect(() => {
    if (notification?.data.length) {
      let latest = [];
      let earlier = [];
      // notification.data.result.forEach((val) => {
      //     if (+moment(val?.created_at).format("D") < +moment().format("D")) {
      //         earlier.push(val)
      //     } else {
      //         latest.push(val)
      //     }
      // })
      notification.data.forEach((val) => {
        if (val?.is_read) {
          earlier.push(val);
        } else {
          latest.push(val);
        }
      });
      setNotifications({ earlier, latest });
    }
  }, [notification]);

  const markReadHandler = () => {
    markReadReq("GET", "/mark-read-notifications");
  };

  useEffect(() => {
    if (markReadRes) {
      notifiReq("GET", "/notifications");
      // setNotifications({ earlier: [], latest: [] })
    }
  }, [markReadRes]);

  const onSelectLanguage = (code) => dispatch(setLanguage(code));

  const onDropDownClick = () => {
    setIsActive(!isActive);
  };
  const onDropDownClickSp = () => {
    setIsActiveSp(!isActiveSp);
  };

  const toggleMenu = () => {
    setMenuState(!menuState);
  };

  const closeNotification = () => {
    let dropdowns = document.getElementsByClassName("dropdown-menu");
    dropdowns[0].classList.remove("show");
    dropdowns[1].classList.remove("show");
  };

  return (
    <div className="header">
      <Nav className={menuState ? "nav-main active" : "nav-main"}>
        <div className="content nav-bar">
          <NavMenu className="nav-menu">
            <NavLink
              to="/"
              className="logo-link header-logo-link"
              onClick={toggleMenu}
            >
              <img src="./images/logo.png" alt="" className="_pc"></img>
              <img
                src="./images/logo-black.png"
                alt=""
                className="_sp logo-sp"
              ></img>
            </NavLink>
            <NavLink to="/challenge" className="nav-link" onClick={toggleMenu}>
              {MultiLang["Challenges"][language]}
            </NavLink>
            {/* <NavLink
                            to="/about"
                            className="nav-link"
                            onClick={toggleMenu}
                        >
                            {MultiLang["About Us"][language]}
                        </NavLink> */}
            {/* <NavLink
                            to="/contact"
                            className="nav-link"
                            onClick={toggleMenu}
                        >
                            {MultiLang["Contact Us"][language]}
                        </NavLink> */}
            <NavLink
              to="/leaderboard"
              className="nav-link"
              onClick={toggleMenu}
            >
              {MultiLang["Leader Board"][language]}
            </NavLink>
          </NavMenu>

          <div className="mob_BalanceOption">
            <h3>
              {MultiLang["My Balance"][language]} :{" "}
              {Math.round(+user?.wallet_balance * 100) / 100}€
            </h3>
            <Link
              className={tab === "payment" ? "active" : ""}
              to="/setting?tab=payment"
              onClick={toggleMenu}
            >
              <img src="/images/profile/deposite-blue.png" alt="" />
              {MultiLang["Deposit"][language]}
            </Link>
            <Link
              className={tab === "withdrawal" ? "active" : ""}
              to="/setting?tab=withdrawal"
              onClick={toggleMenu}
            >
              <img src="/images/profile/withdraw-blue.png" alt="" />
              {MultiLang["Withdraw"][language]}
            </Link>
          </div>

          <div className="menu-container wallet_container">
            <div className="wallet_amount">
              <label>{MultiLang["My Balance"][language]} : </label>{" "}
              <span className="nav-link text-wh">
                {Math.round(+user?.wallet_balance * 100) / 100}€
              </span>
              <div className="header-dropdown">
                <img src="./images/icon-down.png" alt=""></img>
              </div>
            </div>
            <div className="arrow_drop">
              <div className="arrow-top"></div>
              <div className=" wallet_option menu">
                <nav>
                  <ul>
                    <li>
                      <Link
                        className={tab === "payment" ? "active" : ""}
                        to="/setting?tab=payment"
                      >
                      
                        <img
                          src="/images/profile/deposite-blue.png"
                          alt=""
                        ></img>
                        {MultiLang["Deposit"][language]}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={tab === "withdrawal" ? "active" : ""}
                        to="/setting?tab=withdrawal"
                      >
                        <img
                          src="/images/profile/withdraw-blue.png"
                          alt=""
                        ></img>
                        {MultiLang["Withdraw"][language]}
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          <div className="nav-menu">
            <span className="nav-link onlineStatus_menu" onClick={toggleMenu}>
              Online : {onlineUsers}
            </span>
          </div>
          {true && (
            <>
              <ReactFlagsSelect
                selected={language}
                onSelect={onSelectLanguage}
                countries={["GB", "FR", "DE"]}
                showOptionLabel={false}
                showSecondarySelectedLabel={false}
                showSelectedLabel={false}
                selectedSize={20}
                className="select-language"
                /*showSelectedLabel={showSelectedLabel}
                        optionsSize={optionsSize}
                        placeholder={placeholder}
                        searchable={searchable}
                        searchPlaceholder={searchPlaceholder}
                        alignOptionsToRight={alignOptionsToRight}
                        fullWidth={fullWidth}
                        disabled={disabled} */
              />
            </>
          )}

          <div className="nav_right_notification dropdown mr-10 onlyForDesktop">
            <a
              href="javascript:void(0);"
              className="BGLIghtBx rightMenuBtn newNotiMsg"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="Currentcolor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.5606 2.43163C16.167 2.35583 17.3974 2.99059 18.252 4.33593C20.6287 5.56161 22.0056 7.50497 22.3828 10.166C22.4023 11.5723 22.4219 12.9785 22.4414 14.3848C22.5229 14.9806 22.6987 15.547 22.9688 16.084C23.467 16.8458 23.9357 17.627 24.375 18.4277C24.9422 20.1927 24.4246 21.5307 22.8223 22.4414C17.6727 24.1746 12.5164 24.1941 7.35354 22.5C5.71983 21.801 5.08507 20.5803 5.44924 18.8379C5.89608 17.9052 6.40391 17.0068 6.97268 16.1426C7.30936 15.5621 7.50471 14.9371 7.55862 14.2676C7.57813 12.8808 7.5977 11.4942 7.61721 10.1074C8.01618 7.50134 9.37362 5.57749 11.6895 4.33593C12.3578 3.25998 13.3149 2.62521 14.5606 2.43163Z"
                  fill="Currentcolor"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.3965 24.9902C11.4209 24.9537 11.46 24.9342 11.5137 24.9316C13.8388 25.3222 16.1631 25.3222 18.4863 24.9316C18.5058 24.9512 18.5254 24.9707 18.5449 24.9902C17.8891 26.5827 16.6977 27.4226 14.9707 27.5098C13.2396 27.4171 12.0482 26.5773 11.3965 24.9902Z"
                  fill="Currentcolor"
                />
              </svg>
              {notifications.latest.length > 0 && (
                <span className="noticounter">
                  {notifications.latest.length}
                </span>
              )}
            </a>
            <div className="dropdown-menu dropdown-menu-end notificationBox">
              <div className="notiHeader_top">
                <div className="notiHeaderTopHeading">
                  <span className="">Notifications</span>
                </div>
                <div className="viewBtn">
                  <a href="#!" onClick={markReadHandler}>
                    Mark all as read
                  </a>
                  <a
                    onClick={closeNotification}
                    className="closeNotiBtn"
                    href="#!"
                    role="button"
                    data-bs-auto-close="false"
                  >
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.2041 6.79627L4.00037 4M6.79663 1.20374L3.99983 4M3.99983 4L1.2041 1.20374M4.00037 4L6.79663 6.79627"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <ul className="notificationListDrop">
                {notifications.latest.length > 0 ? (
                  <>
                    <li className="NotiTitle mt-0">
                      <div>New</div>
                    </li>
                    {notifications.latest.map((val, ind) => {
                      return seeAll ? (
                        <li key={ind}>
                          Link
                          <a
                            href="javascript:void(0);"
                            className={`dropdown-item ${
                              val?.is_read ? "marked-read" : ""
                            }`}
                          >
                            <div className="notiListCard">
                              <div className="notiListImg">
                                <img
                                  src={
                                    val?.profile_image
                                      ? val.profile_image
                                      : "/images/profile/noAvatar.png"
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="notiListContent">
                                <p>{val?.description}</p>
                                <small>
                                  <svg
                                    width="12"
                                    height="13"
                                    viewBox="0 0 12 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9 3H3C1.89543 3 1 3.89543 1 5V10C1 11.1046 1.89543 12 3 12H9C10.1046 12 11 11.1046 11 10V5C11 3.89543 10.1046 3 9 3Z"
                                      stroke="#A8A8A8"
                                      strokeWidth="1.2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M4 1V4.4M8 1V4.4M1 7H11"
                                      stroke="#A8A8A8"
                                      strokeWidth="1.2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  {moment(val?.created_at).format("DD/MM/YYYY")}
                                </small>
                              </div>
                            </div>
                          </a>
                        </li>
                      ) : (
                        <>
                          {ind < newNotifiCount ? (
                            <li key={ind}>
                              <a
                                href="javascript:void(0);"
                                className={`dropdown-item ${
                                  val?.is_read ? "marked-read" : ""
                                }`}
                              >
                                <div className="notiListCard">
                                  <div className="notiListImg">
                                    <img
                                      src={
                                        val?.profile_image
                                          ? val.profile_image
                                          : "/images/profile/noAvatar.png"
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="notiListContent">
                                    <p>{val?.description}</p>
                                    <small>
                                      <svg
                                        width="12"
                                        height="13"
                                        viewBox="0 0 12 13"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M9 3H3C1.89543 3 1 3.89543 1 5V10C1 11.1046 1.89543 12 3 12H9C10.1046 12 11 11.1046 11 10V5C11 3.89543 10.1046 3 9 3Z"
                                          stroke="#A8A8A8"
                                          strokeWidth="1.2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M4 1V4.4M8 1V4.4M1 7H11"
                                          stroke="#A8A8A8"
                                          strokeWidth="1.2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                      {moment(val?.created_at).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </small>
                                  </div>
                                </div>
                              </a>
                            </li>
                          ) : null}
                        </>
                      );
                    })}
                  </>
                ) : (
                  <div className="emptyMsg">No new notification</div>
                )}
                {notifications.earlier.length > 0 && seeAll && (
                  <>
                    <li className="NotiTitle">
                      <div>EARLIER</div>
                    </li>
                    {notifications.earlier.map((val, ind) => {
                      return (
                        <li key={ind}>
                          <a
                            href="javascript:void(0);"
                            className={`dropdown-item ${
                              val?.is_read ? "marked-read" : ""
                            }`}
                          >
                            <div className="notiListCard">
                              <div className="notiListImg">
                                <img
                                  src={
                                    val?.profile_image
                                      ? val.profile_image
                                      : "/images/profile/noAvatar.png"
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="notiListContent">
                                <p>{val?.description}</p>
                                <small>
                                  <svg
                                    width="12"
                                    height="13"
                                    viewBox="0 0 12 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9 3H3C1.89543 3 1 3.89543 1 5V10C1 11.1046 1.89543 12 3 12H9C10.1046 12 11 11.1046 11 10V5C11 3.89543 10.1046 3 9 3Z"
                                      stroke="#A8A8A8"
                                      strokeWidth="1.2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M4 1V4.4M8 1V4.4M1 7H11"
                                      stroke="#A8A8A8"
                                      strokeWidth="1.2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  {moment(val?.created_at).format("DD/MM/YYYY")}
                                </small>
                              </div>
                            </div>
                          </a>
                        </li>
                      );
                    })}
                  </>
                )}
              </ul>
              {notifications.earlier.length > 0 ||
                (notifications.latest.length > newNotifiCount && (
                  <span
                    onClick={() => {
                      setSeeAll(!seeAll);
                    }}
                    className="seeAllBtn"
                  >
                    See {!seeAll ? "All" : "New"}
                  </span>
                ))}
            </div>
          </div>
          <div
            ref={dropdownRef}
            className="header-user-profile _pc"
            onClick={onDropDownClick}
          >
            <div
              // className={`imgUser onlineStatus ${user?.is_kycVerified ? 'verifiedStatus' : ""}`}
              className={`imgUser onlineStatus`}
            >
              <img
                src={
                  user?.profile_image
                    ? user.profile_image
                    : "/images/profile/noAvatar.png"
                }
                alt=""
              />
            </div>
            <div className="header-dropdown">
              <img src="./images/icon-down.png" alt=""></img>
            </div>
            <div className="menu-container">
              <nav className={`menu ${isActive ? "active" : "inactive"}`}>
                <ul>
                  <li>
                    <Link to="/profile">{MultiLang["Profile"][language]}</Link>
                  </li>
                  <li>
                    <Link to="/setting?tab=profile">
                      {MultiLang["Account"][language]}
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">{MultiLang["About Us"][language]}</Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      {MultiLang["Contact Us"][language]}
                    </Link>
                  </li>
                  <li>
                    <a className="text-dark" onClick={logOut1}>
                      {MultiLang["Logout"][language]}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div className="header-logo-btns _sp">
            <a
              href={settings?.twitter}
              target="_blank"
              className="header-logo-link"
            >
              <img src="./images/social-icon/twitter.png" alt="" />
            </a>
            <a
              href={settings?.facebook}
              target="_blank"
              className="header-logo-link"
            >
              <img src="./images/social-icon/facebook.png" alt="" />
            </a>
            <a
              href={settings?.instagram}
              target="_blank"
              className="header-logo-link"
            >
              <img src="/images/social-icon/instagram.png" alt="" />
            </a>
            <a
              href={settings?.discord}
              target="_blank"
              className="header-logo-link"
            >
              <img src="./images/social-icon/youtube.png" alt="" />
            </a>
          </div>
        </div>
        <NavLink to="/" className="logo-link _sp">
          <img src="./images/logo.png" alt=""></img>
        </NavLink>
        <div
          ref={dropdownRefSp}
          className="header-user-profile header-user-dropdown _sp"
          onClick={onDropDownClickSp}
        >
          {/* <img src={user?.profile_image ? user.profile_image : "./images/profile/noAvatar.png"} alt="" /> */}
          <div
            // className={`imgUser onlineStatus ${user?.is_kycVerified ? 'verifiedStatus' : ""}`}
            className={`imgUser onlineStatus`}
          >
            <img
              src={
                user?.profile_image
                  ? user.profile_image
                  : "/images/profile/noAvatar.png"
              }
              alt=""
            />
          </div>
          <div className="menu-container">
            <nav className={`menu ${isActiveSp ? "active" : "inactive"}`}>
              <ul>
                <li>
                  <Link to="/profile">{MultiLang["Profile"][language]}</Link>
                </li>
                <li>
                  <Link to="/setting?tab=profile">
                    {MultiLang["Setting"][language]}
                  </Link>
                </li>
                <li>
                  <Link to="/about">{MultiLang["About Us"][language]}</Link>
                </li>
                <li>
                  <Link to="/contact">{MultiLang["Contact Us"][language]}</Link>
                </li>
                <li>
                  <a className="text-dark" onClick={logOut}>
                    {MultiLang["Logout"][language]}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="nav_right_notification dropdown mr-10 _sp">
          <a
            href="javascript:void(0);"
            className="BGLIghtBx rightMenuBtn newNotiMsg"
            data-bs-toggle="dropdown"
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5606 2.43163C16.167 2.35583 17.3974 2.99059 18.252 4.33593C20.6287 5.56161 22.0056 7.50497 22.3828 10.166C22.4023 11.5723 22.4219 12.9785 22.4414 14.3848C22.5229 14.9806 22.6987 15.547 22.9688 16.084C23.467 16.8458 23.9357 17.627 24.375 18.4277C24.9422 20.1927 24.4246 21.5307 22.8223 22.4414C17.6727 24.1746 12.5164 24.1941 7.35354 22.5C5.71983 21.801 5.08507 20.5803 5.44924 18.8379C5.89608 17.9052 6.40391 17.0068 6.97268 16.1426C7.30936 15.5621 7.50471 14.9371 7.55862 14.2676C7.57813 12.8808 7.5977 11.4942 7.61721 10.1074C8.01618 7.50134 9.37362 5.57749 11.6895 4.33593C12.3578 3.25998 13.3149 2.62521 14.5606 2.43163Z"
                fill="Currentcolor"
              />
              <pat
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.3965 24.9902C11.4209 24.9537 11.46 24.9342 11.5137 24.9316C13.8388 25.3222 16.1631 25.3222 18.4863 24.9316C18.5058 24.9512 18.5254 24.9707 18.5449 24.9902C17.8891 26.5827 16.6977 27.4226 14.9707 27.5098C13.2396 27.4171 12.0482 26.5773 11.3965 24.9902Z"
                fill="#9EAAB4"
              />
            </svg>

            {notifications.latest.length > 0 && (
              <span className="noticounter">{notifications.latest.length}</span>
            )}
          </a>
          <div className="dropdown-menu dropdown-menu-end notificationBox">
            <div className="notiHeader_top">
              <div className="notiHeaderTopHeading">
                <span className="">Notifications</span>
              </div>
              <div className="viewBtn">
                <small onClick={markReadHandler}>Mark all as read</small>
                <a className="closeNotiBtn" href="javascript:void(0);">
                  <svg
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.2041 6.79627L4.00037 4M6.79663 1.20374L3.99983 4M3.99983 4L1.2041 1.20374M4.00037 4L6.79663 6.79627"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <ul className="notificationListDrop">
              {notifications.latest.length > 0 ? (
                <>
                  <li className="NotiTitle mt-0">
                    <div>New</div>
                  </li>
                  {notifications.latest.map((val, ind) => {
                    return seeAll ? (
                      <li key={ind}>
                        <a
                          href="javascript:void(0);"
                          className={`dropdown-item ${
                            val?.is_read ? "marked-read" : ""
                          }`}
                        >
                          <div className="notiListCard">
                            <div className="notiListImg">
                              <img
                                src={
                                  val?.profile_image
                                    ? val.profile_image
                                    : "/images/profile/noAvatar.png"
                                }
                                alt=""
                              />
                            </div>
                            <div className="notiListContent">
                              <p>{val?.description}</p>
                              <small>
                                <svg
                                  width="12"
                                  height="13"
                                  viewBox="0 0 12 13"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9 3H3C1.89543 3 1 3.89543 1 5V10C1 11.1046 1.89543 12 3 12H9C10.1046 12 11 11.1046 11 10V5C11 3.89543 10.1046 3 9 3Z"
                                    stroke="#A8A8A8"
                                    strokeWidth="1.2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4 1V4.4M8 1V4.4M1 7H11"
                                    stroke="#A8A8A8"
                                    strokeWidth="1.2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                {moment(val?.created_at).format("DD/MM/YYYY")}
                              </small>
                            </div>
                          </div>
                        </a>
                      </li>
                    ) : (
                      <>
                        {ind < newNotifiCount ? (
                          <li key={ind}>
                            <a
                              href="javascript:void(0);"
                              className={`dropdown-item ${
                                val?.is_read ? "marked-read" : ""
                              }`}
                            >
                              <div className="notiListCard">
                                <div className="notiListImg">
                                  <img
                                    src={
                                      val?.profile_image
                                        ? val.profile_image
                                        : "/images/profile/noAvatar.png"
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="notiListContent">
                                  <p>{val?.description}</p>
                                  <small>
                                    <svg
                                      width="12"
                                      height="13"
                                      viewBox="0 0 12 13"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M9 3H3C1.89543 3 1 3.89543 1 5V10C1 11.1046 1.89543 12 3 12H9C10.1046 12 11 11.1046 11 10V5C11 3.89543 10.1046 3 9 3Z"
                                        stroke="#A8A8A8"
                                        strokeWidth="1.2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M4 1V4.4M8 1V4.4M1 7H11"
                                        stroke="#A8A8A8"
                                        strokeWidth="1.2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                    {moment(val?.created_at).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </small>
                                </div>
                              </div>
                            </a>
                          </li>
                        ) : null}
                      </>
                    );
                  })}
                </>
              ) : (
                <div className="emptyMsg">No new notification</div>
              )}
              {notifications.earlier.length > 0 && seeAll && (
                <>
                  <li className="NotiTitle">
                    <div>EARLIER</div>
                  </li>
                  {notifications.earlier.map((val, ind) => {
                    return (
                      <li key={ind}>
                        <a
                          href="javascript:void(0);"
                          className={`dropdown-item ${
                            val?.is_read ? "marked-read" : ""
                          }`}
                        >
                          <div className="notiListCard">
                            <div className="notiListImg">
                              <img
                                src={
                                  val?.profile_image
                                    ? val.profile_image
                                    : "/images/profile/noAvatar.png"
                                }
                                alt=""
                              />
                            </div>
                            <div className="notiListContent">
                              <p>{val?.description}</p>
                              <small>
                                <svg
                                  width="12"
                                  height="13"
                                  viewBox="0 0 12 13"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9 3H3C1.89543 3 1 3.89543 1 5V10C1 11.1046 1.89543 12 3 12H9C10.1046 12 11 11.1046 11 10V5C11 3.89543 10.1046 3 9 3Z"
                                    stroke="#A8A8A8"
                                    strokeWidth="1.2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4 1V4.4M8 1V4.4M1 7H11"
                                    stroke="#A8A8A8"
                                    strokeWidth="1.2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                {moment(val?.created_at).format("DD/MM/YYYY")}
                              </small>
                            </div>
                          </div>
                        </a>
                      </li>
                    );
                  })}
                </>
              )}
            </ul>
            {notifications.earlier.length > 0 ||
              (notifications.latest.length > newNotifiCount && (
                <span
                  onClick={() => {
                    setSeeAll(!seeAll);
                  }}
                  className="seeAllBtn"
                >
                  See {!seeAll ? "All" : "New"}
                </span>
              ))}
          </div>
        </div>
        <div className="menu-toggle-btn _sp" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </Nav>
    </div>
  );
}
