import { useEffect } from "react";
import React from "react";
import "./../css/community.css";

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="_community">
      <div className="community-sec1"></div>
      <div className="community-sec2">
        <div className="content">
          <div className="sec2-card">
            <div className="head">
              <h2>Governance</h2>
              What Governance actually means when you start to dive in deeper?
              And what does it mean within the context of Stronger?
            </div>
            <div>
              Outside of Stronger, and outside of cryptocurrency, we understand
              “Governance” to be a social structure that every participant
              agrees to follow. Everything we use is under some form of
              governance. Its core purpose is to meet the user or participant’s
              needs with available resources as efficiently as possible and
              achieve the long-term sustainability of the structure. It applies
              to any type of group, be it in the real world or the digital
              world.
            </div>
            <div>
              As an institution, organization, or network grows and evolves,
              governance becomes indispensable to respond to people’s evolving
              needs. Governance - as a system itself - also needs to keep
              evolving to be relevant to the current need.
            </div>
          </div>
          <div className="sec2-main">
            <div className="sec-text">
              <div className="com-title">The Old Way and the New Way</div>
              <div className="com-text">
                The old way? Centralized hubs where the company makes all the
                decisions. The Stronger way? Stronger is a decentralized
                network. <span className="com-text-space"></span>
                Most companies and governing bodies are centralized in nature,
                putting the leadership team at the center of the decision-making
                hub. A few people make decisions that are then pushed out to the
                larger company and stakeholders. Stronger believes this is
                antiquated, old-fashioned thinking.{" "}
                <span className="com-text-space"></span>
                Stronger embraces the decentralization that blockchain enables.
                A Decentralized Network means that everyone within it can become
                an owner and make decisions. This is an ever-evolving system
                that should strive to adapt and adjust to the user’s need to
                offer better user benefit and user control. Therefore the
                mechanism of how blockchain can adapt and stay relevant to
                changing times and requirements is called blockchain governance.{" "}
                <span className="com-text-space"></span>
                Stronger goal is to build a decentralized network where the
                community will have a powerful voice in governing and evolving
                Stronger
              </div>
            </div>
            <div>
              <img src="./images/community/sec2-1.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="community-sec3 section-padding-top">
        <div className="content">
          <div className="main-title black">Who is involved?</div>
          <div className="com-sec3-text">
            Anyone who use the stacking options can be involved in governance.
          </div>
          <div className="com-sec3-main">
            <div className="how-item">
              <img src="./images/community/sec3-1.png" alt="" />
              <div>Talent</div>
              <div>
                Freelancers who join Stronger and get STRG tokens when signing
                up and use stacking options
              </div>
            </div>
            <div className="how-item">
              <img src="./images/community/sec3-2.png" alt="" />
              <div>Core team members</div>
              <div>Founders and team members</div>
            </div>
            <div className="how-item">
              <img src="./images/community/sec3-3.png" alt="" />
              <div>Community/Investors</div>
              <div>
                Any individual who holds STRG tokens in stacking options
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="community-sec4 section-padding-top">
        <div className="content">
          <div className="sec2-main">
            <div className="sec-text">
              <div className="com-title">What’s the matter?</div>
              <div className="com-text">
                Governance at Stronger is a system and process that allows
                anyone from the community to propose an idea that can improve
                Stronger features and challenges.
                <span className="com-text-space"></span>
                It all starts with an idea that materializes into a “proposal”,
                which is a write-up of the idea in more details with some
                description and argumentation to make the case.
                <span className="com-text-space"></span>
                Members from the community then express their opinion on the
                proposal by voting on it with the STRG stacking option vote
                mode, which higher the stacking option choice is, more power
                they’ll get for voting. <span className="com-text-space"></span>
                If the results of the voting process for a proposal are
                favorable, the proposal will get executed in the next weeks by
                the Stronger team.
              </div>
            </div>
            <div className="com-img">
              <img src="./images/community/sec4-1.png" alt="" />
            </div>
          </div>
          <div className="sec2-main">
            <div className="com-img">
              <img src="./images/community/sec4-2.png" alt="" />
            </div>
            <div className="sec-text">
              <div className="com-title">Why should investors vote?</div>
              <div className="com-text">
                Participating in governance is becoming an active and continuous
                part of investing. Similar to owning traditional voting shares,
                owning crypto assets that you can vote with allows you to
                influence asset value both directly and indirectly. Investors
                should keep an eye on governance questions facing their assets
                so they will be able to vote for their own interests as needed.
                Protocols can be revised quickly, and even asset holders’ rights
                to vote can be amended.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
