import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import AppRouter from './router/Router';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import { BrowserRouter } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={configureStore()}>    
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>    
  </Provider>
);

reportWebVitals();