import React, { useState, useEffect, useRef } from 'react'
import useRequest from "../../hooks/useRequest";
import { useSelector, useDispatch } from "react-redux"
import { activateFeedback } from '../../redux/actions';
import { useNavigate, Link } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from 'yup';
import PreloaderInner from '../components/preloaderInner';
import { MultiLang } from '../MultiLang';

const BankDeposit = ({ setPaymentMethod }) => {
    const { language } = useSelector((state) => state.reducer);
    const { request, response } = useRequest()
    const { loading, user } = useSelector(state => state.Auth)
    const { settings } = useSelector(state => state.Site)
    const dispatch = useDispatch()


    const [isShow, setIsShow] = useState(false)
    const [fileErr, setFileErr] = useState(null)
    const [commId, setCommId] = useState(null)
    const [verificationModel, setVerificationModel] = useState(false)
    const formik = useFormik({
        initialValues: {
            amount: ""
        },
        // validationSchema: Yup.object({
        //     amount: Yup.number()
        //         .required('Please Enter Amount')
        //         .min(1, `Min Amount must be 1€`)
        //         .max(1000, 'Max Amount must be 1000€'),
        // }),
        onSubmit: values => {

            if (user?.verification_status !== 'approved' && settings?.deposite_before == 1) {
                setVerificationModel(true)
                return
            }

            if (!user.first_name) return;
            setCommId(
                `ID${Math.floor(Math.random() * 100000000) + 1} ${user.first_name}`
            );
            setIsShow(true);
        },

        validate: (values) => {
            let { amount } = values
            let errors = {};

            if (!amount) {
                errors.amount = "Please Enter Amount"
            }

            if (+amount < 1) {
                errors.amount = "Min Amount must be 1€"
            }
            if (+amount > 1000) {
                errors.amount = "Max Amount must be 1000€"
            }
            return errors;
        },
    });

    const handleDeposit = () => {
        console.log(commId)
        if (!commId) {
            setFileErr('Communication ID is required')
            return
        }
        let formdata = new FormData()
        formdata.append('amount', formik?.values?.amount)
        formdata.append('communication_id', commId)
        request("POST", "/bank-deposit", formdata)
    }

    useEffect(() => {
        if (response) {
            dispatch(activateFeedback({ type: response?.status, message: response?.message }))
            if (response?.status === 'success') {
                setIsShow(false)
                for (let key in formik.values) {
                    formik.setFieldValue(key, "")
                    formik.errors[key] = null
                    formik.touched[key] = false;
                    formik.resetForm()
                }
            }
        }
    }, [response])    

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="setting-input">
                    <div className="setting-input-con">
                        <div className="card_labelbx">
                            <label htmlFor="new_email">{MultiLang["Amount"][language]} (€)</label>
                        </div>
                        <input
                            disabled={isShow}
                            name="amount"
                            {...formik.getFieldProps('amount')}
                            type="number"
                        />
                        {formik.touched.amount && formik.errors.amount && <div className="input-error">{formik.errors.amount}</div>}
                    </div>
                </div>
                <div className="setting-bottom margin-btn">
                    <button
                        type='submit'
                        className="blue-btn btn setting-btn"
                        onClick={() => { console.log('clicked') }}
                    >
                        {MultiLang["Deposit"][language]}
                    </button>
                </div>
                <div className="setting-bottom">
                    <button
                        className="btn setting-btn"
                        onClick={() => {
                            setPaymentMethod(null);
                        }}
                    >
                        {MultiLang["Go Back"][language]}
                    </button>
                </div>
            </form>
            <div
                className={
                    isShow
                        ? "finish-modal setting-modal active"
                        : "finish-modal setting-modal"
                }
            >
                <div
                    className="modal-close"
                    onClick={() => {
                        setIsShow(false);
                    }}
                >
                    <img src="images/auth/auth-close.png" alt="" />
                </div>
                <div className="modal-header">{MultiLang["Payment"][language]}</div>
                <div className="modal-body">
                    <p>Bank name: {settings?.bank_name}</p>
                    <p>Bank address: {settings?.bank_address}</p>
                    <p>Beneficiary company name: {settings?.beneficiary_company_name}</p>
                    <p>Bank swift: {settings?.company_bank_swift}</p>
                    <p>Bank account: {settings?.company_bank_account}</p>
                    <p>Communication: {commId}</p>

                    <div className="setting-bottom">
                        <button type='button' className="blue-btn btn setting-btn" onClick={handleDeposit} >{MultiLang["Deposit"][language]}</button>
                    </div>
                    {loading && isShow && <PreloaderInner />}
                </div>
            </div>
            <div className={verificationModel === false ? "finish-modal finish_modalspace" : "finish-modal  finish_modalspace active"}>
                <div className="modal-close" onClick={() => { setVerificationModel(false) }}>
                    <img src="/images/auth/auth-close.png" alt="" />
                </div>
                <div className="finish-modal-main">
                    <div>{MultiLang["not-verified"][language]}</div>
                </div>
                <div >
                    <Link to="/setting?tab=kyc" onClick={() => { setVerificationModel(false) }} className="btn blue-btn">{MultiLang["Verify"][language]}</Link>
                </div>
            </div>
        </>
    )
}

export default BankDeposit
